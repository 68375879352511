import React, { useState } from 'react'

import { AngleDown } from '@rushable/icons'
import luckySpinGamePng from 'assets/img/lucky-spin-game.png'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import Field from 'components/Field'
import { Select } from 'components/Select'
import { TCouponItem } from 'redux/marketingFlyer'
import type { TCampaignItem, TCampaignCoupon } from 'types/campaign'

import { offerItems, dealOfferOptions } from '../helpers/constant'
import CouponSelectDrawer from './AttachOffer/CouponSelectDrawer'
import Line from './Line'

type TContentForm = {
  stepLoading: boolean
  prevStep: () => void
  createOrUpdate: () => void
  formData: TCampaignItem
  viewOnly?: boolean
  handleFormDataChange: (type: string, value: any) => void
}

export default function AttachOfferForm({
  stepLoading,
  prevStep,
  formData,
  createOrUpdate,
  viewOnly,
  handleFormDataChange,
}: TContentForm): JSX.Element {
  const { campaign_offer } = formData
  let type = ''
  let coupons: TCampaignCoupon[] = []
  const valid_days = campaign_offer?.valid_days
  if (campaign_offer) {
    type = campaign_offer?.type
    coupons = campaign_offer?.coupons || []
  }
  const [open, setOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const handleConfirm = (item: TCouponItem) => {
    if (item) {
      setOpenDrawer(false)
      handleFormDataChange('coupon_id', {
        id: item.coupon_id,
        code: item.coupon.code,
      })
    }
  }

  return (
    <div className='mt-8'>
      <div className='text-base text-ink font-bold pb-2'>
        Attach a promo offer?
      </div>
      <div className='flex'>
        <Select
          className='flex-1'
          options={offerItems}
          placeholder='Select A Promo Offer'
          value={type}
          disabled={viewOnly}
          onChange={newType => {
            handleFormDataChange('campaign_offer_type', newType)
          }}
        />
        {type === 'lucky_spin' ? (
          <Button
            color='tertiary'
            className='ml-4'
            onClick={() => setOpen(true)}
          >
            HOW IT WORKS ?
          </Button>
        ) : null}
      </div>
      {type === 'lucky_spin' ? (
        <>
          <div className='mt-4 p-6 border border-zinc rounded-lg'>
            <div className='flex mb-6'>
              <Select
                label='Deal offer 1'
                className='flex-1'
                options={dealOfferOptions}
                placeholder='Select'
                value={coupons[0].type || ''}
                disabled={viewOnly}
                onChange={nowType =>
                  handleFormDataChange('coupons[0]', {
                    newValue: nowType,
                    key: 'type',
                  })
                }
              />
              <Field
                align='right'
                label='Order minimum'
                containerClassName='flex-1 mx-6'
                name='text'
                placeholder='0.00'
                suffix='$'
                type='text'
                inputMode='money'
                value={coupons[0].threshold || ''}
                disabled={viewOnly}
                onChangeMoney={e =>
                  handleFormDataChange('coupons[0]', {
                    newValue: e,
                    key: 'threshold',
                  })
                }
              />
              {coupons[0].type === 'flat' || !coupons[0].type ? (
                <Field
                  align='right'
                  label='Amount'
                  name='text'
                  containerClassName='flex-1'
                  placeholder='0.00'
                  suffix='$'
                  type='text'
                  inputMode='money'
                  value={coupons[0].amount_off || ''}
                  disabled={viewOnly}
                  onChangeMoney={e =>
                    handleFormDataChange('coupons[0]', {
                      newValue: e,
                      key: 'amount_off',
                    })
                  }
                />
              ) : null}
              {coupons[0].type === 'free_item' && (
                <Field
                  label={
                    <>
                      <span>Description</span>
                      <span className='text-silver font-normal'>{` (${
                        coupons[0]?.description?.length || 0
                      }/25 characters)`}</span>
                    </>
                  }
                  containerClassName='flex-1'
                  name='description text'
                  type='text'
                  value={coupons[0].description || ''}
                  disabled={viewOnly}
                  onChange={e => {
                    if (e.target.value.length <= 25) {
                      handleFormDataChange('coupons[0]', {
                        newValue: e.target.value,
                        key: 'description',
                      })
                    }
                  }}
                />
              )}
              {coupons[0].type === 'percentage' && (
                <Field
                  label='Percentage'
                  containerClassName='flex-1'
                  name='percentage text'
                  align='right'
                  append='%'
                  type='text'
                  value={coupons[0].percentage_off || ''}
                  disabled={viewOnly}
                  onChange={e =>
                    handleFormDataChange('coupons[0]', {
                      newValue: e.target.value,
                      key: 'percentage_off',
                    })
                  }
                />
              )}
            </div>
            <div className='flex mb-6'>
              <Select
                label='Deal offer 2'
                className='flex-1'
                options={dealOfferOptions}
                placeholder='Select'
                value={coupons[1].type || ''}
                disabled={viewOnly}
                onChange={nowType =>
                  handleFormDataChange('coupons[1]', {
                    newValue: nowType,
                    key: 'type',
                  })
                }
              />
              <Field
                align='right'
                label='Order minimum'
                containerClassName='flex-1 mx-6'
                name='text'
                placeholder='0.00'
                suffix='$'
                type='text'
                inputMode='money'
                value={coupons[1].threshold || ''}
                disabled={viewOnly}
                onChangeMoney={e =>
                  handleFormDataChange('coupons[1]', {
                    newValue: e,
                    key: 'threshold',
                  })
                }
              />
              {coupons[1].type === 'flat' || !coupons[1].type ? (
                <Field
                  align='right'
                  label='Amount'
                  name='text'
                  containerClassName='flex-1'
                  placeholder='0.00'
                  suffix='$'
                  type='text'
                  inputMode='money'
                  value={coupons[1].amount_off || ''}
                  disabled={viewOnly}
                  onChangeMoney={e =>
                    handleFormDataChange('coupons[1]', {
                      newValue: e,
                      key: 'amount_off',
                    })
                  }
                />
              ) : null}
              {coupons[1].type === 'free_item' && (
                <Field
                  label={
                    <>
                      <span>Description</span>
                      <span className='text-silver font-normal'>{` (${
                        coupons[1]?.description?.length || 0
                      }/25 characters)`}</span>
                    </>
                  }
                  containerClassName='flex-1'
                  name='description text'
                  type='text'
                  value={coupons[1].description || ''}
                  disabled={viewOnly}
                  onChange={e => {
                    if (e.target.value.length <= 25) {
                      handleFormDataChange('coupons[1]', {
                        newValue: e.target.value,
                        key: 'description',
                      })
                    }
                  }}
                />
              )}
              {coupons[1].type === 'percentage' && (
                <Field
                  label='Percentage'
                  containerClassName='flex-1'
                  name='percentage text'
                  align='right'
                  append='%'
                  type='text'
                  // appendClassName='text-silver'
                  value={coupons[1].percentage_off || ''}
                  disabled={viewOnly}
                  onChange={e =>
                    handleFormDataChange('coupons[1]', {
                      newValue: e.target.value,
                      key: 'percentage_off',
                    })
                  }
                />
              )}
            </div>
          </div>
          <div className='mt-4 px-6 py-4 border border-zinc rounded-lg flex items-center'>
            Allow customers to redeem the promo offer within
            <Field
              name='count'
              align='right'
              containerClassName='mx-2 w-[64px]'
              type='text'
              value={valid_days}
              disabled={viewOnly}
              onChange={e => {
                handleFormDataChange('valid_days', e.target.value)
              }}
            />
            days
          </div>
        </>
      ) : null}

      {type === 'coupon_code' ? (
        <div className='mt-6 p-6 border border-dark-16 rounded-lg'>
          <div className='block w-full'>
            <div className='flex justify-between'>
              <span className='text-xs font-bold text-silver'>Coupon code</span>
            </div>
            <div
              className='block relative cursor-pointer'
              onClick={() => setOpenDrawer(true)}
            >
              <div className='transition text-base outline-none h-[46px] relative flex flex-1 w-full hover:outline-none border border-solid mt-2 border-slate rounded-b-lg rounded-t-lg text-ink bg-field ring-slate pl-4 pr-9'>
                {coupons[0]?.code ? (
                  <span className='text-ink py-3'>{coupons[0]?.code}</span>
                ) : (
                  <span className='text-dark-16 py-3'>Select</span>
                )}
                <div className='absolute top-0 right-4 h-10 flex flex-1 items-center transition duration-75'>
                  <AngleDown size={16} className='text-silver' />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <Line className='my-6' />
      <div className='flex justify-between'>
        <Button color='secondary' className='mr-3 w-full' onClick={prevStep}>
          PREVIOUS STEP
        </Button>
        <Button
          color='primary'
          className='w-full'
          onClick={() => createOrUpdate()}
          loading={stepLoading}
        >
          NEXT STEP
        </Button>
      </div>
      <Drawer
        open={open}
        toggle={() => setOpen(!open)}
        rightFooter={<Button onClick={() => setOpen(!open)}>CLOSE</Button>}
      >
        <div className='flex flex-col items-center'>
          <img src={luckySpinGamePng} width='320' className='my-6' />
          <div className='text-base font-bold text-ink'>
            How “Lucky Spin” works
          </div>
          <div className='w-[420px] mt-6'>
            <div className='flex text-base text-ink leading-6'>
              <div className='mr-1'>1.</div>
              <div>
                Setup 2 deal offers to be included in the campaign you are
                currently configuring.
              </div>
            </div>
            <div className='flex text-base text-ink leading-6'>
              <div className='mr-1'>2.</div>
              <div>
                Your customer will receive an email/text with this lucky spin
                game included.
              </div>
            </div>
            <div className='flex text-base text-ink leading-6'>
              <div className='mr-1'>3.</div>
              <div>
                Despite it is a “luck” game, the winning ratio for your customer
                is set to be 100%.
              </div>
            </div>
            <div className='flex text-base text-ink leading-6'>
              <div className='mr-1'>4.</div>
              <div>
                Once your customer won the deal, they can redeem it within the
                time period you set for them.
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <CouponSelectDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        couponId={coupons?.[0]?.id}
        handleConfirm={handleConfirm}
      />
    </div>
  )
}
