import { useState, useEffect } from 'react'

import { SolidUser, SolidFile, SolidStore } from '@rushable/icons'
import cn from 'classnames'
import NavItem, { NavDropdown } from 'components/NavItem'
import {
  marketingRoutes,
  performanceRoutes,
  restaurantRoutes,
} from 'routes/locationRoutes'

const iconArgs = { size: 16, className: 'text-light-64' }
const locationRouteGroups = [
  {
    text: 'Performance',
    routes: performanceRoutes,
    icon: <SolidFile {...iconArgs} />,
  },
  {
    text: 'Restaurant',
    routes: restaurantRoutes,
    icon: <SolidStore {...iconArgs} />,
  },
  {
    text: 'Marketing',
    routes: marketingRoutes,
    icon: <SolidUser {...iconArgs} />,
  },
]

export type TLocationNavItemsProp = {
  routeToPath: (v: string) => void
  activePath?: string
  activeGroup?: string
  permission: Record<string, any>
}
export default function LocationNavItems({
  routeToPath,
  activePath = 'nullablepath',
  activeGroup,
  permission,
}: TLocationNavItemsProp): JSX.Element {
  const [activeNav, setActiveNav] = useState<string>('')
  useEffect(() => {
    setActiveNav(activeGroup || '')
  }, [activeGroup])
  return (
    <div
      className={cn(
        'pt-40 pb-36 h-full overflow-y-auto overscroll-none scrollbar-hide bg-darkblue select-none',
      )}
    >
      {locationRouteGroups.map((v, i) => (
        <NavDropdown
          key={i}
          suffix={v.icon}
          text={v.text}
          open={activeNav === v.text}
          onToggle={(open, text) => {
            setActiveNav(text)
          }}
        >
          <div>
            {v.routes.map((item, index) => (
              <NavItem
                key={index}
                label={item.label}
                value={item.path}
                disabled={permission?.[item?.permissionId || '']?.allowed === 0}
                lv={2}
                onClick={() => routeToPath(item.path)}
                active={activePath.includes(item.path)}
              />
            ))}
          </div>
        </NavDropdown>
      ))}
    </div>
  )
}
