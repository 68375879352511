import React, { useState, useEffect } from 'react'

import cn from 'classnames'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import CalendarField from 'components/CalendarField'
import Label from 'components/Label'
import { Spin } from 'components/Loading'
import { Select, TimeSelect } from 'components/Select'
import TextArea from 'components/TextArea'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import {
  getLocationAnnouncement,
  deleteAnnouncement,
  createAnnouncement,
  editAnnouncement,
} from 'redux/announcement'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { TBaseAnnouncementConfig } from 'types/announcement'

import AnnouncementIcon from './components/AnnouncementIcon'
import { ENDING_DATE_STYLE_LIST } from './helpers/constant'

export default function AnnouncementPage(): JSX.Element {
  const [hasAnnouncement, setHasAnnouncement] = useState(false)
  const { locationId } = useParams<TParamTypes>()
  const {
    location_announcement_config,
    updated,
    createAnnouncementRequest,
    editAnnouncementRequest,
    deleteAnnouncementRequest,
    getAnnouncementRequest,
  } = useAppSelector(state => state.announcement)

  const dispatch = useAppDispatch()

  const [announcementConfig, setAnnouncementConfig] =
    useState<TBaseAnnouncementConfig>({
      id: 0,
      begin_date: undefined,
      begin_time: null,
      end_date: undefined,
      end_time: null,
      description: '',
      type: '',
    })

  useEffect(() => {
    if (location_announcement_config) {
      setHasAnnouncement(true)
      const { begin_time, end_time, type } = location_announcement_config
      setAnnouncementConfig({
        ...location_announcement_config,
        begin_time: begin_time?.split(' ')[1],
        begin_date: begin_time ? new Date(String(begin_time)) : new Date(),
        end_time: end_time?.split(' ')[1],
        end_date: end_time ? new Date(String(end_time)) : new Date(),
        type,
      })
    } else {
      setHasAnnouncement(false)
      setAnnouncementConfig({
        id: 0,
        begin_date: undefined,
        begin_time: null,
        end_date: undefined,
        end_time: null,
        description: '',
        type: '',
      })
    }
  }, [location_announcement_config])

  const onChange = async (name: string, value: any) => {
    setAnnouncementConfig({ ...announcementConfig, [name]: value })
  }

  const formatDate = (value: any) => {
    if (!value) {
      return ''
    }
    const date = new Date(value)
    return `${
      date.toDateString().split(' ')[1]
    } ${date.getDate()},${date.getFullYear()}`
  }

  useEffect(() => {
    dispatch(getLocationAnnouncement({ location_id: Number(locationId) }))
  }, [])

  const { id, begin_date, begin_time, end_date, end_time, type, description } =
    announcementConfig

  const create = async () => {
    const params = {
      begin_time: `${moment(begin_date).format('YYYY-MM-DD')} ${
        begin_time || ''
      }`,
      end_time:
        type === 'fixed_day'
          ? `${moment(end_date).format('YYYY-MM-DD')} ${end_time || ''}`
          : null,
      type,
      description,
    }
    dispatch(createAnnouncement({ params, locationId }))
  }

  const edit = () => {
    const params = {
      id,
      begin_time: `${moment(begin_date).format('YYYY-MM-DD')} ${begin_time}`,
      end_time:
        type === 'fixed_day'
          ? `${moment(end_date).format('YYYY-MM-DD')} ${end_time}`
          : null,
      type,
      description,
    }
    dispatch(editAnnouncement({ params, locationId }))
  }

  const remove = () => {
    dispatch(deleteAnnouncement({ id: Number(id), locationId }))
  }

  return (
    <AdminPage>
      <AdminPageTitle title='Announcement' />
      <Spin
        spining={getAnnouncementRequest}
        className='rounded-b-lg overflow-hidden z-10'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div className='flex flex-col items-center mx-auto pb-6'>
          {hasAnnouncement ? (
            <div className='w-[420px] mt-8'>
              <p className='text-xs text-silver font-bold'>Starting date</p>
              <div className='relative flex space-x-4 rounded-lg border border-solid border-zinc p-4 mt-2 mb-6'>
                <CalendarField
                  className='flex-1'
                  value={formatDate(begin_date)}
                  date={begin_date}
                  onChange={value => onChange('begin_date', value)}
                  placeholder='Select'
                  calendarRestProps={{ minDate: new Date() }}
                />
                <TimeSelect
                  className='flex-1'
                  placeholder='Select option'
                  value={begin_time || ''}
                  onChange={value => onChange('begin_time', value)}
                />
              </div>
              <p className='text-xs text-silver font-bold'>Ending date</p>
              <div className='flex flex-col items-center rounded-lg border border-solid border-zinc p-4 mt-2 mb-6'>
                <Select
                  className={cn(type === 'fixed_day' ? 'mb-4' : '')}
                  options={ENDING_DATE_STYLE_LIST}
                  placeholder='Select'
                  value={type}
                  onChange={value => onChange('type', value)}
                />
                {type === 'fixed_day' && (
                  <div className='relative flex w-full space-x-4'>
                    <CalendarField
                      className='flex-1'
                      value={formatDate(end_date)}
                      date={end_date}
                      onChange={value => onChange('end_date', value)}
                      placeholder='Select'
                      calendarRestProps={{ minDate: new Date() }}
                    />
                    <TimeSelect
                      className='flex-1'
                      placeholder='Select'
                      value={end_time || ''}
                      onChange={value => onChange('end_time', value)}
                    />
                  </div>
                )}
              </div>
              <div className='flex justify-between'>
                <Label>Description</Label>
                <Label
                  className='font-normal'
                  children={<>({description?.length}/300 characters)</>}
                />
              </div>
              <TextArea
                containerClass='flex mt-2'
                name='description'
                maxLength={300}
                value={description || ''}
                placeholder='Input your announcement'
                onChange={e => onChange('description', e.target.value)}
              />
              {!updated ? (
                <Button
                  className='mt-6 w-full'
                  color='primary'
                  onClick={() => create()}
                  loading={createAnnouncementRequest}
                >
                  PUBLISH NOW
                </Button>
              ) : (
                <div className='mt-6 flex space-x-6'>
                  <Button
                    color='tertiary'
                    className='w-full'
                    onClick={() => remove()}
                    loading={deleteAnnouncementRequest}
                  >
                    REMOVE
                  </Button>
                  <Button
                    className='w-full'
                    color='primary'
                    onClick={() => edit()}
                    loading={editAnnouncementRequest}
                  >
                    UPDATE
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <>
              <p className='text-center text-base text-lead mt-[120px] mb-8'>
                You don’t have any announcement yet...
              </p>
              <AnnouncementIcon />
              <Button
                className='mt-6'
                color='primary'
                onClick={() => setHasAnnouncement(true)}
              >
                New Announcement
              </Button>
            </>
          )}
        </div>
      </Spin>
    </AdminPage>
  )
}
