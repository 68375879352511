import React, { useState, useEffect } from 'react'

import { AngleRight, Loading } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import SourceIconRender from 'components/PayIcon/PayIconRender'
import useDebounce from 'hooks/useDebounce'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import type { TCampaignItem } from 'types/campaign'

import { dealOfferOptions } from '../helpers/constant'
import type { TAddOpenDataState } from '../helpers/types'
import Line from './Line'

type TFinalReviewFormProps = {
  stepLoading: boolean
  prevStep: () => void
  createOrUpdate: (extraParam: Record<string, any>) => void
  formData: TCampaignItem
  addOpenData: TAddOpenDataState
  handleFormDataChange: (type: string, value: any) => void
  viewOnly?: boolean
}

export default function FinalReviewForm({
  stepLoading,
  prevStep,
  createOrUpdate,
  formData,
  addOpenData,
  handleFormDataChange,
  viewOnly,
}: TFinalReviewFormProps): JSX.Element {
  const history = useHistory()
  const { brandId, locationId } = useParams<TParamTypes>()
  const { selectedBrand } = useAppSelector(state => state.brand)
  const { selectedLocation } = useAppSelector(state => state.location)
  const card_last4 = selectedLocation?.card_last4
  const card_name = selectedLocation?.card_brand
  const locations = selectedBrand?.locations || []
  const { name, status, type } = formData

  const [locationListOpen, setLocationListOpen] = useState(false)
  const [emailShow, setEmailShow] = useState(false)
  const [locationModal, setLocationModal] = useState({
    open: false,
    id: 0,
    name: '',
  })
  const [removeId, setRemoveId] = useState(0)
  const [paymentShow, setPaymentShow] = useState(false)

  useEffect(() => {
    if (formData.status === 'scheduled') {
      setLocationListOpen(true)
    }
  }, [formData.status])

  const handleEmailShow = useDebounce((type: boolean) => {
    setEmailShow(type)
  }, 0)

  const openLocationModal = (id: number, name: string) => {
    if (formData.campaign_locations.find(item => item.location_id === id)) {
      return
    }
    setLocationModal({
      open: true,
      id,
      name,
    })
  }
  const closeLocationModal = () => {
    setLocationModal({
      ...locationModal,
      open: false,
    })
    setTimeout(() => {
      setLocationModal({
        open: false,
        id: 0,
        name: '',
      })
    }, 300)
  }
  const addLocations = async () => {
    if (locationModal.name === 'all') {
      const allLocations: {
        location_id: number
        is_initiator: number
      }[] = []
      locations.forEach(item => {
        if (item.access) {
          allLocations.push({
            location_id: item.id,
            is_initiator: 0,
          })
        }
      })
      await createOrUpdate({ campaign_locations: allLocations })
    } else if (locationModal.id) {
      const locations = [
        ...formData.campaign_locations,
        { location_id: locationModal.id, is_initiator: 0 },
      ]
      await createOrUpdate({ campaign_locations: locations })
    }
    closeLocationModal()
  }
  const removeLocation = async (id: number) => {
    if (
      formData.campaign_locations.find(
        item => item.id === id && item.location_id === Number(locationId),
      )
    ) {
      return
    }
    setRemoveId(id)
    const allLocations: {
      location_id: number
      is_initiator: number
    }[] = []
    formData.campaign_locations.forEach(item => {
      if (item.location_id !== id) {
        allLocations.push({
          location_id: item.location_id,
          is_initiator: item.is_initiator,
        })
      }
    })
    await createOrUpdate({ campaign_locations: allLocations })
    setRemoveId(0)
  }
  const handlePayment = async () => {
    await createOrUpdate({ status: 'scheduled' })
    setPaymentShow(false)
  }

  const goPayment = () => {
    history.push(
      `/brand/${brandId}/location/${locationId}/billing-and-invoice/billing`,
    )
  }
  // render lucky spin tooltips
  const renderPromotion = (coupon: any) => {
    return (
      <>
        {coupon.type === 'flat' ? (
          <div>
            <div className='text-silver text-xxs font-medium pb-1'>Amount</div>
            <div className='text-ink text-xs'>{`$${coupon.amount_off}`}</div>
          </div>
        ) : null}
        {coupon.type === 'percentage' ? (
          <div>
            <div className='text-silver text-xxs font-medium pb-1'>
              Percentage
            </div>
            <div className='text-ink text-xs'>{`${coupon.percentage_off}%`}</div>
          </div>
        ) : null}
        {coupon.type === 'free_item' ? (
          <div>
            <div className='text-silver text-xxs font-medium pb-1'>
              Description
            </div>
            <div className='text-ink text-xs'>{`${coupon.description}`}</div>
          </div>
        ) : null}
      </>
    )
  }
  const firstCoupon: any = formData?.campaign_offer?.coupons?.[0] || {}
  const secondCoupon: any = formData?.campaign_offer?.coupons?.[1] || {}
  return (
    <div>
      <div
        className={cn('mt-8', {
          'pointer-events-none':
            formData.type === 'one_off' && formData.status === 'scheduled',
        })}
      >
        <div
          className='flex justify-between items-center cursor-pointer'
          onClick={() => setLocationListOpen(!locationListOpen)}
        >
          <div>
            <div className='text-base text-ink font-bold mb-2'>
              Invite other location to participate?
            </div>
            <div className='text-xs text-silver'>
              You can add other location(s) to join this campaign •{' '}
              <span
                className='font-bold text-blue cursor-pointer'
                onClick={e => {
                  e.stopPropagation()
                  openLocationModal(0, 'all')
                }}
              >
                ADD ALL
              </span>
            </div>
          </div>
          <div>
            <AngleRight
              className={cn(
                'text-ink transition duration-150 ease-in-out',
                locationListOpen ? 'transform rotate-90' : '',
              )}
              size={16}
            />
          </div>
        </div>
        {locationListOpen && (
          <div className='mt-6 flex flex-wrap'>
            {locations.map(location => {
              const { name, address, access, id } = location
              return (
                <div
                  key={id}
                  className={cn(
                    'w-[224px] flex justify-between option-box-class relative mb-4 mr-4',
                    formData.campaign_locations.find(
                      item => item.location_id === id,
                    ) && removeId !== id
                      ? 'selected'
                      : '',
                  )}
                  onClick={() => {
                    if (!removeId) {
                      if (
                        formData.campaign_locations.find(
                          item => item.location_id === id,
                        )
                      ) {
                        removeLocation(id)
                      } else {
                        openLocationModal(id, name)
                      }
                    }
                  }}
                >
                  <div>
                    <div
                      className={cn(
                        'text-xs  font-bold pb-3 pt-[2px]',
                        access ? 'text-ink' : 'text-silver',
                      )}
                    >
                      {name}
                    </div>
                    {address && (
                      <div className='text-silver text-xs'>
                        {`${address.line_1 ? address.line_1 + ' ' : ''}${
                          address.line_2 ? address.line_2 + ' ' : ''
                        }${address.city ? address.city + ' ' : ''}${
                          address.state ? ',' + address.state + ' ' : ''
                        }${address.zipcode ? address.zipcode : ''}`}
                      </div>
                    )}
                  </div>
                  <div className='rounded bg-field t text-xl origin-top-right scale-50 px-2 py-1 absolute right-3'>
                    {access ? (
                      formData.campaign_locations.find(
                        item => item.location_id === id,
                      ) ? (
                        removeId === id ? (
                          <Loading
                            className='text-dark-32 mx-2 my-1 relative top-0.5'
                            size={20}
                          />
                        ) : null
                      ) : (
                        <span>Add</span>
                      )
                    ) : (
                      <span className='text-silver'>No Access</span>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
      <Line className={cn('mb-6', locationListOpen ? 'mt-2' : 'mt-6')} />
      <div className='mb-2 text-base text-ink font-bold'>Overview</div>
      <div className='border border-zinc rounded-lg p-6'>
        <div className='flex justify-between'>
          <div>
            <div className='text-xs text-silver font-bold pb-2'>
              Campaign name
            </div>
            <div className='text-base text-ink'>{name}</div>
          </div>
          {formData.type === 'one_off' ? (
            <div>
              <div className='text-xs text-silver font-bold pb-2'>
                Sending Schedule
              </div>
              <div className='text-base text-ink'>
                {moment(formData.trigger_of_date_time).format(
                  'h:mma • MM/DD/YYYY',
                )}
              </div>
            </div>
          ) : null}
          <div
            className='mr-20'
            onMouseOver={() => handleEmailShow(true)}
            onMouseOut={() => handleEmailShow(false)}
          >
            <div className='text-xs text-silver font-bold pb-2'>Promotion</div>
            <div className='flex items-center text-base'>
              {formData?.campaign_offer?.type === 'lucky_spin' ? (
                <span className='text-blue font-bold'>Lucky Spin</span>
              ) : (
                <span className='text-ink'>all</span>
              )}
              <div className='relative'>
                {emailShow &&
                formData?.campaign_offer?.type === 'lucky_spin' ? (
                  <div className='pt-2 absolute right-[-50px] top-[-8px] z-10'>
                    <div
                      className='w-[382px] p-4 border border-zinc rounded bg-white'
                      style={{
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
                      }}
                    >
                      <div className='flex'>
                        <div className='w-[120px]'>
                          <div className='text-lead text-xxs font-medium pb-1'>
                            Promo offer 1
                          </div>
                          <div className='text-ink text-xs'>
                            {
                              dealOfferOptions.find(
                                v => v.value === firstCoupon.type,
                              )?.label
                            }
                          </div>
                        </div>
                        <div className='w-[100px]'>
                          <div className='text-silver text-xxs font-medium pb-1'>
                            Order minimum
                          </div>
                          <div className='text-ink text-xs'>{`$${firstCoupon.threshold}`}</div>
                        </div>
                        <div className='flex-1'>
                          {renderPromotion(firstCoupon)}
                        </div>
                      </div>
                      <div className='flex mt-4'>
                        <div className='w-[120px]'>
                          <div className='text-lead text-xxs font-medium pb-1'>
                            Promo offer 2
                          </div>
                          <div className='text-ink text-xs'>
                            {
                              dealOfferOptions.find(
                                v => v.value === secondCoupon.type,
                              )?.label
                            }
                          </div>
                        </div>
                        <div className='w-[100px]'>
                          <div className='text-silver text-xxs font-medium pb-1'>
                            Order minimum
                          </div>
                          <div className='text-ink text-xs'>{`$${secondCoupon.threshold}`}</div>
                        </div>
                        <div className='flex-1'>
                          {renderPromotion(secondCoupon)}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Line className='my-4' />
        <div className='flex justify-between'>
          <div>
            <div className='text-xs text-silver font-bold pb-2'>Audience</div>
            <div className='text-base text-ink'>
              {formData?.campaign_audience?.conditional_type ===
              'lifetime_order_count' ? (
                <>
                  Subscribers that have placed{' '}
                  <span className='underline'>
                    {formData.campaign_audience.lifetime_order_count_operator}
                  </span>{' '}
                  than{' '}
                  <span className='underline'>
                    {formData.campaign_audience.lifetime_order_count}
                  </span>{' '}
                  order in lifetime.
                </>
              ) : formData?.campaign_audience?.conditional_type ===
                'recent_order_within' ? (
                <>
                  Subscribers that have Ordered within{' '}
                  <span className='underline'>
                    {formData.campaign_audience.recent_order_within}
                  </span>{' '}
                  <span className='underline'>
                    {formData.campaign_audience.recent_order_within_unit}
                  </span>{' '}
                  recently.
                </>
              ) : (
                ' All subscribers'
              )}
            </div>
          </div>
          <div className='mr-6'>
            <div className='text-xs text-silver font-bold pb-2'>
              Total reach
            </div>
            <div className='text-base text-ink'>
              {formData?.campaign_performance?.estimated_audience} Customers
            </div>
          </div>
        </div>
        <div className='mt-4 mb-2 bg-field rounded-lg p-4'>
          <div className='flex justify-between pb-2 relative'>
            <div className='text-xs text-ink font-bold'>
              Estimate email reach
            </div>
            <div className='text-xs text-lead'>
              {formData?.campaign_performance?.estimated_email_audience}/
              {formData?.campaign_performance?.estimated_audience} customers •{' '}
              {formData?.campaign_performance?.estimated_email_token} token
            </div>
            <div className='rounded-sm bg-zinc h-[2px] absolute w-full bottom-0' />
            <div
              className='rounded-sm bg-blue h-[2px] absolute bottom-0'
              style={{
                width: `${
                  ((formData?.campaign_performance?.estimated_email_audience ||
                    0) /
                    (formData?.campaign_performance?.estimated_audience || 0)) *
                  100
                }%`,
              }}
            />
          </div>
          <div className='flex justify-between pb-2 pt-4 relative'>
            <div className='text-xs text-ink font-bold'>
              Estimate text reach
            </div>
            <div className='text-xs text-lead'>
              {formData?.campaign_performance?.estimated_sms_audience}/
              {formData?.campaign_performance?.estimated_audience} customers •{' '}
              {formData?.campaign_performance?.estimated_sms_token} token
            </div>
            <div className='rounded-sm bg-zinc h-[2px] absolute w-full bottom-0' />
            <div
              className='rounded-sm bg-blue h-[2px] absolute bottom-0'
              style={{
                width: `${
                  ((formData?.campaign_performance?.estimated_sms_audience ||
                    0) /
                    (formData?.campaign_performance?.estimated_audience || 0)) *
                  100
                }%`,
              }}
            />
          </div>
        </div>
        <div className='text-orange text-xs'>
          Estimate as of today and will vary upon your actual sending schedule
        </div>
      </div>
      <Line className='my-6' />
      <div className='flex justify-between'>
        {type === 'one_off' && status === 'scheduled' ? (
          viewOnly ? (
            <Button
              color='primary'
              className='mr-3 w-full'
              loading={stepLoading}
              onClick={() =>
                handleFormDataChange('status', addOpenData?.item?.status)
              }
            >
              GO DETAILS
            </Button>
          ) : (
            <Button
              color='secondary'
              className='mr-3 w-full'
              loading={stepLoading}
              onClick={() =>
                createOrUpdate({ status: 'draft', _client_step: 1 })
              }
            >
              EDIT CAMPAIGN
            </Button>
          )
        ) : (
          <>
            <Button
              color='secondary'
              className='mr-3 w-full'
              onClick={prevStep}
            >
              PREVIOUS STEP
            </Button>
            <Button
              color='primary'
              className='w-full'
              onClick={() => setPaymentShow(true)}
            >
              SCHEDULE NOW
            </Button>
          </>
        )}
      </div>
      <ModalFull
        open={locationModal.open}
        title='Invite Other Location(s)'
        toggle={closeLocationModal}
        okText='CONFIRM'
        onCancel={closeLocationModal}
        onOk={addLocations}
        loading={stepLoading}
      >
        <div className='text-base text-ink leading-6 text-center'>
          In order to have{' '}
          {locationModal.name === 'all'
            ? 'all locations'
            : `“${locationModal.name}” location`}{' '}
          participate this campaign, an invitation will be sent. Once it is
          accepted, all subscribed customers from{' '}
          {locationModal.name === 'all'
            ? 'all locations'
            : `“${locationModal.name}” location`}{' '}
          will also get enrolled into this campaign, the system will
          automatically remove duplicated enrollment.
        </div>
      </ModalFull>
      <ModalFull
        open={paymentShow}
        title='Important Reminder'
        toggle={() => {
          setPaymentShow(!paymentShow)
        }}
        okText='PAYMENT IS CORRECT'
        onCancel={() => {
          setPaymentShow(false)
        }}
        onOk={handlePayment}
        loading={stepLoading}
      >
        <>
          <div className='text-base text-ink leading-6 text-center'>
            Please ensure your payment method is up to date, in order to
            successfully recharge your token balance when it is necessary.
          </div>
          <div className='flex justify-center items-center my-4'>
            {SourceIconRender({
              type: card_name,
              iconParams: {
                size: 24,
                className: 'mr-2',
              },
            })}
            <span className='text-base text-ink font-bold'>
              ••• {card_last4}
            </span>
          </div>
          <div className='flex justify-center'>
            <div
              className='w-[135px] h-[28px] border-2 border-zinc rounded flex justify-center items-center text-xs font-bold text-ink cursor-pointer'
              onClick={goPayment}
            >
              UPDATE PAYMENT
            </div>
          </div>
        </>
      </ModalFull>
    </div>
  )
}
