import React, { useState, useEffect } from 'react'

import { SolidCalendar } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getCoupons, TCouponItem } from 'redux/marketingFlyer'
import { getCouponTitle } from 'utils/coupon'

import SkeletonCoupon from './SkeletonCoupon'

type TCouponSelectDrawer = {
  open: boolean
  close: () => void
  couponId?: number
  handleConfirm: (coupon: TCouponItem) => void
}

export default function CouponSelectDrawer({
  open,
  close,
  couponId,
  handleConfirm,
}: TCouponSelectDrawer): JSX.Element {
  const { brandId, locationId } = useParams<TParamTypes>()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { coupons, getCouponsRequest } = useAppSelector(
    state => state.marketingFlyer,
  )
  const [selectCouponId, setSelectCouponId] = useState<number>()
  const [selectItem, setSelectItem] = useState<TCouponItem>()

  const goCouponPage = () => {
    history.push(`/brand/${brandId}/location/${locationId}/coupon-code`)
  }

  useEffect(() => {
    if (open) {
      dispatch(
        getCoupons({
          id: locationId as string,
        }),
      )
      setSelectCouponId(couponId)
    }
  }, [open, couponId])
  return (
    <Drawer
      open={open}
      toggle={close}
      rightFooter={
        <>
          <Button color='secondary' onClick={close}>
            CANCEL
          </Button>
          {selectCouponId ? (
            <Button color='primary' onClick={() => handleConfirm(selectItem!)}>
              CONFIRM
            </Button>
          ) : (
            <Button color='primary' onClick={() => goCouponPage()}>
              CREATE COUPON
            </Button>
          )}
        </>
      }
    >
      <div>
        <div className='text-base font-bold text-center pt-6 pb-2'>
          Select Coupon Code
        </div>
        <div>
          {getCouponsRequest ? (
            <>
              <SkeletonCoupon />
              <SkeletonCoupon />
              <SkeletonCoupon />
            </>
          ) : (
            <>
              {coupons.length === 0 && (
                <p className='text-red text-center text-base mt-6 bg-redOpacity-4 py-4 px-4 rounded-xl'>
                  No available Coupons found
                </p>
              )}
              {coupons.map(item => {
                return (
                  <div
                    key={item.coupon_id}
                    className={cn(
                      'mt-4 option-box-class',
                      selectCouponId === item.coupon_id ? 'selected' : '',
                    )}
                    onClick={() => {
                      setSelectCouponId(item.coupon_id)
                      setSelectItem(item)
                    }}
                  >
                    <div>
                      <div className='flex items-center justify-between'>
                        <div className='text-base text-ink font-bold pr-2'>
                          {item.coupon.description}
                        </div>
                        <div className='text-xs text-lead px-2 py-0.5 bg-field rounded'>
                          {item.coupon.code}
                        </div>
                      </div>
                      <div className='text-xs py-2 text-lead'>
                        {getCouponTitle(item.coupon).showTitle}
                      </div>
                      <div className='flex text-xs text-lead bg-field py-2 px-3 rounded-lg'>
                        <SolidCalendar size={14} className='text-silver mr-2' />
                        <span>
                          {moment(item.coupon.start_date).format(
                            'MMM DD, YYYY',
                          )}{' '}
                          to{' '}
                          {moment(item.coupon.end_date).format('MMM DD, YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </Drawer>
  )
}
