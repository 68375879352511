import React from 'react'

import iphoneDevice from 'assets/img/iphone-device.png'
import TextArea from 'components/TextArea'
import { useAppSelector } from 'redux/hooks'

import Line from './Line'

type TContentFormTextProps = {
  enableCta: number
  smsContent: string
  viewOnly?: boolean
  handleFormDataChange: (type: string, value: any) => void
}

export default function ContentFormText({
  enableCta,
  smsContent,
  viewOnly,
  handleFormDataChange,
}: TContentFormTextProps): JSX.Element {
  const brandName =
    useAppSelector(state => state.brand?.selectedBrand)?.name || ''
  const maxLen = 299 - brandName.length
  return (
    <>
      <Line type='solid' className='mt-4 mb-4' />
      <div className='flex'>
        <div className='flex-1 mr-8'>
          <div>
            <div className='flex justify-between text-xs text-silver'>
              <div className='font-bold'>Message content</div>
              <div>
                ({smsContent?.length || 0}/{maxLen} characters)
              </div>
            </div>
            <TextArea
              name='TextInput'
              containerClass='mt-2'
              inputClass='resize-none h-[141px]'
              placeholder='Write your content here'
              value={smsContent}
              disabled={viewOnly}
              onChange={e => {
                if (e.target.value.length <= maxLen) {
                  handleFormDataChange('sms_content', e.target.value)
                }
              }}
            />
          </div>
        </div>
        <div className='w-[220px]'>
          <div className='text-silver text-xs font-bold mb-2'>
            Text message preview
          </div>
          <div
            className='w-[220px] min-h-[141px] bg-cover relative'
            style={{
              backgroundImage: `url(${iphoneDevice})`,
            }}
          >
            <div
              className='w-[440px] px-8 transform pt-24 pb-4'
              style={{ zoom: 0.5 }}
            >
              <div
                className='p-4 bg-slate rounded-lg text-lead text-sm'
                style={{
                  lineBreak: 'anywhere',
                }}
              >
                <div>{brandName}:</div>
                {smsContent ||
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate tristique metus, varius et. Aliquet feugiat pharetra, cras enim odio commodo, felis ac. Lectus risus amet accumsan turpis. Elit at lectus habitasse lacus. '}
                {!!enableCta && ' https://rsbl.io/your_link'}
                <div>- Reply STOP to opt out</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
