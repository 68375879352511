import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { TGetBrandProp, TSendNewBrandProp } from 'types/brand'
import { TLocation } from 'types/location'
import axios from 'utils/axiosApi'
/**
 * Call API to fetch brands of auth user
 */

export const getBrands = createAsyncThunk('brand/getBrands', async () => {
  const response = await axios.request({
    url: 'users/brands',
    method: 'get',
  })
  return response.data
})

/**
 * Call API to fetch info of selected brand
 */
export const getBrand = createAsyncThunk(
  'brand/getBrand',
  async ({ brand_id, refresh = false }: TGetBrandProp) => {
    const response = await axios.request({
      url: `brand/${brand_id}`,
      method: 'get',
      cache: {
        ignoreCache: refresh,
      },
    })
    return response.data
  },
)

export const addLocationsToBrand = createAction<Array<TLocation>>(
  'brand/addLocationsToBrand',
)

export const sendSlackForNewBrand = ({
  name,
  locationAddress,
  whenToContact,
}: TSendNewBrandProp) => {
  return async () => {
    const response = await axios.request({
      url: 'sendSlackForNewBrand',
      data: {
        name,
        locationAddress,
        whenToContact,
      },
      method: 'post',
    })
    return response.data
  }
}

/*** ================================  domains  =========================================*/
interface IDomainsType {
  brand_id?: string
  with_dns: number
}
export async function getDomainsApi(params: IDomainsType) {
  const response = await axios.request({
    url: `brands/${params.brand_id}/domains`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
    params: { with_dns: params.with_dns },
  })
  return response.data
}
interface IDeleteType {
  brand_id?: string
  id: string | number
}
export async function deleteDomainsApi({ id, brand_id }: IDeleteType) {
  const response = await axios.request({
    url: `brands/${brand_id}/domains/${id}`,
    method: 'delete',
  })
  return response.data
}

// edit domains
interface IEditType {
  brand_id?: string
  id: string | number
}
export async function editDomainsApi({ id, brand_id }: IEditType) {
  const response = await axios.request({
    url: `brands/${brand_id}/domains/${id}`,
    method: 'post',
    params: {
      _method: 'PUT',
    },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

interface IcreateType {
  brand_id?: string
  hostname: string
  is_default?: boolean | number
}
export async function createDomainsApi({ brand_id, ...params }: IcreateType) {
  const response = await axios.request({
    url: `brands/${brand_id}/domains`,
    method: 'post',
    data: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

interface IExternalType {
  brand_id?: string
  external_homepage: string
}
export async function externalHomePageApi({
  brand_id,
  ...params
}: IExternalType) {
  const response = await axios.request({
    url: `brands/${brand_id}/external_homepage`,
    method: 'post',
    data: params,
  })
  return response.data
}

/*** ================================  domains  =========================================*/

/*** ================================  Gallery Posting  =========================================*/

export async function getGallerriesApi(brandId?: string) {
  const response = await axios.request({
    url: `brands/${brandId}/galleries`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function createGallerriesApi(formData: any, brand_id?: string) {
  const response = await axios.request({
    url: `brands/${brand_id}/galleries`,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export async function updateGallerriesApi(
  formData: any,
  id?: string,
  brand_id?: string,
) {
  const response = await axios.request({
    url: `brands/${brand_id}/galleries/${id}`,
    method: 'post',
    params: {
      _method: 'PUT',
    },
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export async function deleteGallerriesApi(id: string, brand_id?: string) {
  const response = await axios.request({
    url: `/brands/${brand_id}/galleries/${id}`,
    method: 'delete',
  })
  return response.data
}

/*** ================================  Gallery Posting  =========================================*/

/*** ================================  website setting  =========================================*/
export async function getWebsiteConstantApi(type: string) {
  const params: any = {}
  if (type === 'cuisine') {
    params.for_website_setting = 1
  } else {
    params.for_banner_search = 1
  }
  const response = await axios.request({
    url: 'cuisine_types',
    method: 'get',
    params,
  })
  return response.data
}

interface Iphotos {
  keyword: string
  page: number
  per_page?: number
}
export async function getUnsplashPhotosApi(params: Iphotos) {
  const response = await axios.request({
    url: 'unsplash/photos',
    method: 'get',
    params: params,
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function getWebsiteInfoApi(brandId?: string) {
  const response = await axios.request({
    url: `brands/${brandId}`,
    method: 'get',
  })
  return response.data
}

export async function updateWebsiteApi(formData: FormData, brandId?: string) {
  const response = await axios.request({
    url: `brands/${brandId}`,
    method: 'post',
    params: {
      _method: 'PUT',
    },
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}
/*** ================================  website setting  =========================================*/

/*** ================================  Gift Card  =========================================*/
export const setPageSize = createAction<number>('brand/set-page-size')
export const setPage = createAction<number>('brand/set-page')

export async function showStripeAccountAPI(brandId?: string) {
  const response = await axios.request({
    url: `brands/${brandId}/stripe_account`,
    method: 'get',
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}

export async function redirectStripeAccountAPI(
  brandId?: string,
  locationId?: string,
) {
  const response = await axios.request({
    url: `brands/${brandId}/stripe_account/locations/${locationId}/redirect`,
    method: 'get',
  })
  return response.data
}

export async function updateStripeAccountAPI(
  selling_status: number,
  brandId?: string,
) {
  const response = await axios.request({
    url: `brands/${brandId}/stripe_account`,
    method: 'patch',
    data: {
      selling_status,
    },
  })
  return response.data
}

export const getGiftCardList = createAsyncThunk(
  'brand/getGiftCardList',
  async ({
    brandId,
    per_page,
    page,
  }: {
    brandId?: string
    per_page: number
    page: number
  }) => {
    const response = await axios.request({
      url: `brands/${brandId}/gift_cards`,
      method: 'get',
      params: {
        per_page,
        page,
      },
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export async function updateGiftCard({
  brandId,
  giftCardId,
  amount,
  notes,
  password,
}: {
  brandId?: string
  giftCardId?: string
  amount?: string | number
  notes?: string
  password?: string
}) {
  const response = await axios.request({
    url: `brands/${brandId}/gift_cards/${giftCardId}`,
    method: 'patch',
    data: {
      amount,
      notes,
      password,
    },
  })
  return response.data
}

export async function getTransactions({
  brandId,
  id,
  per_page,
  page,
}: {
  brandId?: string
  id?: string
  per_page: number
  page: number
}) {
  const response = await axios.request({
    url: `brands/${brandId}/gift_cards/${id}/transactions`,
    method: 'get',
    params: {
      per_page,
      page,
    },
    cache: {
      ignoreCache: true,
    },
  })
  return response.data
}
/*** ================================  Gift Card  =========================================*/
