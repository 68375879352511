import React, { useState, useEffect } from 'react'

import {
  LinedEmail,
  LinedEmailOpen,
  LinedChatSms,
  Check,
  Dots,
} from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import ModalFull from 'components/Modal/ModalFull'
import SourceIconRender from 'components/PayIcon/PayIconRender'
import Switch from 'components/Switch'
import Table from 'components/Table/Table'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCampaignJobs, showCampaign } from 'redux/campaign'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import type { TCampaignItem, TJobs } from 'types/campaign'
import { currencyFormatter, formatPhoneNumber, formatPrice } from 'utils/digit'

type TOverviewFormProps = {
  id: string
  campaigns: TCampaignItem
  createOrUpdate: (extraParam: Record<string, any>) => void
}

export default function OverviewForm({
  id,
  campaigns,
  createOrUpdate,
}: TOverviewFormProps): JSX.Element {
  const history = useHistory()
  const { brandId, locationId } = useParams<TParamTypes>()
  const dispatch = useAppDispatch()
  const { selectedLocation } = useAppSelector(state => state.location)
  const card_last4 = selectedLocation?.card_last4
  const card_name = selectedLocation?.card_brand
  const [resumeModal, setResumeModal] = useState(false)
  // const [campaigns, setCampaigns] = useState<TCampaignItem | null>(null)
  const [jobs, setJobs] = useState<TJobs | null>(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [loading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [statusLoading, setStatusLoading] = useState(false)
  const isPOI = campaigns?.enable_cta || campaigns?.campaign_offer

  // const isPOI = true //campaigns?.enable_cta || campaigns?.campaign_offer
  const [showDirectSales, setShowDirectSales] = useState(false)
  const {
    progress = 0,
    actual_audience = 0,
    actual_email_audience = 0,
    actual_email_token = 0,
    actual_sms_token = 0,
    sales_volume = 0,
    potential_sales_volume = 0,
    roi = 0,
    roi_w_potential_sales = 0,
    cta_click = 0,
    email_opened = 0,
  } = campaigns.campaign_performance || {}

  const columns = [
    {
      key: 'user',
      name: 'CUSTOMER',
      className: 'w-40 px-6 py-4',
      tdClassName: 'text-xs text-ink',
      custom: (value: { first_name: string; last_name: string }) => {
        return (
          <div>
            {value.first_name} {value.last_name}
          </div>
        )
      },
    },
    {
      key: 'phone',
      name: 'PHONE',
      className: 'w-36 px-6 py-4',
      tdClassName: 'text-xs text-lead',
      custom: (value: string) => {
        return <div>{formatPhoneNumber(value)}</div>
      },
    },
    {
      key: 'email',
      name: 'EMAIL',
      className: 'w-64',
      tdClassName: 'text-xs text-lead',
    },
    {
      key: 'method',
      name: 'METHOD',
      align: 'center',
      custom: (value: string, item: any) => {
        const EmailIcon = () => {
          if (item?.email_status === 'delivered') {
            return <LinedEmailOpen size={12} className='text-lead' />
          }
          if (item?.email_status === 'failed') {
            return <LinedEmail size={12} className='text-red' />
          }
          return <LinedEmail size={12} className='text-lead' />
        }
        return (
          <div className='flex justify-center'>
            {item.email_status && <EmailIcon />}
            {item.sms_status && (
              <LinedChatSms
                size={12}
                className={cn(
                  {
                    'text-dark-16': item.sms_status === 'pending',
                  },
                  {
                    'text-lead': item.sms_status === 'delivered',
                  },
                  {
                    'text-red': item.sms_status === 'failed',
                  },
                  { 'ml-1': item.email_status },
                )}
              />
            )}
          </div>
        )
      },
    },
    {
      key: 'last_click',
      name: 'LAST CLICK',
      align: 'right',
      tdClassName: 'text-xs text-lead',
      className: 'w-32',
      custom: (value: string, item: any) => {
        const lastClick =
          new Date(item.email_cta_clicked_at) >
          new Date(item.sms_cta_clicked_at)
            ? item.email_cta_clicked_at
            : item.sms_cta_clicked_at
        return <>{lastClick ? moment(lastClick).format('MM/DD/YYYY') : '-'}</>
      },
    },
    {
      key: showDirectSales ? 'sales_volume' : 'potential_sales_volume',
      name: 'SALES',
      align: 'right',
      tdClassName: 'text-xs text-lead',
      custom: (value: string, item: any) => {
        return (
          <>
            {showDirectSales
              ? currencyFormatter(Number(item.sales_volume))
              : currencyFormatter(
                  Number(item.sales_volume) +
                    Number(item.potential_sales_volume),
                )}
          </>
        )
      },
    },
  ]

  const getJobs = async (page: number, pageSize: number) => {
    try {
      setLoading(true)
      const res = await dispatch(
        getCampaignJobs({
          locationId: locationId as string,
          id,
          page,
          pageSize,
        }),
      )
      setPage(page)
      setPageSize(pageSize)

      if (res) {
        setJobs(res)
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  const getShow = async () => {
    try {
      setPageLoading(true)
      await dispatch(showCampaign(id, locationId as string))
      setPageLoading(false)
    } catch (error: any) {
      setPageLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  const handleStatusChange = async (status: string) => {
    try {
      setStatusLoading(true)
      await createOrUpdate({ status })
      await getShow()
      setStatusLoading(false)
    } catch (error: any) {
      setStatusLoading(false)
      setResumeModal(true)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  const goPayment = () => {
    history.push(
      `/brand/${brandId}/location/${locationId}/billing-and-invoice/billing`,
    )
  }

  const statusNameRender = (status: string) => {
    if (status === 'processing') {
      return 'Processing'
    }
    if (status === 'paused') {
      return 'Paused'
    }
    if (status === 'complete') {
      return 'Processed'
    }
  }

  useEffect(() => {
    // getShow()
    getJobs(1, pageSize)
  }, [])

  const theROI = showDirectSales ? roi : roi_w_potential_sales
  const perDollarROI = theROI ? (theROI / 100).toFixed(2) : 0
  return (
    <Spin
      spining={pageLoading}
      className='rounded-b-lg overflow-hidden z-10'
      styles={{
        minHeight: 'calc(100vh - 144px)',
      }}
    >
      <div className='my-8 px-8'>
        {isPOI ? (
          <div className='flex justify-between border border-zinc rounded-lg px-6 py-4'>
            <div className='w-[230px] mr-16'>
              <div className='text-xs text-ink font-bold pb-2'>
                Campaign ROI
              </div>
              {campaigns.status === 'complete' ? (
                <div className='text-blue text-[32px] leading-8 font-bold pb-6'>
                  {theROI}%
                </div>
              ) : (
                <div className='text-orange text-[32px] leading-8 font-bold pb-6'>
                  Pending
                </div>
              )}
              <div className='text-xs text-ink font-bold pb-2'>
                What does this mean?
              </div>
              {campaigns.status === 'complete' ? (
                <div
                  className='text-xs text-ink'
                  style={{
                    lineHeight: '18px',
                  }}
                >
                  It means for every $1.00 worth of token you spend, you get{' '}
                  {currencyFormatter(Number(perDollarROI))} back as your revenue
                  {`${theROI && theROI > 1000 ? ', this is CRAZY!' : '.'}`}
                </div>
              ) : (
                <div
                  className='text-xs text-ink'
                  style={{
                    lineHeight: '18px',
                  }}
                >
                  This campaign is still being processed and does not have
                  enough data to show ROI result, please check back later.
                </div>
              )}
            </div>
            <div className='flex-1'>
              <div className='h-[60px] bg-field flex justify-between px-6 py-4 mb-4 rounded-lg'>
                {campaigns.type === 'automated' ? (
                  <>
                    <div className='flex items-center text-base font-bold'>
                      {campaigns.status === 'paused' ? (
                        <>
                          <Dots size={16} className='mr-1 text-orange' />
                          Campaign {statusNameRender(campaigns?.status)}
                        </>
                      ) : (
                        <>
                          <Check size={16} className='mr-1 text-green' />
                          Active & Running
                        </>
                      )}
                    </div>
                    <div>
                      <Switch
                        label={
                          campaigns.status === 'paused'
                            ? `${
                                campaigns.paused_at
                                  ? moment().diff(
                                      moment(campaigns.paused_at),
                                      'day',
                                    )
                                  : '-'
                              } days since last paused`
                            : `${
                                campaigns.scheduled_at
                                  ? moment().diff(
                                      moment(campaigns.scheduled_at),
                                      'day',
                                    )
                                  : '-'
                              } days since last start`
                        }
                        inputClassName={cn(
                          'cursor-pointer',
                          campaigns.status === 'paused'
                            ? 'bg-orange'
                            : 'bg-blue',
                        )}
                        loading={statusLoading}
                        name='automated status'
                        checked={campaigns.status === 'processing'}
                        onChange={bool => {
                          handleStatusChange(bool ? 'scheduled' : 'paused')
                        }}
                        position='right'
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex-1 flex justify-between relative'>
                      <div className='text-base text-ink font-bold'>
                        Campaign {statusNameRender(campaigns?.status)} •{' '}
                        {isNaN(progress / actual_audience)
                          ? 0
                          : ((progress / actual_audience) * 100).toFixed(0)}
                        %
                      </div>
                      <div className='text-xs text-lead pt-1'>
                        {progress}/{actual_audience} customer reached
                      </div>
                      <div className='w-full h-1 absolute bottom-0 bg-zinc' />
                      <div
                        className={cn(
                          'h-1 absolute bottom-0',
                          {
                            'bg-orange': campaigns?.status === 'paused',
                          },
                          {
                            'bg-blue': campaigns?.status === 'processing',
                          },
                          {
                            'bg-green': campaigns?.status === 'complete',
                          },
                        )}
                        style={{
                          width: `${(
                            (progress / actual_audience) *
                            100
                          ).toFixed(0)}%`,
                        }}
                      />
                    </div>
                    {campaigns.status === 'paused' && (
                      <Button
                        className='h-[28px] w-[112px] text-xs ml-4'
                        onClick={() => handleStatusChange('scheduled')}
                        loading={statusLoading}
                      >
                        RESUME
                      </Button>
                    )}
                  </>
                )}
              </div>
              <div className='flex'>
                <div className='bg-field mr-4 flex-1 px-6 py-4 rounded-lg'>
                  <div className='text-ink text-2xl font-bold pb-2'>
                    {showDirectSales
                      ? currencyFormatter(Number(sales_volume))
                      : currencyFormatter(
                          Number(potential_sales_volume) + Number(sales_volume),
                        )}
                  </div>
                  <div className='text-lead text-xs whitespace-nowrap'>
                    Sale generated
                  </div>
                </div>
                <div className='bg-field mr-4 flex-1 px-6 py-4 rounded-lg'>
                  <div className='text-ink text-2xl font-bold pb-2'>
                    {isNaN(email_opened / actual_email_audience)
                      ? 0
                      : ((email_opened / actual_email_audience) * 100).toFixed(
                          2,
                        )}
                    %
                  </div>
                  <div className='text-lead text-xs whitespace-nowrap'>
                    Opened
                  </div>
                </div>
                <div className='bg-field mr-4 flex-1 px-6 py-4 rounded-lg'>
                  <div className='text-ink text-2xl font-bold pb-2'>
                    {isNaN(cta_click / actual_audience)
                      ? 0
                      : ((cta_click / actual_audience) * 100).toFixed(0)}
                    %
                  </div>
                  <div className='text-lead text-xs whitespace-nowrap'>
                    Clicks occurred
                  </div>
                </div>
                <div className='bg-field flex-1 px-6 py-4 rounded-lg'>
                  <div className='text-ink text-2xl font-bold pb-2'>
                    {formatPrice(actual_email_token + actual_sms_token)}
                  </div>
                  <div className='text-lead text-xs whitespace-nowrap'>
                    Token spent
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='flex justify-between border border-zinc rounded-lg px-6 py-4'>
            <div className='flex-1 mr-4 rounded-lg bg-field px-6 py-7'>
              <div className='flex justify-between h-full relative'>
                <div className='text-base text-ink font-bold'>
                  Campaign {statusNameRender(campaigns?.status)} •{' '}
                  {isNaN(progress / actual_audience)
                    ? 0
                    : ((progress / actual_audience) * 100).toFixed(0)}
                  %
                </div>
                <div className='text-xs text-lead pt-1'>
                  {progress}/{actual_audience} customer reached
                </div>
                <div className='w-full h-1 absolute bottom-0 bg-zinc' />
                <div
                  className={cn(
                    'h-1 absolute bottom-0 ',
                    {
                      'bg-orange': campaigns?.status === 'paused',
                    },
                    {
                      'bg-blue': campaigns?.status === 'processing',
                    },
                    {
                      'bg-green': campaigns?.status === 'complete',
                    },
                  )}
                  style={{
                    width: `${
                      isNaN(progress / actual_audience)
                        ? 0
                        : ((progress / actual_audience) * 100).toFixed(0)
                    }%`,
                  }}
                />
              </div>
            </div>
            <div className='w-[160px] rounded-lg bg-field px-6 py-4'>
              <div className='font-bold text-2xl text-ink pb-2'>
                {formatPrice(actual_email_token + actual_sms_token)}
              </div>
              <div className='text-xs text-lead'>Token spent</div>
            </div>
          </div>
        )}
        <div className='pt-8 pb-16'>
          <Table
            columns={columns}
            data={jobs?.data || []}
            isPageSet
            totalText='records'
            loading={loading}
            current={jobs?.current_page || 1}
            total={jobs?.total || 0}
            onPageSizeChange={newPageSize => {
              getJobs(page, newPageSize)
            }}
            onPageNoChange={newPage => {
              getJobs(newPage, pageSize)
            }}
            rightButton={
              <Switch
                label='Show sales from clicks only'
                name='showDirectSales'
                checked={showDirectSales}
                onChange={setShowDirectSales}
              />
            }
          />
        </div>

        <ModalFull
          open={resumeModal}
          title='Resume Error'
          toggle={() => {
            setResumeModal(!resumeModal)
          }}
          cancelText='CLOSE'
          okText='UPDATE PAYMENT'
          onCancel={() => setResumeModal(false)}
          onOk={goPayment}
        >
          <>
            <div className='text-base text-ink leading-6 text-center'>
              You need to have at least 5 token to continue. Please navigate to
              your account billing to check if your payment method is up to date
              for auto recharging your token balance.
            </div>
            <div className='flex justify-center items-center mt-4'>
              {SourceIconRender({
                type: card_name,
                iconParams: {
                  size: 24,
                  className: 'mr-2',
                },
              })}
              <span className='text-base text-ink font-bold'>
                ••• {card_last4}
              </span>
            </div>
          </>
        </ModalFull>
      </div>
    </Spin>
  )
}
