import { createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import type { TCouponList } from 'types/coupon'

import {
  getCouponList,
  createCoupon,
  editCoupon,
  deleteCoupon,
} from './actions'

type TCouponState = {
  getCouponsRequest: boolean
  createCouponRequest: boolean
  editCouponRequest: boolean
  deleteCouponRequest: boolean
  coupons: null | TCouponList
}

const initialState: TCouponState = {
  getCouponsRequest: false,
  createCouponRequest: false,
  editCouponRequest: false,
  deleteCouponRequest: false,
  coupons: null,
}

export const couponReducer = createReducer(initialState, builder => {
  builder
    .addCase(getCouponList.pending, state => {
      state.getCouponsRequest = true
    })
    .addCase(getCouponList.fulfilled, (state, action) => {
      state.getCouponsRequest = false
      state.coupons = action.payload
    })
    .addCase(getCouponList.rejected, (state, action) => {
      state.getCouponsRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(createCoupon.pending, state => {
      state.createCouponRequest = true
    })
    .addCase(createCoupon.fulfilled, state => {
      state.createCouponRequest = false
    })
    .addCase(createCoupon.rejected, (state, action) => {
      state.createCouponRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(editCoupon.pending, state => {
      state.editCouponRequest = true
    })
    .addCase(editCoupon.fulfilled, state => {
      state.editCouponRequest = false
    })
    .addCase(editCoupon.rejected, (state, action) => {
      state.editCouponRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(deleteCoupon.pending, state => {
      state.deleteCouponRequest = true
    })
    .addCase(deleteCoupon.fulfilled, state => {
      state.deleteCouponRequest = false
    })
    .addCase(deleteCoupon.rejected, (state, action) => {
      state.deleteCouponRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
})

export default couponReducer
