import { createAsyncThunk } from '@reduxjs/toolkit'
import type { TCouponForm } from 'types/coupon'
import axios from 'utils/axiosApi'

export const getCouponList = createAsyncThunk(
  'coupon/getCouponList',
  async ({
    locationId,
    keyword,
    page,
  }: {
    locationId: string
    keyword: string
    page: number
  }) => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons`,
      params: {
        keyword,
        page,
      },
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const createCoupon = createAsyncThunk(
  'coupon/createCoupon',
  async ({
    locationId,
    params,
  }: {
    locationId: string
    params: TCouponForm
  }) => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons`,
      data: params,
      method: 'post',
    })
    return response.data
  },
)

export const editCoupon = createAsyncThunk(
  'coupon/editCoupon',
  async ({
    params,
    id,
    locationId,
  }: {
    params: TCouponForm
    id: string
    locationId: string
  }) => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons/${id}`,
      data: params,
      method: 'patch',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  },
)

export const deleteCoupon = createAsyncThunk(
  'coupon/deleteCoupon',
  async ({ id, locationId }: { id: string; locationId: string }) => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons/${id}`,
      method: 'delete',
    })
    return response.data
  },
)

export const getCouponData = (locationId: string, id: string) => {
  return async () => {
    const response = await axios.request({
      url: `locations/${locationId}/coupons/${id}`,
      method: 'get',
      cache: {
        ignoreCache: true,
      },
    })
    return response.data
  }
}
