import { SERVER_TIMEZONE } from 'data/constant'
import moment from 'moment-timezone'

const parseServerTimeToLocalTime = (
  datetimeStr: string,
  localTimezone = 'America/Chicago',
  outputFormat = 'lll',
  inputFormat = 'YYYY-MM-DD HH:mm:ss',
) => {
  const validTimeZone = moment.tz.zone(localTimezone)
  if (!validTimeZone) {
    console.log(`${localTimezone} is invalid Time Zone`)
    return `Invalid timezone ${localTimezone}`
  }
  const CdtTime = moment.tz(datetimeStr, inputFormat, SERVER_TIMEZONE)
  if (!CdtTime.isValid()) {
    return 'Invalid Time'
  }
  return CdtTime.tz(localTimezone).format(outputFormat)
}

export default parseServerTimeToLocalTime
