import { AxiosRequestConfig, AxiosInstance } from 'axios'
import { setupCache } from 'axios-cache-adapter'
import { TOKEN_KEY } from 'data/constant'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

import { getAccessToken } from '../auth'

export const cache = setupCache({
  maxAge: 5 * 60 * 1000, // catch get request for 5 mins
  exclude: { query: false },
})

export const setupInterceptors = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config?.headers) {
        throw new Error(
          "Expected 'config' and 'config.headers' not to be undefined",
        )
      }
      const token: string = getAccessToken() || ''
      if (config.url && !config.url.includes('/login') && token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    error => {
      // 对请求错误做些什么
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const status =
        error.status || (error.response ? error.response.status : 0)
      if (status === 401) {
        toast.error('Please log in')
        Cookies.remove(TOKEN_KEY)
        Cookies.remove('selected_brand_id')
        window.location.replace('/login')
        return
      }
      const resData = error.response?.data || {
        message: error.message,
        errors: {},
      }
      return Promise.reject(resData)
    },
  )
}
