import React, { useState, useEffect, useRef } from 'react'

import Field from 'components/Field'
import { Spin } from 'components/Loading'
import SaveChangeBar from 'components/SaveChangeBar'
import { SearchSelect } from 'components/Select'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import { useParams, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateLocationApi } from 'redux/business'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { getLocation } from 'redux/location'
import { formatDigitOnly } from 'utils/digit'
import parseMediaAbsoluteURL from 'utils/parseMediaAbsoluteURL'

import MarketingSetting from './components/MarketSetting'
import MenuPageBanner from './components/MenuPageBanner'
import QRCodeDownload from './components/QRCodeDownload'
const formInit = {
  nickname: '',
  landline: '',
  line1: '',
  line2: '',
  bannerUrl: '',
}
export default function OperationInfo() {
  const [stateInit, setStateInit] = useState(formInit)
  const [state, setState] = useState(formInit)
  const [marketData, setmarketData] = useState<any>({})
  const [formStatus, setFormStatus] = useState({
    loading: false,
    error: '',
    isDirty: false,
  })
  const bannerRef = useRef<any>(null)
  const { selectedLocation, getLocationRequest } = useAppSelector(
    state => state.location,
  )
  const { locationId } = useParams<TParamTypes>()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const compareFn = useDebounce(compare)

  useEffect(() => {
    if (selectedLocation && location?.state?.refresh) {
      dispatch(getLocation({ location_id: Number(locationId), refresh: true }))
    }
  }, [])

  useEffect(() => {
    const {
      name,
      phone,
      address,
      location_business_setting,
      brand,
      banner_url,
      brand_site_url,
    } = selectedLocation || ({} as any)
    const line1 = `${address?.line_1}, ${address?.city}, ${address?.state}, ${address?.country}`
    const data = {
      nickname: name,
      landline: phone || '',
      line1: address?.line_1 ? line1 : '', // 需要做拼接处理参考v2
      line2: address?.line_2 || '',
      bannerUrl: parseMediaAbsoluteURL(banner_url),
    }
    const marketData = {
      id: location_business_setting?.id,
      emailDomain: brand_site_url?.split('//')?.[1],
      emailPrefix: location_business_setting?.marketing_email_prefix || '',
      phone: location_business_setting?.marketing_phone_number || '', // 需对电话号码做格式化处理
      emailStatus: brand?.brand_business_setting?.sendgrid_sender_auth_status, // null,'pending','complete'
    }
    setState({ ...data })
    setStateInit({ ...data })
    setmarketData(marketData)
  }, [selectedLocation])

  useEffect(() => {
    compareFn(state, stateInit, (flag: boolean) =>
      setFormStatus(prev => ({ ...prev, isDirty: flag })),
    )
  }, [state, stateInit])

  const onChangeForm = (name: string, value: any) => {
    setState({ ...state, [name]: value })
  }

  const handleSubmit = async () => {
    const { nickname, landline, line1, line2 } = state
    const formData = new FormData()
    formData.append('_method', 'PATCH')
    formData.append('name', nickname)
    formData.append('phone', formatDigitOnly(landline))
    formData.append('address_line_1', line1)
    formData.append('address_line_2', line2)
    if (bannerRef.current) {
      formData.append('banner', bannerRef.current)
    } else if (stateInit.bannerUrl) {
      formData.append('banner_url', state.bannerUrl || '')
    }
    setFormStatus(prev => ({ ...prev, loading: true, error: '' }))
    try {
      const res = await updateLocationApi(formData, locationId || '')
      toast.success(res.message)
      setFormStatus(prev => ({ ...prev, error: '', isDirty: false }))
    } catch (err: any) {
      const msg = err.message || 'Request error'
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  const { nickname, landline, line1, line2 } = state
  return (
    <>
      <Spin spining={getLocationRequest}>
        <div className='px-8 pt-8 pb-10'>
          <div className='mb-6'>
            <div className='flex mb-6'>
              <Field
                containerClassName='flex-1 mr-6'
                label='Location nickname'
                name='Location nickname'
                value={nickname || ''}
                onChange={e => onChangeForm('nickname', e.target.value)}
                placeholder=''
                type='text'
              />
              <Field
                containerClassName='flex-1'
                label='Location landline'
                name='Location landline'
                value={landline}
                placeholder=''
                type='tel'
                inputMode='tel'
                onChangePhone={value => onChangeForm('landline', value)}
              />
            </div>
            <div className='flex'>
              <SearchSelect
                label='Restaurant address line 1'
                className='flex-1 mr-6'
                value={line1 || ''}
                onChange={value => onChangeForm('line1', value)}
              />
              <Field
                containerClassName='flex-1'
                label='Restaurant address line 2'
                name='Restaurant address line 2'
                value={line2 || ''}
                onChange={e => onChangeForm('line2', e.target.value)}
                placeholder='e.g. Suite 108'
                type='text'
              />
            </div>
          </div>
          <MarketingSetting
            emailDomain={marketData.emailDomain}
            emailPrefix={marketData.emailPrefix}
            phone={marketData.phone}
            emailStatus={marketData.emailStatus || 'none'}
          />
          <div className='flex'>
            <MenuPageBanner
              className='flex-1 mr-6'
              value={state.bannerUrl}
              onChange={(blob, preview) => {
                bannerRef.current = blob
                onChangeForm('bannerUrl', preview)
              }}
              onDelete={() => {
                onChangeForm('bannerUrl', '')
                bannerRef.current = null
              }}
            />
            <QRCodeDownload className='flex-1' />
          </div>
        </div>
      </Spin>
      <>
        {formStatus.isDirty && (
          <SaveChangeBar
            error={formStatus.error}
            confirmRequest={formStatus.loading}
            onConfirm={() => {
              handleSubmit()
            }}
            onCancel={() => setState(stateInit)}
          />
        )}
      </>
      <UnsavedPrompt when={formStatus.isDirty} />
    </>
  )
}
