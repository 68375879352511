import React, { useState } from 'react'

import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Plus } from '@rushable/icons'
import Button from 'components/Button'
import CheckBox from 'components/CheckBox'
import { useHistory } from 'react-router-dom'

import MenuAction from './MenuAction'
import MenuListItem from './MenuListItem'

type TMenuListCategoryItemProp = {
  item: any
  i: number
  j: number
  loading: boolean
  bulkAction: any
  saveBulkEdit: boolean
  menuActionId: number
  url: string
  setBulkEditChecked: (
    value: boolean,
    { i, j, k }: { i: number; j: number; k?: number },
  ) => void
  setMenuActionId: (id: number) => void
  handleChangeMenu: (
    value: string,
    { id, name }: { id: number; name: string },
    type: string,
  ) => void
  onDragEnd: (prop: any, type: string, list?: any) => void
  handleFormChange: (name: string, value: string, option: any) => void
}

export default function MenuListCategoryItem({
  item,
  i,
  j,
  loading,
  bulkAction,
  saveBulkEdit,
  menuActionId,
  url,
  setBulkEditChecked,
  setMenuActionId,
  handleChangeMenu,
  onDragEnd,
  handleFormChange,
}: TMenuListCategoryItemProp): JSX.Element {
  const history = useHistory()
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(mouseSensor)
  const { id } = item
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id,
  })
  let styles
  if (transform) {
    const { x, y }: any = transform
    styles = {
      transform: CSS.Transform.toString({ x, y, scaleX: 1, scaleY: 1 }),
      transition,
    }
  }

  return (
    <div ref={setNodeRef} {...listeners} style={styles} className='mb-6'>
      <div
        className={
          'bg-white pt-0 pb-4 mb-2 border-b border-solid border-zinc flex items-center font-bold'
        }
      >
        {!bulkAction && !saveBulkEdit ? (
          <MenuAction
            size={16}
            open={menuActionId === item.id}
            onChange={v => handleChangeMenu(v, item, 'Category')}
            clickOutside={() => {
              setMenuActionId(0)
            }}
          />
        ) : (
          <CheckBox
            size={20}
            partial={item.noAllChecked}
            checked={item.checked}
            onChange={value => setBulkEditChecked(value, { i, j })}
          />
        )}

        <p className='ml-2 leading-snug'>{item.name}</p>
        <Button
          className='ml-auto'
          color='primary-link'
          onClick={() =>
            history.push(`${url}/menu-item-detail/${item.id}?type=create`)
          }
        >
          <Plus className='mr-1' size={16} />
          ADD NEW ITEM
        </Button>
      </div>
      <DndContext
        sensors={sensors}
        onDragEnd={prop => onDragEnd(prop, 'MenuItem', item.menu_items)}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext disabled={loading} items={item.menu_items || []}>
          {item.menu_items.map((menuItem: any, k: number) => {
            return (
              <MenuListItem
                key={menuItem.name}
                item={menuItem}
                i={i}
                j={j}
                k={k}
                menuActionId={menuActionId}
                bulkAction={bulkAction}
                saveBulkEdit={saveBulkEdit}
                setBulkEditChecked={(value, option) =>
                  setBulkEditChecked(value, option)
                }
                setMenuActionId={id => setMenuActionId(id)}
                handleChangeMenu={(value, option, type) =>
                  handleChangeMenu(value, option, type)
                }
                handleFormChange={(name, value, option) =>
                  handleFormChange(name, value, option)
                }
              />
            )
          })}
        </SortableContext>
      </DndContext>
    </div>
  )
}
