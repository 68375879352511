import React, { useState, useEffect } from 'react'

import moment from 'moment'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { getOrderListApi } from 'redux/location/actions'

import OrderDetailPage from './OrderDetailPage'
import OrderListPage from './OrderListPage'

export default function OrderHistoryPage(): JSX.Element {
  const { path } = useRouteMatch()
  const { locationId } = useParams<TParamTypes>()
  const { selectedLocation } = useAppSelector(state => state.location)
  const [tableData, setTableData] = useState({ data: [], total: 0 })
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [ranges, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'dateRange',
    },
  ])
  const [status, setStatus] = useState('')
  const [dateLabel, setDateLabel] = useState('')
  const [keyword, setKeyword] = useState('')
  const [pageSize, setPageSize] = useState(20)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (!window.location.href.includes('customer')) {
      getOrderList()
    }
  }, [])

  // if selected location has changed
  // rest filters and reload data
  useEffect(() => {
    resetFilters()
  }, [selectedLocation])

  const resetFilters = () => {
    setStatus('')
    setDateLabel('')
    setKeyword('')
    setPageSize(20)
    setPage(1)
    setRanges([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'dateRange',
      },
    ])
  }

  const getOrderList = async (newPage?: number, newPageSize?: number) => {
    setLoading(true)
    try {
      const res = await getOrderListApi(
        {
          page: newPage || page,
          per_page: newPageSize || pageSize,
          keyword: keyword === '' ? undefined : keyword,
          status: status === '' ? undefined : status,
          begin_time:
            moment(ranges[0].startDate).format('YYYY-MM-DD') + ' 00:00:00',
          end_time:
            moment(ranges[0].endDate).format('YYYY-MM-DD') + ' 23:59:59',
        },
        locationId,
      )
      setTableData(res)
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
    setSearchLoading(false)
  }

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <OrderListPage
            loading={loading}
            searchLoading={searchLoading}
            status={status}
            ranges={ranges}
            tableData={tableData}
            keyword={keyword}
            dateLabel={dateLabel}
            page={page}
            onChange={(name, value) => {
              switch (name) {
                case 'searchLoading':
                  setPage(1)
                  setSearchLoading(value)
                  getOrderList(1)
                  break
                case 'status':
                  setStatus(value)
                  break
                case 'ranges':
                  setRanges(value)
                  break
                case 'keyword':
                  setKeyword(value)
                  break
                case 'page':
                  setPage(value)
                  getOrderList(value, pageSize)
                  break
                case 'pageSize':
                  setPage(1)
                  setPageSize(value)
                  getOrderList(1, value)
                  break
                case 'dateLabel':
                  setDateLabel(value)
                  break
              }
            }}
          />
        </Route>
        <Route exact path={`${path}/order-detail/:orderId`}>
          <OrderDetailPage />
        </Route>
      </Switch>
    </>
  )
}
