import { LinedSearch } from '@rushable/icons'
import Button from 'components/Button'
import CopyText from 'components/CopyText'
import Field from 'components/Field'
import Table from 'components/Table/Table'
import { Badge } from 'components/Tag'
import useDebounce from 'hooks/useDebounce'
import moment from 'moment'
import { useAppSelector } from 'redux/hooks'
import type { TCouponList } from 'types/coupon'

import { couponTypeItems } from '../helpers/constant'

type TCouponListProp = {
  keyword: string
  setKeyword: (arg: string) => void
  handleAdd: () => void
  coupons: TCouponList | null
  getCoupons: (no?: number, search?: string) => void
  openCoupon: (id: string) => void
}

export default function CouponList({
  keyword,
  setKeyword,
  handleAdd,
  coupons,
  getCoupons,
  openCoupon,
}: TCouponListProp): JSX.Element {
  const { selectedBrand } = useAppSelector(state => state.brand)
  const { getCouponsRequest } = useAppSelector(state => state.coupon)
  const locationsCount = selectedBrand?.locations?.length || 0

  const handleChange = useDebounce((search: string) => {
    getCoupons(1, search)
  })

  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-120',
      custom: (value: any, data: any) => {
        const { deleted_at, start_date, end_date } = data
        let color: any = ''
        let text = ''
        if (deleted_at) {
          color = 'red'
          text = 'Cancelled'
        } else if (moment().isBefore(start_date)) {
          color = 'blue'
          text = 'Scheduled'
        } else if (moment().isAfter(end_date)) {
          color = 'gray'
          text = 'Expired'
        } else {
          color = 'green'
          text = 'Active'
        }
        return <Badge color={color}>{text}</Badge>
      },
    },
    {
      key: 'description',
      name: 'DESCRIPTION',
      custom: (value: any) => {
        return <div className='truncate'>{value}</div>
      },
    },
    {
      key: 'code',
      name: 'CODE',
      className: 'w-48',
      custom: (value: any) => {
        return (
          <CopyText content={value} className='text-sm text-lead'>
            {value}
          </CopyText>
        )
      },
    },
    {
      key: 'type',
      name: 'TYPE',
      className: 'w-120',
      custom: (value: any, row: any) => {
        let text = ''
        switch (value) {
          case 'free_item':
            text = 'Free Item'
            break
          case 'flat':
            text = `$${row.amount_off} Off`
            break
          case 'percentage':
            text = `${row.percentage_off}% Off`
            break
        }
        return <div>{text}</div>
      },
    },
    {
      key: 'start_date',
      name: 'START DATE',
      className: 'w-120',
      align: 'right',
      custom: (value: any) => {
        return <div>{moment(value).format('MM/DD/YYYY')}</div>
      },
    },
    {
      key: 'end_date',
      name: 'END DATE',
      className: 'w-120',
      align: 'right',
      custom: (value: any) => {
        return <div>{moment(value).format('MM/DD/YYYY')}</div>
      },
    },
    // 等后端加了 优惠券 已被消费的个数再把这块放开
    {
      key: 'usage',
      name: 'USAGE',
      className: 'w-24',
      align: 'right',
      custom: (value: any, row: any) => {
        return (
          <div>
            {value}/{row.usage_type === 'unlimited' ? '∞' : row.usage_limit}
          </div>
        )
      },
    },
    {
      key: 'locations_count',
      name: 'LOCATION',
      className: 'w-24',
      align: 'right',
      custom: (value: any) => {
        return (
          <div className='text-lead'>
            {value}/{locationsCount}
          </div>
        )
      },
    },
  ]

  return (
    <div className='p-8'>
      <div className='flex justify-between mb-6'>
        <Field
          suffix={<LinedSearch size={16} className='text-silver' />}
          placeholder='Search option'
          type='text'
          name='Search option'
          value={keyword || ''}
          onChange={e => {
            setKeyword(e.target.value)
            handleChange(e.target.value)
          }}
        />
        <Button onClick={handleAdd}>ADD NEW COUPON</Button>
      </div>
      <Table
        className='text-ink'
        columns={columns}
        data={coupons?.data || []}
        total={coupons?.total || 0}
        current={coupons?.current_page || 1}
        resizePageSize={coupons?.per_page || 20}
        loading={getCouponsRequest}
        clickedRow={item => {
          openCoupon(item.id)
        }}
        onPageNoChange={(no: number) => {
          getCoupons(no)
        }}
      />
    </div>
  )
}
