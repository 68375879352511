import React, { useState } from 'react'

import { AngleRight, Plus } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import SkeletonLocation from 'components/RestaurantSelector/SkeletonLocation'
import { TBrand } from 'types/brand'
import newid from 'utils/newid'

import LocationSelector from './LocationSelector'
type TRestaurantSelector = {
  brand: TBrand
  onToggle: (v: boolean) => void
  onSelect: (v: number) => void
  onAddModalOpen: (id: number) => void
}
export default function RestaurantSelector({
  brand,
  onToggle,
  onSelect,
  onAddModalOpen,
}: TRestaurantSelector): JSX.Element {
  const [open, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!open)
    if (onToggle) {
      onToggle(!open)
    }
  }
  return (
    <div className='w-400px border border-solid border-zinc rounded-lg p-4 my-4 transition duration-75'>
      <div
        className='flex justify-between items-center'
        role='button'
        onClick={toggle}
      >
        <div className='w-80'>
          <h4 className='text-base leading-5 font-bold text-ink mb-2 truncate'>
            {brand.name}
          </h4>
          <p className='text-xs text-silver truncate'>{`${
            brand?.locations_count || 0
          } ${brand.locations_count > 1 ? 'locations' : 'location'}`}</p>
        </div>
        <div
          className={cn(
            'transition duration-75',
            open ? 'origin-center rotate-90' : '',
          )}
        >
          <AngleRight size={16} className='text-ink' />
        </div>
      </div>
      {open && (
        <div className='flex flex-col w-full border-t border-dashed border-zinc mt-4'>
          {!brand.locations &&
            Array(brand.locations_count)
              .fill(0)
              .map(() => <SkeletonLocation key={newid('bls')} />)}
          {brand.locations &&
            brand.locations.map(location => (
              <LocationSelector
                key={location.id}
                location={location}
                onSelect={onSelect}
              />
            ))}
          <div className='text-center mt-4'>
            <Button
              color='primary-link'
              className='mx-auto'
              onClick={() => onAddModalOpen(brand.id)}
            >
              <Plus size={16} className='mr-1' /> ADD NEW LOCATION
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
