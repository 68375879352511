import React from 'react'

import { LinedChatComment, LinedTag, SolidGiftCard } from '@rushable/icons'
import { currencyFormatter } from 'utils/digit'

import GiftCards from './GiftCards'

type TOrderDetailMenuProp = {
  formData: any
}

type Tcoupon = 'free_item' | 'flat' | 'percentage'
const getCouponDesc = (type: Tcoupon, coupon: any) => {
  switch (type) {
    case 'free_item':
      return coupon.description
    case 'flat':
      return `$${coupon.amount_off || ''} OFF`
    case 'percentage':
      return `${coupon.percentage_off || ''}% OFF`
    default:
      return ''
  }
}

export default function OrderDetailMenu({
  formData,
}: TOrderDetailMenuProp): JSX.Element {
  const getAddress = () => {
    if (formData.address) {
      const { line_1, line_2, city, state, country, zipcode } = formData.address
      const line1 = line_1 ?? ''
      const line2 = line_2 ?? ''
      const city1 = city ?? ''
      const state1 = state ?? ''
      const country1 = country ?? ''
      const zipcode1 = zipcode != null ? zipcode : ''

      return (
        line1 +
        (line2 === '' ? '' : ' ' + line2) +
        ' ' +
        city1 +
        ', ' +
        state1 +
        ' ' +
        zipcode1 +
        ', ' +
        country1
      )
    }

    return 'None'
  }

  const coupon = formData?.location_coupon?.coupon || {}
  const couponDesc = getCouponDesc(coupon.type, coupon)

  return (
    <div className='flex-1 py-8 pr-8'>
      {(formData.type === 'daas_delivery' || formData.type === 'delivery') && (
        <div className='pb-6 mb-6 border-b border-dashed border-zinc'>
          <div className='grid gap-4 bg-slate rounded-lg py-4 px-6 '>
            {(!!formData.check_id || !!formData.check_card) && (
              <div>
                <p className='text-lead text-xs'>Fraud prevention</p>
                <p className='mt-1 text-ink leading-snug'>
                  {formData.check_id && formData.check_card
                    ? 'ID verification and Payment card verification'
                    : formData.check_card
                    ? 'Payment card verification'
                    : formData.check_id
                    ? 'ID verification'
                    : ''}
                </p>
              </div>
            )}

            <div>
              <p className='text-lead text-xs'>Delivery address</p>
              <p className=' mt-1 text-ink leading-snug'>{getAddress()}</p>
              <p className=' mt-1 text-ink leading-snug'>
                {formData?.delivery_note || 'None'}
              </p>
            </div>
          </div>
        </div>
      )}

      <div
        className={`grid gap-6 leading-snug ${
          (formData.coupon_code || formData.customer_note) && 'pb-2'
        }`}
      >
        {formData.order_items &&
          formData.order_items.map((item: any, key: number) => {
            return (
              <div className='flex flex-col' key={key}>
                <p className='text-lead text-xs mb-1 pl-9'>
                  {item.category_name}
                </p>
                <div className='flex items-center text-ink'>
                  <div className='w-5 p-0.5  bg-field rounded flex items-center justify-center text-xs text-lead font-medium mr-4'>
                    {item.quantity}
                  </div>
                  <p className='font-bold text-base'>{item.name}</p>
                  <div className='flex-1 h-1 mx-4  border-t border-dashed border-zinc'></div>
                  <div>{currencyFormatter(item.total.updated)}</div>
                </div>
                {item.options_array && item.options_array.length > 0 && (
                  <div className='inline-block ml-9 mt-1 mb-2 last:mb-0'>
                    {item.options_array.map((options: any, i: number) => {
                      return (
                        <div
                          key={options.name}
                          className={`flex ${
                            !item.customer_note && i === 0 ? 'pt-0' : 'pt-2'
                          }`}
                        >
                          <div className='border-l-2 border-solid border-zinc mr-2 w-0.5'></div>
                          <div>
                            <p className='text-lead text-xs'>{options.name}</p>
                            {options.value.length > 0 &&
                              options.value.map((value_item: any) => {
                                return (
                                  <p
                                    className='text-xs mb-1 last:mb-0 mt-1 text-ink'
                                    key={value_item}
                                  >
                                    {value_item}
                                  </p>
                                )
                              })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
                {item.customer_note && (
                  <div
                    className={`inline-block ml-9 border-l-2 border-solid border-yellow text-ink text-xs pl-2 ${
                      item.options_array.length ? 'mt-0' : 'mt-2'
                    }`}
                  >
                    {item.customer_note}
                  </div>
                )}
              </div>
            )
          })}
        {coupon.type === 'free_item' && (
          <div className='flex flex-col'>
            <p className='text-lead text-xs mb-1 pl-9'>Coupon</p>
            <div className='flex items-center leading-none text-ink'>
              <div className='w-5 p-0.5  bg-field rounded flex items-center justify-center text-xs text-lead font-medium mr-4'>
                1
              </div>
              <div>
                <div className='font-bold'>{coupon.description}</div>
              </div>
              <div className='flex-1 h-1 mx-4  border-t border-dashed border-zinc'></div>
              <div>$0.00</div>
            </div>
          </div>
        )}
      </div>
      {formData.coupon_code && (
        <div className='flex items-center py-3 px-4 bg-ice my-4 rounded-lg text-ink'>
          <LinedTag size={20} className='mr-1' /> {formData.coupon_code} |{' '}
          {couponDesc}
        </div>
      )}
      {formData.customer_note && (
        <div className='flex items-center py-3 px-4 bg-ice mb-4 rounded-lg text-ink'>
          <LinedChatComment size={20} className='mr-1' />{' '}
          {formData.customer_note}
        </div>
      )}

      <div className='mt-6 pt-6  flex justify-between border-t border-dashed border-zinc'>
        <div className='text-silver'>
          {formData.order_items_count} items in total
        </div>
        <div className='w-[316px] leading-snug'>
          <div className='flex flex-col space-y-1'>
            <div className='flex justify-between'>
              <span>Subtotal</span>
              <span>{currencyFormatter(formData.price_summary?.subtotal)}</span>
            </div>
            {formData.price_summary?.coupon &&
            formData.price_summary.coupon > 0 ? (
              <div className='flex justify-between'>
                <span>Discount</span>
                <span>-{currencyFormatter(formData.price_summary.coupon)}</span>
              </div>
            ) : null}
            {formData.price_summary?.custom_fee &&
            formData.price_summary.custom_fee > 0 ? (
              <div className='flex justify-between'>
                <span>Custom fee</span>
                <span>
                  {currencyFormatter(formData.price_summary.custom_fee)}
                </span>
              </div>
            ) : null}

            <div className='flex justify-between'>
              <span>Tax</span>
              <span>
                {currencyFormatter(formData.price_summary?.sales_tax)}
              </span>
            </div>
            {formData.type === 'delivery' && (
              <div className='flex justify-between'>
                <span>Delivery Fee</span>
                <span>
                  {currencyFormatter(formData.price_summary.delivery_fee)}
                </span>
              </div>
            )}
            {formData.price_summary?.service_fee ? (
              <div className='flex justify-between'>
                <span>Convenience fee</span>
                <span>
                  {currencyFormatter(formData.price_summary.service_fee)}
                </span>
              </div>
            ) : null}
            {/** Courier fee **/}
            {formData.type === 'daas_delivery' && (
              <div className='flex justify-between'>
                <span>Courier fee</span>
                <span>
                  {currencyFormatter(
                    formData.price_summary.courier_fee_customer,
                  )}
                </span>
              </div>
            )}
            {/** Restaurant tip / Courier tip **/}
            <div className='flex justify-between'>
              <span>
                {formData.type === 'daas_delivery'
                  ? 'Courier tip'
                  : 'Restaurant tip'}
              </span>
              <span>
                {formData.type === 'daas_delivery'
                  ? currencyFormatter(formData.price_summary?.courier_tip)
                  : currencyFormatter(formData.price_summary?.tip)}
              </span>
            </div>
            {/** Total without refunds **/}
            <div className='flex justify-between font-bold'>
              <span>GRAND TOTAL</span>
              <span>{currencyFormatter(formData.total)}</span>
            </div>
          </div>
          <GiftCards
            brand={formData?.payment_card_brand}
            last_4={formData?.payment_card_last_4}
            total={formData?.total}
            giftCards={formData?.gift_card_transactions}
          />

          {(!!formData.price_summary?.refunds.length ||
            !!formData.credits?.length) && (
            <div
              className={`flex flex-col space-y-2 mt-4 ${
                !formData?.gift_card_transactions?.length &&
                'pt-4 border-t border-dashed border-zinc'
              }`}
            >
              {formData.price_summary.refunds.map(
                (refund: any, key: number) => {
                  return (
                    <div
                      className='bg-red-opacity-8 px-4 py-2 rounded-lg'
                      key={key}
                    >
                      <div className='text-red-darken text-sm font-bold flex justify-between'>
                        <span>
                          {formData.status === 'canceled' &&
                          !formData.confirmed_at
                            ? 'Rejected'
                            : 'Refund'}
                        </span>
                        <span>- {currencyFormatter(refund.amount)}</span>
                      </div>
                      <div className='text-sm text-ink'>{refund.note}</div>
                    </div>
                  )
                },
              )}
              {formData.credits.map(
                (credit: { amount: string }, key: number) => {
                  return (
                    <div key={`adjustment-${key}`}>
                      <div className='bg-red-opacity-8 px-4 py-2 rounded-lg'>
                        <div className='text-red-darken text-sm font-bold flex justify-between'>
                          <span>Adjustment</span>
                          <span>+ ${credit.amount}</span>
                        </div>
                        <div className='text-sm text-ink'>
                          Rushable credit for #{formData.id}
                        </div>
                      </div>
                    </div>
                  )
                },
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
