import React, { useState } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import MenuAction from './MenuAction'

type TMenuDetailModifierItemProp = {
  item: any
  menuActionInfo: any
  index: number
  handleChangeMenu: (
    method: string,
    type: string,
    index: number,
    id?: number,
  ) => void
  setMenuActionInfo: ({ type, index }: { type: string; index: number }) => void
}

export default function MenuDetailModifierItem({
  item,
  menuActionInfo,
  index,
  handleChangeMenu,
  setMenuActionInfo,
}: TMenuDetailModifierItemProp): JSX.Element {
  const { id } = item
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id,
  })
  let styles
  if (transform) {
    const { x, y }: any = transform
    styles = {
      transform: CSS.Transform.toString({ x, y, scaleX: 1, scaleY: 1 }),
      transition,
    }
  }

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      style={styles}
      className='flex items-center'
    >
      <MenuAction
        size={16}
        className='mr-4'
        open={
          menuActionInfo.type === 'modifier' && menuActionInfo.index === index
        }
        onChange={v => handleChangeMenu(v, 'modifier', index, item.id)}
        clickOutside={() => {
          setMenuActionInfo({ type: '', index: -1 })
        }}
      />
      <div
        style={{ width: 'calc(100% - 36px)' }}
        className='bg-field rounded-lg flex items-center justify-between px-4 py-3'
      >
        <div className='flex-1 text-ellipsis leading-snug'>{item.name}</div>
        <div className='text-sm text-silver ml-2'>Usage: {item.usage}</div>
      </div>
    </div>
  )
}
