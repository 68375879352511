import React, { useState, useEffect } from 'react'

import { SolidTrashCan } from '@rushable/icons'
import cn from 'classnames'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import SaveChangeBar from 'components/SaveChangeBar'
import Badge from 'components/Tag/Badge'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  createMenuModifierAPI,
  editMenuModifierAPI,
  showMenuModifierAPI,
} from 'redux/onlineMenu'

import DeleteModifier from './DeleteModifier'
import MenuModifierDetail from './MenuModifierDetail'

type TMenuModifierPageProp = {
  modifierId: number
  initLoading: boolean
  onChange: () => void
}

export default function MenuModifierPage({
  modifierId,
  initLoading,
  onChange,
}: TMenuModifierPageProp): JSX.Element {
  const baseFormData = {
    name: '',
    note: '',
    qty_min: 0,
    qty_max: 0,
    qty_free: 0,
    allow_repeat: 0,
    usage: 0,
    menu_modifier_options: [
      {
        name: '',
        unit_price: '',
        status: 'active',
      },
    ],
  }
  const { locationId } = useParams<TParamTypes>()
  const [openDelete, setOpenDelete] = useState(false)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [formData, setFormData] = useState(baseFormData)
  const [formDataInit, setFormDataInit] = useState(baseFormData)
  const [loading, setLoading] = useState(false)
  const [initRequest, setInitRequest] = useState(false)
  const [error, setError] = useState('')
  const compareFn = useDebounce(compare)

  useEffect(() => {
    if (modifierId) {
      getMenuModifierDetail()
    } else {
      setFormData({ ...baseFormData })
      setFormDataInit({ ...baseFormData })
    }
  }, [modifierId])

  useEffect(() => {
    if (error) {
      setError('')
    }
    compareFn(formData, formDataInit, (flag: boolean) => setFormIsDirty(flag))
  }, [formData, formDataInit])

  const getMenuModifierDetail = async () => {
    setInitRequest(true)
    try {
      const params = {
        menu_modifier_id: modifierId,
        locationId,
      }
      const res = await showMenuModifierAPI(params)
      const {
        name,
        note,
        qty_min,
        qty_max,
        qty_free,
        allow_repeat,
        usage,
        menu_modifier_options,
      } = res
      const detailFormData = {
        name,
        note,
        qty_min,
        qty_max,
        qty_free,
        allow_repeat,
        usage,
        menu_modifier_options: res.menu_modifier_options.length
          ? [...menu_modifier_options]
          : [{ name: '', unit_price: '', status: 'active' }],
      }
      setFormData({ ...detailFormData })
      setFormDataInit({ ...detailFormData })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setInitRequest(false)
  }

  const changeFormData = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value })
  }

  const submitFormData = () => {
    updateMenuModifier()
  }

  const updateMenuModifier = async () => {
    setLoading(true)
    try {
      const { menu_modifier_options } = formData
      const obj: any = { ...formData }

      const newFormData = new FormData()
      Object.keys(obj).forEach(key => {
        if (key !== 'menu_modifier_options') {
          newFormData.append(key, obj[key])
        }
      })

      menu_modifier_options.forEach((item, index) => {
        newFormData.append(`options[${index}][name]`, item.name)
        newFormData.append(`options[${index}][unit_price]`, item.unit_price)
        newFormData.append(`options[${index}][status]`, item.status)
      })

      if (modifierId) {
        const res = await editMenuModifierAPI(
          newFormData,
          modifierId,
          locationId,
        )
        toast.success(res.message)
        onChange()
      } else {
        const res = await createMenuModifierAPI(newFormData, locationId)
        toast.success(res.message)
        onChange()
      }
      setFormDataInit({ ...formData })
    } catch (e: any) {
      const msg = e?.message
      setError(msg || 'request error')
    }
    setLoading(false)
  }
  return (
    <>
      <UnsavedPrompt when={formIsDirty} />
      {formIsDirty && (
        <SaveChangeBar
          className='left-[30rem] border-l'
          error={error}
          confirmRequest={loading}
          confirmText='SAVE CHANGE'
          onConfirm={() => submitFormData()}
          onCancel={() => {
            setFormData({ ...formDataInit })
          }}
        />
      )}
      <div
        className={cn(
          'min-w-[880px] bg-white  mx-auto relative h-[calc(100vh-120px)]   overflow-scroll',
        )}
      >
        <AdminPageTitle
          left={
            <>
              {!!modifierId && (
                <Badge color='gray'>
                  <>Used on {formData.usage} items</>
                </Badge>
              )}
            </>
          }
          title={`${modifierId ? 'Edit' : 'Add New'} Modifier`}
          right={
            <>
              {!!modifierId && (
                <Button
                  color='secondary-link'
                  onClick={() => setOpenDelete(true)}
                >
                  DELETE
                  <SolidTrashCan className='ml-1' size={16} />
                </Button>
              )}
            </>
          }
        />
        <Spin
          spining={initRequest}
          className='rounded-b-lg overflow-hidden'
          styles={{
            minHeight: 'calc(100vh - 150px)',
          }}
        >
          <div className='w-[600px] p-8  mx-auto '>
            <MenuModifierDetail
              loading={initLoading}
              formData={formData}
              onChange={(name, value) => {
                changeFormData(name, value)
              }}
            />
          </div>
        </Spin>
      </div>

      {!!modifierId && (
        <DeleteModifier
          id={openDelete ? modifierId : 0}
          open={openDelete}
          onChange={success => {
            setOpenDelete(false)
            success && onChange()
          }}
        />
      )}
    </>
  )
}
