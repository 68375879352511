import React, { useEffect, useState } from 'react'

import { LinedTrash } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import cloneDeep from 'lodash/cloneDeep'

import MarketPlaceIcon from './MarketPlaceIcon'
import OrderTypeDisclosure from './OrderTypeDisclosure'

export type TMarketPlaceDisclosureProp = {
  name: string
  label: string
  type: string
  marketPlaceConfig: any
  onChange: (name: string, value: any) => void
  tabChange: (value: string) => void
}
export default function MarketPlaceDisclosure({
  name,
  label,
  type,
  tabChange,
  onChange,
  marketPlaceConfig,
}: TMarketPlaceDisclosureProp): JSX.Element {
  const [links, setLinks] = useState<any>([])

  useEffect(() => {
    if (marketPlaceConfig?.links && marketPlaceConfig?.links.length) {
      setLinks([...marketPlaceConfig.links])
    }
  }, [marketPlaceConfig])

  const onLinksRemove = (n: number) => {
    if (links.length > 1) {
      const newLinks = [...links]
      newLinks.splice(n, 1)
      setLinks(newLinks)
      onChange('links', newLinks)
    }
  }

  const onLinksAdd = () => {
    const linkList = [
      ...links,
      { order_marketplace_config_id: 0, source: '', link: '', id: 1 },
    ]
    setLinks(linkList)
    onChange('links', linkList)
  }

  const onLinksChange = (key: number, val: string) => {
    const newLinks = cloneDeep(links)
    newLinks[key].link = val
    setLinks(newLinks)
    onChange('links', newLinks)
  }

  return (
    <OrderTypeDisclosure
      name={name}
      label={label}
      type={type}
      enabled={marketPlaceConfig?.enabled}
      hoursStatus='Follow business hours'
      tabChange={tabChange}
      onChange={onChange}
    >
      <>
        {links.map((item: { link: string; source: string }, key: number) => (
          <div className='flex items-center mb-[14px]' key={key}>
            <MarketPlaceIcon
              size={38}
              source={item.source || null}
              link={item.link}
            />
            <Field
              className='ml-2'
              containerClassName='flex-1'
              type='text'
              name='marketLink'
              placeholder='Insert link here'
              value={item.link}
              onChange={e => onLinksChange(key, e.target.value)}
            />
            <Button
              color='ink-link'
              className='ml-4 w-5 h-5'
              onClick={() => onLinksRemove(key)}
            >
              <LinedTrash size={16} />
            </Button>
          </div>
        ))}
        <div className='flex'>
          <Button
            className='ml-[46px] mr-[32px]  w-full'
            color='tertiary'
            onClick={() => onLinksAdd()}
          >
            ADD MARKETPLACE LINK
          </Button>
        </div>
      </>
    </OrderTypeDisclosure>
  )
}
