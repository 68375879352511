import React from 'react'

import Button from 'components/Button'
import Field from 'components/Field'

import type { TForgotData } from '../LoginPage'
export type TEmailLoginFormProp = {
  setMethod: (v: string) => void
  handleFormChange: (name: string, value: string) => void
  setForgotData: (v: TForgotData) => void
  handleLogin: (e: React.FormEvent<HTMLFormElement>) => void
  email: string
  password: string
  request?: boolean
  error: {
    email?: string[]
    password?: string[]
  } | null
}
export default function EmailLoginForm({
  setMethod,
  handleFormChange,
  setForgotData,
  handleLogin,
  email,
  password,
  request,
  error,
}: TEmailLoginFormProp): JSX.Element {
  return (
    <form onSubmit={handleLogin}>
      <div className='w-400px mx-auto mt-6'>
        <Field
          label='Email'
          name='email'
          placeholder='Input your email'
          type='email'
          className='w-full'
          containerClassName='mb-4'
          onChange={e => handleFormChange('email', e.target.value)}
          value={email}
          error={error?.email?.[0]}
        />
        <Field
          label='Password'
          name='password'
          placeholder='Input your password'
          type='password'
          className='w-full'
          onChange={e => handleFormChange('password', e.target.value)}
          value={password}
          error={error?.password?.[0]}
        />
        <div className='flex mt-2'>
          <span className='text-lead mr-1 text-xs'>Forgot password?</span>
          <Button
            size='sm'
            color='primary-link'
            type='button'
            className='text-blue'
            onClick={() => {
              setForgotData({
                open: true,
                success: false,
                loading: false,
              })
            }}
          >
            Reset password
          </Button>
        </div>
      </div>
      <div className='mt-8 flex space-x-4 items-center justify-center'>
        <Button
          color='secondary'
          className='w-full'
          onClick={() => setMethod('')}
          type='button'
        >
          GO BACK
        </Button>
        <Button
          color='primary'
          className='w-full'
          type='submit'
          loading={request}
        >
          SIGN IN
        </Button>
      </div>
    </form>
  )
}
