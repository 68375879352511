import React, { useState, useEffect } from 'react'

import { AngleLeft, AngleRight, LinedTrash } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import Timeline from 'components/Timeline'
import { useParams, useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { createCampaigns, updateCampaigns, showCampaign } from 'redux/campaign'
import { useAppDispatch } from 'redux/hooks'
import type { TCampaignItem } from 'types/campaign'

import AttachOfferForm from './components/AttachOfferForm'
import ContentForm from './components/ContentForm'
import DeleteCampaignModal from './components/DeleteCampaignModal'
import FinalReviewForm from './components/FinalReviewForm'
import OverviewForm from './components/OverviewForm'
import SendingRulesForm from './components/SendingRulesForm'
import { offerItems } from './helpers/constant'
import type { TAddOpenDataState } from './helpers/types'

type TFormCampaignPageProps = {
  addOpenData: TAddOpenDataState
  setAddOpenData: any
}

export default function FormCampaignPage({
  addOpenData,
  setAddOpenData,
}: TFormCampaignPageProps): JSX.Element {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { typeName, type, step } = addOpenData
  const { brandId, locationId, id } =
    useParams<{ brandId: string; locationId: string; id: string | undefined }>()
  const [formData, setFormData] = useState<TCampaignItem>({
    id: null,
    campaign_template_id: 0,
    trigger_of_inactive_days: null,
    type,
    status: 'draft',
    current_step: step,
    name: '',
    via_email: 0,
    via_sms: 0,
    enable_cta: 1,
    email_subject: '',
    email_image_file: '',
    email_image_url: '',
    email_headline: '',
    email_content: '',
    sms_content: '',
    percent: 0,
    campaign_locations: [
      {
        location_id: Number(locationId),
        is_initiator: 1,
      },
    ],
    campaign_offer: {
      type: '',
      name: '',
      valid_days: 2,
    },
    trigger_of_date_time: '',
    trigger_type: '',
    campaign_audience: null,
    payment: null,
  })
  const [stepLoading, setStepLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleFormDataChange = (type: string, value: any) => {
    if (type === 'email_image') {
      setFormData({
        ...formData,
        email_image_file: value.blob,
        email_image_url: value.preview,
      })
      return
    }
    if (type === 'email_image_url') {
      setFormData({
        ...formData,
        email_image_file: '',
        email_image_url: value,
      })
      return
    }
    if (type === 'remove_image') {
      setFormData({
        ...formData,
        email_image_file: '',
        email_image_url: '',
      })
      return
    }
    if (type === 'campaign_offer_type') {
      setFormData({
        ...formData,
        campaign_offer: {
          type: value,
          name: offerItems.find(v => v.value === value)?.label || '',
          coupons: [
            {
              type: '',
              threshold: '',
              description: '',
              amount_off: '',
              percentage_off: '',
            },
            {
              type: '',
              threshold: '',
              description: '',
              amount_off: '',
              percentage_off: '',
            },
          ],
          valid_days: 2,
        },
      })

      return
    }
    if (type === 'coupons[0]') {
      const { key, newValue }: { key: string; newValue: string } = value
      setFormData({
        ...formData,
        campaign_offer: {
          type: formData.campaign_offer?.type as string,
          name: formData.campaign_offer?.name as string,
          coupons: formData?.campaign_offer?.coupons?.map((item, index) => {
            if (index === 0) {
              if (key === 'type') {
                return {
                  type: newValue as 'flat' | 'percentage' | 'free_item' | '',
                  threshold: '',
                  description: '',
                  amount_off: '',
                  percentage_off: '',
                }
              }
              return {
                ...item,
                [key]: newValue,
              }
            }
            return item
          }),
          valid_days: formData.campaign_offer?.valid_days as number,
        },
      })
      return
    }
    if (type === 'coupons[1]') {
      const { key, newValue }: { key: string; newValue: string } = value
      setFormData({
        ...formData,
        campaign_offer: {
          type: formData.campaign_offer?.type as string,
          name: formData.campaign_offer?.name as string,
          coupons: formData?.campaign_offer?.coupons?.map((item, index) => {
            if (index === 1) {
              if (key === 'type') {
                return {
                  type: newValue as 'flat' | 'percentage' | 'free_item' | '',
                  threshold: '',
                  description: '',
                  amount_off: '',
                  percentage_off: '',
                }
              }
              return {
                ...item,
                [key]: newValue,
              }
            }
            return item
          }),
          valid_days: formData.campaign_offer?.valid_days as number,
        },
      })
      return
    }
    if (type === 'valid_days' && formData.campaign_offer) {
      setFormData({
        ...formData,
        campaign_offer: {
          ...formData.campaign_offer,
          valid_days: Number(value),
        },
      })
      return
    }
    if (type === 'coupon_id' && formData.campaign_offer) {
      setFormData({
        ...formData,
        campaign_offer: {
          ...formData.campaign_offer,
          coupons: [
            {
              id: value.id,
              code: value.code,
              type: '',
              threshold: '',
              description: '',
              amount_off: '',
              percentage_off: '',
            },
          ],
        },
      })
      return
    }
    if (type === 'trigger_of_date') {
      setFormData({
        ...formData,
        trigger_of_date_time: `${value}${
          formData.trigger_of_date_time &&
          formData.trigger_of_date_time.length > 0
            ? ' ' + formData.trigger_of_date_time.split(' ')[1]
            : ''
        }`,
      })
      return
    }
    if (type === 'trigger_of_time') {
      setFormData({
        ...formData,
        trigger_of_date_time: `${
          formData.trigger_of_date_time &&
          formData.trigger_of_date_time.length > 0
            ? formData.trigger_of_date_time.split(' ')[0]
            : ''
        } ${value}`,
      })
      return
    }
    if (type === 'campaign_audience') {
      const { key, newValue } = value
      if (key === 'type') {
        setFormData({
          ...formData,
          campaign_audience: {
            type: newValue,
            conditional_type: '',
            lifetime_order_count_operator: '',
            lifetime_order_count: '',
            recent_order_within_unit: '',
            recent_order_within: '',
          },
        })
        return
      }
      if (key === 'conditional_type') {
        setFormData({
          ...formData,
          campaign_audience: {
            type: formData?.campaign_audience?.type || '',
            conditional_type: newValue,
            lifetime_order_count_operator:
              newValue === 'lifetime_order_count' ? 'more' : '',
            lifetime_order_count:
              newValue === 'lifetime_order_count' ? '1' : '',
            recent_order_within_unit:
              newValue === 'lifetime_order_count' ? '' : 'week',
            recent_order_within: newValue === 'lifetime_order_count' ? '' : '2',
          },
        })
        return
      }
      if (formData.campaign_audience) {
        setFormData({
          ...formData,
          campaign_audience: {
            ...formData.campaign_audience,
            [key]: newValue,
          },
        })
        return
      }
    }
    setFormData({
      ...formData,
      [type]: value,
    })
  }
  const createOrUpdate = async (extraParam: Record<string, any> = {}) => {
    if (addOpenData.viewOnly) {
      nextStep()
      return
    }
    try {
      setStepLoading(true)
      // update
      if (formData.id) {
        // 只针对 one_off 的 status 做处理
        const status =
          formData.type === 'one_off' && step < 4 ? 'draft' : formData.status
        const current_step = step >= 4 ? 4 : step + 1
        const res = await dispatch(
          updateCampaigns(
            { ...formData, current_step, status, ...extraParam },
            locationId,
          ),
        )
        setStepLoading(false)
        if (res && res.id) {
          setFormData({ ...formData, ...res })
          if (extraParam.campaign_locations) {
            return
          }
          // 从第四步点击 edit campaign 进来
          if (extraParam._client_step) {
            setAddOpenData((prev: TAddOpenDataState) => ({ ...prev, step: 1 }))
            return
          }
          if (step === 4) {
            const nextUrl =
              res.type === 'one_off'
                ? `/brand/${brandId}/location/${locationId}/outbound-campaign`
                : `/brand/${brandId}/location/${locationId}/outbound-campaign/automated-campaign`
            history.push(nextUrl)
          } else {
            nextStep()
          }
        } else if (res.message) {
          toast.error(res.message)
        }
      } else {
        // create
        const res = await dispatch(
          createCampaigns({ ...formData, current_step: step + 1 }, locationId),
        )
        if (res && res.id) {
          setFormData({ ...formData, ...res })
          nextStep()
          const nextUrl = `/brand/${brandId}/location/${locationId}/outbound-campaign/campaign-detail/${res.id}`
          history.push(nextUrl)
        }
      }
    } catch (error: any) {
      toast.error(error.message || 'Request error')
    }
    setStepLoading(false)
  }
  const getShow = async (id: string) => {
    setPageLoading(true)
    try {
      const res = await dispatch(showCampaign(id, locationId as string))
      setFormData({
        ...formData,
        ...res,
      })
      setAddOpenData({
        ...addOpenData,
        type: res.type,
        typeName: res.type === 'automated' ? 'Automated Campaign' : '',
        step: res.current_step,
      })
      setPageLoading(false)
    } catch (error: any) {
      setPageLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }
  const nextStep = () => {
    setAddOpenData({
      ...addOpenData,
      step: step + 1,
    })
  }
  const prevStep = () => {
    setAddOpenData({
      ...addOpenData,
      step: step - 1,
    })
  }

  useEffect(() => {
    if (!id && !addOpenData.item) {
      history.push(`/brand/${brandId}/location/${locationId}/outbound-campaign`)
      return
    }
    if (id === '0' && !addOpenData.create) {
      history.push(`/brand/${brandId}/location/${locationId}/outbound-campaign`)
      return
    }
    if (addOpenData.item) {
      setFormData({
        ...formData,
        ...addOpenData.item,
      })
    }
    if (id && id !== '0') {
      getShow(id)
    }
  }, [])

  // 标题右侧按钮
  const renderRight = () => {
    const status = formData.status
    let ele
    if (type === 'automated') {
      if (status !== 'draft' && status !== 'scheduled') {
        ele = (
          <>
            <Button
              color='secondary-link'
              onClick={() => {
                if (!pageLoading) {
                  setFormData({ ...formData, status: 'scheduled' })
                  setAddOpenData({ ...addOpenData, step: 1 })
                }
              }}
            >
              CAMPAIGN SETTING
              <AngleRight size={16} />
            </Button>
          </>
        )
      } else {
        ele = typeName
      }
    }
    if (type === 'one_off' && formData.id) {
      if (status === 'draft' || status === 'scheduled') {
        ele = (
          <Button
            color='secondary-link'
            onClick={() => setShowDeleteModal(true)}
          >
            <LinedTrash size={16} className='text-silver cursor-pointer' />
            <span className='text-silver cursor-pointer text-base ml-1.5'>
              DELETE
            </span>
          </Button>
        )
      } else {
        ele = (
          <>
            <Button
              color='secondary-link'
              onClick={() => {
                if (!pageLoading) {
                  setFormData({ ...formData, status: 'scheduled' })
                  setAddOpenData({ ...addOpenData, step: 1, viewOnly: true })
                }
              }}
            >
              VIEW DETAIL
              <AngleRight size={16} />
            </Button>
          </>
        )
      }
    }
    return ele
  }
  return (
    <div className='flex flex-col items-center'>
      <AdminPageTitle
        className='px-8'
        title={
          type === 'one_off' ? (
            formData.status === 'draft' || formData.status === 'scheduled' ? (
              'One-Off Campaign Setting'
            ) : (
              formData.name
            )
          ) : type === 'automated' ? (
            formData.status === 'draft' || formData.status === 'scheduled' ? (
              formData.name
            ) : (
              <>
                {formData.name}{' '}
                <span className='text-base font-normal'>[Automated]</span>
              </>
            )
          ) : (
            type
          )
        }
        left={
          <Button
            color='secondary-link'
            onClick={() => {
              formData.type === 'one_off'
                ? history.push(
                    `/brand/${brandId}/location/${locationId}/outbound-campaign`,
                  )
                : history.push(
                    `/brand/${brandId}/location/${locationId}/outbound-campaign/automated-campaign`,
                  )
            }}
            // size='lg'
          >
            <AngleLeft className='text-silver mr-1' size={16} />
            GO BACK
          </Button>
        }
        right={<>{renderRight()}</>}
      />
      {formData.status === 'draft' || formData.status === 'scheduled' ? (
        <div className='w-720px py-8'>
          <Timeline
            className='mt-4 mb-6'
            current={step - 1}
            list={[
              { label: '1', title: 'Content' },
              { label: '2', title: 'Attach Offer' },
              { label: '3', title: 'Sending Rules' },
              { label: '4', title: 'Final Review' },
            ]}
          />
          {step === 1 && (
            <ContentForm
              createOrUpdate={createOrUpdate}
              handleFormDataChange={handleFormDataChange}
              formData={formData}
              stepLoading={stepLoading}
              viewOnly={addOpenData.viewOnly}
            />
          )}
          {step === 2 && (
            <AttachOfferForm
              createOrUpdate={createOrUpdate}
              prevStep={prevStep}
              handleFormDataChange={handleFormDataChange}
              formData={formData}
              stepLoading={stepLoading}
              viewOnly={addOpenData.viewOnly}
            />
          )}
          {step === 3 && (
            <SendingRulesForm
              createOrUpdate={createOrUpdate}
              prevStep={prevStep}
              handleFormDataChange={handleFormDataChange}
              formData={formData}
              stepLoading={stepLoading}
              viewOnly={addOpenData.viewOnly}
            />
          )}
          {step === 4 && (
            <FinalReviewForm
              createOrUpdate={createOrUpdate}
              prevStep={prevStep}
              formData={formData}
              stepLoading={stepLoading}
              addOpenData={addOpenData}
              handleFormDataChange={handleFormDataChange}
              viewOnly={addOpenData.viewOnly}
            />
          )}
        </div>
      ) : (
        <div>
          <OverviewForm
            id={id as string}
            campaigns={formData}
            createOrUpdate={createOrUpdate}
          />
        </div>
      )}
      <DeleteCampaignModal
        data={formData}
        open={showDeleteModal}
        toggle={() => setShowDeleteModal(false)}
      />
    </div>
  )
}
