import { ReactNode } from 'react'

import {
  Amex,
  ApplePay,
  Discover,
  GooglePay,
  Master,
  Other,
  Visa,
  DuoToneComputer,
  DuoToneSignature,
  DuoToneCoins,
  DuoToneMoney,
  DuoToneBank,
  DuoToneCalendar,
  DuoToneStars,
} from '@rushable/icons'
import cn from 'classnames'

const ICONMAP = {
  BalanceIcon: DuoToneMoney,
  BankAccountIcon: DuoToneBank,
  PayoutScheduleIcon: DuoToneCalendar,
  RushableTokenIcon: DuoToneCoins,
  ServiceIcon: DuoToneStars,
  SubscriptionIcon: DuoToneSignature,
  ComputerIcon: DuoToneComputer,
  amex: Amex,
  visa: Visa,
  mastercard: Master,
  discover: Discover,
  googlePay: GooglePay,
  applePay: ApplePay,
  unknown: Other,
}
interface Iprops {
  className?: string
  title: string
  mainTxt: string | ReactNode
  secondaryTxt?: string | ReactNode
  descTxt: string
  descHightline?: boolean
  button: ReactNode
  rightElement?: ReactNode
  iconType:
    | 'BalanceIcon'
    | 'BankAccountIcon'
    | 'PayoutScheduleIcon'
    | 'ComputerIcon'
    | 'RushableTokenIcon'
    | 'ServiceIcon'
    | 'SubscriptionIcon'
    | 'amex'
    | 'visa'
    | 'mastercard'
    | 'discover'
    | 'googlePay'
    | 'applePay'
    | 'unknown'
}

export default function DisplayBlock({
  className,
  title,
  mainTxt,
  secondaryTxt,
  descTxt,
  descHightline = false,
  button,
  iconType,
  rightElement,
}: Iprops) {
  const Icon = ICONMAP[iconType]
  return (
    <div
      className={cn(
        'w-full flex px-6 py-4 border border-zinc rounded-lg',
        className,
      )}
    >
      <div className='flex-1'>
        <div className='text-xs text-lead'>{title}</div>
        <div className='flex items-center my-2'>
          {Icon && <Icon size={24} className='mr-2' />}
          <div className='text-base text-ink font-bold mr-1'>{mainTxt}</div>
          <div className='text-xs text-ink'>{secondaryTxt}</div>
        </div>
        <div
          className={cn(
            'mb-4 text-sm',
            descHightline ? 'text-blue' : 'text-silver',
          )}
        >
          {descTxt}
        </div>
        {button}
      </div>
      {rightElement && (
        <>
          <div className='bg-zinc w-px'></div>
          <div className='w-1/2'>{rightElement}</div>
        </>
      )}
    </div>
  )
}
