import React, { useEffect, useState } from 'react'

import BreadCrumbs from 'components/BreadCrumbs'
import { Spin } from 'components/Loading'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import MenuItemDetailPage from './components/MenuItemDetailPage'
import MenuItemListPage from './components/MenuItemListPage'
import MenuLeftNav from './components/MenuLeftNav'
import MenuModifierPage from './components/MenuModifierPage'

export default function OnlineMenuPage(): JSX.Element {
  const [updateMenuNav, setUpdateMenuNav] = useState(false)
  const [openCollection, setOpenCollection] = useState(true)
  const [loading, setLoading] = useState(false)
  const [menuItemList, setMenuItemList] = useState<any>([])
  const [menuId, setMenuId] = useState(0)
  const [menuItemIndex, setMenuItemIndex] = useState({ i: 0, j: 0 })
  const [scrollIndex, setScrollIndex] = useState({ i: 0, j: 0 })
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <div className='flex overflow-hidden'>
            <MenuLeftNav
              openMenu={openCollection}
              updateMenuNav={updateMenuNav}
              scrollIndex={scrollIndex}
              onChangeMenuNav={value => {
                if (typeof value === 'boolean') {
                  setLoading(value)
                }
                setUpdateMenuNav(false)
              }}
              onChangeMenuItem={(itemList, id, isCollection) => {
                setMenuItemList([...itemList])
                setMenuId(id)
                isCollection && setOpenCollection(!openCollection)
              }}
              onClick={value => setMenuItemIndex({ ...value })}
            />
            <div className='flex-1 mr-10'>
              <BreadCrumbs className='mx-0 my-6' />
              {openCollection ? (
                <MenuItemListPage
                  initLoading={loading}
                  menuItemList={menuItemList}
                  menuItemIndex={menuItemIndex}
                  onChange={() => setUpdateMenuNav(true)}
                  onScroll={value => setScrollIndex({ ...value })}
                />
              ) : (
                <MenuModifierPage
                  initLoading={loading}
                  modifierId={menuId}
                  onChange={() => setUpdateMenuNav(true)}
                />
              )}
            </div>
          </div>
        </Route>
        <Route exact path={`${path}/menu-item-detail/:menuItemId`}>
          <div>
            <BreadCrumbs className='mx-10 my-6' />
            <MenuItemDetailPage />
          </div>
        </Route>
      </Switch>
    </>
  )
}
