import React, { useEffect, useState } from 'react'

import { Plus } from '@rushable/icons'
import Button from 'components/Button'
import RestaurantSelector from 'components/RestaurantSelector'
import SkeletonBrand from 'components/RestaurantSelector/SkeletonBrand'
import useSearch from 'hooks/useSearch'
import { useHistory } from 'react-router-dom'
import { logout } from 'redux/auth'
import { getBrands } from 'redux/brand'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getLocations } from 'redux/location'

import AddBrandModal from './components/AddBrandModal'
import AddLocationModal from './components/AddLocationModal'

export type TLocationModal = {
  open: boolean
  id: number | null
}

export default function SelectRestaurant(): JSX.Element {
  const [locationModal, setLocationModal] = useState<TLocationModal>({
    open: false,
    id: null,
  })
  const [brandModalOpen, setBrandModalOpen] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { redirect_from, code } = useSearch()
  const { brands } = useAppSelector(state => state.brand)
  useEffect(() => {
    dispatch(getBrands())
  }, [])
  const handleSignout = () => {
    dispatch(logout())
  }
  const handleToggleBrand = (open: boolean, brand_id: number) => {
    if (open) {
      dispatch(getLocations({ brand_id }))
    }
  }
  const onAddModalOpen = (id: number) => {
    setLocationModal({
      open: true,
      id,
    })
  }
  const handleSelectLocation = (location_id: number, brand_id: number) => {
    if (redirect_from && code) {
      history.push(
        `/brand/${brand_id}/location/${location_id}/integration?redirect_from=${redirect_from}&code=${code}`,
      )
    } else {
      history.push(`/brand/${brand_id}/location/${location_id}/order-history`)
    }
  }
  return (
    <div className='flex flex-col justify-center items-center pb-12'>
      <div className='bg-white rounded-xl p-8 inline-block'>
        <h1 className='text-center text-2xl font-bold'>
          Choose your restaurant
        </h1>
        {!brands.length &&
          Array(3)
            .fill(0)
            .map((num, index) => <SkeletonBrand key={num + index} />)}
        {brands.length > 0 &&
          brands.map(brand => (
            <RestaurantSelector
              key={brand.id}
              brand={brand}
              onToggle={v => handleToggleBrand(v, brand.id)}
              onSelect={v => handleSelectLocation(v, brand.id)}
              onAddModalOpen={onAddModalOpen}
            />
          ))}
        <div className='text-center mt-8'>
          <Button
            color='primary-link'
            className='mx-auto'
            onClick={() => setBrandModalOpen(true)}
          >
            <Plus size={16} className='mr-1' /> ADD NEW BRAND
          </Button>
        </div>
      </div>
      <div className='text-center my-8'>
        <Button color='secondary-link' onClick={handleSignout}>
          SIGN OUT
        </Button>
      </div>
      <AddLocationModal
        locationModal={locationModal}
        setLocationModal={setLocationModal}
      />
      <AddBrandModal
        open={brandModalOpen}
        toggle={() => setBrandModalOpen(false)}
      />
    </div>
  )
}
