import { useState, useEffect } from 'react'

import { DuoToneBank } from '@rushable/icons'
import Button from 'components/Button'
import Table from 'components/Table/Table'
import { StatusTag } from 'components/Tag'
import currency from 'currency.js'
import moment from 'moment-timezone'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPayoutListApi } from 'redux/accountAndPayout'

import { STATUSTAG } from '../../billingAndInvoice/helps'

export default function PayoutsTable() {
  const { locationId = '', brandId = '' } = useParams<TParamTypes>()
  const [pageObj, setPageObj] = useState({
    page: 1,
    per_page: 20,
    total: 0,
    loading: false,
  })
  const [tableData, setTableData] = useState<any>([])
  const history = useHistory()

  useEffect(() => {
    getPayoutList(pageObj)
  }, [])

  const getPayoutList = async (pageObj: any, refresh = false) => {
    setPageObj(prev => ({ ...prev, loading: true }))
    const params = {
      per_page: pageObj.per_page,
      page: pageObj.page,
    }
    try {
      const res = await getPayoutListApi(params, locationId, refresh)
      setTableData(res.data || [])
      setPageObj(prev => ({ ...prev, total: res.total }))
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
    setPageObj(prev => ({ ...prev, loading: false }))
  }

  const columns = [
    {
      className: 'w-40',
      key: 'stripe_status',
      name: 'STATUS',
      custom: (value: any) => {
        const color = STATUSTAG[value]?.color
        const txt = STATUSTAG[value]?.value
        return (
          <div>
            <StatusTag color={color} text={txt} subText='' />
          </div>
        )
      },
    },
    {
      key: 'stripe_amount',
      // align: 'right',
      name: 'AMOUNT',
      custom: (value: string) => <div>{currency(value).format()}</div>,
    },
    {
      key: 'last_4',
      name: 'DEPOSIT',
      custom: (value: string) => {
        if (!value) {
          return <span className='text-lead'>-</span>
        }
        return (
          <div className='flex items-center text-lead text-base'>
            <DuoToneBank size={16} />
            <div className='ml-1'>{value}</div>
          </div>
        )
      },
    },
    {
      className: 'w-[246px]',
      align: 'right',
      key: 'payout_created_at',
      name: 'INITIATED',
      custom: (value: string, row: any) => {
        if (!value) {
          return <span className='text-lead'>-</span>
        }
        const timezone = row.location?.address?.timezone
        const zoneStr = timezone ? moment()?.tz(timezone).zoneAbbr() : ''
        return (
          <div className='text-lead'>
            {moment(value).format('MMM D YYYY, hh:mm a')} {zoneStr}
          </div>
        )
      },
    },
    {
      align: 'right',
      className: 'w-[140px]',
      key: 'estimate_arrival',
      name: 'EST. ARRIVAL',
      custom: (value: string) => {
        return (
          <div className='text-lead'>
            {value ? moment(value).format('ll') : '-'}
          </div>
        )
      },
    },
    {
      align: 'right',
      className: 'w-[130px]',
      key: 'id',
      name: 'ACTION',
      custom: (id: string) => {
        return (
          <div className='flex justify-end'>
            <Button
              color='tertiary'
              size='sm'
              className='my-1 mx-1'
              onClick={() =>
                history.push(
                  `/brand/${brandId}/location/${locationId}/payout/detail/${id}`,
                )
              }
            >
              DETAIL
            </Button>
          </div>
        )
      },
    },
  ]
  return (
    <Table
      loading={pageObj.loading}
      totalText=' payouts'
      columns={columns}
      data={tableData}
      isPageSet={true}
      current={pageObj.page}
      total={pageObj.total}
      resizePageSize={pageObj.per_page}
      onPageNoChange={page => {
        const obj = { ...pageObj, page }
        setPageObj(obj)
        getPayoutList(obj, true)
      }}
      onPageSizeChange={per_page => {
        const obj = { ...pageObj, per_page, page: 1 }
        setPageObj(obj)
        getPayoutList(obj, true)
      }}
    />
  )
}
