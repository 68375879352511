import React from 'react'

import cn from 'classnames'

type TNavItemProp = {
  label?: string
  value: string
  lv?: 1 | 2
  className?: string
  active?: boolean
  disabled?: boolean
  icon?: React.ReactElement
  onClick?: (value: string) => void
}

export default function NavItem({
  label,
  value,
  className = '',
  onClick,
  active = false,
  lv = 1,
  disabled = false,
  icon,
  ...arg
}: TNavItemProp): JSX.Element {
  if (disabled) {
    return <></>
  }
  return (
    <a
      className={cn(
        'flex items-center rounded-lg text-white py-3 text-sm hover:bg-light-8 cursor-pointer',
        lv === 1 ? 'px-4' : 'pl-8 pr-4',
        active ? '!bg-light-16' : '',
        // { hidden: disabled },
        className,
      )}
      onClick={() => onClick && onClick(value)}
      {...arg}
    >
      {icon && <span className='mr-2 flex items-center'>{icon}</span>}
      {label}
    </a>
  )
}
