import React, { useState, useEffect } from 'react'

import { Dollar } from '@rushable/icons'
import Field from 'components/Field'
import OpenHoursForm from 'components/OpenHoursForm'
import TabChangeBar from 'components/TabChangeBar'
import BaseOrderTypeConfig from 'pages/orderSetting/components/BaseOrderTypeConfig'
import { returnHoursInDays } from 'utils/hours'

import InHouseDeliverFee from './InHouseDeliverFee'
import OrderTypeDisclosure from './OrderTypeDisclosure'

export type TDeliverDisclosureProp = {
  name: string
  label: string
  type: string
  activeItem: string
  deliveryConfig: any
  inHouseConfig: any
  onChange: (name: string, value: any) => void
  tabChange: (value: string) => void
}
export default function DeliverDisclosure({
  name,
  label,
  type,
  activeItem,
  deliveryConfig,
  inHouseConfig,
  onChange,
  tabChange,
}: TDeliverDisclosureProp): JSX.Element {
  const options = (dispatchFee: string | number) => [
    `On-Demand Delivery ($${dispatchFee || '1.50'}/order dispatch fee)`,
    'In-House Delivery (Free)',
  ]
  const [openHoursDay, setOpenHoursDay] = useState({})

  const {
    enabled,
    open_hours_collection,
    order_min,
    availability,
    prior_to_close,
  } = (activeItem.includes('In-House') ? inHouseConfig : deliveryConfig) || {}
  const { dispatch_fee, courier_fee, restaurant_pay } = deliveryConfig || {}
  const { delivery_fees } = inHouseConfig || {}

  useEffect(() => {
    let days: any = returnHoursInDays([])
    open_hours_collection?.open_hours.map((v: { day: string | number }) => {
      days = { ...days, [v.day]: [...days[v.day], v] }
    })
    setOpenHoursDay({ ...days })
  }, [open_hours_collection])

  return (
    <OrderTypeDisclosure
      name={name}
      label={label}
      type={type}
      enabled={enabled}
      hoursStatus={'Follow business hours'}
      tabChange={tabChange}
      onChange={onChange}
    >
      <>
        <TabChangeBar
          items={options(dispatch_fee)}
          activeItem={
            activeItem.includes('In-House')
              ? options(dispatch_fee)[1]
              : options(dispatch_fee)[0]
          }
          className={'w-full mb-4'}
          containerClassName={'w-388px'}
          onClick={v => tabChange(v)}
        />

        {activeItem.includes('In-House') ? (
          <InHouseDeliverFee deliveryFees={delivery_fees} onChange={onChange} />
        ) : (
          <div className='flex gap-8 mb-4'>
            <Field
              type='text'
              disabled={true}
              suffix={<Dollar size={16} className='text-silver' />}
              value={`${courier_fee} per order`}
              name='courier_fee'
              label='Courier fee'
              containerClassName='w-full'
            />
            <Field
              type='tel'
              inputMode='decimal'
              name='customer_pay'
              label='Customer pay'
              value={6.99 - Number(restaurant_pay)}
              suffix={<Dollar size={16} className='text-silver' />}
              containerClassName='w-full'
              className='text-right'
              disabled={true}
            />
            <Field
              type='tel'
              inputMode='money'
              name='restaurant_pay'
              label='Restaurant pay'
              value={restaurant_pay}
              suffix={<Dollar size={16} className='text-silver' />}
              containerClassName='w-full'
              className='text-right'
              onChangeMoney={value => onChange('restaurant_pay', value)}
            />
          </div>
        )}

        <BaseOrderTypeConfig
          label={label}
          formData={{
            order_min,
            availability,
            prior_to_close,
          }}
          onFormChange={(name, value) => onChange(name, value)}
        />
        {availability === 'customize' && (
          <div className='border border-solid rounded-lg p-4 border-zinc mt-6'>
            <OpenHoursForm
              hoursInDay={openHoursDay}
              onChange={value => onChange('open_hours', value)}
            />
          </div>
        )}
      </>
    </OrderTypeDisclosure>
  )
}
