import React, { useState, useEffect } from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import { useAppSelector } from 'redux/hooks'

import CodeVerifyForm from './CodeVerifyForm'
export type TPhoneLoginFormProp = {
  setMethod: (v: string) => void
  handleFormChange: (name: string, value: string) => void
  handleSendCode: () => void
  phone: string
  errorMsg: string
}
export default function PhoneLoginForm({
  setMethod,
  handleFormChange,
  handleSendCode,
  phone,
  errorMsg,
}: TPhoneLoginFormProp): JSX.Element {
  const { sendCodeRequest, sendCodeSuccess, sendCodeError } = useAppSelector(
    state => state.auth,
  )
  const [type, setType] = useState<boolean>(false)
  useEffect(() => {
    if (sendCodeRequest) {
      setType(true)
    }
  }, [sendCodeRequest, sendCodeSuccess])
  return (
    <div>
      {type && sendCodeSuccess ? (
        <CodeVerifyForm
          phone={phone}
          setMethod={setMethod}
          handleFormChange={handleFormChange}
          handleSendCode={handleSendCode}
          errorMsg={errorMsg}
        />
      ) : (
        <form onSubmit={handleSendCode}>
          <div className='w-400px mx-auto my-6'>
            <Field
              label='Cellphone'
              name='phone'
              placeholder='Input your cellphone'
              inputMode='tel'
              type='tel'
              className='w-full'
              containerClassName='mb-4'
              onChangePhone={value => {
                handleFormChange('phone', value)
              }}
              value={phone}
              error={sendCodeError}
            />
          </div>
          <div className='mt-8 flex space-x-4 items-center justify-center'>
            <Button
              color='secondary'
              type='button'
              className='w-full'
              onClick={() => setMethod('')}
            >
              GO BACK
            </Button>
            <Button
              color='primary'
              type='submit'
              className='w-full'
              loading={sendCodeRequest}
            >
              SIGN IN
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
