import React, { useState, useEffect } from 'react'

import {
  AngleLeft,
  AngleRight,
  DuoToneMoney,
  DuoToneBank,
} from '@rushable/icons'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import moment from 'moment'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { getPayoutSummaryApi } from 'redux/accountAndPayout'
import { currencyFormatter } from 'utils/digit'

import TransactionsTable from './components/TransactionsTable'

export default function PayoutDetail() {
  const { locationId = '', payoutId = '' } = useParams<TParamTypes>()
  const [summary, setSummary] = useState<any>({})
  const getPayoutSummary = async () => {
    try {
      const res = await getPayoutSummaryApi(locationId, payoutId)
      setSummary(res)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
  }

  useEffect(() => {
    getPayoutSummary()
  }, [])

  return (
    <div>
      <AdminPageTitle
        title={`Payout ${
          summary.payout_created_at
            ? 'on ' + moment(summary.payout_created_at).format('ll')
            : 'Detail'
        }`}
        left={
          <Button color='secondary-link' onClick={() => history.go(-1)}>
            <AngleLeft className='mr-1' size={16} />
            GO BACK
          </Button>
        }
      />
      <div className='flex justify-between items-center my-6 px-8'>
        <div className='w-[488px] flex border border-zinc rounded-lg py-6 px-4 justify-between'>
          <div className='w-120px'>
            <span className='text-dark-64 text-xs'>
              {summary.summary?.order_count} Orders
            </span>
            <h6 className='text-dark-100 text-base font-bold leading-[24px]'>
              {summary.summary
                ? currencyFormatter(summary.summary?.order_total)
                : ''}{' '}
              USD
            </h6>
          </div>
          <div className='border-r border-zinc h-11'></div>
          <div className='w-120px'>
            <span className='text-dark-64 text-xs'>
              {summary.summary?.refund_count} Refunds
            </span>
            <h6 className='text-dark-100 text-base font-bold leading-[24px]'>
              {summary.summary
                ? currencyFormatter(summary.summary?.refund_amount)
                : ''}{' '}
              USD
            </h6>
          </div>
          <div className='border-r border-zinc h-11'></div>
          <div className='w-120px'>
            <span className='text-dark-64 text-xs'>
              {summary.summary?.adjustment_count} Adjustments
            </span>
            <h6 className='text-dark-100 text-base font-bold leading-[24px]'>
              {summary.summary
                ? currencyFormatter(summary.summary?.adjustment)
                : ''}{' '}
              USD
            </h6>
          </div>
        </div>
        <div>
          <AngleRight className='text-blue' size={24} />
        </div>
        <div className='w-[352px] flex justify-between border border-zinc rounded-lg py-6 px-4'>
          <div className='mr-6'>
            <span className='text-dark-64 text-xs'>Net amount</span>
            <div className='text-dark-100 text-base font-bold leading-[24px] flex items-center'>
              <DuoToneMoney className='mr-2' size={24} />
              {summary.summary
                ? currencyFormatter(summary.summary?.net_amount)
                : ''}{' '}
              USD
            </div>
          </div>
          <div className='w-36'>
            <span className='text-dark-64 text-xs'>
              {summary?.bank_name || 'Bank'}
            </span>
            <div className='text-dark-100 text-base font-bold leading-[24px] flex items-center'>
              <DuoToneBank className='mr-2' size={24} /> ••• {summary?.last_4}
            </div>
          </div>
        </div>
      </div>
      <div className='px-8 pb-8'>
        <TransactionsTable />
      </div>
    </div>
  )
}
