import cn from 'classnames'
import { SUPPORT_EMAIL } from 'data/constant'

import styles from './terms.module.scss'
export default function TermsOfService(): JSX.Element {
  return (
    <div className='container max-w-3xl mx-auto'>
      <div className='py-12'>
        <article
          className={cn(styles.terms, 'text-lead text-justify leading-6')}
        >
          <h1 className='text-5xl text-ink font-bold mb-2 text-center'>
            Terms of Service
          </h1>
          <p className='text-center'>Last updated on: June 29, 2020. </p>

          <p className='mt-12'>
            Welcome to&nbsp;Rushable, Inc.&nbsp;(&ldquo;Rushable&rdquo;,
            &ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;,
            &ldquo;us&rdquo;)! As you have just clicked our Terms of Service,
            please pause, grab a cup of coffee, and carefully read the following
            content. It will take you approximately 20&nbsp;minutes.
          </p>

          <p>
            These Terms of Service (&ldquo;Terms&rdquo;, &ldquo;Terms of
            Service&rdquo;) govern your use of&nbsp;our service via web access
            at https://rushable.io and its associated sub-domain websites, and
            our mobile application&nbsp;Rushable (together or individually
            &ldquo;Service&rdquo;)&nbsp;operated by&nbsp;Rushable, Inc.
          </p>

          <p>
            Our&nbsp;Privacy Policy&nbsp;also governs your use of our Service
            and explains how we collect, safeguard, and disclose information
            that results from your use of our Service.
          </p>

          <p>
            Additional terms and conditions may also apply to specific portions,
            services, or features of our Service. All such additional terms and
            conditions are hereby incorporated by this reference into these
            Terms.
          </p>

          <p>
            Your agreement with us includes these Terms&nbsp;and our Privacy
            Policy&nbsp;(&ldquo;Agreements&rdquo;). You acknowledge that you
            have read and understood the Agreements, and agree to be bound by
            them.
          </p>

          <p>
            If you do not agree with (or cannot comply with) the Agreements,
            then you must not access or use the Service, but please let us know
            by emailing at {SUPPORT_EMAIL} so we can try to find a solution.
            These Terms apply to all visitors, users, and others who wish to
            access or use the Service.
          </p>

          <p>
            The Service is intended only for access and use by individuals at
            least eighteen (18) years old. By accessing or using any of the
            Service, you warrant and represent that you are at least eighteen
            (18) years of age and with the full authority, right, and capacity
            to enter into the Agreements and abide by all of the terms and
            conditions thereof. If you are not at least eighteen (18) years old,
            you are prohibited from both the access and usage of the Service.
          </p>

          <p>Thank you for being responsible!</p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Account and Account Security{' '}
          </h3>

          <p>
            When you create an account with us, you guarantee that the
            information you provide us is accurate, complete, and current at all
            times. Inaccurate, incomplete, or obsolete information may result in
            the immediate termination of your account.
          </p>

          <p>
            You are responsible for maintaining the confidentiality of your
            account and password, including but not limited to the restriction
            of access to your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with
            our Service or a third-party service. You must notify us immediately
            upon becoming aware of any breach of security or unauthorized use of
            your account.
          </p>

          <p>
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
          </p>

          <p>
            We have the right to disable any username, password, or other
            identifier, whether chosen by you or provided by us, at any time if,
            in our opinion, you have violated any provision of these Terms. You
            are responsible for both:
          </p>

          <p>
            &bull; &nbsp; Making all arrangements necessary for you to have
            access to the Service.
          </p>

          <p>
            &bull; &nbsp;Ensuring that all persons who access the Service
            through your internet connection are aware of these Terms of Service
            and comply with them.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Purchases and Subscriptions
          </h3>

          <p>
            If you wish to purchase any product or service made available
            through the Service (&ldquo;Purchase&rdquo;), you may be asked to
            supply certain information relevant to your Purchase including,
            without limitation, your credit card number, the expiration date of
            your credit card, your billing address, and your shipping
            information.
          </p>

          <p>
            You represent and warrant that: (i) you have the legal right to use
            any credit card(s) or other payment method(s) in connection with any
            Purchase; and that (ii) the information you supply to us is true,
            correct and complete. &nbsp;
          </p>

          <p>
            You agree to pay fees in accordance with the rates listed
            https://rushable.io/pricing, unless otherwise set forth in an order
            form or order confirmation between the parties. you shall be
            responsible for and shall pay all Taxes imposed on or with respect
            to the Service. &quot;Taxes&quot; mean all applicable federal, state
            and local taxes, fees, charges, or other similar exactions,
            including, without limitation, sales and use taxes. For purposes of
            this paragraph, Taxes do not include any Taxes that are imposed on
            or measured by our net income, property tax, or payroll taxes.
          </p>

          <p>
            We may use third-party services for the purpose of facilitating
            payment and the completion of Purchases. By submitting your
            information, you grant us the right to provide the information to
            these third parties&nbsp;subject to our Privacy Policy.
          </p>

          <p>
            We reserve the right to refuse or cancel your order at any time for
            reasons including but not limited to: product or service
            availability, errors in the description or price of the product or
            service, error in your order or other reasons.
          </p>

          <p>
            We reserve the right to refuse or cancel your order if fraud or an
            unauthorized or illegal transaction is suspected.&nbsp;
          </p>

          <p>
            Some parts of the Service are billed on a subscription basis
            (&ldquo;Subscription(s)&rdquo;). You will be billed in advance on a
            recurring and periodic basis (&ldquo;Billing Cycle&rdquo;). Billing
            Cycles are set&nbsp;on a monthly basis.
          </p>

          <p>
            AT THE END OF EACH BILLING CYCLE, YOUR SUBSCRIPTION WILL
            AUTOMATICALLY RENEW UNLESS YOU CANCEL IT
            OR&nbsp;RUSHABLE&nbsp;CANCELS IT. You may cancel your Subscription
            renewal with ten (10) days prior written notice either through your
            account management page or by
            contacting&nbsp;Rushable&rsquo;s&nbsp;customer support team.
          </p>

          <p>
            A valid payment method, including a credit card, is required to
            process the payment for your subscription. You shall
            provide&nbsp;Rushable&nbsp;with accurate and complete billing
            information including full name, address, state, zip code, telephone
            number, and valid payment method information. By submitting such
            payment information, you automatically authorize&nbsp;Rushable to
            charge all Subscription fees incurred through your account to any
            such payment instruments.
          </p>

          <p>
            Should automatic billing fail to occur for any
            reason,&nbsp;Rushable&nbsp;will issue an electronic invoice
            indicating that you must proceed manually, within a certain deadline
            date, with the full payment corresponding to the billing period as
            indicated on the invoice.
          </p>

          <p>
            Rushable, in its sole discretion and at any time, may modify the
            Subscription fees. Any Subscription fee change will become effective
            at the end of the then-current Billing Cycle.
          </p>

          <p>
            Rushable &nbsp;will provide you with a reasonable prior notice of
            any change in the Subscription fees to give you an opportunity to
            terminate your Subscription before such change becomes effective.
          </p>

          <p>
            Your continued use of Service after the Subscription fee change
            comes into effect constitutes your agreement to pay the modified
            Subscription fee amount.
          </p>

          <p>
            Except when required by law, paid Subscription fees are
            non-refundable.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Website Hosting Service{' '}
          </h3>

          <p>
            If you subscribe to our website hosting service, you are able to use
            our Service to create a website for your restaurant
            (&ldquo;Restaurant Website&rdquo;). By subscribing to this service,
            you agree to use the Terms of Use and Privacy Policy built in the
            template for the Restaurant Website. You understand that the Terms
            of Use and Privacy Policy are agreements between you and your
            customers who visit the Restaurant Website, and that Rushable is not
            a party to these agreements and shall not be liable to your
            customers in any form or manner.&nbsp;
          </p>

          <p>
            Our Service allows you to post, link, store, share and otherwise
            make available certain information, text, graphics, videos, or other
            material (&ldquo;Content&rdquo;) on the Restaurant Website. You are
            responsible for Content that you post on or through the Service,
            including its legality, reliability, and appropriateness.
          </p>

          <p>
            By posting the Content on or through the Service, You represent and
            warrant that: (i) the Content is yours (you own it) and/or you have
            the right to use it and the right to grant us the rights and license
            as provided in these Terms, and (ii) that the posting of your
            Content on or through the Service does not violate the privacy
            rights, publicity rights, copyrights, contract rights or any other
            rights of any person or entity. We reserve the right to suspend or
            terminate the account of anyone found to be infringing on the
            intellectual property rights of others.
          </p>

          <p>
            You retain any and all of your rights to any Content you submit,
            post or display on or through the Service and you are responsible
            for protecting those rights. We take no responsibility and assume no
            liability for the Content you or any third party posts on or through
            the Service. However, by posting the Content using the Service you
            grant us the right and license to use, modify, publicly perform,
            publicly display, reproduce, and distribute such Content on and
            through the Service. You agree that this license includes the right
            for us to make your Content available to other users of the Service,
            who may also use your Content subject to these Terms.
          </p>

          <p>
            Rushable neither endorses nor assumes any liability for any Content
            uploaded or submitted by users through the Service. Rushable has the
            right but not the obligation to monitor and edit all Content
            provided by users. Rushable does not warrant the offerings of any
            users and does not assume any responsibility or liability for the
            actions, products, and services of its users.&nbsp;
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>Prohibited Uses </h3>

          <p>
            You may use the Service only for lawful purposes and in accordance
            with the Terms. You agree not to use the Service:
          </p>

          <ol>
            <li dir='ltr'>
              <p>
                In any way that violates any applicable national or
                international law or regulation.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content or otherwise.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                To transmit, or procure the sending of, any advertising or
                promotional material, including any &ldquo;junk mail&rdquo;,
                &ldquo;chain letter,&rdquo; &ldquo;spam,&rdquo; or any other
                similar solicitation.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                To impersonate or attempt to impersonate the Company, a Company
                employee, another user, or any other person or entity.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                In any way that infringes upon the rights of others, or in any
                way is illegal, threatening, fraudulent, or harmful, or in
                connection with any unlawful, illegal, fraudulent, or harmful
                purpose or activity.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                To engage in any other conduct that restricts or inhibits
                anyone&rsquo;s use or enjoyment of Service, or which, as
                determined by us, may harm or offend the Company or users of
                Service or expose them to liability.
              </p>
            </li>
          </ol>

          <p>Additionally, you agree not to:</p>

          <ol>
            <li dir='ltr'>
              <p>
                Use the Service in any manner that could disable, overburden,
                damage, or impair the Service or interfere with any other
                party&rsquo;s use of the Service, including their ability to
                engage in real-time activities through the Service.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                Use any robot, spider, or other automatic devices, process, or
                means to access the Service for any purpose, including
                monitoring or copying any of the material on the Service.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                Use any manual process to monitor or copy any of the material on
                the Service or for any other unauthorized purpose without our
                prior written consent.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                Use any device, software, or routine that interferes with the
                proper working of the Service.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                Introduce any viruses, trojan horses, worms, logic bombs, or
                other material which is malicious or technologically harmful.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Service, the server on which the
                Service is stored, or any server, computer, or database
                connected to the Service.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                Attack the Service via a denial-of-service attack or a
                distributed denial-of-service attack.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                Take any action that may damage or falsify the Company rating.
              </p>
            </li>
            <li dir='ltr'>
              <p>
                Otherwise attempt to interfere with the proper working of the
                Service.
              </p>
            </li>
          </ol>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Online Ordering Service{' '}
          </h3>

          <p>
            If you use our online ordering service, you authorize us to process
            your customer orders (&ldquo;Orders&rdquo;). We use Stripe, Inc.
            (&ldquo;Stripe&rdquo;) to collect credit card payments from your
            customers in connection with the Orders for You. You agree to be
            bound by the Stripe Connected Account Agreement, the Stripe Services
            Agreement, and any updates or modifications to either of those
            documents made from time to time by Stripe. You shall comply with
            ours and Stripe&rsquo;s instructions to timely, accurately and
            truthfully set up the payment account on our online ordering
            platform (&ldquo;Platform&rdquo;), which is necessary for us to
            perform our obligations under these Terms. Subject to the
            disclaimers expressly provided in these Terms, our sole liability is
            to use commercially reasonable efforts to assist you in the event
            there is any issue or dispute regarding collecting credit card
            payments from your customers with Stripe.
          </p>

          <p>
            YOU EXPRESSLY ACKNOWLEDGE, AGREE, AND UNDERSTAND THAT RUSHABLE IS
            NOT AND SHALL NOT BE LIABLE FOR ANY SUMS THAT STRIPE FAILS TO REMIT
            TO YOU IN CONNECTION WITH THE ORDERS, AND YOUR SOLE RECOURSE SHALL
            BE DIRECTLY AGAINST STRIPE. YOU HEREBY FULLY, FINALLY, AND FOREVER
            RELEASE RUSHABLE FROM ANY AND ALL CLAIMS ARISING OUT OF
            STRIPE&rsquo;S FAILURE TO REMIT PAYMENT IN CONNECTION WITH THE
            ORDERS.
          </p>

          <p>
            In connection with the Orders, You shall: (i) ensure that the tax
            rates set forth in your Rushable account are correct; (ii) update
            and change such tax rates in the account if the tax rates change;
            and (iii) pay all applicable sales, use, and other such taxes
            related to the Orders and the processing of such Orders for your
            customers (excluding any taxes imposed or based on Rushable&rsquo;s
            income).
          </p>

          <p>
            The proceeds from your customers are displayed in your account on
            our Platform and are scheduled to be paid to you periodically via
            settlement into the bank account connected to your account. You
            agree that a fulfillment charge (&ldquo;Fulfillment Charge&rdquo;)
            at the rate set forth on our website will be deducted from the
            proceeds for each transaction processed through our Platform.
            Rushable, in its sole discretion, may modify the rate of the
            Fulfillment Charge at any time. Rushable will provide you with a
            reasonable prior notice of any change to the Fulfillment Charge to
            give you an opportunity to terminate the Service before such change
            becomes effective. Your continued use of the Service after the
            change comes into effect constitutes your agreement to pay the
            modified Fulfillment Charge.
          </p>

          <p>
            Our Platform allows you to make a refund to your customer within
            thirty (30) days after the customer&rsquo;s payment is posted to
            your account. In the event that a refund is issued to your customer
            through our Platform, Rushable will deduct the refund amount from
            your account on our Platform. In the event your account balance is
            below the refund amount, Rushable will draft the difference through
            Stripe from your bank connected to your account and then will deduct
            the refund amount from your account. If Rushable fails to draft the
            fund from your bank account for any reason, we will issue an
            electronic invoice to you, which shall be paid according to the
            terms of the invoice. Please note that the Fulfillment Charge for
            the refund amount is non-refundable.&nbsp;
          </p>

          <p>
            In the event that your customer disputes a payment, the disputed
            amount and a dispute fee (the amount of which may vary depending on
            the policy of the customer&rsquo;s bank) will be deducted from your
            account through the process set forth in the preceding paragraph.
            &nbsp;You have the ability to respond to the dispute and submit
            accurate and relevant documents and other evidence (collectively,
            the &ldquo;Evidence&rdquo;) through our Platform to support your
            appeal, which the Evidence will in turn be submitted through Stripe
            to the customer&rsquo;s bank for review. If you appeal successfully
            with the customer&rsquo;s bank, the disputed amount and the dispute
            fee will be issued back to your account on our Platform. If your
            customer&rsquo;s bank denies your appeal, the disputed amount will
            be refunded to the customer and our Platform will not support
            further appeal. Please note that the Fulfillment Charge for the
            disputed amount is non-refundable, regardless of the appeal result.
            Rushable&rsquo;s sole responsibility in the process is to use
            commercially reasonable efforts to assist you and Rushable cannot
            promise or guarantee specific results of your appeal.&nbsp;
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Advertisements and Promotions
          </h3>

          <p>
            By creating an account with us, you agree to subscribe to
            newsletters, marketing or promotional materials and other
            information we may send. However, you may opt out of receiving any,
            or all, of these communications from us by following the unsubscribe
            link.
          </p>

          <p>
            Any contests, sweepstakes or other promotions (collectively,
            &ldquo;Promotions&rdquo;) made available through the Service may be
            governed by rules that are separate from these Terms of Service. If
            you participate in any Promotions, please review the applicable
            rules&nbsp;as well as our Privacy Policy. If the rules for a
            Promotion conflict with these Terms of Service, the Promotion rules
            will apply.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Reservation of Rights
          </h3>

          <p>
            The Service and its original content (excluding Content provided by
            users), features and functionality are and will remain the exclusive
            property of&nbsp;Rushable,&nbsp;and its licensors. The Service is
            protected by copyright, trademark, and other laws of&nbsp;the United
            States and foreign countries. Nothing in these Terms grants you any
            right, title, or interest in or to any intellectual property rights
            in or relating to, the Service, our content, or third-party
            materials, whether expressly, by implication, estoppel, or
            otherwise. You may not copy, reproduce, modify, republish, upload,
            post, transmit, or distribute any content from our Service in any
            form or by any means whatsoever or use our trademarks, trade dress,
            or any other intellectual property in connection with any product or
            service without prior written permission from us.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Copyright Policy{' '}
          </h3>

          <p>
            We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content posted on or through our
            &nbsp;Service infringes on the copyright or other intellectual
            property rights (&ldquo;Infringement&rdquo;) of any person or
            entity.
          </p>

          <p>
            If you are a copyright owner, or authorized on behalf of one, and
            you believe that the copyrighted work has been copied or used in a
            way that constitutes copyright infringement, please submit your
            claim to our copyright agent designated below by written
            notification with the subject line: &ldquo;Copyright
            Infringement&rdquo; and include in your claim a detailed description
            of the alleged Infringement as detailed below.&nbsp;
          </p>

          <p>
            Pursuant to the Digital Millennium Copyright Act
            (&ldquo;DMCA&rdquo;) (17 U.S.C 512(c)(3)), the written notice
            (&ldquo;DMCA Notice&rdquo;) must include substantially the
            following:
          </p>

          <ol>
            <li dir='ltr'>
              <p>
                an electronic or physical signature of the person authorized to
                act on behalf of the owner of the copyright&rsquo;s interest;
              </p>
            </li>
            <li dir='ltr'>
              <p>
                a description of the copyrighted work that you claim has been
                infringed, including the URL (i.e., web page address) of the
                location where the copyrighted work exists or a copy of the
                copyrighted work;
              </p>
            </li>
            <li dir='ltr'>
              <p>
                identification of the material you believe to be infringing in a
                sufficiently precise manner to allow us to allocate the
                material;&nbsp;
              </p>
            </li>
            <li dir='ltr'>
              <p>
                adequate information by which we can contact you including your
                name, address, telephone number, and email address;
              </p>
            </li>
            <li dir='ltr'>
              <p>
                a statement by you that you have a good faith belief that the
                use of the copyrighted material is not authorized by the
                copyright owner, its agent, or the law;
              </p>
            </li>
            <li dir='ltr'>
              <p>
                a statement by you, made under penalty of perjury, that the
                above information in your notice is accurate; and&nbsp;
              </p>
            </li>
            <li dir='ltr'>
              <p>
                a statement, under penalty of perjury, that you are the
                copyright owner or authorized to act on the copyright
                owner&rsquo;s behalf.
              </p>
            </li>
          </ol>

          <p>Our designated copyright agent to receive DMCA Notices is:</p>

          <p>
            &nbsp; &nbsp; Copyright Compliance Department &nbsp; &nbsp; &nbsp;
            &nbsp;&nbsp;
          </p>

          <p>Rushable Inc.&nbsp;</p>

          <p>10333 Harwin Drive, Suite 570</p>

          <p>Houston, TX 77036&nbsp;</p>

          <p>+1 855 909 2609</p>

          <p>legal@rushable.io</p>

          <p>&nbsp;</p>

          <p>
            If you fail to comply with all of the requirements of Section
            512(c)(3) of the DMCA, your DMCA Notice may not be effective.
          </p>

          <p>
            Please be aware that you may be held accountable for damages
            (including costs and attorneys&rsquo; fees) for misrepresentation or
            bad faith claims on the infringement of any Content found on and/or
            through our Service on your copyright under Section 512(f) of the
            DMCA.
          </p>

          <p>
            If you believe that Content you posted on or through our Service was
            removed or access to it was disabled by mistake or
            misidentification, you may file a counter notification with us (a
            &quot;Counter Notice&quot;) by submitting written notification to
            our copyright agent designated above. Pursuant to the DMCA, the
            Counter Notice must include substantially the following:
          </p>

          <ol>
            <li dir='ltr'>
              <p>your physical or electronic signature;</p>
            </li>
            <li dir='ltr'>
              <p>
                an identification of the material that has been removed or to
                which access has been disabled and the location at which the
                Content appeared before it was removed or access disabled;
              </p>
            </li>
            <li dir='ltr'>
              <p>
                adequate information by which we can contact you, including your
                name, postal address, telephone number, and, if available, email
                address;
              </p>
            </li>
            <li dir='ltr'>
              <p>
                a statement under penalty of perjury by you that you have a good
                faith belief that the material identified above was removed or
                disabled as a result of a mistake or misidentification of the
                material to be removed or disabled; and
              </p>
            </li>
            <li dir='ltr'>
              <p>
                a statement that you will consent to the jurisdiction of the
                Federal District Court for the judicial district in which your
                address is located (or if you reside outside the United States
                for any judicial district in which we may be found) and that you
                will accept service from the person (or an agent of that person)
                who provided us with the complaint at issue.
              </p>
            </li>
          </ol>

          <p>
            The DMCA allows us to restore the removed Content if the party
            filing the original DMCA Notice does not file a court action against
            you within ten (10) business days of receiving the copy of your
            Counter Notice.
          </p>

          <p>
            Please be aware that if you knowingly materially misrepresent that
            material or activity on or through our Service was removed or
            disabled by mistake or misidentification, you may be held liable for
            damages (including costs and attorneys&#39; fees) under Section
            512(f) of the DMCA.
          </p>

          <p>
            It is our policy in appropriate circumstances to disable and/or
            terminate the accounts of users who are repeat infringers.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            User Feedback &nbsp;
          </h3>

          <p>
            You may provide us&nbsp;directly at {SUPPORT_EMAIL}&nbsp;with
            information and feedback concerning errors, suggestions for
            improvements, ideas, problems, complaints, and other matters related
            to our Service (&ldquo;Feedback&rdquo;). You acknowledge and agree
            that: (i) you shall not retain, acquire or assert any intellectual
            property right or other right, title or interest in or to the
            Feedback; (ii) Rushable may have development ideas similar to the
            Feedback; (iii) Feedback does not contain confidential information
            or proprietary information from you or any third party; and (iv)
            Rushable is not under any obligation of confidentiality with respect
            to the Feedback. In the event the transfer of the ownership to the
            Feedback is not possible due to applicable mandatory laws, you grant
            Rushable and its affiliates an exclusive, transferable, irrevocable,
            free-of-charge, sub-licensable, unlimited and perpetual right to use
            (including copy, modify, create derivative works, publish,
            distribute and commercialize) the Feedback in any manner and for any
            purpose.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Third-Party Websites
          </h3>

          <p>
            Our Service may contain links to third-party websites or services
            that are not owned or controlled by&nbsp;Rushable.
          </p>

          <p>
            Rushable has no control over and assumes no responsibility for the
            content, privacy policies, or practices of any third-party websites
            or services. We do not warrant the offerings of any of these
            entities/individuals or their websites.
          </p>

          <p>
            YOU ACKNOWLEDGE AND AGREE THAT&nbsp;RUSHABLE&nbsp;SHALL NOT BE
            RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR
            LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF
            OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
            THROUGH ANY SUCH THIRD-PARTY WEBSITES OR SERVICES.
          </p>

          <p>
            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
            POLICIES OF ANY THIRD-PARTY WEBSITES OR SERVICES THAT YOU VISIT.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Temporary Interruptions
          </h3>

          <p>
            You understand and agree that temporary interruptions of our Service
            may occur as normal events that are out of our control. You also
            understand and agree that we have no control over the third-party
            networks or service(s) that we may use to provide you with our
            Service.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Disclaimer of Warranties
          </h3>

          <p>
            THESE SERVICES ARE PROVIDED BY COMPANY ON AN &ldquo;AS IS&rdquo; AND
            &ldquo;AS AVAILABLE&rdquo; BASIS. COMPANY MAKES NO REPRESENTATIONS
            OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION
            OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED
            THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE, THE
            CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE
            RISK.
          </p>

          <p>
            NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
            SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
            ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE, THE
            CONTENT, OR ANY OTHER SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE
            WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
            DEFECTS WILL BE CORRECTED, THAT THE SERVICE OR THE SERVER THAT MAKES
            IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT
            THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE
            WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>

          <p>
            COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
            ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            PARTICULAR PURPOSE.
          </p>

          <p>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>Indemnification</h3>

          <p>
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
            PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
            ARISES (INCLUDING ATTORNEYS&rsquo; FEES AND ALL RELATED COSTS AND
            EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF
            ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED),
            WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS
            ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT,
            INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR
            PROPERTY DAMAGE, ARISING FROM THIS &nbsp;AGREEMENT AND ANY VIOLATION
            BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
            REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE.&nbsp;
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Limitation on Liability{' '}
          </h3>

          <p>
            EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART
            OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS
            AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
            CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Limitation on Time to File Claims{' '}
          </h3>

          <p>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THESE TERMS OF SERVICE OR THE SERVICE MUST BE COMMENCED WITHIN
            ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH
            CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
          </p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Confidential Information.{' '}
          </h3>

          <p>
            All non-public, confidential or proprietary information of Rushable,
            including but not limited to, specifications, patterns, designs,
            plans, drawings, documents, data, business operations, customer
            lists, pricing, or discounts disclosed by Rushable to you, whether
            disclosed orally or disclosed or accessed in written, electronic or
            other form or media, and whether or not marked, designated, or
            otherwise identified as &ldquo;confidential&rdquo; in connection
            with the Service is confidential, solely for the use of performing
            these Terms and may not be disclosed or copied unless authorized in
            advance by Rushable in writing. Upon Rushable&rsquo;s request, you
            shall promptly return all documents and other materials received
            from Rushable. Rushable shall be entitled to injunctive relief for
            any violation of this paragraph. This paragraph does not apply to
            information that is: (a) in the public domain; (b) known to you at
            the time of disclosure; or (c) rightfully obtained by you on a
            non-confidential basis from a third party provided that such third
            party is not and was not prohibited from disclosing such
            confidential information to you by a legal, fiduciary, or
            contractual obligation to Rushable.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>Force Majeure </h3>

          <p>
            Rushable will not be liable or responsible to you, nor be deemed to
            have defaulted or breached these Terms, for any failure or delay in
            our performance under these Terms when and to the extent such
            failure or delay is caused by or results from acts or circumstances
            beyond our reasonable control, including, without limitation, acts
            of God, flood, fire, earthquake, explosion, governmental actions,
            war, invasion or hostilities (whether war is declared or not),
            terrorist threats or acts, riot or other civil unrest, national
            emergency, revolution, insurrection, epidemic, lockouts, strikes or
            other labor disputes (whether or not relating to our workforce), or
            restraints or delays affecting carriers or inability or delay in
            obtaining supplies of adequate or suitable materials, materials or
            telecommunication breakdown or power outage.
          </p>

          <h3 className='text-2xl text-ink font-bold my-6'>Assignment</h3>

          <p>
            You shall not assign any of its rights or delegate any of its
            obligations under these Terms without our prior written consent. Any
            purported assignment or delegation in violation of this paragraph is
            null and void. No assignment or delegation relieves you of any of
            its obligations under these Terms.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            No Third-Party Beneficiary
          </h3>

          <p>
            These terms for the sole benefit of the parties hereto and their
            respective successors and permitted assigns and nothing herein,
            express or implied, is intended to or shall confer upon any other
            person or entity any legal or equitable right, benefit, or remedy of
            any nature whatsoever under or by reason of these Terms.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Relationship of the Parties
          </h3>

          <p>
            The relationship between the parties is that of independent
            contractors. Nothing contained in these Terms shall be construed as
            creating any agency, partnership, joint venture, or other form of
            joint enterprise, employment, or fiduciary relationship between the
            parties, and neither party shall have authority to contract for or
            bind the other party in any manner whatsoever.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Governing Law and Jurisdiction{' '}
          </h3>

          <p>
            These Terms shall be governed and construed in accordance with the
            laws of the State of Texas, United States, without regard to its
            conflict of law provisions.&nbsp;
          </p>

          <p>
            Any legal suit, action, or proceeding arising out of, or related to,
            these Terms or the Service shall be instituted exclusively in the
            federal courts of the United States or the courts of the State of
            Texas, in each case located in the City of Houston and County of
            Harris. You waive any and all objections to the exercise of
            jurisdiction over you by such courts and to venue in such courts.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Modification of Service{' '}
          </h3>

          <p>
            We reserve the right to withdraw or amend our Service, and any
            service or material we provide via the Service, in our sole
            discretion without notice. We will not be liable if for any reason
            all or any part of the Service is unavailable at any time or for any
            period. From time to time, we may restrict access to some parts of
            the Service, or the entire Service, to users, including registered
            users.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Changes to the Terms
          </h3>

          <p>
            We may amend the Terms at any time by posting the amended terms on
            this site. All changes are effective immediately when we post them.
          </p>

          <p>
            Your continued use of our Services following the posting of the
            revised Terms means that you accept and agree to the changes. You
            are expected to check this page frequently, so you are aware of any
            changes, as they are binding on you. If you do not agree to the new
            terms, you are no longer authorized to use the Service.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>No Waiver </h3>

          <p>
            No waiver by Company of any term or condition set forth in these
            Terms shall be deemed a further or continuing waiver of such term or
            condition or a waiver of any other term or condition, and any
            failure of Company to assert a right or provision under these Terms
            shall not constitute a waiver of such right or provision.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>Severability</h3>

          <p>
            If any provision of these Terms is held by a court or other tribunal
            of competent jurisdiction to be invalid, illegal or unenforceable
            for any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of these Terms
            will continue in full force and effect.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>Termination</h3>

          <p>
            We may terminate or suspend your account and bar access to the
            Service immediately, without prior notice or liability, under our
            sole discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of the Terms.
          </p>

          <p>
            If you wish to terminate your account, you may simply discontinue
            using the Service.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>Survival</h3>

          <p>
            All provisions of these Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>

          <p>&nbsp;</p>

          <h3 className='text-2xl text-ink font-bold my-6'>
            Entire Agreement{' '}
          </h3>

          <p>
            These Terms and other agreements incorporated herein (including but
            not limited to the Privacy Policy) &nbsp;constitute the entire
            agreement between us regarding our Service and supersede and replace
            any prior agreements we might have had between us regarding the
            Service.
          </p>

          <p>&nbsp;</p>

          <p>&nbsp;</p>

          <p>
            BY USING THE SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
            ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO
            BE BOUND BY THEM.
          </p>

          <p>
            Please send your feedback, comments, requests for technical support:
          </p>

          <p>By email: {SUPPORT_EMAIL}.</p>
        </article>
      </div>
    </div>
  )
}
