export const SELECTIONS_LIST = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
]

export const SELECTIONS_LIST_MAX = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '999999999', label: 'unlimited' },
]

export const REPEAT_ALLOW = [
  { value: 0, label: 'NO' },
  { value: 1, label: 'YES' },
]

export const BULK_EDIT_ACTION = [
  { value: 'price', label: 'Bulk price adjustment' },
  { value: 'availability', label: 'Bulk availability adjustment' },
]

export const PRICE_BULK_EDIT = [
  { value: 'flat', label: 'Increase by a flat amount' },
  { value: 'increase_percent', label: 'Increase by a percentage' },
  { value: 'decrease_percent', label: 'Decrease by a percentage' },
]

export const AVAILABILITY_BULK_EDIT = [
  { value: 'availability', label: 'Available' },
  { value: 'restock', label: 'Restock on a date' },
  { value: 'make_inactive', label: 'Make inactive' },
]

export const BUSINESS_HOURS_OPTIONS = [
  { value: 'same_as_store', label: 'Same as store hours' },
  { value: 'customize', label: 'Custom hours' },
]

export const PRICING_MODE = [
  { value: 'simple_pricing', label: 'Simple Pricing' },
  { value: 'variable_pricing', label: 'Variable Pricing' },
]
