// Copied as is from https://usehooks.com/usePrevious/
import { useRef } from 'react'

// Hook
function usePrevious<T>(value: T) {
  const ref = useRef(value)
  ref.current = value

  return ref
}

export default usePrevious
