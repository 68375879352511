import React from 'react'

import { SolidGiftCard } from '@rushable/icons'
import PayIconRender from 'components/PayIcon/PayIconRender'
import { currencyFormatter } from 'utils/digit'

type TGiftCardTransaction = {
  amount: string
  created_at: string
  gift_card: { id: number; card_number_last_4: string }
  gift_card_id: number
  id: number
  order_id: number
  status: string
  type: string
}

type TGiftCard = {
  brand: string
  last_4: string
  total: string
  giftCards: TGiftCardTransaction[]
}

export default function GiftCards({
  brand,
  last_4,
  total,
  giftCards,
}: TGiftCard) {
  if (!giftCards || !giftCards?.length) {
    return null
  }
  const paymentTotal = () => {
    let result = Number(total) * 100
    if (giftCards && giftCards.length > 0) {
      giftCards.forEach(item => {
        result = result - Number(item.amount) * 100
      })
    }
    return currencyFormatter(result / 100)
  }
  return (
    <div className='mt-4 pt-4 border-t border-zinc border-dashed text-base text-ink'>
      <div className='pb-2'>Payment method</div>
      {giftCards.map(item => {
        return (
          <div className='pb-2 flex justify-between items-end' key={item.id}>
            <div className='flex items-center'>
              <SolidGiftCard className='text-dark-32 mr-1' size={16} />
              <span>
                •••• {item?.gift_card?.card_number_last_4} (gift card)
              </span>
            </div>
            <div>{currencyFormatter(+item.amount)}</div>
          </div>
        )
      })}
      {brand && last_4 ? (
        <div className='flex justify-between items-end'>
          <div className='flex items-center'>
            <PayIconRender
              type={brand}
              iconParams={{ size: 16, className: 'inline-block mr-1' }}
            />
            <span>{last_4 && `•••• ${last_4}`}</span>
          </div>
          <div>{paymentTotal()}</div>
        </div>
      ) : null}
    </div>
  )
}
