import { createReducer } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { TLocationStateProp } from 'types/location'

import { getLocations, getLocation, setLocations } from './actions'

const initialState: TLocationStateProp = {
  getLocationsRequest: false,
  locations: [],
  getLocationRequest: false,
  selectedLocation: null,
}

export const locationReducer = createReducer(initialState, builder => {
  builder
    .addCase(getLocations.pending, state => {
      state.getLocationsRequest = true
    })
    .addCase(getLocations.fulfilled, (state, action) => {
      state.getLocationsRequest = false
      const data = action.payload || {}
      state.locations = data
    })
    .addCase(getLocations.rejected, (state, action) => {
      state.getLocationsRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(getLocation.pending, state => {
      state.getLocationRequest = true
    })
    .addCase(getLocation.fulfilled, (state, action) => {
      state.getLocationRequest = false
      const data = action.payload || {}
      state.selectedLocation = data
    })
    .addCase(getLocation.rejected, (state, action) => {
      state.getLocationRequest = false
      if (action.error.message) {
        toast.warn(action.error.message)
      }
    })
    .addCase(setLocations, (state, action) => {
      const { locations } = action.payload
      state.locations = locations
    })
})

export default locationReducer
