import React, { useEffect, useState } from 'react'

import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getMenuModifierCountAPI,
  deleteMenuModifierAPI,
} from 'redux/onlineMenu'

type TDeleteModifierProp = {
  id: number
  open: boolean
  onChange: (isSuccess: boolean) => void
}

export default function DeleteModifier({
  id,
  open,
  onChange,
}: TDeleteModifierProp): JSX.Element {
  const [count, setCount] = useState(0)
  const [deleteValue, setDeleteValue] = useState('')
  const [loading, setLoading] = useState(false)
  const { locationId } = useParams<TParamTypes>()

  useEffect(() => {
    setDeleteValue('')
    if (open && id) {
      getMenuModifierCount()
    }
  }, [open])

  const getMenuModifierCount = async () => {
    try {
      const params = {
        menu_modifier_id: id,
        locationId,
      }
      const res = await getMenuModifierCountAPI(params)
      setCount(res)
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
  }

  const deleteMenuModifier = async () => {
    setLoading(true)
    try {
      const params = {
        menu_modifier_id: id,
        locationId,
      }
      const res = await deleteMenuModifierAPI(params)
      onChange(true)
      toast.success(res.message)
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  return (
    <ModalFull
      open={open}
      toggle={() => onChange(false)}
      title='Deletion Confirmation'
      loading={loading}
      okBtnDisabled={deleteValue !== 'Delete'}
      okBtnColor={'warning'}
      onCancel={() => onChange(false)}
      onOk={deleteMenuModifier}
    >
      <div className='text-center mb-6'>
        This modifier is being used by other {count} menu items, deleting it
        will cause it disappear across your menu, please confirm your deletion.
      </div>
      <Field
        name='Delete'
        type='text'
        value={deleteValue}
        placeholder='Delete'
        label='Type “Delete” to confirm'
        onChange={e => setDeleteValue(e.target.value)}
      />
    </ModalFull>
  )
}
