import React, { useState, useEffect, useRef } from 'react'

import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import {
  LinedSearch,
  Dollar,
  Percentage,
  Plus,
  SolidImage,
  ArrowRight,
} from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import CalendarField from 'components/CalendarField'
import CheckBox from 'components/CheckBox'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import SaveChangeBar from 'components/SaveChangeBar'
import { Select } from 'components/Select'
import TextArea from 'components/TextArea'
import ThumbnailsImage from 'components/ThumbnailsImage'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import useScroll from 'hooks/useScroll'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { StickyContainer, Sticky } from 'react-sticky'
import { toast } from 'react-toastify'
import {
  editMenuCategoryAPI,
  deleteMenuCategoryAPI,
  deleteMenuItemAPI,
  sortMenuCategoryAPI,
  sortMenuItemAPI,
  showMenuCategoryAPI,
  bulkEditMenuItemAPI,
  getMenuCollectionCountAPI,
} from 'redux/onlineMenu'
import parseMediaAbsoluteURL from 'utils/parseMediaAbsoluteURL'

import {
  BULK_EDIT_ACTION,
  PRICE_BULK_EDIT,
  AVAILABILITY_BULK_EDIT,
} from '../helpers/constant'
import MenuListCategoryItem from './MenuListCategoryItem'
import OnlineMenuSkeleton from './OnlineMenuSkeleton'

type TMenuItemListPageProp = {
  menuItemList: any[]
  initLoading: boolean
  menuItemIndex: any
  onScroll: ({ i, j }: any) => void
  onChange: () => void
}

export default function MenuItemListPage({
  menuItemList,
  initLoading,
  menuItemIndex,
  onScroll,
  onChange,
}: TMenuItemListPageProp): JSX.Element {
  const { locationId } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<any>([])
  const [formDataInit, setFormDataInit] = useState<any>([])
  const [formIsDirty, setFormIsDirty] = useState(false)
  const [rightScrollTopList, setRightScrollTopList] = useState<any>({ 0: [0] })
  const [filterList, setFilterList] = useState<any>([])
  const [filterValue, setFilterValue] = useState('')
  const [priceValue, setPriceValue] = useState('')
  const [calendarValue, setCalendarValue] = useState('')
  const [menuActionId, setMenuActionId] = useState(0)
  const [bulkOpen, setBulkOpen] = useState(false)
  const [bulkEdit, setBulkEdit] = useState(false)
  const [bulkAction, setBulkAction] = useState('')
  const [bulkActionOption, setBulkActionOption] = useState('')
  const [error, setError] = useState('')
  const [saveBulkEdit, setSaveBulkEdit] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searching, setSearching] = useState(false)
  const [searchFormData, setSearchFormData] = useState<any>([])
  const [checkedSelectCount, setCheckedSelectCount] = useState(0)
  const [shadow, setShadow] = useState(false)
  const [menuActionInfo, setMenuActionInfo] = useState<Record<string, any>>({
    open: false,
    method: '',
    type: '',
    id: 0,
    name: '',
    description: '',
    deleteValue: '',
  })
  const rightItemList = useRef(null)
  const rightScroll = useScroll(rightItemList)
  const history = useHistory()
  const { url } = useRouteMatch()
  const compareFn = useDebounce(compare)
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  })
  const sensors = useSensors(mouseSensor)

  useEffect(() => {
    getMenuCollectionCount()
  }, [])

  useEffect(() => {
    if (menuItemList.length) {
      if (rightItemList && rightItemList.current) {
        const rightList: any = rightItemList.current
        const categoryList: any = rightList.children
        let newRightScrollTopList: any = { 0: [0] }
        let scrollHeight = 0
        setTimeout(() => {
          Array.from(categoryList).map((category: any, i: number) => {
            const menuItemL: any = category.children[1].children
            Array.from(menuItemL).map((menuItem: any, j: number) => {
              scrollHeight +=
                menuItem.offsetHeight +
                40 +
                (j === menuItemL.length - 1 ? -80 : 0)
              if (newRightScrollTopList[i]) {
                newRightScrollTopList[i].push(scrollHeight)
              } else {
                newRightScrollTopList = {
                  ...newRightScrollTopList,
                  [i]: [scrollHeight],
                }
              }
            })
          })
          setRightScrollTopList({ ...newRightScrollTopList })
        }, 0)
      }
    }
    setFormData([...menuItemList])
    setFormDataInit([...menuItemList])
  }, [menuItemList])

  useEffect(() => {
    const newRightItemList: any = rightItemList.current

    if (rightScrollTopList[0].length === 1 && !rightScrollTopList[1]) {
      newRightItemList.scrollTop = 0
    } else {
      const scrollTop = rightScrollTopList[menuItemIndex.i][menuItemIndex.j]
      newRightItemList.scrollTop = scrollTop || 0
    }
  }, [menuItemIndex])

  useEffect(() => {
    const scrollTop = rightScroll?.top
    if (scrollTop) {
      setShadow(scrollTop > 50)
      let scrollIndex = { i: 0, j: 0 }
      Object.keys(rightScrollTopList).map((rightItem, i) => {
        rightScrollTopList[rightItem].map((scrollTopItem: any, j: number) => {
          if (scrollTop >= scrollTopItem) {
            scrollIndex = { i, j }
          }
        })
      })
      onScroll({ ...scrollIndex })
    }
  }, [rightScroll])

  useEffect(() => {
    if (error) {
      setError('')
    }

    let formDataItemCount = 0
    formData.forEach((collectionItem: any) => {
      collectionItem.menu_categories.forEach((categoryItem: any) => {
        categoryItem.menu_items.forEach((item: any) => {
          formDataItemCount = formDataItemCount + 1
        })
      })
    })

    let formDataInitItemCount = 0
    formDataInit.forEach((collectionItem: any) => {
      collectionItem.menu_categories.forEach((categoryItem: any) => {
        categoryItem.menu_items.forEach(() => {
          formDataInitItemCount = formDataInitItemCount + 1
        })
      })
    })

    setSearching(formDataItemCount === formDataInitItemCount)

    compareFn(
      formData,
      formDataItemCount === formDataInitItemCount
        ? formDataInit
        : searchFormData,
      (flag: boolean) => setFormIsDirty(flag),
    )
  }, [formData, formDataInit])

  const getMenuCollectionCount = async () => {
    try {
      const res = await getMenuCollectionCountAPI({ locationId })
      const list: any = []
      Object.keys(res).forEach(item => {
        if (item === 'all_items') {
          list.push({ label: `All items(${res[item]})`, value: '' })
        }
        if (item === 'restocking') {
          list.push({
            label: `Restocking(${res[item]})`,
            value: 'out_of_order',
          })
        }
        if (item === 'unavailable') {
          list.push({ label: `Unavailable(${res[item]})`, value: 'inactive' })
        }
      })
      setFilterList([...list])
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
  }

  const handleChangeMenu = (
    method: string,
    { id, name }: { id: number; name: string },
    type: string,
  ) => {
    switch (method) {
      case 'open':
        setMenuActionId(menuActionId === id ? 0 : id)
        break
      case 'edit':
        if (type === 'Category') {
          setMenuActionInfo(prev => ({
            ...prev,
            id,
            type,
            method,
            open: true,
            name: '',
            describe: '',
          }))
          handleShowCategory(id)
        } else {
          history.push(`${url}/menu-item-detail/${id}`)
        }
        break
      case 'delete':
        setMenuActionInfo(prev => ({
          ...prev,
          id,
          type,
          name,
          method,
          open: true,
        }))
        break
    }
  }

  const handleFormChange = (
    name: string,
    value: string,
    { i, j, k, m }: any,
  ) => {
    const newFormData = cloneDeep(formData)
    const menuList = newFormData[i].menu_categories[j].menu_items
    if (typeof m !== 'undefined') {
      const modifierItem =
        menuList[k].menu_modifiers[0]?.menu_modifier_options[m]
      if (modifierItem) {
        modifierItem[name] = value
      }
    } else {
      menuList[k][name] = value
    }
    setFormData([...newFormData])
  }

  const closeBulkEdit = () => {
    if (!bulkEdit) {
      setBulkAction('')
    } else {
      setBulkActionOption('')
      setBulkEdit(false)
    }

    setBulkOpen(!bulkOpen)
  }

  const setBulkEditChecked = (value: boolean, { i, j, k }: any) => {
    const newFormData = cloneDeep(formData)

    if (typeof j !== 'undefined' && typeof k !== 'undefined') {
      const categoryItem = newFormData[i].menu_categories[j]

      categoryItem.menu_items[k].checked = value
      let checkedCount = 0
      categoryItem.menu_items.forEach((v: any) => {
        if (v.checked) {
          checkedCount = checkedCount + 1
        }
      })

      if (!checkedCount) {
        categoryItem.noAllChecked = false
        categoryItem.checked = false
      } else {
        if (categoryItem.menu_items.length !== checkedCount) {
          categoryItem.noAllChecked = true
          categoryItem.checked = false
        } else {
          categoryItem.noAllChecked = false
          categoryItem.checked = true
        }
      }
    } else if (typeof i !== 'undefined') {
      const categoryItem = newFormData[i].menu_categories[j]
      categoryItem.noAllChecked = false
      categoryItem.checked = value
      categoryItem.menu_items.forEach((v: any) => {
        v['checked'] = value
      })
    } else {
      newFormData.forEach((collectionItem: any) => {
        collectionItem.menu_categories.forEach((categoryItem: any) => {
          categoryItem['checked'] = value
          categoryItem.menu_items.forEach((v: any) => {
            v['checked'] = value
          })
        })
      })
      setBulkOpen(false)
    }

    let count = 0
    newFormData.forEach((collectionItem: any) => {
      collectionItem.menu_categories.forEach((categoryItem: any) => {
        categoryItem.menu_items.forEach((v: any) => {
          if (v.checked) {
            count = count + 1
          }
        })
      })
    })
    setCheckedSelectCount(count)
    setFormData([...newFormData])

    if (saveBulkEdit) {
      setBulkEditValue(newFormData)
    }
  }

  const setBulkEditValue = (formData: any) => {
    setBulkOpen(false)
    setSaveBulkEdit(true)

    const newFormData = cloneDeep(formData)
    newFormData.forEach((collectionItem: any) => {
      collectionItem.menu_categories.forEach((categoryItem: any) => {
        categoryItem.menu_items.forEach((menuItem: any) => {
          if (bulkAction === 'availability') {
            if (menuItem.checked) {
              const newStatus =
                bulkActionOption === 'availability'
                  ? 'active'
                  : bulkActionOption === 'make_inactive'
                  ? 'inactive'
                  : `Restock on ${moment(calendarValue).format('ll')}` // active
              menuItem.new_status = newStatus
            } else {
              delete menuItem?.new_status
            }
          } else if (bulkAction === 'price') {
            if (menuItem.menu_modifiers[0]?.private_type === 'pricing') {
              menuItem.menu_modifiers[0].menu_modifier_options.forEach(
                (priceItem: any) => {
                  if (menuItem.checked) {
                    const newPrice =
                      bulkActionOption === 'flat'
                        ? Number(priceItem.unit_price) + Number(priceValue)
                        : Number(priceItem.unit_price) *
                          (bulkActionOption === 'increase_percent'
                            ? Math.round(100 + Number(priceValue)) / 100
                            : Math.round(100 - Number(priceValue)) / 100)
                    priceItem.new_unit_price = !newPrice
                      ? '0.00'
                      : Number(newPrice).toFixed(2)
                  } else {
                    delete priceItem?.new_unit_price
                  }
                },
              )
            } else {
              if (menuItem.checked) {
                const newPrice =
                  bulkActionOption === 'flat'
                    ? Number(menuItem.unit_price) + Number(priceValue)
                    : Number(menuItem.unit_price) *
                      (bulkActionOption === 'increase_percent'
                        ? Math.round(100 + Number(priceValue)) / 100
                        : Math.round(100 - Number(priceValue)) / 100)
                menuItem.new_unit_price = !newPrice
                  ? '0.00'
                  : Number(newPrice).toFixed(2)
              } else {
                delete menuItem?.new_unit_price
              }
            }
          }
        })
      })
    })
    setFormData([...newFormData])
  }

  const handleChangeBar = () => {
    if (menuActionInfo.method) {
      saveSortMenu()
    } else {
      if (
        !saveBulkEdit &&
        (bulkAction === 'price' || bulkAction === 'availability')
      ) {
        setBulkOpen(!bulkOpen)
        setBulkEdit(true)
      } else {
        handleSaveBulkEdit()
      }
    }
  }

  const handleSaveBulkEdit = async () => {
    setLoading(true)
    try {
      const newFormData = cloneDeep(formData)
      const bulkMenuItemList: any = []
      const bulkModifierList: any = []
      newFormData.forEach((collectionItem: any, i: number) => {
        collectionItem.menu_categories.forEach(
          (categoryItem: any, j: number) => {
            categoryItem.menu_items.forEach((menuItem: any, k: number) => {
              const initMenuItem =
                formDataInit[i].menu_categories[j].menu_items[k]
              if (
                (menuItem[saveBulkEdit ? 'new_unit_price' : 'unit_price'] &&
                  menuItem[saveBulkEdit ? 'new_unit_price' : 'unit_price'] !==
                    initMenuItem.unit_price) ||
                (saveBulkEdit && menuItem['new_status'])
              ) {
                const isRestock = menuItem?.new_status?.includes('Restock')

                let status = undefined
                status = menuItem?.new_status
                  ? isRestock
                    ? 'out_of_order'
                    : menuItem.new_status
                  : menuItem.status

                let reactive_at = undefined
                if (status === 'out_of_order') {
                  reactive_at = menuItem?.new_status
                    ? moment(
                        menuItem?.new_status.split('Restock on ')[1],
                      ).format('YYYY-MM-DD HH:mm:ss')
                    : menuItem.reactive_at
                }

                bulkMenuItemList.push({
                  id: menuItem.id,
                  unit_price:
                    saveBulkEdit && menuItem.new_unit_price
                      ? menuItem.new_unit_price
                      : menuItem.unit_price,
                  status,
                  reactive_at,
                })
              }
              if (menuItem.menu_modifiers[0]?.private_type === 'pricing') {
                menuItem.menu_modifiers[0].menu_modifier_options.forEach(
                  (modifierItem: any, m: number) => {
                    const initModifierItem =
                      initMenuItem.menu_modifiers[0].menu_modifier_options[m]
                    if (
                      modifierItem[
                        saveBulkEdit ? 'new_unit_price' : 'unit_price'
                      ] &&
                      modifierItem[
                        saveBulkEdit ? 'new_unit_price' : 'unit_price'
                      ] !== initModifierItem.unit_price
                    ) {
                      bulkModifierList.push({
                        id: modifierItem.id,
                        unit_price: saveBulkEdit
                          ? modifierItem.new_unit_price
                          : modifierItem.unit_price,
                      })
                    }
                  },
                )
              }
            })
          },
        )
      })

      const params = {
        menu_items: bulkMenuItemList,
        menu_modifier_options: bulkModifierList,
      }
      const res = await bulkEditMenuItemAPI(params, locationId)
      toast.success(res.message)
      onChange()
      setSaveBulkEdit(false)
      setBulkEdit(false)
      setBulkAction('')
      setBulkActionOption('')
      setPriceValue('')
      setCalendarValue('')
      setSearchValue('')
      // 刷新顶部筛选dropdown
      getMenuCollectionCount()
    } catch (e: any) {
      const msg = e?.message
      setError(msg || 'request error')
    }
    setLoading(false)
  }

  const cancelChangeBar = () => {
    setFormData([...(searching ? formDataInit : searchFormData)])
    setBulkAction('')
    setBulkActionOption('')
    setPriceValue('')
    setCalendarValue('')
    setBulkEdit(false)
    setSaveBulkEdit(false)
    closeMenuActionModal()
  }

  const showBulkField = () => {
    if (bulkEdit) {
      if (bulkAction === 'price' && bulkActionOption) {
        return (
          <Field
            containerClassName='mt-6'
            className={`${bulkActionOption !== 'flat' && 'pl-[1rem]'}`}
            name='bulk'
            type='tel'
            value={priceValue}
            onChange={e => {
              if (bulkActionOption === 'flat') {
                setPriceValue(e.target.value)
              } else {
                setPriceValue(
                  Number(e.target.value) > 100 ? '100' : e.target.value,
                )
              }
            }}
            align={bulkActionOption === 'flat' ? 'right' : 'left'}
            suffix={
              bulkActionOption === 'flat' ? (
                <Dollar className='text-silver' size={16} />
              ) : (
                <Percentage className='text-silver' size={16} />
              )
            }
            // suffixClassName={`${
            //   bulkActionOption !== 'flat' && 'left-auto right-4'
            // }`}
            label='The amount to set'
          />
        )
      }
      if (bulkAction === 'availability' && bulkActionOption === 'restock') {
        return (
          <CalendarField
            className='mt-6'
            value={calendarValue}
            onChange={date =>
              setCalendarValue(moment(date).format('MM/DD/YYYY'))
            }
            label='The amount to set'
          />
        )
      }
    }
    return <></>
  }

  const handleShowCategory = async (categoryId: number) => {
    try {
      const params = {
        menu_category_id: categoryId,
      }
      const res = await showMenuCategoryAPI(params, locationId)
      const { name, description, id } = res[0]
      setMenuActionInfo(prev => ({
        ...prev,
        id,
        name,
        description: description || '',
      }))
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
  }

  const handleUpdateMenu = async () => {
    setLoading(true)
    if (menuActionInfo.method === 'edit') {
      try {
        const params = {
          menu_category_id: menuActionInfo.id,
          name: menuActionInfo.name,
          description: menuActionInfo.description,
        }
        const res = await editMenuCategoryAPI(params, locationId)
        toast.success(res.message)
        onChange()
      } catch (e: any) {
        const msg = e?.message
        toast.error(msg || 'request error')
      }
    } else {
      try {
        if (menuActionInfo.type === 'Category') {
          const params = {
            menu_category_id: menuActionInfo.id,
            locationId,
          }
          const res = await deleteMenuCategoryAPI(params)
          toast.success(res.message)
        } else {
          const params = {
            menu_item_id: menuActionInfo.id,
            locationId,
          }
          const res = await deleteMenuItemAPI(params)
          toast.success(res.message)
        }
        onChange()
      } catch (e: any) {
        const msg = e?.message
        toast.error(msg || 'request error')
      }
    }
    setLoading(false)
    closeMenuActionModal()
  }

  const onDragEnd = async (props: any, type: string, itemList?: any) => {
    const newFormData = cloneDeep(formData)

    const { active, over } = props
    if (!active || !over) {
      return null
    }
    const list = itemList || []
    const activeIndex = list.findIndex((item: any) => item.id === active.id)
    const overIndex = list.findIndex((item: any) => item.id === over.id)
    const menuItemList = arrayMove(list, activeIndex, overIndex)
    setMenuActionId(0)
    setMenuActionInfo(prev => ({
      ...prev,
      id: active.id,
      type,
      method: 'sort',
    }))
    let collectionIndex = 0,
      categoryIndex = 0
    newFormData.forEach((collectionItem: any, i: number) => {
      collectionItem.menu_categories.forEach((categoryItem: any, j: number) => {
        if (type === 'Category') {
          if (categoryItem.id === active.id) {
            collectionIndex = i
            categoryIndex = j
            setMenuActionInfo(prev => ({
              ...prev,
              collectionIndex: i,
            }))
          }
        } else {
          categoryItem.menu_items.forEach((menuItem: any, k: number) => {
            if (menuItem.id === active.id) {
              collectionIndex = i
              categoryIndex = j
              menuItemIndex = k
              setMenuActionInfo(prev => ({
                ...prev,
                collectionIndex: i,
                categoryIndex: j,
              }))
            }
          })
        }
      })
    })

    if (type === 'Category') {
      newFormData[collectionIndex].menu_categories = [...menuItemList]
    } else {
      newFormData[collectionIndex].menu_categories[categoryIndex].menu_items = [
        ...menuItemList,
      ]
    }
    setFormData([...newFormData])
  }

  const saveSortMenu = async () => {
    setLoading(true)
    try {
      if (menuActionInfo.type === 'Category') {
        let idList: any = []
        formData[menuActionInfo.collectionIndex].menu_categories.map(
          (categoryItem: { id: number }) => {
            idList = [...idList, categoryItem.id]
          },
        )
        const params = {
          menu_collection_id: formData[menuActionInfo.collectionIndex].id,
          menu_category_ids: idList,
        }
        const res = await sortMenuCategoryAPI(params, locationId)
        toast.success(res.message)
      } else {
        let idList: any = []
        formData[menuActionInfo.collectionIndex].menu_categories[
          menuActionInfo.categoryIndex
        ].menu_items.map((menuItem: { id: number }) => {
          idList = [...idList, menuItem.id]
        })
        const params = {
          menu_category_id:
            formData[menuActionInfo.collectionIndex].menu_categories[
              menuActionInfo.categoryIndex
            ].id,
          menu_item_ids: idList,
        }
        const res = await sortMenuItemAPI(params, locationId)
        toast.success(res.message)
      }
      onChange()
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
    setLoading(false)
  }

  const handleFilterMenuItem = (value: string, type: string) => {
    type === 'search' ? setSearchValue(value) : setFilterValue(value)
    if (value) {
      const newFormData = cloneDeep(formDataInit)
      newFormData.forEach((collectionItem: any, i: number) => {
        collectionItem.menu_categories.forEach(
          (categoryItem: any, j: number) => {
            categoryItem.menu_items = categoryItem.menu_items.filter(
              (menuItem: any) => {
                if (type === 'select') {
                  return menuItem.status === value
                } else {
                  const menuName = menuItem.name.toLowerCase()
                  const searchName = value.toLowerCase()
                  return menuName.includes(searchName)
                }
              },
            )
            return categoryItem
          },
        )
        return collectionItem
      })
      setFormData([...newFormData])
      setSearchFormData([...newFormData])
    } else {
      setFormData([...formDataInit])
    }
  }

  const closeMenuActionModal = () => {
    setMenuActionId(0)
    setMenuActionInfo({
      method: '',
      type: '',
      id: 0,
      name: '',
      description: '',
      deleteValue: '',
      open: false,
    })
  }

  return (
    <>
      <UnsavedPrompt when={formIsDirty} />
      {(formIsDirty || bulkAction) && (
        <SaveChangeBar
          className='left-[30rem] border-l'
          error={error}
          confirmRequest={loading}
          confirmText={
            !saveBulkEdit && bulkAction === 'price'
              ? 'SET NEW PRICES'
              : !saveBulkEdit && bulkAction === 'availability'
              ? 'SET NEW AVAILABILITY'
              : 'SAVE CHANGE'
          }
          onConfirm={() => handleChangeBar()}
          onCancel={() => cancelChangeBar()}
        />
      )}
      <div
        className={cn(
          'bg-white mr-10 relative w-full min-w-[670px] min-h-[calc(100vh-120px)]',
        )}
      >
        <div className='px-8 py-3 flex items-center border-b border-solid border-zinc'>
          <Field
            name='search'
            placeholder='Search'
            type='text'
            suffix={<LinedSearch className='text-silver' size={16} />}
            value={searchValue}
            containerClassName='w-60 mr-4'
            onChange={e => handleFilterMenuItem(e.target.value, 'search')}
          />
          <Select
            options={filterList}
            placeholder={'Select'}
            defaultValue={filterList[0]?.label}
            className='w-40 mr-4'
            value={filterValue}
            onChange={value => handleFilterMenuItem(value, 'select')}
          />
          {!bulkAction && !saveBulkEdit ? (
            <Button
              className='ml-auto mr-2 whitespace-nowrap'
              color='tertiary'
              onClick={() => setBulkOpen(true)}
            >
              BULK EDIT
            </Button>
          ) : (
            <div className='text-blue ml-auto mr-2'>
              {checkedSelectCount} Items Selected
            </div>
          )}
        </div>

        <div
          className='h-[calc(100vh-200px)] overflow-y-scroll'
          ref={rightItemList}
        >
          {initLoading ? (
            <>
              <OnlineMenuSkeleton type={1} />
              <OnlineMenuSkeleton type={1} />
              <OnlineMenuSkeleton type={1} />
            </>
          ) : (
            <>
              {formData.map((collectionItem: any, i: number) => {
                return (
                  <div key={collectionItem.name}>
                    <StickyContainer className='sticky z-[25] top-0'>
                      <Sticky relative={true}>
                        {({ style }: { style: any }) => (
                          <div
                            style={style}
                            className='pt-4 pb-8 px-8 bg-white'
                          >
                            <div className='rounded-lg bg-ice font-bold py-3 pl-4 leading-snug'>
                              {collectionItem.name}
                            </div>
                          </div>
                        )}
                      </Sticky>
                    </StickyContainer>
                    <div className='px-8'>
                      <DndContext
                        sensors={sensors}
                        onDragEnd={prop =>
                          onDragEnd(
                            prop,
                            'Category',
                            collectionItem.menu_categories,
                          )
                        }
                        modifiers={[
                          restrictToVerticalAxis,
                          restrictToWindowEdges,
                        ]}
                      >
                        <SortableContext
                          disabled={
                            initLoading || !(!bulkAction && !saveBulkEdit)
                          }
                          items={collectionItem.menu_categories || []}
                        >
                          {collectionItem.menu_categories.map(
                            (categoryItem: any, j: number) => {
                              return (
                                <MenuListCategoryItem
                                  key={categoryItem.name}
                                  item={categoryItem}
                                  i={i}
                                  j={j}
                                  url={url}
                                  loading={
                                    initLoading ||
                                    !(!bulkAction && !saveBulkEdit)
                                  }
                                  bulkAction={bulkAction}
                                  saveBulkEdit={saveBulkEdit}
                                  menuActionId={menuActionId}
                                  setBulkEditChecked={(value, option) =>
                                    setBulkEditChecked(value, option)
                                  }
                                  setMenuActionId={id => setMenuActionId(id)}
                                  handleChangeMenu={(value, option, type) =>
                                    handleChangeMenu(value, option, type)
                                  }
                                  handleFormChange={(name, value, option) =>
                                    handleFormChange(name, value, option)
                                  }
                                  onDragEnd={(prop, type, list) =>
                                    onDragEnd(prop, type, list)
                                  }
                                />
                              )
                            },
                          )}
                        </SortableContext>
                      </DndContext>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
      <ModalFull
        open={bulkOpen}
        toggle={() => closeBulkEdit()}
        title={
          !bulkEdit
            ? 'Bulk Edit'
            : bulkAction === 'price'
            ? 'Set New Prices'
            : 'Set New Availability'
        }
        okBtnDisabled={!bulkEdit ? !bulkAction : !bulkActionOption}
        onCancel={() => closeBulkEdit()}
        onOk={() => {
          if (!bulkEdit) {
            setBulkEditChecked(false, {})
          } else {
            setBulkEditValue(formData)
          }
        }}
      >
        <Select
          options={
            !bulkEdit
              ? BULK_EDIT_ACTION
              : bulkAction === 'price'
              ? PRICE_BULK_EDIT
              : AVAILABILITY_BULK_EDIT
          }
          placeholder='Select option'
          className='w-full'
          label={
            !bulkEdit
              ? 'Choose your bulk edit action'
              : bulkAction === 'price'
              ? 'How would you like to set new prices'
              : 'How would you like to set new availability'
          }
          value={!bulkEdit ? bulkAction : bulkActionOption}
          onChange={value =>
            !bulkEdit ? setBulkAction(value) : setBulkActionOption(value)
          }
        />
        {showBulkField()}
      </ModalFull>
      <ModalFull
        open={menuActionInfo.open}
        toggle={() => closeMenuActionModal()}
        title={
          menuActionInfo.method === 'edit'
            ? 'Edit Menu Category'
            : `Delete Menu ${menuActionInfo.type}`
        }
        onCancel={() => closeMenuActionModal()}
        okBtnDisabled={
          menuActionInfo.method === 'delete' &&
          menuActionInfo.deleteValue !== 'Delete'
        }
        onOk={() => handleUpdateMenu()}
        okBtnColor={menuActionInfo.method === 'delete' ? 'warning' : 'primary'}
        loading={loading}
      >
        <>
          {menuActionInfo.method !== 'delete' ? (
            <>
              <Field
                name='name'
                type='text'
                value={menuActionInfo.name}
                onChange={e =>
                  setMenuActionInfo(prev => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                placeholder='Input here'
                label='Category name'
              />
              <TextArea
                label={
                  <div className='w-full flex justify-between text-silver'>
                    <span>Description</span>
                    <span className='font-normal'>{`(${menuActionInfo.description.length}/300 characters)`}</span>
                  </div>
                }
                containerClass='mt-6'
                name='description'
                maxLength={300}
                value={menuActionInfo.description}
                placeholder='Input'
                onChange={e =>
                  setMenuActionInfo(prev => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </>
          ) : (
            <>
              <div className='text-center mb-6'>
                {`This action will permanently delete “${menuActionInfo.name}${
                  menuActionInfo.type === 'Category'
                    ? ` ${menuActionInfo.type}`
                    : ''
                }”${
                  menuActionInfo.type === 'Category' ? ' and all its items' : ''
                }. You will not be able to restore this after the deletion.`}
              </div>
              <Field
                name='Delete'
                label='Type “Delete” below to confirm this action'
                type='text'
                value={menuActionInfo.deleteValue}
                placeholder='Delete'
                onChange={e =>
                  setMenuActionInfo(prev => ({
                    ...prev,
                    deleteValue: e.target.value,
                  }))
                }
              />
            </>
          )}
        </>
      </ModalFull>
    </>
  )
}
