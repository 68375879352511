import React from 'react'

import cn from 'classnames'

export type TStatusTagProp = {
  color?: 'red' | 'yellow' | 'blue' | 'green' | 'gray' | string
  text: string | React.ReactElement
  subText?: string
  className?: string
}

export default function StatusTag({
  color = 'blue',
  text,
  subText,
}: TStatusTagProp): JSX.Element {
  return (
    <div
      className={cn(
        'bg-slate rounded border-l-4 border-solid px-2 py-1 inline-flex items-center text-xs font-bold',
        { 'border-yellow': color === 'yellow' },
        { 'border-blue': color === 'blue' },
        { 'border-red': color === 'red' },
        { 'border-green': color === 'green' },
        { 'border-dark-32': color === 'gray' },
      )}
    >
      {text}
      {subText && (
        <span className='text-dark-64 font-normal ml-1'>{subText}</span>
      )}
    </div>
  )
}
