import React, { useEffect, useState } from 'react'

import {
  LinedStore,
  LinedCar,
  LinedUtensils,
  SolidCopy,
  Dollar,
} from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
// import PayIconRender from 'components/PayIcon/PayIconRender'
import SourceIconRender, {
  SOURCEICONMAP,
} from 'components/SourceIcon/SourceIconRender'
import Badge from 'components/Tag/Badge'
import Tips from 'components/Tips'
import ToolTips from 'components/ToolTips'
import { SERVER_TIMEZONE } from 'data/constant'
import { PERMISSION } from 'data/permission'
import useCopyToClipboard from 'hooks/useCopyToClipboard'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { refundOrderApi } from 'redux/location'
import {
  formatPhoneNumber,
  formatCurrency,
  currencyFormatter,
} from 'utils/digit'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

import OrderProcessLog from './OrderProcessLog'

type TOrderDetailStyleProp = {
  onReload: () => void
  formData: any
}

export default function OrderDetailStyle({
  onReload,
  formData,
}: TOrderDetailStyleProp): JSX.Element {
  const auth = useAppSelector(state => state.auth.authProfile.data)

  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE
  const { locationId } = useParams<TParamTypes>()

  const [open, setOpen] = useState(false)
  const [openLog, setOpenLog] = useState(false)
  const [status, setStatus] = useState<any>({ value: '', color: '' })
  const [copiedText, copy] = useCopyToClipboard()
  const isCanISSUEREFUND =
    auth?.permissionMap?.[PERMISSION.ISSUEREFUND]?.allowed == 1
  const [refund, setRefund] = useState({
    open: false,
    loading: false,
    amount: '',
    reason: '',
  })

  useEffect(() => {
    changeStatus(formData.status)
  }, [formData])

  const copyText = (str: string) => {
    copy(str)
    toast.success('Copied')
  }

  const changeStatus = (value: string) => {
    if (value) {
      switch (value) {
        case 'placed':
          setStatus({ value: 'New', color: 'yellow' })
          break
        case 'confirmed':
        case 'ready':
        case 'done':
          setStatus({ value: 'Processed', color: 'blue' })
          break
        case 'canceled':
          setStatus({
            value: !formData.confirmed_at ? 'Rejected' : 'Cancelled',
            color: !formData.confirmed_at ? 'gray' : 'red',
          })
          break
        default:
          setStatus({ value: 'Deposited', color: 'green' })
          break
      }
    }
  }

  const handleRefundOrder = async () => {
    setRefund(prev => ({ ...prev, loading: true }))
    try {
      const res = await refundOrderApi(locationId, {
        order_id: formData.id,
        amount: refund.amount,
        reason_to_cancel: refund.reason,
      })
      toast.success(res.message)
      setRefund({ open: false, loading: false, amount: '', reason: '' })
      onReload()
    } catch (error: any) {
      toast.error(error.message || 'Request error')
      setRefund(prev => ({ ...prev, loading: false }))
    }
  }

  const total = parseFloat(formData.total) || 0
  const availableRefund =
    parseFloat(formData.total || 0) -
    parseFloat(formData.price_summary?.refunds_sum || 0) -
    parseFloat(formData.price_summary?.gift_card_amount || 0)

  return (
    <div className='w-[400px] flex-y-1 mr-8 border-r border-solid border-zinc'>
      <div className='p-8 rounded-lg'>
        <div className='flex justify-between items-center pb-6 border-b border-dashed border-zinc'>
          <div className='mr-4'>
            <p className='font-bold leading-snug text-ink'>{`${formData.customer?.first_name} ${formData.customer?.last_name}`}</p>
            <p className='text-lead text-sm leading-snug mt-2'>{`Placed ${
              formData?.customer?.location_order_count ||
              formData.location_order_count ||
              0
            }  orders in total`}</p>
          </div>
          <Button size='sm' color='tertiary' onClick={() => setOpen(true)}>
            CONTACT
          </Button>
        </div>
        <div className='flex pt-6'>
          <div
            style={{ width: 'calc(50% - 12px)' }}
            className='flex flex-col space-y-6 mr-6'
          >
            <div className='leading-none'>
              <p className='text-lead text-xxs'>Status</p>
              <div className='flex items-center mt-1'>
                <Badge color={status.color}>{status.value}</Badge>
              </div>
            </div>
            <div className='leading-none'>
              <p className='text-lead text-xxs'>Source</p>
              <div className='flex items-center mt-1 leading-snug'>
                {SOURCEICONMAP[formData.utm_tracking?.source] ||
                !formData.utm_tracking?.source ? (
                  <>
                    <SourceIconRender
                      type={formData.utm_tracking?.source}
                      iconParams={{ size: 16, className: 'inline-block' }}
                    />
                    <span className='ml-1'>
                      {SOURCEICONMAP[formData.utm_tracking?.source] || 'Other'}
                    </span>
                  </>
                ) : (
                  <ToolTips
                    className='inline-flex'
                    tipsClass='text-ink top-4'
                    hoverEl={
                      <div className='flex leading-snug'>
                        <SourceIconRender
                          type={formData.utm_tracking?.source}
                          iconParams={{ size: 16, className: 'inline-block' }}
                        />
                        <span className='ml-1'>{'Other'}</span>
                      </div>
                    }
                  >
                    <div>{formData.utm_tracking?.source}</div>
                  </ToolTips>
                )}
              </div>
            </div>
            <div className='leading-none'>
              <p className='text-lead text-xxs'>Submit date</p>
              <div className='flex items-center mt-1 leading-snug'>
                {parseServerTimeToLocalTime(
                  formData.created_at,
                  timezone,
                  'll',
                )}
              </div>
            </div>
            <div className='leading-none'>
              <p className='text-lead text-xxs'>Request date</p>
              <div className='flex items-center mt-1 leading-snug'>
                {parseServerTimeToLocalTime(
                  formData.request_at,
                  timezone,
                  'll',
                )}
              </div>
            </div>
            {/* <div className='leading-none'>
              <p className='text-lead text-xxs'>Order total</p>
              <div className='flex items-center mt-[3px]'>
                {currencyFormatter(formData.total)}
              </div>
            </div> */}
          </div>
          <div
            style={{ width: 'calc(50% - 12px)' }}
            className='flex flex-col  space-y-6 text-ink'
          >
            <div className='leading-none'>
              <p className='text-lead text-xxs'>Order ID</p>
              <div className='flex items-center mt-1 leading-snug'>
                #{formData.id}
              </div>
            </div>
            <div className='leading-none'>
              <p className='text-lead text-xxs'>Order type</p>
              <div className='flex items-center mt-1 leading-snug'>
                {formData.type === 'pickup' && (
                  <>
                    <LinedStore size={16} className='mr-1' />
                    <span>Pickup</span>
                  </>
                )}
                {formData.type === 'delivery' && (
                  <>
                    <LinedCar size={16} className='mr-1' />
                    <span>Delivery</span>
                  </>
                )}
                {formData.type === 'daas_delivery' && (
                  <>
                    <LinedCar size={16} className='mr-1' />
                    <span>Delivery (OD)</span>
                  </>
                )}
                {formData.type === 'dine-in' && (
                  <>
                    <LinedUtensils size={16} className='mr-1' />
                    <span>Dine-in</span>
                  </>
                )}
                {formData.type === 'curbside' && (
                  <>
                    <LinedStore size={16} className='mr-1' />
                    <span>Curbside</span>
                  </>
                )}
                {formData.type === 'takeout' && (
                  <>
                    <LinedStore size={16} className='mr-1' />
                    <span>Takeout</span>
                  </>
                )}
              </div>
            </div>
            <div className=' leading-none'>
              <p className='text-lead text-xxs'>Submit time</p>
              <div className='flex items-center mt-1 leading-snug'>
                {parseServerTimeToLocalTime(
                  formData.created_at,
                  timezone,
                  'hh:mm A',
                )}
              </div>
            </div>
            <div className=' leading-none'>
              <p className='text-lead text-xxs'>Request time</p>
              <div className='flex items-center mt-1 leading-snug'>
                {formData.request_at <= formData.created_at
                  ? 'ASAP'
                  : parseServerTimeToLocalTime(
                      formData.request_at,
                      timezone,
                      'hh:mm A',
                    )}
              </div>
            </div>
            {/* <div className='leading-none'>
              <p className='text-lead text-xxs-l min-w-[200px]'>
                Payment method
              </p>
              <div className='flex items-center mt-[3px]'>
                <PayIconRender
                  type={formData.payment_method?.card?.brand}
                  iconParams={{ size: 16, className: 'inline-block' }}
                />{' '}
                <span className='ml-1'>
                  ••• {formData.payment_method?.card?.last4}
                </span>
              </div>
            </div> */}
          </div>
        </div>
        <div className='grid gap-4 mt-6 pt-6 border-t border-dashed border-zinc'>
          {status.value === 'New' && formData.express_order_confirmation_link && (
            <Button
              color='primary'
              className='w-full'
              disabled={!isCanISSUEREFUND}
              onClick={() =>
                window.open(formData.express_order_confirmation_link, '_blank')
              }
            >
              MANAGE ORDER
            </Button>
          )}

          <Button
            color='secondary'
            className='w-full'
            onClick={() => setOpenLog(true)}
          >
            PROCESSING LOG
          </Button>
          {/* "confirmed", "ready", "done" 情况下显示该按钮 */}
          {['confirmed', 'ready', 'done'].includes(formData.status) && (
            <Button
              color='tertiary'
              onClick={() => setRefund(prev => ({ ...prev, open: true }))}
            >
              ISSUE REFUND
            </Button>
          )}
        </div>
      </div>
      <ModalFull
        open={open}
        title={`${formData.customer?.first_name} ${formData.customer?.last_name}`}
        toggle={() => setOpen(!open)}
        footer={
          <div className='w-full flex justify-end'>
            <Button color='primary' onClick={() => setOpen(false)}>
              CLOSE
            </Button>
          </div>
        }
      >
        <div className='grid gap-6 px-[68px]'>
          <div className='flex items-center py-3 px-4 border-2 border-solid border-zinc rounded-lg'>
            <div className='flex-1'>
              {formatPhoneNumber(formData.customer?.phone)}
            </div>
            <Button
              color='secondary-link'
              onClick={() =>
                copyText(formatPhoneNumber(formData.customer?.phone) || '')
              }
            >
              <SolidCopy className='text-blue' size={20} />
            </Button>
          </div>
          <div className='flex items-center  py-3 px-4 border-2 border-solid border-zinc rounded-lg'>
            <div className='flex-1'>{formData.customer?.email}</div>
            <Button
              color='secondary-link'
              onClick={() => copyText(formData.customer?.email || '')}
            >
              <SolidCopy className='text-blue' size={20} />
            </Button>
          </div>
        </div>
      </ModalFull>

      <ModalFull
        open={refund.open}
        loading={refund.loading}
        onOk={() => handleRefundOrder()}
        title='ISSUE REFUND'
        toggle={() => setRefund(prev => ({ ...prev, open: false }))}
      >
        <div>
          <Tips>
            <>
              ${formatCurrency(availableRefund)} out of total $
              {formatCurrency(total)} available for refund
            </>
          </Tips>
          <Field
            containerClassName='mb-4 mt-2'
            label='Input the amount to refund'
            name='Input the amount to refund'
            value={refund.amount}
            type='text'
            placeholder='0.00'
            align='right'
            inputMode='money'
            suffix={<Dollar size={16} className='text-silver' />}
            maxLength={6}
            onChangeMoney={value => {
              const n = Number(value)
              if (n <= availableRefund && n >= 0) {
                setRefund(prev => ({ ...prev, amount: value }))
              }
            }}
          />
          <Field
            label='Please specify the reason to Refund:'
            name='refund_reason'
            placeholder=''
            type='text'
            value={refund.reason}
            onChange={e => {
              setRefund(prev => ({ ...prev, reason: e.target.value }))
            }}
          />
        </div>
      </ModalFull>
      {openLog && (
        <OrderProcessLog
          data={formData}
          open={openLog}
          toggle={() => setOpenLog(!openLog)}
        />
      )}
    </div>
  )
}
