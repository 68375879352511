import React, { useState, useEffect } from 'react'

import { AngleLeft, Dollar } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { redirectStripeAccountAPI } from 'redux/brand/actions'

import CreateStripeAccount from './components/CreateStripeAccount'

export default function ConnectAccountPage(): JSX.Element {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { brandId, locationId } = useParams<TParamTypes>()
  const [loading, setLoading] = useState(false)

  const handleConnectAccount = async () => {
    setLoading(true)
    try {
      const res = await redirectStripeAccountAPI(brandId, locationId)
      if (res) {
        window.open(res, '_self')
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  return (
    <AdminPage>
      <AdminPageTitle
        title={'Connect Stripe Account'}
        left={
          <Button color='secondary-link' onClick={() => history.go(-1)}>
            <AngleLeft className='mr-1' size={16} />
            Go Back
          </Button>
        }
      />
      <>
        <div className='flex flex-col items-center mx-auto pb-6'>
          <CreateStripeAccount
            loading={loading}
            onClick={() => handleConnectAccount()}
          />
        </div>
      </>
    </AdminPage>
  )
}
