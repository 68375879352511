import React, { useState, useEffect } from 'react'

import Button from 'components/Button'
import DateRangeModal from 'components/DateRangeModal'
import Table from 'components/Table/Table'
import Tag from 'components/Tag'
import { SERVER_TIMEZONE } from 'data/constant'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getTransactionsListApi,
  downloadApi,
  TDownloadApi,
} from 'redux/accountAndPayout'
import { useAppSelector } from 'redux/hooks'
import { currencyFormatter } from 'utils/digit'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

import {
  STATUSTYPE,
  PAYDETAILFILTER,
  ORDERREFERENCE,
} from '../../billingAndInvoice/helps/index'

type TPageObj = {
  page: number
  per_page: number
  total: number
  type: string | null
  begin_time: any
  end_time: any
  loading: boolean
}
export default function TransactionsTable() {
  const { locationId = '', payoutId = '' } = useParams<TParamTypes>()
  const [pageObj, setPageObj] = useState<TPageObj>({
    page: 1,
    per_page: 20,
    total: 0,
    type: PAYDETAILFILTER[0].value,
    loading: false,
    begin_time: null,
    end_time: null,
  })
  const [tableData, setTableData] = useState<any>([])
  const [loading, setLoading] = useState({
    exportData: false,
    topBlock: false,
  })

  const [dateOpen, setDateOpen] = useState(false)

  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE

  useEffect(() => {
    let params = {
      ...pageObj,
    }
    if (!payoutId) {
      params = {
        ...params,
        begin_time: moment().startOf('month').toDate(), // set default begin time as the first date of month
        end_time: new Date(),
      }
    }
    setPageObj(params)
    getTransactionsList(params, false)
  }, [])

  const getTransactionsList = async (pageObj: any, refresh = false) => {
    const params = {
      payout_id: payoutId || null,
      per_page: pageObj.per_page,
      page: pageObj.page,
      type: pageObj.type,
      begin_time:
        payoutId || !pageObj.begin_time
          ? null
          : moment(pageObj.begin_time).format('YYYY-MM-DD 00:00:00'),
      end_time:
        payoutId || !pageObj.end_time
          ? null
          : moment(pageObj.end_time).format('YYYY-MM-DD 23:59:59'),
    }
    setPageObj(prev => ({ ...prev, loading: true }))
    try {
      const res = await getTransactionsListApi(params, locationId, refresh)
      setTableData(res.data || [])
      setPageObj(prev => ({ ...prev, total: res.total }))
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
    setPageObj(prev => ({ ...prev, loading: false }))
  }

  const exportData = async () => {
    setLoading(prev => ({ ...prev, exportData: true }))
    try {
      const params = {
        payout_id: payoutId,
        begin_time:
          payoutId || !pageObj.begin_time
            ? null
            : moment(pageObj.begin_time).format('YYYY-MM-DD 00:00:00'),
        end_time:
          payoutId || !pageObj.end_time
            ? null
            : moment(pageObj.end_time).format('YYYY-MM-DD 23:59:59'),
      } as TDownloadApi

      const res = await downloadApi(params, locationId)
      const blobUrl = window.URL.createObjectURL(
        new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' }),
      )
      const elink = document.createElement('a')
      elink.download = 'payout-detail.xlsx'
      elink.style.display = 'none'
      elink.href = blobUrl
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
    setLoading(prev => ({ ...prev, exportData: false }))
  }

  const columns = [
    {
      className: 'w-40',
      key: 'type',
      name: 'TYPE',
      custom: (value: string) => {
        const { color, desc } = STATUSTYPE[value] || {}
        return (
          <div>
            <Tag dot dotColor={color} text={desc} />
          </div>
        )
      },
    },
    {
      key: 'order',
      name: 'ASSO. ORDER',
      custom: (order: any, row: any) => {
        let assoOrder = ''
        if (order?.id) {
          assoOrder = order.id
        }
        if (row.refund?.id) {
          assoOrder = row.refund?.id
        }
        return (
          <span className='text-xs'>{assoOrder ? `#${assoOrder}` : '-'}</span>
        )
      },
    },
    {
      key: 'order',
      name: 'REFERENCE',
      custom: (order: any, row: any) => {
        let desc = ''
        // $row->order 不为空，用 $order->type
        // $row->refund 不为空，用 $refund->note
        // $row->adjustment 不为空，就用 $adjustment->note
        // $row->dispute 不为空，$dispute->reason
        // $row->credit 不为空，$credit->comment
        if (row.order) {
          desc = ORDERREFERENCE[row.order?.type] || ''
        }
        if (row.refund) {
          desc = row.refund?.note
        }
        if (row.adjustment) {
          desc = row.adjustment?.note
        }
        if (row.dispute) {
          desc = row.dispute?.reason
        }
        if (row.credit) {
          desc = row.credit?.comment
        }
        return (
          <div className='flex items-center text-lead text-xs'>
            {desc || '-'}
          </div>
        )
      },
    },
    {
      align: 'right',
      className: 'w-[140px]',
      key: 'stripe_created_at',
      name: 'DATE',
      custom: (value: string) => {
        return (
          <div className='text-lead text-xs'>
            {value
              ? parseServerTimeToLocalTime(value, timezone, 'MM/DD/YYYY')
              : '-'}
          </div>
        )
      },
    },
    {
      align: 'right',
      className: 'w-[140px]',
      key: 'stripe_created_at',
      name: 'TIME', // stripe_created_at 取这个的时分
      custom: (value: string) => {
        return (
          <div className='text-lead text-xs'>
            {value
              ? parseServerTimeToLocalTime(value, timezone, 'h:mm a')
              : '-'}
          </div>
        )
      },
    },
    {
      align: 'right',
      className: 'w-[140px]',
      key: 'stripe_net',
      name: 'NET PAYOUT',
      custom: (value: string) => {
        return (
          <span className='font-medium text-xs'>
            {value ? `${currencyFormatter(parseFloat(value))}` : '-'}
          </span>
        )
      },
    },
  ]
  return (
    <>
      <Table
        loading={pageObj.loading}
        columns={columns}
        data={tableData}
        isPageSet={true}
        total={pageObj.total}
        totalText=' transactions'
        resizePageSize={pageObj.per_page}
        current={pageObj.page}
        rightButton={
          <div className='flex space-x-2'>
            {payoutId ? null : (
              <Button
                color='tertiary'
                size='sm'
                onClick={() => setDateOpen(!dateOpen)}
              >
                {`${moment(pageObj.begin_time).format('M/D/YYYY')} to ${moment(
                  pageObj.end_time,
                ).format('M/D/YYYY')}`}
              </Button>
            )}
            <Button
              color='primary'
              size='sm'
              loading={loading.exportData}
              onClick={exportData}
            >
              Download Detailed Report
            </Button>
          </div>
        }
        onPageNoChange={page => {
          const obj = { ...pageObj, page }
          setPageObj(obj)
          getTransactionsList(obj, true)
        }}
        onPageSizeChange={per_page => {
          const obj = { ...pageObj, per_page, page: 1 }
          setPageObj(obj)
          getTransactionsList(obj, true)
        }}
      />
      <DateRangeModal
        open={dateOpen}
        toggle={() => setDateOpen(!dateOpen)}
        onChange={({ dateRange }) => {
          setPageObj({
            ...pageObj,
            begin_time: dateRange.startDate,
            end_time: dateRange.endDate,
            page: 1,
          })
        }}
        ranges={[
          {
            startDate: pageObj.begin_time,
            endDate: pageObj.end_time,
            key: 'dateRange',
          },
        ]}
        onConfirm={() => getTransactionsList(pageObj, true)}
      />
    </>
  )
}
