import React, { useEffect, useState, useRef } from 'react'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getOrderSummaryApi } from 'redux/location/actions'

import SummaryDetail from './SummaryDetail'

type TOrderSummaryProp = {
  open: boolean
  toggle: () => void
  ranges: any
  status: string
}

export default function OrderSummary({
  open,
  toggle,
  ranges,
  status,
}: TOrderSummaryProp): JSX.Element {
  const [isPDF, setIsPDF] = useState(false)
  const [formData, setFormData] = useState<Record<string, any>>({})
  const [nodeHeight, setNodeHeight] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  })
  const ref = useRef<HTMLDivElement>(null)
  const { locationId } = useParams<TParamTypes>()

  useEffect(() => {
    if (open) {
      getSummary()
    }
  }, [open])

  let fileName = `Payout ${moment(ranges[0].startDate).format(
    'YYYY-MM-DD',
  )} to ${moment(ranges[0].endDate).format('YYYY-MM-DD')}`
  fileName = fileName.replace(/\s+/g, '-').replace(/[^\w-]+/g, '')

  const exportPDF = async (title: string, node: any) => {
    await setIsPDF(true)
    const element = document.getElementById(node)
    html2canvas(element as any, {
      allowTaint: true,
      scale: 3,
    }).then(canvas => {
      const pageData = canvas.toDataURL('image/jpeg', 3.0)
      const str: any = ''
      const pdf = new jsPDF(str, 'pt', 'a4')
      const width = pdf.internal.pageSize.getWidth()
      const height = pdf.internal.pageSize.getHeight()

      pdf.addImage(pageData, 'JPEG', 0, 0, width, height - 20)
      pdf.save(`${title}.pdf`)
    })
    setIsPDF(false)
  }

  const getSummary = async () => {
    try {
      const res = await getOrderSummaryApi(
        {
          begin_time:
            moment(ranges[0].startDate).format('YYYY-MM-DD') + ' 00:00:00',
          end_time:
            moment(ranges[0].endDate).format('YYYY-MM-DD') + ' 23:59:59',
        },
        locationId,
      )
      setFormData(res)
    } catch (e: any) {
      const msg = e?.response?.data?.message
      toast.error(msg || 'request error')
    }
  }

  return (
    <>
      <Drawer
        open={open}
        toggle={toggle}
        position='right'
        onScroll={e =>
          setNodeHeight({
            scrollTop: e?.top || 0,
            scrollHeight: e?.scrollHeight || 0,
            clientHeight: e?.clientHeight || 0,
          })
        }
      >
        <>
          <div className='' ref={ref} id='detail'>
            <div className='px-8 pt-6'>
              <h2 className='text-base font-medium text-ink text-center pb-2'>
                Order Summary
              </h2>
            </div>
            <div className='pt-2'>
              <SummaryDetail
                formData={formData}
                status={status}
                ranges={ranges}
                isPDF={isPDF}
              />
            </div>
          </div>
          <div className='fixed  right-0 bottom-0 w-full   bg-white flex justify-end items-center px-8 py-4 border-t border-solid border-zinc'>
            <Button className='mr-4' color='primary' onClick={toggle}>
              CLOSE
            </Button>
          </div>
        </>
      </Drawer>
    </>
  )
}
