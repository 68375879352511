// base
export const LOGIN = '/login'
export const RESETPASSWORD = '/reset-password'
export const SELECTRESTAURANT = '/select-restaurant'
export const TERMSOFSERVICE = '/terms-of-service'
export const PRIVACYPOLICY = '/privacy-policy'
export const USERINVITATION = '/user-invitation/:token'
export const CLOVER = '/redirect/clover/:locationId'
export const REDIRECT = '/redirect'
// brand
export const BRAND = '/brand/:brandId'
export const LOCATION = '/brand/:brandId/location/:locationId'
export const DOMAINSETTING = LOCATION + '/domain-setting'
export const WEBSITESETTING = LOCATION + '/website-setting'
export const GALLERYPOSTING = LOCATION + '/gallery-posting'
export const GIFTCARD = LOCATION + '/gift-card'

// performance
export const SAMPLE = LOCATION + '/sample'
export const STATISTICS = LOCATION + '/stat'
export const ORDERHISTORY = LOCATION + '/order-history'

// restaurant
export const ONLINEMENU = LOCATION + '/online-menu'
export const ORDERSETTING = LOCATION + '/order-setting'
export const ANNOUNCEMENT = LOCATION + '/announcement'
export const BUSINESSHOURS = LOCATION + '/business-hours'
export const BUSINESSSETTING = LOCATION + '/business-setting'
export const ACCOUNTANDPAYOUT = LOCATION + '/payout'
export const BILLINGANDINVOICE = LOCATION + '/billing-and-invoice'
export const INTERGRATION = LOCATION + '/integration'

// marketing
export const MARKETINGSETTING = LOCATION + '/marketing-setting'
export const COUPONCODE = LOCATION + '/coupon-code'
export const MARKETINGFLYER = LOCATION + '/marketing-flyer'
export const OUTBOUNDCAMPAIGN = LOCATION + '/outbound-campaign'
export const EMAILANDTEXT = LOCATION + '/email-and-text'
export const PHONEATTENDANT = LOCATION + '/phone-attendant'
export const REVIEWGENERATOR = LOCATION + '/review-generator'
export const LOYALTYPROGRAM = LOCATION + '/royalty-program'
export const CUSTOMERDATA = LOCATION + '/customer-data'

// admin nav bottom routes
export const MYPROFILE = LOCATION + '/profile'
// export const USERMANAGEMENT = LOCATION + '/users'
