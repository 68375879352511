export const toggleTierDeliveryFee = (fees: any, tierNum: number) => {
  const deliveryFees = [...fees]
  const existIndex = deliveryFees.findIndex(v => v.to === tierNum)
  // 1. existing
  // remove it
  // merge next span with this span
  if (existIndex > -1) {
    if (deliveryFees.length > 1 && existIndex !== deliveryFees.length - 1) {
      deliveryFees[existIndex + 1].from = deliveryFees[existIndex].from
    }
    deliveryFees.splice(existIndex, 1)
  } else {
    // 2. in between one delivery fee
    // break it into two spans
    const inBetweenIndex = deliveryFees.findIndex(v => {
      console.log(v)
      return tierNum > v.from && tierNum < v.to
    })
    if (inBetweenIndex > -1) {
      const inBetween = deliveryFees[inBetweenIndex]
      const newSpan = { from: inBetween.from, to: tierNum, fee: inBetween.fee }

      inBetween.from = tierNum
      deliveryFees.splice(inBetweenIndex, 0, newSpan)
    } else {
      // 3. else
      // push new one to list
      const lastSpan =
        deliveryFees.length > 0
          ? deliveryFees[deliveryFees.length - 1]
          : { from: 0, to: 0, fee: 0 }
          const newSpan = { from: lastSpan.to, to: tierNum, fee: lastSpan.fee }
      deliveryFees.push(newSpan)
    }
  }
  return deliveryFees
}
