import React from 'react'

import cn from 'classnames'

import TimelineNode from './TimelineNode'

export type TTimelineProp = {
  /** the timeline nodes */
  list: {
    label: string | React.ReactElement
    title: string
  }[]
  className?: string
  lineClassName?: string
  /** current index */
  current?: number
}

export default function Timeline({
  list,
  className,
  lineClassName = 'top-3',
  current = 0,
}: TTimelineProp): JSX.Element {
  return (
    <div className={cn('relative overflow-hidden', className)}>
      <div
        className={cn(
          'w-full border-t border-zinc border-solid absolute',
          lineClassName,
        )}
      ></div>
      <ol className={cn('items-center flex justify-between')}>
        {list.map((item, index) => (
          <TimelineNode
            {...item}
            index={index}
            key={index}
            current={current}
            total={list.length}
          />
        ))}
      </ol>
    </div>
  )
}
