import Button from 'components/Button'
import Calendar from 'components/CalendarField'
import DayClose from 'components/OpenHoursForm/DayClose'
import DayHoursField from 'components/OpenHoursForm/DayHoursField'
import moment from 'moment'
import { THoursInBusiness } from 'types/openHour'
import newid from 'utils/newid'

export type TDayHoursCollectionFormProp = {
  dailyHours?: THoursInBusiness[] | []
  onChange: (hoursInDay: THoursInBusiness[] | []) => void
}

type DayHoursType = 'from' | 'to'

export default function DayHoursCollection({
  dailyHours = [],
  onChange,
}: TDayHoursCollectionFormProp): JSX.Element {
  // 时间范围改变
  const onHourChange = (
    dateIndex: number,
    index: number,
    value: string,
    name: string,
  ) => {
    const item = dailyHours?.[dateIndex]?.value?.[index] || {}
    item[name as DayHoursType] = value
    onChange(dailyHours)
  }
  // 日期改变
  const onDateChange = (dateIndex: number, value: string) => {
    dailyHours[dateIndex].label = value
    onChange(dailyHours)
  }

  const addHours = (index: number, day: string) => {
    const item = { id: newid('hours'), day: day, from: '', to: '' }
    dailyHours[index].value.push(item)
    onChange(dailyHours)
  }

  const removeHours = (dateIndex: number, index: number) => {
    const values = dailyHours[dateIndex].value || []
    values.splice(index, 1)
    onChange(dailyHours)
  }

  const removeDays = (index: number) => {
    dailyHours.splice(index, 1)
    onChange(dailyHours)
  }

  const addNewDate = () => {
    const obj = { label: '', value: [] }
    onChange([...dailyHours, obj])
  }
  return (
    <>
      <div>
        {dailyHours.map((dateItem, dateIndex) => {
          const hoursList = dateItem.value || []
          const calendarValue = dateItem.label
            ? moment(dateItem.label).format('MM.DD.YYYY')
            : ''
          return (
            <div
              key={dateIndex}
              className='flex justify-between py-3'
              // first:pt-0 border-zinc border-dashed border-b last:border-0
            >
              <Calendar
                value={calendarValue}
                className='w-[136px]'
                calendarClassName='z-[99]'
                calendarRestProps={{ minDate: new Date() }}
                date={dateItem.label ? new Date(dateItem.label) : undefined}
                onChange={e => {
                  const str = moment(e)?.format('YYYY-MM-DD')
                  onDateChange(dateIndex, str)
                }}
              ></Calendar>
              <div className='block'>
                {!hoursList.length && (
                  <DayClose
                    tips='CLOSED'
                    canDelete={true}
                    addHours={() => {
                      addHours(dateIndex, dateItem.label)
                    }}
                    removeDays={() => {
                      removeDays(dateIndex)
                    }}
                  />
                )}
                {hoursList.map((dayHours, index) => (
                  <DayHoursField
                    index={index}
                    key={dayHours.id}
                    dayHours={dayHours}
                    onChange={(name, value) => {
                      onHourChange(dateIndex, index, value, name)
                    }}
                    addHours={() => {
                      addHours(dateIndex, dateItem.label)
                    }}
                    removeHours={() => {
                      removeHours(dateIndex, index)
                    }}
                  />
                ))}
              </div>
            </div>
          )
        })}
      </div>
      <div className='py-3'>
        <Button
          color='tertiary'
          onClick={() => addNewDate()}
          className='w-full'
        >
          ADD NEW DATE
        </Button>
      </div>
    </>
  )
}
