import React, { useRef, useImperativeHandle, forwardRef } from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Label from 'components/Label'
import { STRIPE_API_KEY } from 'data/constant'

import StripForm from './StripForm'

interface Iprops {
  label?: string | React.ReactElement
  onChange?: (e: any) => void
}
const stripePromise = loadStripe(STRIPE_API_KEY)
function StripElement({ label, onChange }: Iprops, ref: any) {
  const formRef = useRef<any>()
  useImperativeHandle(ref, () => ({
    getPaymentId: formRef.current?.getPaymentId,
    getToken: formRef.current?.getToken,
  }))

  return (
    <div className='text-zero'>
      <Label className='flex mb-2'>{label}</Label>
      <Elements stripe={stripePromise}>
        <StripForm ref={formRef} onChange={onChange} />
      </Elements>
    </div>
  )
}
export default forwardRef(StripElement)
