import { createReducer } from '@reduxjs/toolkit'

import { getMarketingFlyer, getCoupons } from './actions'

export type TCoupon = {
  first_order_only: number
  code: string
  description: string
  type: string
  amount_off: string
  threshold: string
  percentage_off: string
  start_date: string
  end_date: string
}

export type TCouponItem = {
  id: number
  coupon_id: number
  coupon: TCoupon
}

type TMarketingFlyerState = {
  getMarketingFlyerRequest: boolean
  getCouponsRequest: boolean
  coupons: TCouponItem[]
}

const initialState: TMarketingFlyerState = {
  getMarketingFlyerRequest: false,
  getCouponsRequest: false,
  coupons: [],
}

export const marketingFlyerReducer = createReducer(initialState, builder => {
  builder
    .addCase(getMarketingFlyer.pending, state => {
      state.getMarketingFlyerRequest = true
    })
    .addCase(getMarketingFlyer.fulfilled, state => {
      state.getMarketingFlyerRequest = false
    })
    .addCase(getMarketingFlyer.rejected, state => {
      state.getMarketingFlyerRequest = false
    })
    .addCase(getCoupons.pending, state => {
      state.getCouponsRequest = true
    })
    .addCase(getCoupons.fulfilled, (state, action) => {
      state.getCouponsRequest = false
      state.coupons = action.payload
    })
    .addCase(getCoupons.rejected, state => {
      state.getCouponsRequest = false
    })
})

export default marketingFlyerReducer
