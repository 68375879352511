import React, { useState } from 'react'

import Button from 'components/Button'
import CodeInput from 'components/CodeInput'
import Tips from 'components/Tips/index'
import useCountdown from 'hooks/useCountDown'
import { useAppSelector } from 'redux/hooks'
export type TCodeVerifyFormProp = {
  phone: string
  setMethod: (v: string) => void
  handleFormChange: (name: string, value: string) => void
  handleSendCode: () => void
  errorMsg: string
}

const COUNTDOWN_SECONDS = 60000
export default function CodeVerifyForm({
  phone,
  errorMsg,
  setMethod,
  handleFormChange,
  handleSendCode,
}: TCodeVerifyFormProp): JSX.Element {
  const { loginRequest } = useAppSelector(state => state.auth)
  const [newTime, setNewTime] = useState(new Date().getTime())
  const [timeLeft, formattedRes] = useCountdown({
    targetDate: newTime + COUNTDOWN_SECONDS,
  })
  const { seconds } = formattedRes
  const onCodeChange = (v: string) => {
    handleFormChange('code', v)
  }
  return (
    <div>
      <div className='w-400px mx-auto my-6'>
        <label className='font-bold text-center block text-xs text-silver'>
          Please input the code sent to {phone}
        </label>
        <CodeInput
          className='mt-2'
          length={4}
          fieldWidth={80}
          onChange={onCodeChange}
        />
        {errorMsg && <Tips className='mt-2' text={errorMsg} />}
      </div>
      <div className='mt-8 flex gap-4 items-center justify-center'>
        <Button
          color='secondary'
          className='w-full'
          onClick={() => setMethod('')}
        >
          GO BACK
        </Button>
        <Button
          color='tertiary'
          className='w-full'
          disabled={seconds > 0 || loginRequest}
          onClick={() => {
            handleSendCode()
            setNewTime(new Date().getTime())
          }}
          loading={loginRequest}
        >
          {loginRequest
            ? 'SENDING'
            : `RESEND${seconds > 0 ? `(${seconds})` : ''}`}
        </Button>
      </div>
    </div>
  )
}
