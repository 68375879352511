import React, { useEffect, useState } from 'react'

import useSearch from 'hooks/useSearch'
import { useHistory, useLocation } from 'react-router-dom'
import {
  login,
  getProfile,
  sendCode,
  passwordResetSend,
  clearError,
} from 'redux/auth'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { getAccessToken } from 'utils/auth'
import { formatDigitOnly } from 'utils/digit'

import EmailLoginForm from './components/EmailLoginForm'
import ForgotPasswordModal from './components/ForgotPasswordModal'
import LoginMethod from './components/LoginMethod'
import PhoneLoginForm from './components/PhoneLoginForm'
type TLocationState = {
  from: { pathname: string }
}

export type TForgotData = {
  open: boolean
  success: boolean
  loading: boolean
}
export default function LoginPage(): JSX.Element {
  const { redirect_from, code } = useSearch()
  const [method, setMethod] = useState('')
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    phone: '',
    code: '',
  })
  const [forgotData, setForgotData] = useState<TForgotData>({
    open: false,
    success: false,
    loading: false,
  })
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const locationState: TLocationState = location.state as TLocationState
  const fromPath = locationState?.from?.pathname || '/'
  console.log(fromPath, 'fromPath')
  const {
    loggedIn,
    loginRequest,
    loginErrors,
    passwordResetSendRequest,
    passwordResetSendError,
  } = useAppSelector(state => state.auth)

  const handleFormChange = (name: string, value: string) => {
    dispatch(clearError())
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleLogin = async (e?: React.FormEvent) => {
    e && e.preventDefault()
    const params = {
      email: method === 'email' ? formData.email || undefined : undefined,
      password: method === 'email' ? formData.password || undefined : undefined,
      phone:
        method === 'phone'
          ? formatDigitOnly(formData.phone)
            ? formatDigitOnly(formData.phone)
            : undefined
          : undefined,
      verification_code:
        method === 'phone'
          ? formData.code
            ? formData.code
            : undefined
          : undefined,
    }
    await dispatch(login(params))
  }

  const handleForgotPassword = async () => {
    setForgotData({
      open: true,
      success: false,
      loading: true,
    })
    const data = await dispatch(passwordResetSend(formData.email))
    if (data.type !== 'auth/password-reset-send/rejected') {
      setForgotData({
        open: true,
        success: true,
        loading: false,
      })
    }
  }

  const handleSendCode = (e?: React.FormEvent) => {
    e && e.preventDefault()
    const params = {
      phone: formatDigitOnly(formData.phone),
      type: 'login',
    }
    dispatch(sendCode(params))
  }

  useEffect(() => {
    if (!loggedIn && getAccessToken()) {
      dispatch(getProfile())
    }
  }, [])

  useEffect(() => {
    if (loggedIn) {
      if (redirect_from && code) {
        history.replace(
          `/select-restaurant?redirect_from=${redirect_from}&code=${code}`,
        )
      } else {
        history.replace(fromPath)
      }
    }
  }, [loggedIn])

  useEffect(() => {
    if (formData.code.length === 4) {
      handleLogin()
    }
  }, [formData])

  useEffect(() => {
    dispatch(clearError())
  }, [method])

  useEffect(() => {
    if (!forgotData.open) {
      dispatch(clearError())
    }
  }, [forgotData])
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='bg-white rounded-xl p-8 inline-block'>
        <h1 className='text-center text-2xl font-bold'>Restaurant Dashboard</h1>
        {method === 'email' ? (
          <EmailLoginForm
            setMethod={setMethod}
            handleFormChange={handleFormChange}
            setForgotData={setForgotData}
            email={formData.email}
            password={formData.password}
            handleLogin={handleLogin}
            request={loginRequest}
            error={loginErrors?.errors || null}
          />
        ) : method === 'phone' ? (
          <PhoneLoginForm
            setMethod={setMethod}
            handleFormChange={handleFormChange}
            phone={formData.phone}
            handleSendCode={handleSendCode}
            errorMsg={loginErrors?.message || ''}
          />
        ) : (
          <LoginMethod setMethod={setMethod} />
        )}
      </div>
      <ForgotPasswordModal
        forgotData={forgotData}
        setForgotData={setForgotData}
        handleFormChange={handleFormChange}
        handleForgotPassword={handleForgotPassword}
        email={formData.email}
        request={passwordResetSendRequest}
        error={passwordResetSendError}
      />
    </div>
  )
}
