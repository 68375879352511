import React, { useEffect, useState } from 'react'

import { Spin } from 'components/Loading'
import { PERMISSION } from 'data/permission'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPayoutConfigApi } from 'redux/accountAndPayout'
import { useAppSelector } from 'redux/hooks'
import { titleCase } from 'utils/textFormat'

import DisplayBlock from '../billingAndInvoice/components/DisplayBlock'
import ModalChangeSchedule from './components/ModalChangeSchedule'
import ModalUpdateBank from './components/ModelUpdateBank'

type Interval = 'daily' | 'weekly'
type TPayoutInfo = {
  schedule: string
  interval: Interval
  bankName: string
  bankLast4: string
}

export default function PayoutSetting(): JSX.Element {
  const auth = useAppSelector(state => state.auth.authProfile.data)

  const { selectedLocation } = useAppSelector(state => state.location)
  const payoutConfigId = selectedLocation?.location_payout_config?.id || ''
  const isCanUpdateBank =
    auth?.permissionMap?.[PERMISSION.UPDATEBANKACCOUNT]?.allowed == 1
  const isCanUpdateSchedule =
    auth?.permissionMap?.[PERMISSION.UPDATEPAYOUTSCHEDULE]?.allowed == 1

  const { locationId } = useParams<TParamTypes>()

  const [loading, setLoading] = useState(false)
  const [payoutInfo, setPayoutInfo] = useState<TPayoutInfo>()

  useEffect(() => {
    if (payoutConfigId) {
      getPayoutInfo(false)
    }
  }, [payoutConfigId])

  const getPayoutInfo = async (refresh: boolean) => {
    setLoading(true)
    try {
      const res = await getPayoutConfigApi(
        locationId || '',
        payoutConfigId,
        refresh,
      )
      const schedule = res?.interval as Interval

      const info = res
        ? {
            schedule: schedule ? `${titleCase(schedule)} Payout` : '',
            interval: schedule,
            bankName: res.bank_name ? `(${res.bank_name})` : '',
            bankLast4: res.bank_last_4 ? `••• ${res.bank_last_4}` : '',
          }
        : undefined
      setPayoutInfo(info)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const refreshLocations = () => {
    getPayoutInfo(true)
  }
  return (
    <Spin spining={loading}>
      <div className='flex'>
        <DisplayBlock
          className='mr-8'
          title='Bank deposit'
          iconType='BankAccountIcon'
          mainTxt={payoutInfo?.bankLast4}
          secondaryTxt={payoutInfo?.bankName}
          descTxt='This is your checking account to receive your payout'
          button={
            <ModalUpdateBank
              disabled={!isCanUpdateBank}
              onSuccess={refreshLocations}
            />
          }
        />
        <DisplayBlock
          title='Payout schedule'
          iconType='PayoutScheduleIcon'
          mainTxt={payoutInfo?.schedule || ''}
          descTxt='This is the frequency of your payout schedule.'
          button={
            <ModalChangeSchedule
              disabled={!isCanUpdateSchedule}
              currentInterval={payoutInfo?.interval || 'weekly'}
              onSuccess={refreshLocations}
              payoutConfigId={payoutConfigId}
            />
          }
        />
      </div>
    </Spin>
  )
}
