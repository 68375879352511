import React from 'react'

import {
  Direct,
  Unknown,
  Google,
  Facebook,
  Yelp,
  Instagram,
  TikTok,
  Campaign,
  MobileApp,
} from '@rushable/icons'

import type { TCommonIconProp } from './type'

type TSourceIconRenderProps = {
  type?: string
  iconParams?: TCommonIconProp
}

export default function SourceIconRender({
  type,
  iconParams,
}: TSourceIconRenderProps): JSX.Element {
  switch (type) {
    case '(direct)':
      return <Direct {...iconParams} />
    case 'rushable_campaign':
      return <Campaign {...iconParams} />
    case 'sms_campaign':
      return <MobileApp {...iconParams} />
    case 'google.com':
    case 'google':
      return <Google {...iconParams} />
    case 'facebook.com':
    case 'facebook':
      return <Facebook {...iconParams} />
    case 'instagram':
      return <Instagram {...iconParams} />
    case 'yelp':
    case 'm.yelp.com':
      return <Yelp {...iconParams} />
    case 'tiktok':
      return <TikTok {...iconParams} />
    default:
      return <Unknown {...iconParams} />
  }
}

export const SOURCEICONMAP: any = {
  '(direct)': 'Direct Visit',
  rushable_campaign: 'Campaign',
  sms_campaign: 'Mobile App',
  'google.com': 'Google',
  google: 'Google',
  'facebook.com': 'Facebook',
  facebook: 'Facebook',
  instagram: 'Instagram',
  yelp: 'Yelp',
  'm.yelp.com': 'Yelp',
  tiktok: 'Tiktok',
}
