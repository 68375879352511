import React, { useEffect, useState } from 'react'

import { Spin } from 'components/Loading'
import SaveChangeBar from 'components/SaveChangeBar'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { getGeneralSettingApi, editGeneralSettingApi } from 'redux/location'
import { TOnlineOrderConfig } from 'types/onlineOrderConfig'

import GeneralSettingForm from './components/GeneralSettingForm'

export default function OrderGeneralSettingPage(): JSX.Element {
  const [updateConfigRequest, setUpdateConfigRequest] = useState(false)
  const [formIsDirty, setFormIsDirty] = useState(false)
  const { selectedLocation } = useAppSelector(state => state.location)
  const [formData, setFormData] = useState<TOnlineOrderConfig>({})
  const [formDataInit, setFormDataInit] = useState<TOnlineOrderConfig>({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { locationId } = useParams<TParamTypes>()

  const compareFn = useDebounce(compare)

  useEffect(() => {
    if (selectedLocation && !loading) {
      setLoading(true)
      getGeneralSetting()
    }
  }, [selectedLocation])

  const getGeneralSetting = async () => {
    if (selectedLocation?.online_order_config?.id) {
      try {
        const res = await getGeneralSettingApi({
          id: selectedLocation?.online_order_config?.id,
          locationId,
        })
        initFormData(res)
      } catch (e: any) {
        toast.error(e?.message || 'request error')
      }
    }
    setLoading(false)
  }

  /**
   * Initialize form data
   */
  const initFormData = (res: any) => {
    const formData = {
      ...res,
      online_order_config_id:
        res.custom_fees && res.custom_fees.length > 0
          ? res.custom_fees[0].online_order_config_id
          : 0,
      custom_fee_label:
        res.custom_fees && res.custom_fees.length > 0
          ? res.custom_fees[0].fee_label
          : '',
      allow_later_order: res.scheduled_order_days,
      allow_customer_note: res.allow_customer_note,
      check_fee:
        res.custom_fees && res.custom_fees.length > 0
          ? res.custom_fees[0].active
          : false,
      custom_fee_money:
        res.custom_fees && res.custom_fees.length > 0
          ? res.custom_fees[0].fixed_amount
          : '',
      custom_fee_percent:
        res.custom_fees && res.custom_fees.length > 0
          ? res.custom_fees[0].percentage
          : '',
      estimate_order_time: res.base_prep_time,
    }

    setFormData(formData)
    setFormDataInit(formData)
  }

  useEffect(() => {
    compareFn(formData, formDataInit, (flag: boolean) => {
      setFormIsDirty(flag)
      setError('')
    })
  }, [formData, formDataInit])

  const onFormChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const resetFormData = () => {
    setFormData(formDataInit)
  }

  const submitForm = () => {
    submitGeneralSetting()
  }

  const submitGeneralSetting = async () => {
    const {
      id,
      sales_tax,
      estimate_order_time,
      allow_later_order,
      allow_customer_note,
      check_card,
      check_id,
      check_fee,
      fraud_check_threshold,
      custom_fee_label,
      custom_fee_money,
      custom_fee_percent,
      phone_notification,
      email_notification,
    } = formData || {}
    const params = {
      active: Number(check_fee),
      fee_label: custom_fee_label,
      fixed_amount: Number(custom_fee_money),
      percentage: Number(custom_fee_percent),
      sales_tax,
      base_prep_time: estimate_order_time,
      scheduled_order_days: Number(allow_later_order),
      allow_customer_note: Number(allow_customer_note),
      check_card: Number(check_card),
      check_id: Number(check_id),
      fraud_check_threshold: Number(fraud_check_threshold),
      phone_notification,
      email_notification,
    }
    try {
      setUpdateConfigRequest(true)
      const res: any = await editGeneralSettingApi(id, params, locationId)
      toast.success(res.message)
      getGeneralSetting()
    } catch (e: any) {
      const msg = e.message || 'request error'
      setError(msg)
    } finally {
      setUpdateConfigRequest(false)
    }
  }

  return (
    <div className=''>
      <UnsavedPrompt when={formIsDirty} />
      {formIsDirty && (
        <SaveChangeBar
          error={error}
          onConfirm={submitForm}
          onCancel={resetFormData}
          confirmRequest={updateConfigRequest}
        />
      )}
      <Spin
        spining={loading}
        className='rounded-b-lg'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <GeneralSettingForm formData={formData} onFormChange={onFormChange} />
      </Spin>
    </div>
  )
}
