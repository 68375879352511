import React, { useState, useEffect, useRef } from 'react'

import { LinedStore, LinedCar, LinedUser } from '@rushable/icons'
import Button from 'components/Button'
import Drawer from 'components/Drawer'
import { SERVER_TIMEZONE } from 'data/constant'
import moment from 'moment'
import { useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { getOrderDetailApi } from 'redux/location/actions'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

import ProcessLogSkeleton from './ProcessLogSkeleton'

type TOrderProcessLogProp = {
  data: any
  open: boolean
  toggle: () => void
}

export default function OrderProcessLog({
  data,
  open,
  toggle,
}: TOrderProcessLogProp): JSX.Element {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const { pathname } = useLocation()
  const [formData, setFormData] = useState<Record<string, any>>({})
  const { locationId } = useParams<TParamTypes>()

  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE

  useEffect(() => {
    if (data.type === 'daas_delivery') {
      getOrderDetail()
    } else {
      setFormData({ ...data })
    }
  }, [data])

  const getOrderDetail = async () => {
    try {
      const res = await getOrderDetailApi(
        pathname.split('detail/')[1],
        locationId,
        {
          'expand[]': 'daas_delivery',
        },
      )
      setFormData({ ...res })
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
  }

  const sameOrFutureDay = (dt1: string | number, dt2: string | number) => {
    return moment(dt1).format('MM-DD') === moment(dt2).format('MM-DD')
      ? 'on the same day'
      : 'on the future day'
  }

  useEffect(() => {
    if (Object.keys(formData).length) {
      if (ref && ref.current) {
        const height = ref.current.clientHeight
        setHeight(height - 72)
      }
    }
  }, [formData])

  return (
    <>
      {open && (
        <Drawer
          open={open}
          toggle={toggle}
          position='right'
          title='PROCESSING LOG'
          rightFooter={
            <>
              <Button color='primary' onClick={toggle}>
                CLOSE
              </Button>
            </>
          }
        >
          {Object.keys(formData).length ? (
            <div className='relative' ref={ref}>
              <div
                style={{ height: height }}
                className='absolute inline-block w-[15px] top-[48px] border-r border-dashed border-zinc'
              ></div>
              <div className='relative z-10 grid'>
                <div className='text-ink font-bold'>
                  {formData?.created_at &&
                    parseServerTimeToLocalTime(
                      formData.created_at,
                      timezone,
                      'll',
                    )}
                </div>
                <div className='flex my-6'>
                  <div
                    className='inline-block mr-4'
                    style={{ minWidth: '99px' }}
                  >
                    <div className='bg-slate w-[98px] h-7 px-2 flex items-center justify-between  text-sm text-lead'>
                      <LinedUser size={16} />
                      {parseServerTimeToLocalTime(
                        formData?.created_at,
                        timezone,
                        'hh:mm A',
                      )}
                    </div>
                  </div>
                  <div className='mt-1'>
                    Order submitted for{' '}
                    {formData.request_at <= formData.created_at
                      ? 'ASAP'
                      : 'scheduled'}{' '}
                    {formData.type === 'daas_delivery'
                      ? 'delivery'
                      : formData.type}
                  </div>
                </div>
                {(formData.status === 'canceled'
                  ? formData.canceled_at
                  : formData.confirmed_at) &&
                  moment(formData.created_at).format('MM-DD') !==
                    moment(
                      formData.status === 'canceled'
                        ? formData.canceled_at
                        : formData.confirmed_at,
                    ).format('MM-DD') && (
                    <div className='text-ink font-bold mb-6'>
                      {parseServerTimeToLocalTime(
                        formData.status === 'canceled'
                          ? formData.canceled_at
                          : formData.confirmed_at,
                        timezone,
                        'll',
                      )}
                    </div>
                  )}

                {(formData.status === 'canceled'
                  ? formData.canceled_at
                  : formData.confirmed_at) && (
                  <div className='flex'>
                    <div
                      className='inline-block mr-4'
                      style={{ minWidth: '99px' }}
                    >
                      <div className='bg-slate w-[98px] h-7 px-2 flex items-center justify-between  text-sm text-lead'>
                        <LinedStore size={16} />
                        {parseServerTimeToLocalTime(
                          formData.status === 'canceled'
                            ? formData.canceled_at
                            : formData.confirmed_at,
                          timezone,
                          'hh:mm A',
                        )}
                      </div>
                    </div>
                    {formData.status === 'canceled' ? (
                      <>
                        <div className='mt-1'>
                          Order canceled by the restaurant at{' '}
                          {parseServerTimeToLocalTime(
                            formData.canceled_at,
                            timezone,
                            'hh:mm A',
                          )}{' '}
                          {sameOrFutureDay(
                            formData.canceled_at,
                            formData.created_at,
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='mt-1'>
                          Order confirmed to be ready{' '}
                          {['pickup', 'delivery', 'daas_delivery'].includes(
                            formData.type,
                          ) &&
                            `for${' '}
                            ${
                              formData.type === 'daas_delivery'
                                ? 'delivery'
                                : formData.type
                            }${' '}`}
                          at{' '}
                          {parseServerTimeToLocalTime(
                            moment(
                              moment(formData.request_at).valueOf() +
                                formData.prep_time * 60000,
                            ).format('YYYY-MM-DD HH:mm:ss'),
                            timezone,
                            'hh:mm A',
                          )}{' '}
                          {sameOrFutureDay(
                            moment(formData.request_at).valueOf() +
                              formData.prep_time * 60000,
                            formData.created_at,
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}

                <>
                  {formData?.daas_deliveries?.[0]?.events.map(
                    (eventItem: any, i: number) => {
                      return (
                        <div key={i}>
                          {moment(formData.created_at).format('MM-DD') !==
                            moment(eventItem.created_at).format('MM-DD') && (
                            <div className='text-ink font-bold mt-6'>
                              {parseServerTimeToLocalTime(
                                eventItem.created_at,
                                timezone,
                                'll',
                              )}
                            </div>
                          )}
                          <div className='flex mt-6'>
                            <div
                              className='inline-block mr-4'
                              style={{ minWidth: '99px' }}
                            >
                              <div className='bg-slate w-[98px] h-7 px-2 flex items-center justify-between  text-sm text-lead'>
                                {eventItem.event === 'delivery_attempted' ? (
                                  <LinedStore size={16} />
                                ) : (
                                  <LinedCar size={16} />
                                )}
                                {parseServerTimeToLocalTime(
                                  eventItem?.created_at,
                                  timezone,
                                  'hh:mm A',
                                )}
                              </div>
                            </div>

                            {eventItem.event === 'delivery_created' && (
                              <div className='mt-1'>
                                DoorDash estimated driver pickup at{' '}
                                {parseServerTimeToLocalTime(
                                  formData?.daas_deliveries[0]
                                    ?.estimated_pickup_time,
                                  timezone,
                                  'hh:mm A',
                                )}{' '}
                                {sameOrFutureDay(
                                  formData?.daas_deliveries[0]
                                    ?.estimated_pickup_time,
                                  formData?.created_at,
                                )}
                              </div>
                            )}
                            {eventItem.event === 'dasher_confirmed' && (
                              <div className='mt-1'>
                                Driver assigned to{' '}
                                {
                                  formData?.daas_deliveries[0]
                                    ?.driver_first_name
                                }{' '}
                                {formData?.daas_deliveries[0]?.driver_last_name}
                              </div>
                            )}
                            {eventItem.event ===
                              'dasher_confirmed_store_arrival' && (
                              <div className='mt-1'>Driver store arrival</div>
                            )}
                            {eventItem.event === 'delivery_cancelled' && (
                              <div className='mt-1 font-bold'>
                                <span className='text-red'>
                                  Driver cancelled service
                                </span>{' '}
                                (reason given: {eventItem.note})
                              </div>
                            )}
                            {eventItem.event === 'delivery_attempted' && (
                              <div className='mt-1'>
                                Restaurant requested a new driver
                              </div>
                            )}
                            {eventItem.event === 'dasher_picked_up' && (
                              <div className='mt-1'>
                                Driver picked up order and enroute to the
                                customer
                              </div>
                            )}
                            {eventItem.event ===
                              'dasher_confirmed_consumer_arrival' && (
                              <div className='mt-1'>
                                Driver arrived at the customer
                              </div>
                            )}
                            {eventItem.event === 'dasher_dropped_off' && (
                              <div className='mt-1 font-bold text-green'>
                                Order delivered
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    },
                  )}
                </>
              </div>
            </div>
          ) : (
            <>
              <ProcessLogSkeleton />
              <ProcessLogSkeleton />
              <ProcessLogSkeleton />
            </>
          )}
        </Drawer>
      )}
    </>
  )
}
