import React, { useState, useEffect } from 'react'

import {
  DuoToneUser,
  DuoToneCellphone,
  DuoToneEmail,
  LinedStore,
  LinedCar,
  LinedUtensils,
  AngleLeft,
} from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import SourceIconRender, {
  SOURCEICONMAP,
} from 'components/SourceIcon/SourceIconRender'
import Table from 'components/Table/Table'
import Badge from 'components/Tag/Badge'
import ToolTips from 'components/ToolTips'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCustomerDetail } from 'redux/customer'
import { useAppDispatch } from 'redux/hooks'
import { TCustomerDetailOrders, TCustomerDetailUser } from 'types/customer'
import { formatPhoneNumber, currencyFormatter } from 'utils/digit'

export default function CustomerDetailPage(): JSX.Element {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { locationId, orderId, brandId } = useParams<TParamTypes>()

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [customer, setCustomer] = useState<TCustomerDetailUser>({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  })
  const [orders, setOrders] = useState<TCustomerDetailOrders>(null)

  const getDetail = async (nowPage: number, pageSizeNow: number) => {
    try {
      setLoading(true)
      setPage(nowPage)
      setPageSize(pageSizeNow)
      const res = await dispatch(
        getCustomerDetail({
          orderId: orderId as string,
          location_id: locationId as string,
          per_page: pageSizeNow,
          page: nowPage,
        }),
      )
      if (res.customer) {
        setCustomer(res.customer)
      }
      if (res.orders) {
        setOrders(res.orders)
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }

  useEffect(() => {
    if (orderId) {
      getDetail(1, pageSize)
    }
  }, [orderId])

  const goDetail = (orderId: number) => {
    history.push(
      `/brand/${brandId}/location/${locationId}/order-history/order-detail/${orderId}?customer=1`,
    )
  }

  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-[112px]',
      custom: (value: string, item: any) => {
        let statusValue = ''
        let color: any = ''
        switch (value) {
          case 'placed':
            statusValue = 'New'
            color = 'yellow'
            break
          case 'confirmed':
          case 'ready':
          case 'done':
            statusValue = 'Processed'
            color = 'blue'
            break
          case 'canceled':
            statusValue = item.confirmed_at ? 'Cancelled' : 'Rejected'
            color = item.confirmed_at ? 'red' : 'gray'
            break
        }

        if (
          item.transaction &&
          item.transaction?.stripe_object_id &&
          item.transaction.stripe_object_id !== ''
        ) {
          statusValue = 'Deposited'
          color = 'green'
        }
        return <Badge color={color}>{statusValue}</Badge>
      },
    },
    {
      key: 'id',
      name: 'ORDER ID',
      align: 'right',
      className: 'w-[120px]',
      custom: (value: any) => {
        return <div>#{value}</div>
      },
    },
    {
      key: 'type',
      name: 'TYPE',
      // className: 'w-36',
      custom: (value: any) => {
        return (
          <div className='flex items-center text-lead'>
            {value === 'pickup' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Pickup</span>
              </>
            )}
            {value === 'delivery' && (
              <>
                <LinedCar size={16} className='mr-1' />
                <span>Delivery</span>
              </>
            )}
            {value === 'daas_delivery' && (
              <>
                <LinedCar size={16} className='mr-1' />
                <span className='whitespace-nowrap'>Delivery (OD)</span>
              </>
            )}
            {value === 'dine-in' && (
              <>
                <LinedUtensils size={16} className='mr-1' />
                <span>Dine-in</span>
              </>
            )}
            {value === 'curbside' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Curbside</span>
              </>
            )}
            {value === 'takeout' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Takeout</span>
              </>
            )}
          </div>
        )
      },
    },
    {
      key: 'utm_tracking',
      name: 'SOURCE',
      align: 'center',
      custom: (value: any) => {
        const source = value?.source
        return (
          <div className='flex justify-center items-center'>
            <ToolTips
              className='inline-flex'
              tipsClass='text-ink'
              hoverEl={
                <div className='flex'>
                  <SourceIconRender
                    type={source}
                    iconParams={{
                      size: 20,
                    }}
                  />
                </div>
              }
            >
              <div className='whitespace-nowrap'>
                {SOURCEICONMAP[source] || source || 'Other'}
              </div>
            </ToolTips>
          </div>
        )
      },
    },
    {
      key: 'customer',
      name: 'CUSTOMER',
      className: 'w-[104px]',
      tdClassName: 'text-lead',
      custom: (customer: any) => {
        return (
          <div className='text-ellipsis'>
            {customer.first_name} {customer.last_name}
          </div>
        )
      },
    },
    {
      key: 'created_at',
      name: 'DATE',
      align: 'right',
      tdClassName: 'text-lead',
      custom: (value: any) => {
        if (value) {
          return <span>{moment(value).format('MM/DD/YYYY')} </span>
        }
        return (
          <div className='flex justify-end items-center'>
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          </div>
        )
      },
    },
    {
      key: 'created_at',
      name: 'TIME',
      align: 'right',
      custom: (value: any) => {
        if (value) {
          return <span>{moment(value).format('hh:mm A')} </span>
        }
        return (
          <div className='flex justify-end items-center'>
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          </div>
        )
      },
    },
    {
      key: 'total',
      name: 'TOTAL',
      align: 'right',
      className: 'w-[104px]',
      custom: (value: any) => {
        return (
          <span className='font-medium'>{`${currencyFormatter(
            Number(value),
          )}`}</span>
        )
      },
    },
    {
      key: 'refund_amount',
      name: 'REFUND',
      align: 'right',
      className: 'w-[104px]',
      custom: (value: string) => {
        return (
          <>
            {value ? (
              <span className='text-red'>{`${currencyFormatter(
                Number(value),
              )}`}</span>
            ) : (
              <div className='flex justify-end items-center'>
                <span
                  style={{ width: '5px', height: '1px' }}
                  className='bg-ink inline-block'
                ></span>
              </div>
            )}
          </>
        )
      },
    },
  ]

  return (
    <AdminPage>
      <AdminPageTitle
        title='Customer Details'
        left={
          <Button color='secondary-link' onClick={() => history.go(-1)}>
            <AngleLeft className='mr-1' size={16} />
            GO BACK
          </Button>
        }
      />
      <Spin
        spining={page === 1 && loading && !orders}
        className='rounded-b-lg overflow-hidden z-10'
        styles={{
          minHeight: 'calc(100vh - 144px)',
        }}
      >
        <div className='py-8 px-8'>
          <div className='flex pb-6 border-b border-dashed border-zinc'>
            <div className='mr-6 flex flex-col flex-1'>
              <p className='text-xs text-lead'>Customer</p>
              <div className='mt-2 flex'>
                <DuoToneUser size={24} />
                <p className='font-bold ml-2 text-ink'>
                  {' '}
                  {customer.first_name
                    ? `${customer.first_name} ${customer.last_name}`
                    : '-'}
                </p>
              </div>
            </div>
            <div className='mr-6 flex flex-col flex-1'>
              <p className='text-xs text-lead'>Phone</p>
              <div className='mt-2 flex'>
                <DuoToneCellphone size={24} />
                <p className='font-bold ml-2 text-ink'>
                  {customer.phone ? formatPhoneNumber(customer.phone) : '-'}
                </p>
              </div>
            </div>
            <div className='flex flex-col flex-1'>
              <p className='text-xs text-lead'>Email</p>
              <div className='mt-2 flex'>
                <DuoToneEmail size={24} />
                <p className='font-bold ml-2 text-ink'>
                  {' '}
                  {customer.email ? customer.email : '-'}
                </p>
              </div>
            </div>
          </div>
          <div className='mt-6'>
            <Table
              loading={loading}
              isPageSet={true}
              containerClassName='text-xs text-ink rounded-lg border border-solid border-zinc overflow-hidden'
              current={orders?.current_page || 1}
              columns={columns}
              data={orders?.data || []}
              total={orders?.total || 0}
              totalText='records'
              onPageNoChange={value => {
                getDetail(value, pageSize)
              }}
              onPageSizeChange={value => {
                getDetail(1, value)
              }}
              clickedRow={row => goDetail(row.id)}
            />
          </div>
        </div>
      </Spin>
    </AdminPage>
  )
}
