import React from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTab from 'components/AdminPageTab'
import AdminPageTabs from 'components/AdminPageTabs'
import useActivePageTab from 'hooks/useActivePageTab'
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import PayoutDetail from './PayoutDetail'
import PayoutSetting from './PayoutSetting'
import TransactionHistory from './TransactionHistory'

export default function PayoutPage(): JSX.Element {
  const history = useHistory()
  const { path, url } = useRouteMatch()
  const switchTab = (tab: string) => {
    history.push(`${url}/${tab}`)
  }

  const activePath = useActivePageTab({})

  return (
    <AdminPage>
      <Switch>
        <Route path={`${path}/detail/:payoutId`} exact>
          <PayoutDetail />
        </Route>
        <Route path={path}>
          <AdminPageTabs>
            <AdminPageTab
              text='Payout Setting'
              active={activePath === 'setting'}
              onClick={() => switchTab('setting')}
            />
            <AdminPageTab
              text='Transaction History'
              active={activePath === 'transactions'}
              onClick={() => switchTab('transactions')}
            />
          </AdminPageTabs>
          <div className='p-8'>
            <Switch>
              <Route path={`${path}/setting`} exact>
                <PayoutSetting />
              </Route>
              <Route path={`${path}/transactions`} exact>
                <TransactionHistory />
              </Route>
              <Redirect from={`${path}`} to={`${path}/setting`} exact />
            </Switch>
          </div>
        </Route>
      </Switch>
    </AdminPage>
  )
}
