import { LinedTrash } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import { Select } from 'components/Select'

interface Iprops {
  value?: any[]
  onChange?: (value: any[]) => void
}

const TRACKINS = [
  { label: 'Google Analytics', value: 'google_analytics' },
  { label: 'Facebook Pixel', value: 'facebook_pixel' },
  { label: 'TikTok Pixel', value: 'tikTok_pixel' },
]

const AddLink = ({ value = [], onChange }: Iprops): JSX.Element => {
  const tracks = value

  const onChangeForm = (arrs: any[]) => {
    const copyArrs = [...arrs]
    onChange?.(copyArrs)
  }
  return (
    <div className='w-full'>
      <div className='text-xs text-silver font-bold mb-2'>
        Traffic tracking integration
      </div>
      {tracks.map((item, index) => {
        return (
          <div className='flex items-center	mb-2' key={index}>
            <Select
              className='mr-4 w-60'
              value={item.type || ''}
              options={TRACKINS}
              onChange={value => {
                tracks[index].type = value
                onChangeForm(tracks)
              }}
            />

            <Field
              type='text'
              name='tracking'
              value={item.code || ''}
              onChange={(e: any) => {
                const value = e.target.value
                tracks[index].code = value
                onChangeForm(tracks)
              }}
              placeholder='Input your tracking ID'
              containerClassName='flex-1'
            />
            <Button
              className='ml-4'
              color='ink-link'
              onClick={() => {
                tracks.splice(index, 1)
                onChangeForm(tracks)
              }}
            >
              <LinedTrash size={16} />
            </Button>
          </div>
        )
      })}
      <div>
        <Button
          className='w-full'
          color='tertiary'
          onClick={() => {
            tracks.push({})
            onChangeForm(tracks)
          }}
        >
          + ADD TRACKING
        </Button>
      </div>
    </div>
  )
}

export default AddLink
