import React, { useState } from 'react'

import BankInfoSvg from 'assets/img/bankInfo.svg'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateBankAccountApi } from 'redux/accountAndPayout'

import { ACCOUNTTYPE } from '../../billingAndInvoice/helps'

interface IModalTypes {
  disabled?: boolean
  onSuccess: () => void
}
export default function ModalUpdateBank({ disabled, onSuccess }: IModalTypes) {
  const { locationId = '' } = useParams<TParamTypes>()
  const [isVisible, setIsVisible] = useState(false)
  const [formStatus, setFormStatus] = useState({
    loading: false,
    error: '',
  })
  const [formData, setFormData] = useState({
    accountName: '',
    accountType: '',
    routingNumber: '',
    accountNumber: '',
    repeatAccountNumber: '',
  })

  const handleSubmit = async () => {
    setFormStatus(prev => ({ ...prev, loading: true }))
    const params = {
      account_holder_name: formData.accountName,
      account_holder_type: formData.accountType,
      routing_number: formData.routingNumber,
      account_number: formData.accountNumber,
    }
    try {
      const res = await updateBankAccountApi(params, locationId)
      setFormStatus(prev => ({ ...prev, error: '' }))
      setIsVisible(false)
      onSuccess()
      toast.success(res.message)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  const onChangeForm = (name: string, value: any) => {
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const {
    accountName,
    accountType,
    routingNumber,
    accountNumber,
    repeatAccountNumber,
  } = formData
  return (
    <>
      <Button
        color='secondary'
        size='sm'
        disabled={disabled}
        onClick={() => setIsVisible(true)}
      >
        UPDATE BANK
      </Button>
      <ModalFull
        open={isVisible}
        title='Update Bank'
        loading={formStatus.loading}
        error={formStatus.error}
        toggle={() => setIsVisible(false)}
        onOk={() => {
          handleSubmit()
        }}
      >
        <>
          <div className='w-[368px] h-[185px] m-auto'>
            <img src={BankInfoSvg} alt='' />
          </div>
          <div className='mt-6'>
            <div className=''>
              <Field
                label='Account name'
                name='Account name'
                placeholder='Input here'
                type='text'
                value={accountName}
                onChange={e => {
                  onChangeForm('accountName', e.target.value)
                }}
              />
            </div>
            <div className='flex my-6'>
              <div className='flex-1 mr-6 '>
                <Select
                  className='flex-1'
                  label='Account type'
                  value={accountType}
                  placeholder='Select option'
                  options={ACCOUNTTYPE}
                  onChange={(mode: any) => {
                    onChangeForm('accountType', mode)
                  }}
                />
              </div>
              <div className='flex-1'>
                <Field
                  className='flex-1'
                  label='Routing number'
                  name='Routing number'
                  placeholder='Input here'
                  type='text'
                  value={routingNumber}
                  onChange={e => {
                    onChangeForm('routingNumber', e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='flex'>
              <div className='flex-1 mr-6 '>
                <Field
                  className='flex-1'
                  label='Account number'
                  name='Account number'
                  placeholder='Input here'
                  type='text'
                  value={accountNumber}
                  onChange={e => {
                    onChangeForm('accountNumber', e.target.value)
                  }}
                />
              </div>
              <div className='flex-1'>
                <Field
                  className='flex-1'
                  label='Repeat account number'
                  name='Account number'
                  placeholder='Input here'
                  type='text'
                  value={repeatAccountNumber}
                  onChange={e => {
                    onChangeForm('repeatAccountNumber', e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </>
      </ModalFull>
    </>
  )
}
