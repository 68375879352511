import React, { useState, useEffect } from 'react'

import SaveChangeBar from 'components/SaveChangeBar'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import useDebounce, { compare } from 'hooks/useDebounce'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getHolidaysApi,
  getSpecialHourApi,
  updateSpecialHourApi,
} from 'redux/business/actions'
import { THoursInBusiness } from 'types/openHour'

import DateAdd from './components/DateAdd'
import HolidaySelector from './components/HolidaySelector'
import SkeletonItem from './components/SkeletonItem'

export default function PickupSettingDisclosure(): JSX.Element {
  const { locationId = '' } = useParams<TParamTypes>()
  const [holidays, setHolidays] = useState<any[]>([])
  const [hoursInDay, setHoursInDay] = useState<THoursInBusiness[]>([])
  const [hoursInDayInit, sethoursInDayInit] = useState<THoursInBusiness[]>([])
  const [formStatus, setFormStatus] = useState({
    loading: false,
    error: '',
    isDirty: false,
    isRequested: false,
  })

  const compareFn = useDebounce(compare)

  useEffect(() => {
    compareFn(hoursInDay, hoursInDayInit, (flag: boolean) =>
      setFormStatus(prev => ({ ...prev, isDirty: flag })),
    )
  }, [hoursInDay, hoursInDayInit])

  useEffect(() => {
    getHolidays()
    getSpecialHour()
  }, [])

  useEffect(() => {
    checkLeftStatus()
  }, [hoursInDay, holidays.length])

  const getHolidays = async () => {
    try {
      const res = await getHolidaysApi()
      setHolidays(res)
    } catch (e: any) {
      const msg = e?.message || 'request error'
      toast.error(msg)
    }
  }

  const getSpecialHour = async () => {
    try {
      const res = await getSpecialHourApi(locationId)
      initData(res)
    } catch (e: any) {
      const msg = e?.message
      toast.error(msg || 'request error')
    }
  }

  const initData = (res = []) => {
    const arr: any[] = []
    res.forEach((item: any) => {
      const obj = {
        label: item.date,
        value: item.special_hours,
      }
      arr.push(obj)
    })
    setHoursInDay(arr)
    sethoursInDayInit(cloneDeep(arr))
    setFormStatus(prev => ({ ...prev, isRequested: true }))
  }

  const updateRegularHour = async () => {
    const arr: any[] = []
    hoursInDay.forEach(item1 => {
      const date = item1.label ? moment(item1.label).format('YYYY-MM-DD') : ''
      if (item1.value?.length) {
        item1.value?.forEach(item2 => {
          const obj = {
            date,
            from: item2.from,
            to: item2.to,
          }
          arr.push(obj)
        })
      } else {
        if (date) {
          const obj = { date, from: '', to: '' }
          arr.push(obj)
        }
      }
    })

    setFormStatus(prev => ({ ...prev, loading: true }))
    try {
      const res = await updateSpecialHourApi(
        { special_hours: arr },
        locationId || '',
      )
      toast.success(res.message)
      setFormStatus(prev => ({ ...prev, error: '', isDirty: false }))
      getSpecialHour()
    } catch (e: any) {
      const msg = e?.message || 'request error'
      toast.error(msg)
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  const checkLeftStatus = () => {
    // console.log('..checkLeftStatus.')
    const labelArrs = hoursInDay.map(v => v.label)
    holidays.forEach(v => {
      if (labelArrs.includes(v.date)) {
        v.disabled = true
      } else {
        v.disabled = false
      }
    })
    setHolidays([...holidays])
  }

  const onInputChange = (arr: THoursInBusiness[]) => {
    setHoursInDay([...arr])
  }
  return (
    <>
      <div className='px-8 flex' style={{ minHeight: 'calc(100vh - 144px)' }}>
        <div className='py-8 flex-1 border-r border-zinc'>
          <h2 className='mb-6 text-base text-ink font-bold'>
            Holiday Reference
          </h2>
          {holidays.length ? (
            <HolidaySelector
              list={holidays}
              onChange={date => {
                const obj = {
                  label: date,
                  value: [],
                }
                hoursInDay.push(obj)
                onInputChange(hoursInDay)
              }}
            />
          ) : (
            <SkeletonItem />
          )}
        </div>
        <div className='flex-1 py-8'>
          <div className='ml-8'>
            <h2 className='mb-3 text-base text-ink font-bold'>Dates Added</h2>
            {formStatus.isRequested ? (
              <DateAdd
                dailyHours={hoursInDay}
                onChange={value => {
                  onInputChange(value)
                }}
              ></DateAdd>
            ) : (
              <SkeletonItem />
            )}
          </div>
        </div>
      </div>

      <UnsavedPrompt when={formStatus.isDirty} />
      {formStatus.isDirty && (
        <SaveChangeBar
          onConfirm={() => {
            updateRegularHour()
          }}
          onCancel={() => {
            setFormStatus(prev => ({ ...prev, isDirty: false }))
            setHoursInDay(cloneDeep(hoursInDayInit))
          }}
          error={formStatus.error}
          confirmRequest={formStatus.loading}
        />
      )}
    </>
  )
}
