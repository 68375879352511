import React, { useEffect, useState } from 'react'

import BreadCrumbs from 'components/BreadCrumbs'
import { SaveChangeBarProvider } from 'context/SaveChangeContext'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast, Slide } from 'react-toastify'
import { getPermissionsAndProfile } from 'redux/auth'
import { getBrand } from 'redux/brand'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getLocation } from 'redux/location'

import LeftNav from './components/LeftNav'

type TAdminLayoutProps = {
  children: React.ReactElement
  permissionId?: number
  showBreadCrumbs?: boolean
}
export default function AdminLayout({
  children,
  permissionId,
  showBreadCrumbs = false,
}: TAdminLayoutProps): JSX.Element {
  const [activeMode, setActiveMode] = useState<'brand' | 'location'>('location')
  const dispatch = useAppDispatch()
  const { brandId, locationId } = useParams<TParamTypes>()
  const [reload, setReload] = useState(false)
  // const permission = useAppSelector(state => state.auth.authProfile)
  useEffect(() => {
    dispatch(getBrand({ brand_id: Number(brandId) }))
    dispatch(getPermissionsAndProfile({ brand_id: Number(brandId) }))
  }, [brandId])

  useEffect(() => {
    updateLocation()
  }, [locationId])

  const updateLocation = async () => {
    dispatch(getLocation({ location_id: Number(locationId) }))
  }

  // 自定义 403页面 看后续是否需要此逻辑，如需则打开
  // let view = null
  // if (permissionId) {
  //   if (permission.data) {
  //     const pMap = permission.data?.permissionMap || {}
  //     if (pMap[permissionId]?.allowed == 0) {
  //       console.log('no permissons to visit the page')
  //       view = (
  //         <div className='text-center mt-[360px] text-4xl'>
  //           no permissons visit the page
  //         </div>
  //       )
  //     } else {
  //       view = !reload && children
  //     }
  //   }
  // } else {
  //   view = !reload && children
  // }

  return (
    <SaveChangeBarProvider>
      <div className='bg-slate h-screen w-screen overflow-auto'>
        <ToastContainer
          position={toast.POSITION.TOP_CENTER}
          transition={Slide}
          limit={3}
        />
        <LeftNav
          activeMode={activeMode}
          onChange={value => setReload(value)}
          setActiveMode={setActiveMode}
        />
        <div className='absolute left-60 right-0 top-0 bottom-0 overflow-y-scroll overscroll-none'>
          {showBreadCrumbs && <BreadCrumbs />}
          {!reload && children}
        </div>
      </div>
    </SaveChangeBarProvider>
  )
}
