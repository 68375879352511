import React, { useState } from 'react'

import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import { Select } from 'components/Select'
import TextArea from 'components/TextArea'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { cancelSubjectApi } from 'redux/accountAndPayout'

import { CANCELREASON } from '../helps'

interface IModalTypes {
  onSuccess: () => void
}
export default function ModalCancel({ onSuccess }: IModalTypes) {
  const { locationId = '' } = useParams<TParamTypes>()
  const [isVisible, setIsVisible] = useState(false)
  const [formStatus, setFormStatus] = useState({ loading: false, error: '' })
  const [formData, setFormData] = useState({ reason: '', notes: '' })

  const handleSubmit = async () => {
    setFormStatus(prev => ({ ...prev, loading: true }))
    try {
      const res = await cancelSubjectApi(formData, locationId)
      setFormStatus(prev => ({ ...prev, error: '' }))
      toast.success(res.message)
      onSuccess()
      setIsVisible(false)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  const onChangeForm = (name: string, value: any) => {
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const { reason, notes } = formData
  return (
    <>
      <Button color='tertiary' size='sm' onClick={() => setIsVisible(true)}>
        SUBMIT REQUEST
      </Button>
      <ModalFull
        open={isVisible}
        okBtnColor='warning'
        okText='SUBMIT'
        title='Account Cancelling'
        loading={formStatus.loading}
        error={formStatus.error}
        toggle={() => setIsVisible(false)}
        onOk={() => {
          handleSubmit()
        }}
      >
        <>
          <div className='text-ink text-base leading-6 text-center'>
            Please submit the form below, and after our team received your
            cancelling request, we will start your off-boarding process.
          </div>
          <div className='mt-6'>
            <Select
              className='mb-6'
              label='The reason of the cancellation'
              value={reason}
              placeholder='Select'
              options={CANCELREASON}
              onChange={(mode: any) => {
                onChangeForm('reason', mode)
              }}
            />
            <TextArea
              label='Could you help us understand the reason above in more details'
              name='TextInput'
              placeholder='Input'
              value={notes}
              onChange={(e: any) => {
                onChangeForm('notes', e.target.value)
              }}
            />
          </div>
        </>
      </ModalFull>
    </>
  )
}
