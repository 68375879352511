import React, { useState, useEffect, useRef } from 'react'

import { AngleRight } from '@rushable/icons'
import Button from 'components/Button'
import DateRangeModal from 'components/CalendarField/DateRange'
import { Spin } from 'components/Loading'
import { Select } from 'components/Select'
import Table from 'components/Table/Table'
import { Badge } from 'components/Tag'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSubscriptionsApi, getInvoiceListApi } from 'redux/accountAndPayout'
import { currencyFormatter } from 'utils/digit'

import { STATUSOPTIONS, STATUSOPTIONSMAP } from './helps'

const SetupEmail = (): JSX.Element => {
  const { locationId = '' } = useParams<TParamTypes>()
  const [typeOptions, setTypeOptions] = useState([
    { label: 'Any Type', value: '' },
  ])
  const [searchForm, setSearchForm] = useState({
    status: undefined,
    type: undefined,
    startDate: '',
    endDate: '',
    loading: false,
  })
  const [pageObj, setPageObj] = useState({
    per_page: 20,
    id: undefined,
    loading: false,
  })
  const [tableData, setTableData] = useState<any>([])
  const [pageLoadMore, setPageLoadMore] = useState(false)
  const typeMapData = useRef(null)

  useEffect(() => {
    getSubscriptions()
    getInvoiceList(pageObj)
  }, [])

  const getSubscriptions = async () => {
    setSearchForm(prev => ({ ...prev, loading: true }))
    try {
      const res = await getSubscriptionsApi(locationId)
      const types = Object.keys(res || {}).map(key => ({
        label: res[key],
        value: key,
      }))
      typeMapData.current = res
      setTypeOptions(prev => [...prev, ...types])
    } catch (e: any) {
      // ...
    }
    setSearchForm(prev => ({ ...prev, loading: false }))
  }

  const getInvoiceList = async (pageObj?: any, isLoadMore = false) => {
    const s = searchForm.startDate && moment(searchForm.startDate).unix()
    const e = searchForm.endDate && moment(searchForm.endDate).unix()
    const params = {
      status: searchForm.status || undefined,
      type: searchForm.type || undefined,
      limit: pageObj.per_page,
      start_date: s || undefined,
      end_date: e || undefined,
      starting_after: pageObj.id || undefined,
    }
    setPageObj(prev => ({ ...prev, loading: true }))
    try {
      const res = await getInvoiceListApi(params, locationId)
      if (isLoadMore) {
        setTableData((prev: any) => [...prev, ...res.data])
      } else {
        setTableData(res.data || [])
      }
      setPageLoadMore(res.has_more)
    } catch (e: any) {
      const msg = e.message || 'Request error'
      toast.error(msg)
    }
    setPageObj(prev => ({ ...prev, loading: false }))
  }

  const handleSearch = () => {
    setTableData([])
    getInvoiceList(pageObj)
  }

  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-[110px]',
      custom: (value: string) => {
        return (
          <div>
            <Badge color={STATUSOPTIONSMAP[value]?.color}>
              {STATUSOPTIONSMAP[value]?.label}
            </Badge>
          </div>
        )
      },
    },
    {
      key: 'subscription_id',
      name: 'TYPE',
      className: 'w-[180px]',
      custom: (type: string) => {
        return (
          <span className='text-xs'>{typeMapData.current?.[type] || '-'}</span>
        )
      },
    },
    {
      align: 'right',
      key: 'date',
      className: 'w-[100px]',
      name: 'DATE',
      custom: (value: number) => {
        return (
          <div className='text-lead text-xs'>
            {value ? moment.unix(value).format('MM/DD/YYYY') : '-'}
          </div>
        )
      },
    },
    {
      key: 'id',
      name: 'INVOICE ID',
      className: 'w-[240px]',
      custom: (id: string) => {
        return <span className='text-xs text-lead'>{id}</span>
      },
    },
    // {
    //   key: 'payment',
    //   name: 'PAYMENT',
    //   custom: (value: string) => {
    //     return (
    //       <div className='flex items-center text-ink text-xs'>
    //         <BankAccountIcon size={16} />
    //         <div className='ml-1'>{value}</div>
    //       </div>
    //     )
    //   },
    // },
    {
      align: 'right',
      key: 'amount',
      className: 'w-[140px]',
      name: 'AMOUNT',
      custom: (value: string) => (
        <div className='font-medium text-xs'>
          {currencyFormatter(parseFloat(value))}
        </div>
      ),
    },
    {
      align: 'right',
      key: 'url',
      className: 'w-[100px]',
      name: 'ACTION',
      custom: (url: string) => {
        return (
          <a
            className='flex text-silver text-xs justify-end font-bold hover:text-blue'
            target='_blank'
            href={url}
          >
            DETAIL
            <AngleRight size={12} className='ml-1' />
          </a>
        )
      },
    },
  ]

  return (
    <div>
      <Spin spining={searchForm.loading}>
        <div className='grid grid-cols-4 pb-6 mb-6 gap-6 border-b border-dotted border-zinc'>
          <div>
            <Select
              label='Status'
              placeholder='Select option'
              options={STATUSOPTIONS}
              onChange={(mode: any) => {
                setSearchForm(prev => ({ ...prev, status: mode }))
              }}
            />
          </div>
          <div>
            <Select
              label='Type'
              placeholder='Select option'
              options={typeOptions}
              onChange={(mode: any) => {
                setSearchForm(prev => ({ ...prev, type: mode }))
              }}
            />
          </div>
          <div>
            <DateRangeModal
              label='Date Range'
              placeholder='Select option'
              onChange={e => {
                setSearchForm(prev => ({
                  ...prev,
                  startDate: moment(e.startDate).format('YYYY/MM/DD'),
                  endDate: moment(e.endDate).format('YYYY/MM/DD'),
                }))
              }}
            />
          </div>
          <div className='flex items-end'>
            <Button className='w-full' onClick={handleSearch}>
              SEARCH
            </Button>
          </div>
        </div>
      </Spin>

      <Table
        loading={pageObj.loading}
        columns={columns}
        data={tableData}
        isPageSet={true}
        paginationShow={false}
        pageLoadMore={pageLoadMore}
        showTotal={false}
        resizePageSize={pageObj.per_page}
        onClickLoadMore={() => {
          const lastItem = tableData[tableData.length - 1]
          const obj = { ...pageObj, id: lastItem.id }
          setPageObj(obj)
          getInvoiceList(obj, true)
        }}
        onPageSizeChange={per_page => {
          setTableData([])
          const obj = { ...pageObj, per_page, id: undefined }
          setPageObj(obj)
          getInvoiceList(obj)
        }}
      />
    </div>
  )
}

export default SetupEmail
