import React, { useEffect, useState } from 'react'

import OpenHoursForm from 'components/OpenHoursForm'
import BaseOrderTypeConfig from 'pages/orderSetting/components/BaseOrderTypeConfig'
import { returnHoursInDays } from 'utils/hours'

import OrderTypeDisclosure from './OrderTypeDisclosure'

export type TPickupSettingDisclosureProp = {
  name: string
  label: string
  type: string
  hoursStatus?: string
  pickupConfig: any
  onChange: (name: string, value: any) => void
  tabChange: (value: string) => void
}
export default function PickupSettingDisclosure({
  name,
  label,
  type,
  hoursStatus,
  pickupConfig,
  onChange,
  tabChange,
}: TPickupSettingDisclosureProp): JSX.Element {
  const {
    open_hours_collection,
    order_min,
    availability,
    prior_to_close,
    enabled,
  } = pickupConfig || {}

  const [openHoursDay, setOpenHoursDay] = useState({})

  useEffect(() => {
    let days: any = returnHoursInDays([])
    open_hours_collection?.open_hours.map((v: { day: string | number }) => {
      days = { ...days, [v.day]: [...days[v.day], v] }
    })
    setOpenHoursDay({ ...days })
  }, [open_hours_collection])

  return (
    <>
      <OrderTypeDisclosure
        name={name}
        label={label}
        type={type}
        enabled={enabled}
        hoursStatus={hoursStatus || 'Follow business hours'}
        tabChange={tabChange}
        onChange={onChange}
      >
        <>
          <BaseOrderTypeConfig
            label={label}
            formData={{
              order_min,
              availability,
              prior_to_close,
            }}
            onFormChange={(name, value) => onChange(name, value)}
          />
          {availability === 'customize' && (
            <div className='border border-solid rounded-lg p-4 border-zinc mt-6'>
              <OpenHoursForm
                hoursInDay={openHoursDay}
                onChange={value => onChange('open_hours', value)}
              />
            </div>
          )}
        </>
      </OrderTypeDisclosure>
    </>
  )
}
