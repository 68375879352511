import React from 'react'

export type TEstimateOrderTimeProp = {
  value: number
  children: React.ReactElement
}

export default function EstimateOrderTime({
  value,
  children,
}: TEstimateOrderTimeProp): JSX.Element {
  const minutes = value || 0
  return (
    <div className='flex flex-1 items-center text-dark-100 font-normal'>
      Minimum prep time is usually
      {children}
      minutes.
      <span className='text-dark-64 ml-8'>
        Estimate Pickup {minutes}-{minutes + 15} minutes • Estimate Delivery{' '}
        {minutes + 20}-{minutes + 40} minutes
      </span>
    </div>
  )
}
