import React from 'react'

import { SolidTag, Percentage, Dollar, Plus } from '@rushable/icons'
import Field from 'components/Field'
import Label from 'components/Label'
import Switch from 'components/Switch'

export type TCustomFeeProp = {
  check_fee: boolean | undefined
  custom_fee_label: string
  custom_fee_money: string | number
  custom_fee_percent: string | number
  onFormChange: (name: string, value: any) => void
}

export default function CustomFee({
  check_fee,
  onFormChange,
  custom_fee_label,
  custom_fee_money,
  custom_fee_percent,
}: TCustomFeeProp): JSX.Element {
  return (
    <>
      <div
        className={`flex flex-1  items-center text-dark-100 font-normal ${
          check_fee && 'pb-4'
        }`}
      >
        <SolidTag size={16} className='mr-2 text-silver' />
        Apply a custom fee on each order
        <Switch
          className='ml-auto py-0'
          name='check_fee'
          checked={check_fee || false}
          onChange={v => onFormChange('check_fee', v)}
        />
      </div>
      {!!check_fee && (
        <>
          <div className='border-t border-dashed border-zinc' />
          <div className='flex pt-4 box-border'>
            <Field
              label={
                <span className='text-silver font-normal'>
                  Custom fee label
                </span>
              }
              value={custom_fee_label}
              onChange={e => onFormChange('custom_fee_label', e.target.value)}
              name='custom_fee_label'
              type='text'
              containerClassName='flex flex-col w-72 pr-8'
              placeholder='e.g. Payment Processing'
            />
            <div className='mt-[13px] h-[45px] border-l border-dashed border-zinc'></div>
            <div className='pl-8 flex flex-col'>
              <Label className='text-sliver font-normal'>
                Custom fee amount
              </Label>
              <div className='flex text-dark-100 items-center pt-2'>
                Apply
                <Field
                  name='custom_fee_money'
                  value={custom_fee_money || ''}
                  type='text'
                  placeholder='0.00'
                  align='right'
                  inputMode='money'
                  suffix={<Dollar size={16} className='text-silver' />}
                  containerClassName='w-[104px] ml-2'
                  maxLength={6}
                  onChangeMoney={value => {
                    const n = Number(value)
                    if (n <= 999.99 && n >= 0) {
                      onFormChange('custom_fee_money', value)
                    }
                  }}
                />
                <Plus size={16} className='mx-2' />
                <Field
                  value={custom_fee_percent || ''}
                  maxLength={5}
                  onChange={e => {
                    const n = Number(e.target.value)
                    if (n <= 99.99 && n >= 0) {
                      onFormChange('custom_fee_percent', e.target.value)
                    }
                  }}
                  name='custom_fee_percent'
                  placeholder='0.00'
                  type='tel'
                  containerClassName='w-[104px] mr-2'
                  append={<Percentage size={16} className='text-silver' />}
                />
                of subtotal on each order.
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
