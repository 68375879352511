import React, { useState, useEffect } from 'react'

import { LinedStore, LinedCar, LinedUtensils } from '@rushable/icons'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import DateRangeModal from 'components/DateRangeModal'
import Field from 'components/Field'
import { Select } from 'components/Select'
import SourceIconRender, {
  SOURCEICONMAP,
} from 'components/SourceIcon/SourceIconRender'
import Table from 'components/Table/Table'
import Badge from 'components/Tag/Badge'
import ToolTips from 'components/ToolTips'
import { SERVER_TIMEZONE } from 'data/constant'
import moment from 'moment'
import { ORDER_HISTORY_STATUS_OPTIONS } from 'pages/orderHistory/helpers/constant'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { currencyFormatter } from 'utils/digit'
import parseServerTimeToLocalTime from 'utils/parseServerTimeToLocalTime'

import OrderSummary from './components/OrderSummary'

type TOrderHistoryPageProp = {
  tableData: any
  status: string
  ranges: any[]
  loading: boolean
  searchLoading: boolean
  keyword: string
  dateLabel: string
  page: number
  onChange: (name: string, value: any) => void
}

export default function OrderHistoryPage({
  tableData,
  status,
  loading,
  searchLoading,
  ranges,
  keyword,
  dateLabel,
  page,
  onChange,
}: TOrderHistoryPageProp): JSX.Element {
  const [dateOpen, setDateOpen] = useState(false)

  const [openSummary, setOpenSummary] = useState(false)
  const history = useHistory()
  const { url } = useRouteMatch()
  const { selectedLocation } = useAppSelector(state => state.location)
  const timezone = selectedLocation?.address?.timezone || SERVER_TIMEZONE

  const columns = [
    {
      key: 'status',
      name: 'STATUS',
      className: 'w-[136px]',
      custom: (value: string, item: any) => {
        let statusValue = ''
        let color: any = ''
        switch (value) {
          case 'placed':
            statusValue = 'New'
            color = 'yellow'
            break
          case 'confirmed':
          case 'ready':
          case 'done':
            statusValue = 'Processed'
            color = 'blue'
            break
          case 'canceled':
            statusValue = item.confirmed_at ? 'Cancelled' : 'Rejected'
            color = item.confirmed_at ? 'red' : 'gray'
            break
        }

        if (
          item.transaction &&
          item.transaction?.stripe_object_id &&
          item.transaction.stripe_object_id !== ''
        ) {
          statusValue = 'Deposited'
          color = 'green'
        }
        return <Badge color={color}>{statusValue}</Badge>
      },
    },
    {
      key: 'id',
      name: 'ORDER ID',
      align: 'left',
      className: 'w-[136px]',
      tdClassName: 'text-ink',
      custom: (value: any) => {
        return <div>#{value}</div>
      },
    },
    {
      key: 'type',
      name: 'TYPE',
      className: 'w-[136px]',
      custom: (value: any) => {
        return (
          <div className='flex items-center text-lead'>
            {value === 'pickup' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Pickup</span>
              </>
            )}
            {value === 'delivery' && (
              <>
                <LinedCar size={16} className='mr-1' />
                <span>Delivery</span>
              </>
            )}
            {value === 'daas_delivery' && (
              <>
                <LinedCar size={16} className='mr-1' />
                <span className='whitespace-nowrap'>Delivery (OD)</span>
              </>
            )}
            {value === 'dine-in' && (
              <>
                <LinedUtensils size={16} className='mr-1' />
                <span>Dine-in</span>
              </>
            )}
            {value === 'curbside' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Curbside</span>
              </>
            )}
            {value === 'takeout' && (
              <>
                <LinedStore size={16} className='mr-1' />
                <span>Takeout</span>
              </>
            )}
          </div>
        )
      },
    },
    {
      key: 'utm_tracking',
      name: 'SOURCE',
      align: 'center',
      className: 'w-[88px]',
      custom: (value: any) => {
        const source = value?.source
        return (
          <div className='flex justify-center items-center'>
            <ToolTips
              className='flex justify-center'
              tipsClass='text-ink'
              hoverEl={
                <div className='flex'>
                  <SourceIconRender
                    type={source}
                    iconParams={{
                      size: 20,
                    }}
                  />
                </div>
              }
            >
              <div className='whitespace-nowrap'>
                {SOURCEICONMAP[source] || source || 'Other'}
              </div>
            </ToolTips>
          </div>
        )
      },
    },
    {
      key: 'customer',
      name: 'CUSTOMER',
      custom: (customer: any) => {
        return (
          <div className='text-ellipsis'>
            {customer.first_name} {customer.last_name}
          </div>
        )
      },
    },
    {
      key: 'total',
      name: 'TOTAL',
      align: 'right',
      custom: (value: any) => {
        return <span>{`${currencyFormatter(Number(value))}`}</span>
      },
    },
    {
      key: 'refund_sum',
      name: 'REFUND',
      align: 'right',
      custom: (value: string) => {
        return (
          <>
            {value ? (
              <span className='text-red'>{`${currencyFormatter(
                Number(value),
              )}`}</span>
            ) : (
              <div className='flex justify-end items-center'>
                <span
                  style={{ width: '5px', height: '1px' }}
                  className='bg-ink inline-block'
                ></span>
              </div>
            )}
          </>
        )
      },
    },
    {
      key: 'created_at',
      name: 'TIME',
      align: 'right',
      className: 'w-[200px]',
      custom: (value: any) => {
        if (value) {
          return (
            <span>
              {parseServerTimeToLocalTime(
                value,
                timezone,
                'MM/DD/YYYY @ hh:mm A',
              )}{' '}
            </span>
          )
        }
        return (
          <div className='flex justify-end items-center'>
            <span
              style={{ width: '5px', height: '1px' }}
              className='bg-ink inline-block'
            ></span>
          </div>
        )
      },
    },
  ]

  const goDetail = (orderId: number) => {
    history.push(`${url}/order-detail/${orderId}`)
  }

  const renderPicker = (e: any) => {
    e.preventDefault()
    toggleModal()
  }

  const toggleModal = () => {
    if (!dateOpen && !ranges[0]) {
      onChange('ranges', [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'dateRange',
        },
      ])
    }
    setDateOpen(!dateOpen)
  }

  useEffect(() => {
    const label = ranges[0]
      ? moment(ranges[0].startDate).format('MM/DD/YY') +
        ' - ' +
        moment(ranges[0].endDate).format('MM/DD/YY')
      : ''
    onChange('dateLabel', label)
  }, [ranges])

  return (
    <AdminPage>
      <AdminPageTitle title='Order History' />
      <div className='py-8 px-8'>
        <div className='grid grid-cols-4 gap-6 items-end pb-6 border-b border-dashed border-zinc'>
          <Field
            containerClassName='w-full'
            label='Keyword'
            name='keyword'
            value={keyword}
            type='tel'
            placeholder='Order ID, Customer'
            onChange={e => onChange('keyword', e.target.value)}
          />
          <Select
            options={ORDER_HISTORY_STATUS_OPTIONS}
            placeholder='Select'
            value={status}
            label='Status'
            className='w-full'
            onChange={value => onChange('status', value)}
          />
          <Field
            containerClassName='w-full'
            label='Date Range'
            name='date-range'
            type='tel'
            value={dateLabel}
            onClick={e => renderPicker(e)}
            onChange={() => null}
          />
          <Button
            loading={searchLoading}
            className='w-full'
            color='primary'
            onClick={() => {
              onChange('searchLoading', true)
            }}
          >
            SEARCH
          </Button>
        </div>
        <div className='mt-6'>
          <Table
            loading={loading}
            isPageSet={true}
            current={page}
            containerClassName='text-xs text-ink rounded-lg border border-solid border-zinc overflow-hidden'
            columns={columns}
            data={tableData.data}
            total={tableData.total}
            totalText={'results'}
            rightButton={
              <Button
                size='sm'
                color='tertiary'
                onClick={() => setOpenSummary(true)}
              >
                SHOW SUMMARY
              </Button>
            }
            onPageNoChange={value => onChange('page', value)}
            onPageSizeChange={value => {
              onChange('pageSize', value)
            }}
            clickedRow={row => goDetail(row.id)}
          />
        </div>
        <OrderSummary
          open={openSummary}
          toggle={() => setOpenSummary(!openSummary)}
          ranges={ranges}
          status={status}
        />
        <DateRangeModal
          open={dateOpen}
          toggle={toggleModal}
          onChange={e => {
            const { dateRange } = e

            onChange('ranges', [
              {
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                key: 'dateRange',
              },
            ])
          }}
          ranges={ranges}
        />
      </div>
    </AdminPage>
  )
}
