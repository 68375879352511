import React from 'react'

import CouponNoneImg from 'assets/img/coupon-none.png'
import Button from 'components/Button'

type TCouponNoneProp = {
  handleAdd: () => void
}

export default function CouponNone({
  handleAdd,
}: TCouponNoneProp): JSX.Element {
  return (
    <div className='pt-[120px] flex justify-center'>
      <div>
        <div className='text-base text-lead pb-8'>
          You don’t have any coupon yet...
        </div>
        <img src={CouponNoneImg} className='w-[160px] m-auto' />
        <Button className='mt-6 m-auto' onClick={handleAdd}>
          ADD NEW COUPON
        </Button>
      </div>
    </div>
  )
}
