import React from 'react'

import { SELECTRESTAURANT } from 'data/routes'
import RequireAuth from 'pages/auth/RequireAuth'
import NoMatch from 'pages/error/NoMatch'
import SimpleLayout from 'pages/layout/SimpleLayout'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { pageRoutes } from './index'

const childRoutes = (routes: Array<TRouteType>) => {
  return routes.map(
    ({
      component: Component,
      path,
      auth,
      layout: Layout,
      permissionId,
      showBreadCrumbs,
    }) => {
      const container = Layout ? (
        <Layout permissionId={permissionId} showBreadCrumbs={showBreadCrumbs}>
          <Component />
        </Layout>
      ) : (
        <Component />
      )
      const comp = auth ? <RequireAuth>{container}</RequireAuth> : container
      return (
        <Route key={path} path={path}>
          {comp}
        </Route>
      )
    },
  )
}

export default (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        {childRoutes(pageRoutes)}
        <Route exact path='/' children={<Redirect to={SELECTRESTAURANT} />} />
        <Redirect
          exact
          from='/brand/:brandId/location/:locationId'
          to='/brand/:brandId/location/:locationId/order-history'
        />
        <Route path='*'>
          <SimpleLayout>
            <NoMatch />
          </SimpleLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
