import { useState } from 'react'

import Button from 'components/Button'
import DragBar from 'components/DragBar'
import ModalFull from 'components/Modal/ModalFull'
import currency from 'currency.js'
import { SUPPORT_PHONE, SUPPORT_EMAIL } from 'data/constant'

type Treview = {
  discount: number
}
export default function ModalReviewSubscription({ discount }: Treview) {
  const [isVisible, setIsVisible] = useState(false)
  const [value, setValue] = useState(149)

  const convenienceFee = currency(1.49)
    .subtract(currency(value).subtract(49).multiply(0.00496))
    .format()
  return (
    <>
      <Button color='secondary' size='sm' onClick={() => setIsVisible(true)}>
        REVIEW SUBSCRIPTION
      </Button>
      <ModalFull
        open={isVisible}
        title='Review Subscription'
        footer={
          <div className='w-full flex justify-end'>
            <Button color='secondary' onClick={() => setIsVisible(false)}>
              CLOSE
            </Button>
          </div>
        }
        toggle={() => setIsVisible(false)}
      >
        <>
          <div className='w-full p-6 rounded-lg bg-field text-ink'>
            <div className='flex justify-between text-base font-bold'>
              <span>Restaurant pay</span>
              <span>Customer pay</span>
            </div>
            <DragBar
              className='my-3'
              discount={+discount}
              min={+discount > 49 ? +discount : 49}
              max={349}
              value={value}
              onChange={value => {
                setValue(value)
              }}
            />
            <div className='flex justify-between text-sm'>
              <span>
                <span className='line-through'>{currency(value).format()}</span>{' '}
                {currency(value).subtract(discount).format()}/month
              </span>
              <span>{convenienceFee}/order</span>
            </div>
            <div className='text-xs text-lead'>
              {currency(discount).format()} monthly discount applied to your
              account
            </div>
          </div>
          <p className='text-center mt-6 text-ink text-sm'>
            To adjust the subscription scale, please contact us at your earliest
            convenience by calling our support line at{' '}
            <span className='font-bold'>{SUPPORT_PHONE}</span> or email to{' '}
            <span className='font-bold'>{SUPPORT_EMAIL}</span>
          </p>
        </>
      </ModalFull>
    </>
  )
}
