import React, { useState, useEffect } from 'react'

import { LinedTrash, Percentage, Dollar } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import CalendarField from 'components/CalendarField'
import Drawer from 'components/Drawer'
import Field from 'components/Field'
import { Select } from 'components/Select'
import Switch from 'components/Switch'
import moment from 'moment'
import { useParams } from 'react-router'
import { getCouponData } from 'redux/coupon'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import type { TCouponForm } from 'types/coupon'

import {
  useCaseItems,
  usageTypeItems,
  couponTypeItems,
} from '../helpers/constant'
import CouponSkeleton from './CouponSkeleton'

type TCouponDrawerProp = {
  open: boolean
  toggle: () => void
  id: string
  create: (item: TCouponForm) => void
  edit: (item: TCouponForm, id: string) => void
  couponDelete: (id: string) => void
}

export default function CouponDrawer({
  open,
  toggle,
  id,
  create,
  edit,
  couponDelete,
}: TCouponDrawerProp): JSX.Element {
  const dispatch = useAppDispatch()
  const { selectedBrand } = useAppSelector(state => state.brand)
  const { createCouponRequest, editCouponRequest, deleteCouponRequest } =
    useAppSelector(state => state.coupon)
  const locations = selectedBrand?.locations || []
  const { locationId } = useParams<{ locationId: string }>()
  const [formData, setFormData] = useState<TCouponForm>({
    location_ids: [locationId],
    order_types: [],
    first_order_only: 0,
    code: '',
    usage_type: '',
    auto_display: 0,
    start_date: '',
    end_date: '',
    type: '',
    order_minimum: '',
    description: '',
    brand_id: '',
    usage_limit: '',
    amount_off: '',
    percentage_off: '',
  })
  const [loading, setLoading] = useState(false)
  const disabled = !!id
  const onFormDataChange = (type: string, value: string | boolean) => {
    if (
      type === 'pickup' ||
      type === 'delivery' ||
      type === 'dine_in' ||
      type === 'categring'
    ) {
      const { order_types } = formData
      if (order_types.indexOf(type) > -1) {
        const result: string[] = []
        order_types.forEach(oType => {
          if (oType !== type) {
            result.push(oType)
          }
        })
        setFormData({
          ...formData,
          order_types: result,
        })
        return
      } else {
        setFormData({
          ...formData,
          order_types: formData.order_types.concat(type),
        })
        return
      }
    }
    if (type === 'auto_display') {
      setFormData({
        ...formData,
        auto_display: value ? 1 : 0,
      })
      return
    }
    if (type === 'location_ids') {
      if (formData.location_ids.indexOf(value as string) > -1) {
        const result: string[] = []
        formData.location_ids.forEach(id => {
          if (id !== value) {
            result.push(id)
          }
        })
        setFormData({
          ...formData,
          location_ids: result,
        })
        return
      } else {
        setFormData({
          ...formData,
          location_ids: formData.location_ids.concat(value as string),
        })
        return
      }
    }
    setFormData({
      ...formData,
      [type]: value,
    })
  }

  const handleSave = () => {
    if (id) {
      edit(formData, id)
    } else {
      create(formData)
    }
  }

  useEffect(() => {
    if (open && id) {
      const getData = async () => {
        setLoading(true)
        const data = await dispatch(getCouponData(locationId, id))
        setLoading(false)
        if (+data.id === +id) {
          setFormData({
            location_ids: data.locations.map((location: { id: string }) =>
              location.id.toString(),
            ),
            order_types: data.coupon_apply_types.map(
              (type: { order_type: string }) => type.order_type,
            ),
            first_order_only: data.first_order_only,
            code: data.code,
            usage_type: data.usage_type,
            auto_display: data.auto_display,
            start_date: data.start_date,
            end_date: data.end_date,
            type: data.type,
            order_minimum: data.threshold,
            description: data.description,
            brand_id: '',
            usage_limit: data.usage_limit,
            amount_off: data.amount_off,
            percentage_off: data.percentage_off,
          })
        }
      }
      getData()
    } else {
      setFormData({
        location_ids: [locationId],
        order_types: [],
        first_order_only: 0,
        code: '',
        usage_type: '',
        auto_display: 0,
        start_date: '',
        end_date: '',
        type: '',
        order_minimum: '',
        description: '',
        brand_id: '',
        usage_limit: '',
        amount_off: '',
        percentage_off: '',
      })
    }
  }, [open, id])

  return (
    <Drawer
      open={open}
      toggle={toggle}
      title={`${id ? 'Edit' : 'Create'} Coupon`}
      leftFooter={
        <Button
          color='secondary-link'
          loading={deleteCouponRequest}
          onClick={() => {
            if (id) {
              couponDelete(id)
            } else {
              toggle()
            }
          }}
        >
          {!deleteCouponRequest && (
            <LinedTrash className='text-silver pr-1' size={16} />
          )}
          DELETE
        </Button>
      }
      rightFooter={
        <>
          <Button color='secondary' onClick={toggle}>
            CANCEL
          </Button>
          <Button
            className='w-[119px]'
            color='primary'
            onClick={handleSave}
            loading={createCouponRequest || editCouponRequest}
          >
            SAVE
          </Button>
        </>
      }
    >
      {loading ? (
        <>
          <CouponSkeleton />
          <CouponSkeleton />
          <CouponSkeleton />
        </>
      ) : (
        <>
          <Select
            options={useCaseItems}
            placeholder='Select'
            value={formData.first_order_only}
            onChange={value => onFormDataChange('first_order_only', value)}
            label='Use case'
            disabled={disabled}
          />
          <div className='mt-6 flex justify-between'>
            <div>
              <Field
                className='w-60'
                label='Coupon code'
                name='Coupon code'
                type='text'
                placeholder='Input Text'
                value={formData.code}
                maxLength={12}
                onChange={e => {
                  onFormDataChange('code', e.target.value)
                }}
                disabled={disabled}
              />
              {/* <div className='text-xs text-red'>
                Max. 12 digits (letters & numbers)
              </div> */}
            </div>
            <div>
              <div className='flex justify-between'>
                <Select
                  className={cn(
                    formData.usage_type === 'limited'
                      ? 'w-[144px] mr-2'
                      : 'w-60',
                  )}
                  options={usageTypeItems}
                  placeholder='Select'
                  value={formData.usage_type}
                  onChange={value => onFormDataChange('usage_type', value)}
                  label='Usage type'
                />
                {formData.usage_type === 'limited' && (
                  <Field
                    className='w-[80px]'
                    label='Limit'
                    name='Limit'
                    type='number'
                    value={formData.usage_limit}
                    onChange={e =>
                      onFormDataChange('usage_limit', e.target.value)
                    }
                  />
                )}
              </div>
              {formData.usage_type === 'limited' && (
                <div className='mt-2 text-xs text-silver'>
                  Total count this coupon can be redeemed
                </div>
              )}
            </div>
          </div>
          <div className='mt-6 flex justify-between'>
            <Select
              className='w-60'
              options={couponTypeItems}
              placeholder='Select Option'
              value={formData.type}
              onChange={value => onFormDataChange('type', value)}
              label='Coupon type'
              disabled={disabled}
            />
            <div className='flex'>
              {formData.type === 'flat' && (
                <Field
                  containerClassName='w-[112px] mr-3'
                  label='Amount'
                  name='Amount'
                  align='right'
                  type='text'
                  inputMode='money'
                  suffix={<Dollar className='text-silver' />}
                  placeholder='0.00'
                  value={formData.amount_off}
                  onChangeMoney={e => onFormDataChange('amount_off', e)}
                  disabled={disabled}
                />
              )}
              {formData.type === 'percentage' && (
                <Field
                  containerClassName='w-[112px] mr-3'
                  label='Percentage'
                  name='Percentage'
                  align='right'
                  type='text'
                  inputMode='money'
                  append={<Percentage className='text-silver' />}
                  placeholder='0.00'
                  value={formData.percentage_off}
                  onChangeMoney={e => onFormDataChange('percentage_off', e)}
                  disabled={disabled}
                />
              )}
              <Field
                containerClassName={cn(
                  formData.type === 'flat' || formData.type === 'percentage'
                    ? 'w-[112px]'
                    : 'w-60',
                )}
                label='Order minimum'
                name='Order minimum'
                align='right'
                type='text'
                inputMode='money'
                suffix={<Dollar className='text-silver' />}
                placeholder='0.00'
                value={formData.order_minimum}
                onChangeMoney={e => onFormDataChange('order_minimum', e)}
                disabled={disabled}
              />
            </div>
          </div>
          <div className='mt-6'>
            <div className='text-silver text-xs font-bold mb-2'>
              Apply to order type(s)
            </div>
            <div className='h-10 border border-zinc rounded-lg flex items-center px-4'>
              <Switch
                className='pr-4'
                checked={formData.order_types.indexOf('pickup') > -1}
                label='Pickup'
                name='Pickup'
                onChange={e => onFormDataChange('pickup', e)}
                position='left'
              />
              <Switch
                className='pr-4'
                checked={formData.order_types.indexOf('delivery') > -1}
                label='Delivery'
                name='Delivery'
                onChange={e => onFormDataChange('delivery', e)}
                position='left'
              />
              <Switch
                className='pr-4'
                checked={formData.order_types.indexOf('dine_in') > -1}
                label='Dine-In'
                name='Dine-In'
                onChange={e => onFormDataChange('dine_in', e)}
                position='left'
              />
              <Switch
                checked={formData.order_types.indexOf('catering') > -1}
                label='Catering(coming soon)'
                name='Catering'
                disabled
                onChange={e => onFormDataChange('catering', e)}
                position='left'
              />
            </div>
          </div>
          <div className='mt-6 flex justify-between'>
            <div className='flex-1 mr-6'>
              <Field
                label={
                  <>
                    <span>Description</span>
                    <span className='text-silver font-normal'>{` (${formData.description.length}/25 characters)`}</span>
                  </>
                }
                name='Description'
                type='text'
                placeholder='Description gose here'
                value={formData.description}
                onChange={e => {
                  if (e.target.value.length <= 25) {
                    onFormDataChange('description', e.target.value)
                  }
                }}
              />
              {formData.type === 'free_item' && (
                <div className='mt-2 text-xs text-silver'>
                  This will be label of the free item
                </div>
              )}
            </div>
            <div>
              <div className='text-silver text-xs font-bold'>Auto display</div>
              <div>
                <Switch
                  className='pt-5'
                  checked={!!formData.auto_display}
                  label={formData.auto_display ? 'ON' : 'OFF'}
                  name='Auto display'
                  onChange={e => onFormDataChange('auto_display', e)}
                  position='left'
                />
              </div>
            </div>
          </div>
          <div className='mt-6 flex justify-between'>
            <CalendarField
              label='Start date'
              className='w-60'
              calendarClassName={'bottom-10'}
              onChange={time => {
                const nowTime = moment(time)
                if (
                  nowTime.isBefore(moment(formData.end_date)) ||
                  formData.start_date.length === 0
                ) {
                  onFormDataChange(
                    'start_date',
                    nowTime.format('YYYY-MM-DD HH:mm:ss'),
                  )
                }
              }}
              date={
                formData.start_date ? new Date(formData.start_date) : undefined
              }
              value={
                formData.start_date
                  ? moment(formData.start_date, 'YYYY-MM-DD HH:mm:ss').format(
                      'YYYY-MM-DD',
                    )
                  : ''
              }
              disabled={disabled}
            />
            <CalendarField
              label='End date'
              className='w-60'
              calendarClassName={'right-0 bottom-10'}
              onChange={time => {
                const nowTime = moment(time)
                if (
                  nowTime.isAfter(moment(formData.start_date)) ||
                  formData.start_date.length === 0
                ) {
                  onFormDataChange(
                    'end_date',
                    nowTime.format('YYYY-MM-DD') + ' 23:59:59',
                  )
                }
              }}
              date={formData.end_date ? new Date(formData.end_date) : undefined}
              value={
                formData.end_date
                  ? moment(formData.end_date, 'YYYY-MM-DD HH:mm:ss').format(
                      'YYYY-MM-DD',
                    )
                  : ''
              }
              disabled={disabled}
            />
          </div>
          <div className='mt-6'>
            <div className='text-silver text-xs font-bold pb-2'>
              Participation
            </div>
            <div className='grid gap-[10px] grid-cols-2'>
              {locations.map(location => {
                const { name, address, access, id } = location
                return (
                  <div
                    key={id}
                    className={cn(
                      'w-[240px] option-box-class',
                      formData.location_ids.indexOf(id.toString()) > -1
                        ? 'selected'
                        : '',
                      { disabled: !access },
                    )}
                    onClick={() =>
                      onFormDataChange('location_ids', location.id.toString())
                    }
                  >
                    <div>
                      <div
                        className={cn(
                          'text-base  font-bold pb-1 flex justify-between',
                        )}
                      >
                        <span>{name}</span>
                        {!access && (
                          <div className='rounded bg-field text-lead text-xs px-2 py-0.5'>
                            No Access
                          </div>
                        )}
                      </div>
                      {address && (
                        <div className={cn('text-xs')}>
                          {`${address.line_1 ? address.line_1 + ' ' : ''}${
                            address.line_2 ? address.line_2 + ' ' : ''
                          }${address.city ? address.city + ' ' : ''}${
                            address.state ? ',' + address.state + ' ' : ''
                          }${address.zipcode ? address.zipcode : ''}`}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </Drawer>
  )
}
