export const useCaseItems = [
  {
    value: 0,
    label: 'Regular coupon',
  },
  {
    value: 1,
    label: 'New customer only',
  },
]

export const usageTypeItems = [
  {
    value: 'unlimited',
    label: 'Unlimited',
  },
  {
    value: 'limited',
    label: 'Limited',
  },
]

export const couponTypeItems = [
  { value: 'flat', label: 'Flat amount' },
  { value: 'percentage', label: 'Percentage rate' },
  { value: 'free_item', label: 'Free item' },
]
