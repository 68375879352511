import AutomatedCampaignPage from 'pages/outboundCampaign/AutomatedCampaignPage'
import OneOffCampaignPage from 'pages/outboundCampaign/OneOffCampaignPage'

export const AllStatusOptions = [
  { value: '', label: 'All Status' },
  { value: 'draft', label: 'Draft' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'processing', label: 'Processing' },
  { value: 'paused', label: 'Paused' },
  { value: 'complete', label: 'Sent' },
]

export const offerItems = [
  { value: '', label: 'No' },
  {
    value: 'lucky_spin',
    label: 'Play a “Lucky Spin” game to win an offer (100% winning ratio)',
  },
  {
    value: 'coupon_code',
    label: 'Display a coupon code',
  },
]

export const dealOfferOptions = [
  { value: 'flat', label: 'Flat amount off' },
  { value: 'free_item', label: 'Free item' },
  { value: 'percentage', label: 'Percentage rate' },
]

export const audienceTypes = [
  { value: 'all', label: 'Target all my subscribed customers' },
  { value: 'conditional', label: 'Target customers with condition' },
]

export const conditionalTypes = [
  { value: 'lifetime_order_count', label: 'Lifetime order count' },
  { value: 'recent_order_within', label: 'Recent order date' },
]

export const dateTypes = [
  { value: 'day', label: 'Days' },
  { value: 'week', label: 'Weeks' },
  { value: 'year', label: 'Years' },
]

const ONEOFFCAMPAIGN: TRouteType = {
  path: 'one-off-campaign',
  name: 'ONEOFFCAMPAIGN',
  label: 'One-Off Campaign',
  component: OneOffCampaignPage,
}
const AUTOMATEDCAMPAIGN: TRouteType = {
  path: 'automated-campaign',
  name: 'AUTOMATEDCAMPAIGN',
  label: 'Automated Campaign',
  component: AutomatedCampaignPage,
}

export const outboundCampaignRoutes = [ONEOFFCAMPAIGN, AUTOMATEDCAMPAIGN]
