import React, { useState, useEffect } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import { Spin } from 'components/Loading'
import useDebounce from 'hooks/useDebounce'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  getMarketingFlyer,
  editMarketingFlyer,
  uploadFlyerImg,
  deleteFlyerImg,
} from 'redux/marketingFlyer'
import { getCouponTitle } from 'utils/coupon'

import CouponSelectDrawer from './components/CouponSelectDrawer'
import MarketingFlyerForm from './components/MarketingFlyerForm'
import MarketingFlyerShow from './components/MarketingFlyerShow'
import type { TFlyerColorOptions } from './helpers/constant'
import { flyerColorOptions, FLYER_OPTIONS } from './helpers/constant'

export type TFormData = {
  id: string
  color: TFlyerColorOptions
  title: string
  template: string
  description: string
  pOffer: number
  pTitle: string
  pCode: string
  couponDescription: string
}

export default function MarketingFlyer(): JSX.Element {
  const { locationId } = useParams<{ locationId: string }>()
  const dispatch = useAppDispatch()
  const { selectedBrand } = useAppSelector(state => state.brand)
  const [formData, setFormData] = useState<TFormData>({
    id: '',
    color: flyerColorOptions[0],
    title: 'Pay Less Next Time!',
    template: FLYER_OPTIONS[0].value,
    description: FLYER_OPTIONS[0].text,
    pOffer: 0,
    pTitle: 'Get a free sandwich when order $20.00 above',
    pCode: '',
    couponDescription: '',
  })
  const [storeHostname, setStoreHostname] = useState('rushable.io')
  const [imgUrl, setImgUrl] = useState('')
  const [storeLogo, setStoreLogo] = useState('')
  const [storeName, setStoreName] = useState('rushable.io')
  const [open, setOpen] = useState<boolean>(false)
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [couponId, setCouponId] = useState<number>(0)
  const [pageLading, setPageLoading] = useState(false)
  const [storeBanner, setStoreBanner] = useState<string | null>(null)

  useEffect(() => {
    setStoreBanner(selectedBrand?.banner_url || null)
  }, [selectedBrand])

  const handleEdit = useDebounce(({ id, params }) => {
    dispatch(
      editMarketingFlyer({
        locationId,
        id,
        params,
      }),
    )
  })
  const handleChange = (
    type: string,
    value: TFlyerColorOptions | string | number,
  ) => {
    if (type === 'color') {
      setFormData({
        ...formData,
        color: value as TFlyerColorOptions,
      })
      handleEdit({
        id: formData.id,
        params: {
          accent_color: (value as TFlyerColorOptions).value,
        },
      })
    } else if (type === 'title') {
      setFormData({
        ...formData,
        title: value as string,
      })
      if ((value as string)?.length <= 25) {
        handleEdit({
          id: formData.id,
          params: {
            flyer_title: value,
          },
        })
      }
    } else if (type === 'template') {
      const text = FLYER_OPTIONS.filter(option => option.value === value)[0]
        .text
      setFormData({
        ...formData,
        description: text,
        template: value as string,
      })
      handleEdit({
        id: formData.id,
        params: {
          flyer_description: text,
          preset_template: value,
        },
      })
    } else if (type === 'description') {
      setFormData({
        ...formData,
        template: 'custom',
        description: value as string,
      })
      if ((value as string)?.length <= 300) {
        handleEdit({
          id: formData.id,
          params: {
            preset_template: 'custom',
            flyer_description: value,
          },
        })
      }
    } else if (type === 'pOffer') {
      setFormData({
        ...formData,
        pOffer: value as number,
      })
      handleEdit({
        id: formData.id,
        params: {
          promo_offer: value,
        },
      })
    } else if (type === 'couponDescription') {
      setFormData({
        ...formData,
        couponDescription: value as string,
      })
      if ((value as string)?.length <= 55) {
        handleEdit({
          id: formData.id,
          params: {
            coupon_description: value,
          },
        })
      }
    }
  }
  const getData = async () => {
    const data = await dispatch(getMarketingFlyer({ location_id: locationId }))
    setUploadLoading(false)
    setPageLoading(false)
    if (data.type === 'flyer/getMarketingFlyer/fulfilled') {
      setFormData({
        id: data.payload.id,
        color: data.payload.accent_color
          ? flyerColorOptions.filter(
              option => option.value === data.payload.accent_color,
            )[0]
          : flyerColorOptions[0],
        title: data.payload.flyer_title,
        template: data.payload.preset_template,
        description: data.payload.flyer_description || FLYER_OPTIONS[0].text,
        pOffer: data.payload.promo_offer,
        pTitle: getCouponTitle(data.payload.location_coupon?.coupon).title,
        pCode: data.payload.location_coupon?.coupon?.code || '',
        couponDescription: data.payload.coupon_description,
      })
      setImgUrl(data.payload.image)
      setStoreHostname(data.payload.location.brand.domains[0].hostname)
      setStoreLogo(data.payload.location.brand.logo_url)
      setStoreName(data.payload.location.brand.name)
      setCouponId(data.payload.location_coupon_id)
    }
  }
  const handleUpload = async (aBlob: Blob) => {
    setUploadLoading(true)
    try {
      const res = await dispatch(uploadFlyerImg(aBlob, formData.id, locationId))
      if (res.message === 'Uploaded.') {
        getData()
      } else {
        setUploadLoading(false)
      }
    } catch (error: any) {
      setUploadLoading(false)
      if (error.message) {
        toast.warn(error.message)
      }
    }
  }
  const handleDelete = async () => {
    setUploadLoading(true)
    try {
      const res = await dispatch(deleteFlyerImg(formData.id, locationId))
      if (res.message === 'Image destroyed.') {
        getData()
      } else {
        setUploadLoading(false)
      }
    } catch (e) {
      setUploadLoading(false)
    }
  }
  useEffect(() => {
    setPageLoading(true)
    getData()
  }, [])
  return (
    <AdminPage>
      <AdminPageTitle title='Marketing Flyer' />
      <Spin spining={pageLading} className='z-50'>
        <>
          <div className='flex justify-center'>
            <MarketingFlyerForm
              formData={formData}
              uploadLoading={uploadLoading}
              handleChange={handleChange}
              openDrawer={() => setOpen(true)}
              imgUrl={imgUrl}
              handleUpload={handleUpload}
              handleDelete={handleDelete}
            />
            <MarketingFlyerShow
              formData={formData}
              storeHostname={storeHostname}
              imgUrl={imgUrl}
              storeLogo={storeLogo}
              storeName={storeName}
              storeBanner={storeBanner}
            />
          </div>
          <CouponSelectDrawer
            open={open}
            close={() => setOpen(false)}
            couponId={couponId}
            flyerId={formData.id}
            getData={getData}
          />
        </>
      </Spin>
    </AdminPage>
  )
}
