import React from 'react'

import {
  LinedEmail,
  LinedChatSms,
  LinedGift,
  AngleRight,
} from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import CircleProcess from 'components/CircleProcess'
import moment from 'moment'
import type { TCampaignItem } from 'types/campaign'

import { AllStatusOptions } from '../helpers/constant'

type TOneOffItemProp = {
  item: TCampaignItem
  noBorder?: boolean
  goDetail: (item: TCampaignItem) => void
}

export default function OneOffItem({
  item,
  noBorder,
  goDetail,
}: TOneOffItemProp): JSX.Element {
  const {
    status,
    name,
    via_email,
    via_sms,
    enable_cta,
    campaign_performance,
    trigger_of_date_time,
  } = item
  let salesText = '-'
  let reachText = '-'
  let clickText = '-'
  let emailOpenedText = '-'
  if (campaign_performance) {
    const {
      sales_volume,
      progress,
      actual_audience,
      cta_click,
      email_opened,
      actual_email_audience,
    } = campaign_performance
    if (!(status === 'draft' || status === 'scheduled')) {
      salesText = `$${sales_volume ? sales_volume : 0}`
      // tokenText = `${actual_sms_token + actual_email_token}`
      emailOpenedText = `${
        isNaN(email_opened / actual_email_audience)
          ? 0
          : ((email_opened / actual_email_audience) * 100).toFixed(2)
      }%`
      reachText = `${progress}`
      clickText = `${
        isNaN(cta_click / actual_audience)
          ? 0
          : ((cta_click / actual_audience) * 100).toFixed(0)
      }%`
    }
  }
  const progress =
    ((campaign_performance?.progress || 0) /
      (campaign_performance?.actual_audience || 0)) *
    100
  const percentText =
    status === 'draft' || status === 'scheduled'
      ? '-'
      : `${isNaN(progress) ? 0 : progress.toFixed(0)}%`
  const statusText = AllStatusOptions.find(v => v.value === status)?.label || ''

  let strokeColor = '#00A5EB'
  if (status === 'paused') {
    strokeColor = '#EBB700'
  }
  if (status === 'complete') {
    strokeColor = '#00CA9F'
  }

  return (
    <div
      className={cn(
        'pt-6 pb-2 flex items-center',
        noBorder ? '' : 'border-b border-zinc border-dashed',
      )}
    >
      <div className='w-[152px] h-[50px] flex bg-field rounded-3xl  mr-8'>
        <CircleProcess
          percent={progress || 0}
          size={50}
          strokeWidth={8}
          strokeColor={strokeColor}
          trailColor={'#E1E5E9'}
          text={percentText}
          textClassName='text-xxs-c text-lead'
        />
        <div className='ml-2 pt-2'>
          <div className='text-xs text-ink font-bold pb-1'>{statusText}</div>
          <div className='text-xs text-lead'>
            {status === 'draft'
              ? '-'
              : moment(trigger_of_date_time).format('MMM DD, YYYY')}
          </div>
        </div>
      </div>
      <div className='w-[240px]'>
        <div className='text-ink text-base font-bold pb-2'>{name}</div>
        <div className='flex'>
          <LinedEmail
            className={cn('mr-2', via_email ? 'text-ink' : 'text-dark-16')}
            size={16}
          />
          <LinedChatSms
            className={cn('mr-2', via_sms ? 'text-ink' : 'text-dark-16')}
            size={16}
          />
          <LinedGift
            className={cn(enable_cta ? 'text-ink' : 'text-dark-16')}
            size={16}
          />
        </div>
      </div>
      <div className='flex'>
        <div className='w-[72px] mr-2 text-right'>
          <div className='text-base font-bold text-ink pb-2'>{reachText}</div>
          <div className='text-base text-silver'>Reach</div>
        </div>
        <div className='w-[72px] mr-2 text-right'>
          <div className='text-base font-bold text-ink pb-2'>
            {emailOpenedText}
          </div>
          <div className='text-base text-silver'>Open</div>
        </div>
        <div className='w-[72px] mr-2 text-right'>
          <div className='text-base font-bold text-ink pb-2'>{clickText}</div>
          <div className='text-base text-silver'>Click</div>
        </div>
        <div className='w-[72px] mr-2 text-right'>
          <div className='text-base font-bold text-ink pb-2'>{salesText}</div>
          <div className='text-base text-silver'>Sales</div>
        </div>
      </div>
      <div className='flex-1 flex justify-end items-center text-silver text-base font-bold cursor-pointer'>
        <Button color='secondary-link' onClick={() => goDetail(item)}>
          {status === 'draft' ? 'Edit' : 'DETAILS'}
          <AngleRight className='text-silver ml-1' size={16} />
        </Button>
      </div>
    </div>
  )
}
