import React, { useState } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ArrowRight, SolidImage, Dollar } from '@rushable/icons'
import CheckBox from 'components/CheckBox'
import Field from 'components/Field'
import ThumbnailsImage from 'components/ThumbnailsImage'
import moment from 'moment'
import parseMediaAbsoluteURL from 'utils/parseMediaAbsoluteURL'

import MenuAction from './MenuAction'

type TMenuListItemProp = {
  item: any
  i: number
  j: number
  k: number
  menuActionId: number
  bulkAction: any
  saveBulkEdit: boolean
  setBulkEditChecked: (
    value: boolean,
    { i, j, k }: { i: number; j: number; k: number },
  ) => void
  setMenuActionId: (id: number) => void
  handleChangeMenu: (
    value: string,
    { id, name }: { id: number; name: string },
    type: string,
  ) => void
  handleFormChange: (name: string, value: string, { i, j, k, m }: any) => void
}

export default function MenuListItem({
  item,
  i,
  j,
  k,
  menuActionId,
  bulkAction,
  saveBulkEdit,
  setBulkEditChecked,
  setMenuActionId,
  handleChangeMenu,
  handleFormChange,
}: TMenuListItemProp): JSX.Element {
  const { id } = item
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id,
  })
  let styles
  if (transform) {
    const { x, y }: any = transform
    styles = {
      transform: CSS.Transform.toString({ x, y, scaleX: 1, scaleY: 1 }),
      transition,
    }
  }

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      style={styles}
      className='flex items-start py-2 leading-snug'
    >
      <div className='flex items-center'>
        {!bulkAction && !saveBulkEdit ? (
          <MenuAction
            className='mr-4'
            size={16}
            hasDetail={true}
            open={menuActionId === item.id}
            onChange={v => handleChangeMenu(v, item, 'MenuItem')}
            clickOutside={() => {
              setMenuActionId(0)
            }}
          />
        ) : (
          <CheckBox
            size={20}
            className='mr-2'
            checked={item.checked}
            onChange={value => setBulkEditChecked(value, { i, j, k })}
          />
        )}

        {item.image_url ? (
          <div>
            <ThumbnailsImage
              className='w-14 h-12 rounded'
              src={parseMediaAbsoluteURL(item.image_url, undefined, true)}
              fallbackSrc={parseMediaAbsoluteURL(item.image_url)}
              alt=''
            />
          </div>
        ) : (
          <div className='w-14 h-12 bg-field rounded flex justify-center items-center'>
            <SolidImage size={16} className='text-silver' />
          </div>
        )}
        <div
          className={`mx-4 flex flex-1 flex-col justify-center ${
            item.status && item.status !== 'active' && 'mt-[7px]'
          }`}
        >
          <div className='font-medium text-ink'>{item.name}</div>
          <div className='flex items-center text-xxs-l mt-[-3px]'>
            {item.status && item.status !== 'active' && (
              <span
                className={`${
                  item.status === 'inactive'
                    ? 'bg-red-shaded/[.05] text-red-shaded'
                    : 'bg-yellow-shaded/[.05] text-yellow-shaded'
                }  flex items-center px-1 py-1  font-500 rounded-sm`}
              >
                {item.status === 'inactive'
                  ? 'Unavailable'
                  : `Restock on ${moment(item.reactive_at).format('ll')}`}
              </span>
            )}
            {item.new_status && (
              <>
                {!(
                  item.status === 'active' && item.new_status === 'active'
                ) && (
                  <ArrowRight
                    className={'ml-2 mr-2 text-green scale-[2]'}
                    size={14}
                  />
                )}

                {item.new_status !== 'active' && (
                  <span
                    className={`${
                      item.new_status === 'inactive'
                        ? 'bg-red-shaded/[.05] text-red-shaded'
                        : 'bg-yellow-shaded/[.05] text-yellow-shaded'
                    } flex items-center px-1 py-1  font-500 rounded-sm`}
                  >
                    {item.new_status === 'inactive'
                      ? 'Unavailable'
                      : item.new_status}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className='grid gap-2 ml-auto'>
        {item.menu_modifiers[0]?.private_type === 'pricing' ? (
          item.menu_modifiers[0]?.menu_modifier_options.map(
            (priceItem: any, m: number) => (
              <div className='flex items-center justify-end' key={m}>
                <div className='text-lead'>{priceItem.name}</div>
                <Field
                  name='price'
                  type='tel'
                  inputMode='money'
                  suffix={<Dollar className='text-silver' size={16} />}
                  align='right'
                  disabled={saveBulkEdit}
                  containerClassName={`w-[106px] ml-${
                    priceItem?.new_unit_price ? '2' : '4'
                  }`}
                  value={priceItem.unit_price}
                  onChangeMoney={value =>
                    handleFormChange('unit_price', value, { i, j, k, m })
                  }
                />
                {priceItem?.new_unit_price && (
                  <>
                    <ArrowRight className='mx-4 text-green' size={24} />
                    <Field
                      name='price'
                      type='tel'
                      inputMode='money'
                      suffix={<Dollar className='text-silver' size={16} />}
                      align='right'
                      containerClassName='w-[106px]'
                      value={priceItem.new_unit_price}
                      onChangeMoney={value =>
                        handleFormChange('new_unit_price', value, {
                          i,
                          j,
                          k,
                          m,
                        })
                      }
                    />
                  </>
                )}
              </div>
            ),
          )
        ) : (
          <div className='flex items-center justify-end'>
            <Field
              name='price'
              type='tel'
              inputMode='money'
              suffix={<Dollar className='text-silver' size={16} />}
              align='right'
              containerClassName='w-[106px]'
              disabled={saveBulkEdit}
              value={item.unit_price}
              onChangeMoney={value =>
                handleFormChange('unit_price', value, { i, j, k })
              }
            />
            {item?.new_unit_price && (
              <>
                <ArrowRight className='mx-4 text-green' size={24} />
                <Field
                  name='price'
                  type='tel'
                  inputMode='money'
                  suffix={<Dollar className='text-silver' size={16} />}
                  align='right'
                  containerClassName='w-[106px]'
                  value={item.new_unit_price}
                  onChangeMoney={value =>
                    handleFormChange('new_unit_price', value, { i, j, k })
                  }
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
