import React from 'react'

import { Percentage, Dollar, SolidPhone, SolidEmail } from '@rushable/icons'
import Field from 'components/Field'
import { Select } from 'components/Select'
import Switch from 'components/Switch'
import { TOnlineOrderConfig } from 'types/onlineOrderConfig'

import { ALLOW_CUSTOMER_NOTE_OPTIONS } from '../helpers/constant'
import CustomFee from './CustomFee'
import EstimateOrderTime from './EstimateOrderTime'
import FraudSettingItem from './FraudSettingItem'

export type TGeneralSettingFormProp = {
  formData: TOnlineOrderConfig
  onFormChange: (name: string, value: any) => void
}

export default function GeneralSettingForm({
  formData,
  onFormChange,
}: TGeneralSettingFormProp): JSX.Element {
  const {
    sales_tax,
    custom_fee_label,
    allow_later_order,
    allow_customer_note,
    custom_fee_money,
    custom_fee_percent,
    fraud_check_threshold,
    estimate_order_time,
    phone_notification,
    email_notification,
    check_id,
    check_card,
    check_fee,
  } = formData

  return (
    <div className='py-8'>
      <div className='flex justify-between gap-6'>
        <Field
          label='Sales tax rate'
          value={sales_tax || ''}
          onChange={e => onFormChange('sales_tax', e.target.value)}
          name='sales_tax'
          type='tel'
          containerClassName='mb-6 w-full'
          append={<Percentage size={16} className='text-silver' />}
        />
        <Select
          options={ALLOW_CUSTOMER_NOTE_OPTIONS}
          placeholder='Select'
          value={allow_later_order}
          className='mb-6 w-full'
          label='Order for later'
          onChange={value => onFormChange('allow_later_order', value)}
        />
        <Select
          options={ALLOW_CUSTOMER_NOTE_OPTIONS}
          placeholder='Select'
          value={allow_customer_note}
          className='mb-6 w-full'
          label='Custom item note'
          onChange={value => onFormChange('allow_customer_note', value)}
        />
      </div>
      <div className='pb-6'>
        <p className='text-xs font-bold text-silver'>Estimate order time</p>
        <div className='flex ring-1 ring-zinc p-4 rounded-lg mt-2'>
          <EstimateOrderTime value={Number(estimate_order_time)}>
            <Field
              className='mx-2 w-16'
              name='estimate_order_time'
              maxLength={3}
              value={estimate_order_time || ''}
              type='tel'
              align='right'
              onChange={e => {
                const timeNumber = Number(e.target.value)
                if (timeNumber <= 120 && timeNumber >= 0) {
                  onFormChange('estimate_order_time', e.target.value)
                }
              }}
            />
          </EstimateOrderTime>
        </div>
      </div>
      <div className='pb-6'>
        <p className='text-xs font-bold text-silver'>New order email</p>
        <div
          className={`flex flex-col justify-center ring-1 ring-zinc px-4 py-3 rounded-lg mt-2 ${
            !email_notification && 'bg-field'
          }`}
        >
          <div
            className={'flex flex-1  items-center text-dark-100 font-normal'}
          >
            <SolidEmail size={16} className='mr-2 text-silver' />
            Receive a notification email at the restaurant email address for all
            new orders
            <Switch
              className='ml-auto'
              name='check_fee'
              checked={email_notification || false}
              onChange={v => onFormChange('email_notification', v)}
            />
          </div>
        </div>
      </div>
      <div className='pb-6'>
        <p className='text-xs font-bold text-silver'>Order reminder call</p>
        <div
          className={`flex flex-col justify-center ring-1 ring-zinc px-4 py-3 rounded-lg mt-2 ${
            !phone_notification && 'bg-field'
          }`}
        >
          <div
            className={'flex flex-1  items-center text-dark-100 font-normal'}
          >
            <SolidPhone size={16} className='mr-2 text-silver' />
            Receive a reminder call to restaurant number when a new order is not
            confirmed for 5-10 minutes
            <Switch
              className='ml-auto'
              name='check_fee'
              checked={phone_notification || false}
              onChange={v => onFormChange('phone_notification', v)}
            />
          </div>
        </div>
      </div>
      <div>
        <p className='text-xs font-bold text-silver'>Custom fee</p>
        <div
          className={`flex flex-col justify-center ring-1 ring-zinc px-4 rounded-lg mt-2 ${
            !check_fee ? 'bg-field py-3' : 'py-4'
          }`}
        >
          <CustomFee
            custom_fee_money={custom_fee_money || ''}
            custom_fee_percent={custom_fee_percent || ''}
            custom_fee_label={custom_fee_label || ''}
            check_fee={check_fee}
            onFormChange={onFormChange}
          ></CustomFee>
        </div>
      </div>
      <div className='pt-6'>
        <p className='text-xs font-bold text-silver'>Fraud prevention</p>
        <div
          className='flex  px-4 ring-1 ring-zinc py-4
         rounded-lg divide-x divide-dashed divide-zinc mt-2'
        >
          <FraudSettingItem
            className='justify-start'
            containerClassName='pr-8'
            label='Show the card used'
            description='At the checkout, customers are required to agree the term of presenting the card used upon receiving their order.'
          >
            <Switch
              name='check_card'
              checked={check_card || false}
              onChange={v => onFormChange('check_card', v)}
            />
          </FraudSettingItem>
          <FraudSettingItem
            className='justify-center'
            containerClassName='px-8'
            label='Present the matching ID'
            description='At the checkout, customers are required to agree the term of presenting the matching ID upon receiving their order.'
          >
            <Switch
              name='check_card'
              checked={check_id || false}
              onChange={v => onFormChange('check_id', v)}
            />
          </FraudSettingItem>
          <FraudSettingItem
            className='justify-end'
            containerClassName='pl-8'
            label='Amount trigger'
            description='This is is the triggering amount of the order total for the system to show the term at the checkout.'
          >
            <div className='w-28'>
              <Field
                name='fraud_check_threshold'
                value={fraud_check_threshold || ''}
                type='text'
                placeholder='0.00'
                align='right'
                inputMode='money'
                suffix={<Dollar size={16} className='text-silver' />}
                onChangeMoney={value =>
                  onFormChange('fraud_check_threshold', value)
                }
              />
            </div>
          </FraudSettingItem>
        </div>
      </div>
    </div>
  )
}
