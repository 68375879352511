import { useState } from 'react'

import Button from 'components/Button'
import Field from 'components/Field'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updatePrefixApi, validateDomainAuthApi } from 'redux/business'

import { getRoutePath } from '../helps'
import GetHelp from './GetHelp'

interface Iprops {
  emailPrefix: string
  emailDomain: string
  emailStatus: string
  id: string
  onStep: (step: number, domainList?: any[]) => void
}
export default function Step1Email(props: Iprops): JSX.Element {
  const history = useHistory()
  const { locationId = '' } = useParams<TParamTypes>()
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const updateEmailPrefix = async () => {
    setLoading(true)
    try {
      const res = await updatePrefixApi(
        { email: email ?? props.emailPrefix },
        props.id,
        locationId,
      )
      toast.success(res.message || 'update successful')
      if (!props.emailPrefix && props.emailStatus !== 'none') {
        const path = getRoutePath()
        history.replace(path, { refresh: true })
      } else {
        props.onStep(1, res)
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  return (
    <div className='mx-[120px]'>
      <GetHelp type='email' />
      <div className='flex justify-between items-center w-full h-[72px] mt-6 px-6 border border-zinc rounded-lg'>
        <div>Input your desired prefix (e.g. marketing)</div>
        <div className='flex items-center'>
          <Field
            name='email-prefix'
            type='email'
            className='text-right'
            value={email ?? props.emailPrefix}
            placeholder='marketing'
            onChange={e => setEmail(e.target.value)}
            containerClassName='mt-0 w-[147px]'
          />
          <span className='ml-2'>@{props.emailDomain}</span>
        </div>
      </div>
      <Button
        color='primary'
        loading={loading}
        className='w-[354px] mx-auto mt-10'
        onClick={updateEmailPrefix}
      >
        {!props.emailPrefix && props.emailStatus !== 'none'
          ? 'CONFIRM'
          : 'NEXT STEP'}
      </Button>
    </div>
  )
}
