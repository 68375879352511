import { useImperativeHandle, forwardRef } from 'react'

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import './index.scss'

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      border: '1px solid #32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

interface Iprops {
  onChange?: (e: any) => void
}
function StripForm({ onChange }: Iprops, ref: any) {
  const stripe = useStripe()
  const elements = useElements()

  useImperativeHandle(ref, () => ({
    getPaymentId: getPaymentId,
    getToken: getToken,
  }))

  const getToken = () => {
    const cardElement: any = elements?.getElement('card')
    return new Promise((resolve, reject) => {
      stripe
        ?.createToken(cardElement)
        .then((res: any) => {
          // Handle result.error or result.token
          if (res.error) {
            reject(res)
          } else {
            resolve(res)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  const getPaymentId = () => {
    const cardElement: any = elements?.getElement('card')
    return new Promise((resolve, reject) => {
      stripe
        ?.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then((res: any) => {
          if (res.error) {
            reject(res)
          } else {
            resolve(res)
          }
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  const handleChange = async (event: any) => {
    onChange?.(event)
  }

  return (
    <div>
      <CardElement
        id='card-element'
        options={cardStyle}
        onChange={handleChange}
      />
      {/* <div className='mt-2 text-base' onClick={() => getPaymentId()}>
        测试getPaymentId
      </div>
      <div className='mt-2 text-base' onClick={() => getToken()}>
        测试getToken
      </div> */}
    </div>
  )
}

export default forwardRef(StripForm)
