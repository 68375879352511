import { useRef } from 'react'

import { Plus, Minus, Dollar } from '@rushable/icons'
import classnames from 'classnames'
import Field from 'components/Field'
import Label from 'components/Label'
import cloneDeep from 'lodash/cloneDeep'
import { TDeliveryPrice } from 'types/onlineOrderConfig'

import useResize from '../../../hooks/useResize'
import { toggleTierDeliveryFee } from '../../../utils/deliveryFee'

type TInHouseDeliverFeeProp = {
  deliveryFees: TDeliveryPrice[]
  onChange: (name: string, value: any) => void
}

export default function InHouseDeliverFee({
  deliveryFees,
  onChange,
}: TInHouseDeliverFeeProp): JSX.Element {
  const stopArr: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const ref = useRef(null)

  const { width } = useResize(ref)

  const perStopWidth = width / 10

  const isActive = (num: number) => {
    const filtered = deliveryFees.filter(v => v.to === num)
    return filtered.length > 0
  }

  const handleToggleDeliveryFee = (num: number) => {
    const fees = cloneDeep(deliveryFees)
    const delivery_fees = toggleTierDeliveryFee(fees, num)
    onChange('delivery_fees', [...delivery_fees])
  }

  const changeFee = (value: string, key: number) => {
    const delivery_fees = cloneDeep(deliveryFees)
    delivery_fees[key].fee = value
    onChange('delivery_fees', [...delivery_fees])
  }

  return (
    <>
      <Label className='mb-2 inline-block'>Delivery fee by distance</Label>
      <div className='relative' ref={ref}>
        {deliveryFees &&
          deliveryFees.map((deliveryFee, i) => (
            <div
              className='absolute inline-block'
              style={{
                top: '-68px',
                left:
                  deliveryFee.from * perStopWidth +
                  (i === 0 ? 0 : 1.6 * (10 - deliveryFee.from)),
                width:
                  (deliveryFee.to - deliveryFee.from) * perStopWidth -
                  (i === 0 || i === deliveryFees.length - 1
                    ? (deliveryFee.to - deliveryFee.from) * 1.6
                    : 16 + (deliveryFee.to - deliveryFee.from) * 1.6),
              }}
              key={`fee-stop-input-key-${i}`}
            >
              <Field
                className={'text-center'}
                name='fee'
                type='tel'
                suffix={<Dollar size={16} className='text-silver' />}
                inputMode='money'
                value={deliveryFee.fee || '0.00'}
                onChangeMoney={value => {
                  changeFee(value, i)
                }}
              />
            </div>
          ))}
        <div
          className='flex justify-between items-center rounded bg-zinc w-full h-1'
          style={{ marginTop: '76px', marginBottom: '51px' }}
        >
          {stopArr.map(v => (
            <div className='relative' role='button' key={`stop-key-${v}`}>
              <div
                className={classnames(
                  'w-6 h-6 rounded-full flex justify-center items-center',
                  v > 0 && !isActive(v) ? 'bg-blue' : 'bg-field',
                )}
                onClick={() => handleToggleDeliveryFee(v)}
              >
                {v > 0 &&
                  (isActive(v) ? (
                    <Minus size={16} className='text-blue' />
                  ) : (
                    <Plus size={16} className='text-white' />
                  ))}
              </div>
              <span
                style={{
                  transform: 'translateX(-50%)',
                  left: '50%',
                }}
                className='absolute mt-1 font-medium whitespace-nowrap text-silver leading-none text-xs'
              >{`${v} mi`}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
