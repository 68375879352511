import axios from 'utils/axiosApi'

export async function getIntegrationInfoApi(locationId: string) {
  const response = await axios.request({
    url: `locations/${locationId}/integrations`,
    method: 'get',
  })
  return response.data
}

export async function bindCheckMateApi(locationId: string, code: string) {
  const response = await axios.request({
    url: `locations/${locationId}/itsacheckmate_oauth`,
    params: {
      code,
    },
    method: 'get',
  })
  return response.data
}
