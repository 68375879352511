import { useEffect, useState } from 'react'

import {
  FullClover,
  FullSquare,
  Check,
  FullItsaCheckMate,
} from '@rushable/icons'
import cn from 'classnames'
import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Button from 'components/Button'
import { Spin } from 'components/Loading'
import useSearch from 'hooks/useSearch'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getIntegrationInfoApi, bindCheckMateApi } from 'redux/integration'

type TIntegrationType = 'clover' | 'square' | 'check_mate'

export default function IntegrationPage(): JSX.Element {
  const history = useHistory()
  const { locationId, brandId } =
    useParams<{ locationId: string; brandId: string }>()
  const { redirect_from, code } = useSearch()
  const [connect, setConnect] = useState({
    square: false,
    clover: false,
    check_mate: false,
  })
  const [connectLinks, setConnectLinks] = useState({
    check_mate: '',
    clover: '',
    square: '',
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (redirect_from === 'iacm' && code) {
      bindCheckMate(code)
    } else {
      getIntegrationInfo(locationId)
    }
  }, [])

  const getIntegrationInfo = async (locationId: string) => {
    setLoading(true)
    try {
      const res = await getIntegrationInfoApi(locationId)
      if (res && res?.connect_link) {
        setConnectLinks(res?.connect_link)
        setConnect({
          square:
            res?.location_pos_config?.pos_type === 'square' &&
            !!res?.location_pos_config?.token_expires_at,
          clover:
            res?.location_pos_config?.pos_type === 'clover' &&
            !!res?.location_pos_config?.token_expires_at,
          check_mate: !!res?.location_check_mate_config?.token_expires_at,
        })
      }
    } catch (error: any) {
      toast.error(error.message)
    }
    setLoading(false)
  }

  const bindCheckMate = async (code: string) => {
    setLoading(true)
    try {
      await bindCheckMateApi(locationId, code)
      history.replace(`/brand/${brandId}/location/${locationId}/integration`)
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message)
    }
    getIntegrationInfo(locationId)
  }

  const cls =
    'w-[560px] h-[72px] m-auto border border-zinc rounded-lg px-6 flex justify-between items-center'

  const renderConnectBtn = (type: TIntegrationType, status: boolean) => {
    if (status) {
      return (
        <div className='flex'>
          <div className='flex'>
            <Button color='ink-link' size='sm' className='pr-2'>
              <div className='mr-1 bg-blue rounded-full flex items-center justify-center w-3 h-3'>
                <Check size={8} className='text-white' />
              </div>
              CONNECTED
            </Button>
          </div>
        </div>
      )
    }
    return (
      <Button size='sm' onClick={() => window.open(connectLinks[type])}>
        CONNECT
      </Button>
    )
  }
  return (
    <AdminPage>
      <AdminPageTitle title='Integration' />
      <Spin spining={loading} className='z-50'>
        <div>
          {/* <div className={cn(cls, 'mt-10')}>
          <FullGbp size={108} />
          {renderConnectBtn('google', 'complete')}
        </div> */}
          <div className={cn(cls, 'mt-6')}>
            <FullClover size={75} />
            {renderConnectBtn('clover', connect.clover)}
          </div>
          <div className={cn(cls, 'mt-6')}>
            <FullSquare size={75} />
            {renderConnectBtn('square', connect.square)}
          </div>
          <div className={cn(cls, 'mt-6')}>
            <FullItsaCheckMate size={112} />
            {renderConnectBtn('check_mate', connect.check_mate)}
          </div>
        </div>
        {/* <IntergrationDetail
        open={open}
        type={openType}
        toggle={() => setOpen(false)}
      /> */}
      </Spin>
    </AdminPage>
  )
}
