/**
 * View of Inviting New User to the Brand
 */
import { useState, useEffect, useRef } from 'react'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import Timeline from 'components/Timeline'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import {
  getPermissionListAPI,
  invitationUserAPI,
  getUserDetailOrInvitationAPI,
  cancelInvitationAPI,
  resendInvitationAPI,
} from 'redux/user'
import { formatDigitOnly } from 'utils/digit'

import { timelineList } from '../helpers/data'
import AccessSettingForm from './AccessSettingForm'
import AccessSkeleton from './AccessSkeleton'
import LocationsForm from './LocationsForm'
import ProfileForm from './ProfileForm'

export type TNewUserDrawerProp = {
  title?: string
  userId?: string
  open: boolean
  isInvite: boolean
  toggle: () => void
  handleInvite: () => void
}

export default function NewUserDrawer({
  title = 'Invite New User',
  userId,
  open,
  isInvite = false,
  toggle,
  handleInvite,
}: TNewUserDrawerProp): JSX.Element {
  const baseFormData = {
    first_name: '',
    last_name: '',
    phone: '',
    repeat_phone: '',
    role: '',
    locations: [],
    userPermissions: [],
  }
  const { selectedBrand } = useAppSelector(state => state.brand)
  const locations = selectedBrand?.locations || []
  const { brandId } = useParams<TParamTypes>()
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState(baseFormData)
  const [loading, setLoading] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [scrollInfo, setScrollInfo] = useState<any>({})
  const [hasScrollBottom, setHasScrollBottom] = useState(false)
  const ref = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (open && !isInvite) {
      getPermissionList()
    }
    if (open && isInvite && userId) {
      setStep(2)
      getUserDetailOrInvitation()
    }
  }, [open])

  useEffect(() => {
    if (
      step === 2 &&
      !hasScrollBottom &&
      scrollInfo.top === scrollInfo.scrollHeight - scrollInfo.clientHeight
    ) {
      setHasScrollBottom(true)
    }
  }, [scrollInfo])

  useEffect(() => {
    if (!isInvite && step === 2) {
      if (ref && ref.current) {
        const height = ref.current.clientHeight
        height <= window.innerHeight - 240 && setHasScrollBottom(true)
      }
    }
  }, [step])

  const getPermissionList = async (userDetail?: any) => {
    try {
      const res = await getPermissionListAPI()
      if (isInvite && userDetail) {
        const userPermissions = [...res]
        userPermissions.forEach((childItem: any) => {
          childItem.permissions.forEach((v: any) => {
            const index = userDetail.userPermissions.findIndex(
              (m: any) => m.permission.name === v.name,
            )
            v.allowed = userDetail.userPermissions[index].allowed
          })
        })
        onFormChange('userPermissions', [...userPermissions])
      } else {
        onFormChange('userPermissions', [...res])
      }
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
  }

  const getUserDetailOrInvitation = async () => {
    try {
      const res = await getUserDetailOrInvitationAPI({
        type: 'invitation',
        user_invitation_id: userId,
        brand_id: brandId,
      })
      getPermissionList(res)
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
  }

  const saveInviteUser = async (resend: boolean) => {
    setLoading(true)
    try {
      const permissionsList: any = []
      formData.userPermissions.forEach((childItem: any) => {
        childItem.permissions.forEach((permissionItem: any) => {
          permissionsList.push({
            id: permissionItem.id,
            allowed: !!permissionItem.allowed,
          })
        })
      })

      if (resend) {
        const res = await resendInvitationAPI(brandId, userId)
        toast.success(res.message)
      } else {
        const params = {
          role: formData.role,
          first_name: formData.first_name,
          last_name: formData.last_name,
          phone: formatDigitOnly(formData.phone),
          location_ids: formData.locations,
          permissions: permissionsList,
        }
        const res = await invitationUserAPI(brandId, params)
        toast.success(res.message)
      }
      closeDrawerModal()
      handleInvite()
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setLoading(false)
  }

  const cancelInvitation = async () => {
    setCancelLoading(true)
    try {
      const res = await cancelInvitationAPI(brandId, userId)
      toast.success(res.message)
      closeDrawerModal()
      handleInvite()
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setCancelLoading(false)
  }

  const onFormChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const closeDrawerModal = () => {
    setStep(0)
    setFormData({ ...baseFormData })
    toggle()
    setHasScrollBottom(false)
  }

  return (
    <Drawer
      title={title}
      open={open}
      toggle={() => closeDrawerModal()}
      position='right'
      size='lg'
      onScroll={e => {
        setScrollInfo(e)
      }}
      rightFooter={
        <>
          {isInvite ? (
            <div className='grid grid-cols-2 w-[496px] gap-[98px]'>
              <Button
                loading={cancelLoading}
                className='flex-1'
                color='warning'
                onClick={() => cancelInvitation()}
              >
                REVOKE INVITATION
              </Button>
              <Button
                loading={loading}
                className='flex-1'
                color='primary'
                onClick={() => saveInviteUser(true)}
              >
                RESEND INVITATION
              </Button>
            </div>
          ) : (
            <>
              {step > 0 ? (
                <Button
                  color='secondary'
                  onClick={() => setStep(prev => prev - 1)}
                >
                  GO BACK
                </Button>
              ) : (
                <Button color='secondary' onClick={() => closeDrawerModal()}>
                  CANCEL
                </Button>
              )}
              {step < 2 ? (
                <Button
                  disabled={
                    !formData.role ||
                    !formData.first_name ||
                    !formData.last_name ||
                    !formData.phone ||
                    formData.phone !== formData.repeat_phone ||
                    formatDigitOnly(formData.phone).length < 10 ||
                    (step === 1 && formData.locations.length < 1)
                  }
                  color='primary'
                  onClick={() => setStep(prev => prev + 1)}
                >
                  NEXT STEP
                </Button>
              ) : (
                <>
                  {hasScrollBottom ? (
                    <Button
                      loading={loading}
                      color='primary'
                      onClick={() => saveInviteUser(false)}
                    >
                      SEND INVITATION
                    </Button>
                  ) : (
                    <Button
                      color='primary'
                      onClick={() => setHasScrollBottom(true)}
                    >
                      REVIEW
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </>
      }
    >
      <>
        <Timeline list={timelineList} current={step} />
        {isInvite && !formData.userPermissions.length ? (
          <>
            <AccessSkeleton />
            <AccessSkeleton />
          </>
        ) : (
          <form
            style={{
              width: 'calc(100% + 8px)',
            }}
            ref={ref}
          >
            <div className='my-6 border-b border-dashed border-zinc h-px' />
            {step === 0 && (
              <ProfileForm formData={formData} onFormChange={onFormChange} />
            )}
            {step === 1 && (
              <LocationsForm
                locations={locations}
                formData={formData}
                onFormChange={onFormChange}
              />
            )}
            {step === 2 && (
              <AccessSettingForm
                isInvite={isInvite}
                formData={formData}
                onFormChange={onFormChange}
              />
            )}
          </form>
        )}
      </>
    </Drawer>
  )
}
