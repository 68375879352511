import * as ROUTES from 'data/routes'
import LoginPage from 'pages/auth/LoginPage'
import ResetPasswordPage from 'pages/auth/ResetPasswordPage'
import CloverPage from 'pages/clover'
import SimpleLayout from 'pages/layout/SimpleLayout'
import RedirectPage from 'pages/redirect'
import SelectRestaurant from 'pages/selectRestaurant/SelectRestaurant'
import PrivacyPolicy from 'pages/terms/PrivacyPolicy'
import TermsOfService from 'pages/terms/TermsOfService'
import UserInvitationPage from 'pages/user/UserInvitationPage'

const LOGIN: TRouteType = {
  path: ROUTES.LOGIN,
  name: 'LOGIN',
  label: 'Login',
  component: LoginPage,
  layout: SimpleLayout,
  auth: false,
}

const RESETPASSWORD: TRouteType = {
  path: ROUTES.RESETPASSWORD,
  name: 'RESETPASSWORD',
  label: 'RESET PASSWORD',
  component: ResetPasswordPage,
  layout: SimpleLayout,
  auth: false,
}

const SELECTRESTAURANT: TRouteType = {
  path: ROUTES.SELECTRESTAURANT,
  name: 'SELECTRESTAURANT',
  label: 'SELECT RESTAURANT',
  component: SelectRestaurant,
  layout: SimpleLayout,
  auth: true,
}

const TERMSOFSERVICE: TRouteType = {
  path: ROUTES.TERMSOFSERVICE,
  name: 'TERMSOFSERVICE',
  label: 'TERMS OF SERVICES',
  component: TermsOfService,
  layout: SimpleLayout,
  auth: false,
}

const PRIVACYPOLICY: TRouteType = {
  path: ROUTES.PRIVACYPOLICY,
  name: 'TERMSOFSERVICE',
  label: 'TERMS OF SERVICES',
  component: PrivacyPolicy,
  layout: SimpleLayout,
  auth: false,
}

const USERINVITATION: TRouteType = {
  path: ROUTES.USERINVITATION,
  name: 'USERINVITATION',
  label: 'USER INVITATION',
  component: UserInvitationPage,
  layout: SimpleLayout,
  auth: false,
}

const CLOVER: TRouteType = {
  path: ROUTES.CLOVER,
  name: 'CLOVER',
  label: 'Clover',
  component: CloverPage,
  layout: SimpleLayout,
  auth: false,
}

const REDIRECT: TRouteType = {
  path: ROUTES.REDIRECT,
  name: 'REDIRECT',
  label: 'Redirect',
  component: RedirectPage,
  layout: SimpleLayout,
  auth: false,
}

export const baseRoutes = [
  LOGIN,
  RESETPASSWORD,
  SELECTRESTAURANT,
  TERMSOFSERVICE,
  PRIVACYPOLICY,
  USERINVITATION,
  CLOVER,
  REDIRECT,
]
