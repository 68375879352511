import React, { useState } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { AngleRight } from '@rushable/icons'

import MenuAction from './MenuAction'

type TMenuModifierItemProp = {
  formData: any
  item: any
  index: number
  menuActionName: string
  menuActionIndex: any
  handleMenuAction: (
    value: string,
    { name, id }: { name: string; id: number },
    { i, j }: { i: number; j: number },
    type?: string,
  ) => void
  setMenuActionIndex: (item: any) => void
  setMenuActionName: (name: string) => void
  getMenuDetail: (name: string, id: number) => void
}

export default function MenuModifierItem({
  formData,
  item,
  index,
  menuActionName,
  menuActionIndex,
  handleMenuAction,
  setMenuActionIndex,
  setMenuActionName,
  getMenuDetail,
}: TMenuModifierItemProp): JSX.Element {
  const { id } = item
  const { setNodeRef, listeners, transform, transition } = useSortable({
    id,
  })
  let styles
  if (transform) {
    const { x, y }: any = transform
    styles = {
      transform: CSS.Transform.toString({ x, y, scaleX: 1, scaleY: 1 }),
      transition,
    }
  }

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      style={styles}
      className='flex items-center text-sm pl-4 pr-2 cursor-pointer'
    >
      <MenuAction
        hasDetail={false}
        className='mr-2'
        open={menuActionName === item.name && menuActionIndex.i === index}
        onChange={v => handleMenuAction(v, item, { i: index, j: 0 })}
        clickOutside={() => {
          setMenuActionIndex({ i: -1, j: -1 })
          setMenuActionName('')
        }}
      />

      <div
        className={`py-3 flex w-full ${
          formData.id === item.id ? 'text-blue' : 'text-ink'
        }`}
        onClick={() => getMenuDetail(item.name, item.id)}
      >
        <div className='w-[158px] text-ellipsis leading-snug'>{item.name}</div>
        <div className={'ml-auto flex items-center'}>
          <AngleRight
            size={16}
            className={`${formData.id === item.id && 'text-blue'}`}
          />
        </div>
      </div>
    </div>
  )
}
