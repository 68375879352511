import React, { useState, useEffect } from 'react'

import { Check, MarkX } from '@rushable/icons'
import Button from 'components/Button'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import Table, { TColumnProp } from 'components/Table/Table'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getDomainsApi,
  deleteDomainsApi,
  editDomainsApi,
  createDomainsApi,
} from 'redux/brand/actions'

const OPEN = <Check size={20} className='text-green font-bold' />
const CLOSE = <MarkX size={20} className='text-red font-bold' />

export default function DomainTable(): JSX.Element {
  const { brandId } = useParams<TParamTypes>()
  const [visible, setIsVisible] = useState(false)
  const [tableData, setTableData] = useState([])
  const [hostname, setHostname] = useState('')
  const [formStatus, setFormStatus] = useState<Record<string, any>>({
    hostnameError: '',
    modalLoading: false,
    tableLoading: true,
  })

  useEffect(() => {
    getDomains()
  }, [])

  const getDomains = async () => {
    setFormStatus(prev => ({ ...prev, tableLoading: true }))
    try {
      const res = await getDomainsApi({ with_dns: 1, brand_id: brandId })
      const data = res.data || []
      setTableData(data)
    } catch (e) {
      console.log(e)
    }
    setFormStatus(prev => ({ ...prev, tableLoading: false }))
  }

  const createDomains = async (value: string) => {
    try {
      setFormStatus(prev => ({ ...prev, modalLoading: true }))
      const res = await createDomainsApi({
        brand_id: brandId,
        hostname: `https://${value}`,
        is_default: 0,
      })
      getDomains()
      toast.success(res?.message)
      setIsVisible(false)
      setHostname('')
    } catch (e: any) {
      setFormStatus(prev => ({ ...prev, hostnameError: e.message }))
    }
    setFormStatus(prev => ({ ...prev, modalLoading: false }))
  }

  const deleteDomains = async (id: string | number) => {
    setFormStatus(prev => ({ ...prev, tableLoading: true }))
    try {
      const res = await deleteDomainsApi({ id, brand_id: brandId })
      toast.success(res?.message)
      getDomains()
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
      setFormStatus(prev => ({ ...prev, tableLoading: false }))
    }
  }

  const editDomains = async (id: string | number) => {
    setFormStatus(prev => ({ ...prev, tableLoading: true }))
    try {
      const res = await editDomainsApi({ id, brand_id: brandId })
      toast.success(res?.message)
      getDomains()
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
      setFormStatus(prev => ({ ...prev, tableLoading: false }))
    }
  }

  const tableCols: TColumnProp[] = [
    {
      key: 'hostname',
      name: 'DOMAIN',
      className: 'text-left py-2',
    },
    {
      key: 'arecord_verified',
      name: 'A RECORD STATUS',
      custom: value => (value === 1 || value ? OPEN : CLOSE),
      className: 'w-36',
      align: 'center',
    },
    {
      key: 'cname_verified',
      name: 'CNAME STATUS',
      custom: value => (value === 1 || value ? OPEN : CLOSE),
      className: 'w-32',
      align: 'center',
    },
    {
      key: 'ssl_verified',
      name: 'SSL STATUS',
      custom: value => (value === 1 || value ? OPEN : CLOSE),
      className: 'w-28',
      align: 'center',
    },
    {
      key: 'is_default',
      name: 'PRIMARY',
      className: 'w-36',
      custom: (value, row) => {
        if (value === 1) {
          return OPEN
        } else {
          return (
            <Button
              color='primary-link'
              size='sm'
              className='mx-auto'
              onClick={() => {
                editDomains(row.id)
              }}
            >
              MAKE PRIMARY
            </Button>
          )
        }
      },
      align: 'center',
    },
    {
      key: 'opt',
      name: 'ACTION',
      align: 'center',
      // className: 'w-20',
      custom: (value, row) => {
        if (row.is_default === 1 || row.is_locked) {
          return null
        }
        return (
          <Button
            inline
            color='secondary-link'
            size='sm'
            onClick={() => {
              deleteDomains(row.id)
            }}
          >
            DELETE
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <div className='mt-8'>
        <h3 className='pt-8 py-4 border-t border-dashed border-zinc font-bold text-base text-ink'>
          Domain State
        </h3>
        <Table
          loading={formStatus.tableLoading}
          columns={tableCols}
          data={tableData}
          paginationShow={false}
        />
        {tableData.length < 4 && (
          <Button
            className='mt-4'
            color='secondary'
            size='sm'
            onClick={() => setIsVisible(true)}
          >
            ADD A NEW DOMIN
          </Button>
        )}
      </div>
      <ModalFull
        open={visible}
        toggle={() => setIsVisible(!visible)}
        title='Add New Domain'
        loading={formStatus.modalLoading}
        error={formStatus.hostnameError}
        onCancel={() => {
          setHostname('')
          setIsVisible(false)
        }}
        onOk={() => {
          createDomains(hostname)
        }}
      >
        <>
          <Field
            type='text'
            name='Domain name'
            label='Domain name'
            suffix='https://'
            className='pl-[68px]'
            value={hostname}
            onChange={(e: any) => {
              const value = e.target.value
              setHostname(value)
              setFormStatus(prev => ({ ...prev, hostnameError: '' }))
            }}
            placeholder=''
          />
        </>
      </ModalFull>
    </>
  )
}
