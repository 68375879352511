import { useState } from 'react'

type Iprops = {
  src: string
  fallbackSrc: string
  className: string
  [propName: string]: any
}
export default function ThumbnailsImage({
  src,
  fallbackSrc,
  className = '',
  ...rest
}: Iprops) {
  const [errored, setErrored] = useState(false)
  return (
    <img
      src={src}
      className={className}
      {...rest}
      onError={({ currentTarget }) => {
        // 避免无限回调 https://stackoverflow.com/questions/34097560/react-js-replace-img-src-onerror
        if (!errored) {
          currentTarget.onerror = null
          currentTarget.src = fallbackSrc
          setErrored(true)
        }
      }}
    />
  )
}
