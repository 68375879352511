import { useEffect, useState } from 'react'

import Tips from 'components/Tips'
import useSearch from 'hooks/useSearch'
import { useHistory } from 'react-router-dom'
import { getAccessToken } from 'utils/auth'

export default function RedirectPage(): JSX.Element {
  const history = useHistory()
  const [textInfo, setTextInfo] = useState({ text: '', status: '' })
  const { redirect_from, code } = useSearch()
  console.log(redirect_from, code)

  useEffect(() => {
    if (redirect_from === 'iacm' && code) {
      // iacm 跳转的相关逻辑
      history.push(
        `${
          getAccessToken() ? 'select-restaurant' : 'login'
        }?redirect_from=${redirect_from}&code=${code}`,
      )
    }
    setTextInfo({ text: 'Invalid input', status: 'error' })
  }, [])

  return (
    <div className='flex justify-center'>
      {textInfo.text && <Tips status={textInfo.status} text={textInfo.text} />}
    </div>
  )
}
