import React, { useState } from 'react'

import TabChangeBar from 'components/TabChangeBar'

import PayoutsTable from './components/PayoutsTable'
import TransactionsTable from './components/TransactionsTable'

export default function TransactionHistory(): JSX.Element {
  const [activeTab, setActiveTab] = useState('Show all transactions')

  const onTabChange = (tabName: string) => {
    switch (tabName) {
      case 'Show all transactions':
        setActiveTab('Show all transactions')
        break
      case 'Group transactions by payout':
        setActiveTab('Group transactions by payout')
        break

      default:
        break
    }
  }
  return (
    <div>
      <TabChangeBar
        activeItem={activeTab}
        className='w-784px'
        containerClassName='w-388px'
        items={['Show all transactions', 'Group transactions by payout']}
        onClick={e => {
          onTabChange(e)
        }}
      />
      <div className='mt-6'>
        {activeTab === 'Group transactions by payout' ? (
          <PayoutsTable />
        ) : (
          <TransactionsTable />
        )}
      </div>
    </div>
  )
}
