import { useState, useEffect, useRef } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import Field from 'components/Field'
import FieldColor from 'components/Field/FieldColor/index'
import { Spin } from 'components/Loading'
import SaveChangeBar from 'components/SaveChangeBar'
import { Select } from 'components/Select'
import UnsavedPrompt from 'components/UnsavedPrompt/UnsavedPrompt'
import Upload from 'components/Upload/Upload'
import { BRAND_COLOR } from 'data/constant'
import useDebounce, { compare } from 'hooks/useDebounce'
import cloneDeep from 'lodash/cloneDeep'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getWebsiteConstantApi,
  getWebsiteInfoApi,
  updateWebsiteApi,
} from 'redux/brand/actions'
import getThiefColor from 'utils/colorTool'
import parseMediaAbsoluteURL from 'utils/parseMediaAbsoluteURL'

import AddLink from './components/AddLink'
import AddTracking from './components/AddTracking'
import SetImage from './components/SetImage'
import TextareaComps from './components/TextareaComps'
import WebsiteDisplay from './components/WebsiteDisplay'

const formDataInit = {
  websiteMode: '',
  brandName: '',
  slogan: '',
  logo: '',
  favicon: '',
  banner: '',
  logoShape: '',
  cuisineType: [],
  tagLines: '',
  brandColor: '',
  websiteColor: '',
  logoPadding: false,
  about: '',
  ins: '',
  fb: '',
  links: [{}],
  trackings: [{}],
  defaultDomain: '',
}

const WebsiteSetting = (): JSX.Element => {
  const { brandId } = useParams<TParamTypes>()
  const [cuisineTypes, setCuisineTypes] = useState([])
  const [colorOptions, setColorOptions] = useState<string[]>(BRAND_COLOR)
  const [stateInit, setStateInit] = useState(formDataInit)
  const [state, setState] = useState(formDataInit)
  const [formStatus, setFormStatus] = useState<Record<string, any>>({
    loading: false,
    saveLoading: false,
    error: '',
    isDirty: false,
  })
  const imgRef = useRef<Record<'banner' | 'favicon' | 'logo', any>>({
    banner: null,
    favicon: null,
    logo: null,
  })
  const compareFn = useDebounce(compare)

  useEffect(() => {
    compareFn(state, stateInit, (flag: boolean) =>
      setFormStatus(prev => ({ ...prev, isDirty: flag })),
    )
  }, [state, stateInit])

  useEffect(() => {
    getWebsiteConstant('cuisine')
    getWebsiteInfo()
  }, [])

  // 获取常量枚举
  const getWebsiteConstant = async (type: string) => {
    try {
      const res = await getWebsiteConstantApi(type)
      const options = res?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
      setCuisineTypes(options)
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
  }

  const getWebsiteInfo = async () => {
    setFormStatus(prev => ({ ...prev, loading: true }))
    try {
      const res = await getWebsiteInfoApi(brandId)
      initFormData(res)
      toast.success(res?.message)
    } catch (e: any) {
      const msg = e.message || 'request error'
      toast.error(msg)
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  // 初始化表单数据
  const initFormData = (res: any) => {
    const obj = {
      websiteMode: res.status,
      brandName: res.name,
      slogan: res.slogan,
      logo: parseMediaAbsoluteURL(res.logo_url),
      favicon: parseMediaAbsoluteURL(res.pavicon_url),
      banner: parseMediaAbsoluteURL(res.banner_url),
      logoShape: res.logo_shape,
      cuisineType: res.cuisine_type_ids || [],
      tagLines: res.tagline || '',
      brandColor: res.theme_color?.code || '',
      websiteColor: res.appearance || 'light',
      logoPadding: !!res.logo_padding,
      about: res.about || '',
      ins:
        res.social_media?.find((v: any) => v.source === 'Instagram')?.link ||
        '',
      fb:
        res.social_media?.find((v: any) => v.source === 'Facebook')?.link || '',
      links: res.nav_links?.length ? res.nav_links : [{}],
      trackings: res.tracking_codes?.length ? res.tracking_codes : [{}],
      defaultDomain: res.default_domain || '',
    }
    setState(obj)
    setStateInit(cloneDeep(obj))
    handleThiefColor(obj.logo)
  }

  const updateWebsiteInfo = async (param: FormData) => {
    setFormStatus(prev => ({ ...prev, saveLoading: true, error: '' }))
    try {
      const res = await updateWebsiteApi(param, brandId)
      toast.success(res?.message)
      setFormStatus(prev => ({ ...prev, isDirty: false }))
      setStateInit({ ...state })
    } catch (e: any) {
      const msg = e.message || 'request error'
      setFormStatus(prev => ({ ...prev, error: msg }))
    }
    setFormStatus(prev => ({ ...prev, saveLoading: false }))
  }

  const onChangeForm = (name: string, value: any) => {
    setState({ ...state, [name]: value })
  }

  const handleSave = () => {
    // 获取图片文件
    const { banner, favicon, logo } = imgRef.current || {}

    const obj: Record<string, any> = {
      status: state.websiteMode,
      name: state.brandName,
      logo_shape: state.logoShape,
      logo_padding: state.logoPadding ? 1 : 0,
      theme_color_code: state.brandColor,
      appearance: state.websiteColor,
      slogan: state.slogan || 'More Than Delicious Food',
      tagline: state.tagLines,
      about: state.about,
    }
    const formData = new FormData()
    Object.keys(obj).forEach(key => {
      if (obj[key] !== undefined) {
        formData.append(key, obj[key])
      }
    })

    if (logo?.blob) {
      formData.append('logo', logo?.blob)
    } else if (stateInit.logo) {
      formData.append('logo_url', state.logo || '')
    }
    if (favicon?.blob) {
      formData.append('pavicon', favicon?.blob)
    } else if (stateInit.favicon) {
      formData.append('pavicon_url', state.favicon || '')
    }
    if (banner?.blob) {
      formData.append('banner', banner?.blob)
    } else if (stateInit.banner || state.banner) {
      formData.append('banner_url', state.banner || '')
    }

    // FormData 传递复杂数据类型
    state.cuisineType.forEach((item, index) => {
      formData.append(`cuisine_type_ids[${index}]`, item)
    })

    const linksArr = []
    if (state.fb) {
      const obj = { source: 'Facebook', link: state.fb }
      linksArr.push(obj)
    }
    if (state.ins) {
      const obj = { source: 'Instagram', link: state.ins }
      linksArr.push(obj)
    }

    linksArr.forEach((item: any, index) => {
      if (item.source || item.link) {
        formData.append(`social_media[${index}][source]`, item.source || '')
        formData.append(`social_media[${index}][link]`, item.link || '')
      }
    })

    state.trackings.forEach((item: any, index) => {
      if (item.type || item.code) {
        formData.append(`tracking_codes[${index}][type]`, item.type || '')
        formData.append(`tracking_codes[${index}][code]`, item.code || '')
      }
    })

    links.forEach((item: any, index) => {
      if (item.name || item.url) {
        formData.append(`custom_navigation_links[${index}][name]`, item.name)
        formData.append(`custom_navigation_links[${index}][url]`, item.url)
      }
    })
    updateWebsiteInfo(formData)
    // console.log('state--->', obj, state, imgRef)
  }

  // 拾取logo颜色提供给filedColor，增加备选项
  const handleThiefColor = (preview: string) => {
    getThiefColor('#thief-color', preview)
      .then((colors: string[]) => {
        if (colors.length) {
          setColorOptions(colors)
        } else {
          setColorOptions(colorOptions)
        }
      })
      .catch(err => {
        console.log('err-->', err)
        setColorOptions(colorOptions)
      })
  }

  const {
    websiteMode,
    logo,
    favicon,
    banner,
    brandName,
    logoShape,
    cuisineType,
    tagLines,
    brandColor,
    websiteColor,
    logoPadding,
    about,
    ins,
    fb,
    links,
    trackings,
    defaultDomain,
  } = state
  return (
    <AdminPage>
      <AdminPageTitle title='Website Setting' />
      <Spin spining={formStatus.loading} top>
        <div className='flex'>
          <div className='flex-1 py-6 px-8'>
            <div className='flex pb-6'>
              <div className='flex-1 mr-6'>
                <Select
                  className='flex-1'
                  label='Website mode'
                  value={websiteMode}
                  options={[
                    { label: 'Active', value: 'active' },
                    { label: 'Maintenance', value: 'inactive' },
                  ]}
                  onChange={mode => {
                    onChangeForm('websiteMode', mode)
                  }}
                />
              </div>
              <div className='flex-1'>
                <Field
                  className='flex-1'
                  label='Brand name'
                  name='Brand name'
                  value={brandName}
                  onChange={e => {
                    onChangeForm('brandName', e.target.value)
                  }}
                  placeholder='Brand name'
                  type='text'
                />
              </div>
            </div>
            <div className='pb-6 border-b border-dashed border-zinc'>
              <Select
                label='Cuisine type'
                value={cuisineType}
                placeholder='Select Cuisine type'
                isMulti={true}
                options={cuisineTypes}
                onChange={value => {
                  onChangeForm('cuisineType', value)
                }}
              />
            </div>
            <div className='flex pt-6 pb-6 border-b border-dashed border-zinc'>
              <div className='flex-1 mr-6'>
                <div className='hidden'>
                  {/* 提供给 colorThief 拾取颜色 */}
                  <img id='thief-color' src={logo} alt='' />
                </div>
                <div className='flex'>
                  <Upload
                    className='flex-1 mr-4'
                    labelText='Logo'
                    height='141px'
                    value={logo}
                    bgSize='bg-contain'
                    onChange={(blob, preview) => {
                      onChangeForm('logo', preview)
                      imgRef.current.logo = { blob, preview }
                      handleThiefColor(preview)
                    }}
                    onDelete={() => {
                      onChangeForm('logo', '')
                      imgRef.current.logo = null
                    }}
                  ></Upload>
                  <Upload
                    className='flex-1'
                    labelText='Favicon'
                    height='141px'
                    // accept='.png'
                    value={favicon}
                    bgSize='bg-contain'
                    onChange={(blob, preview) => {
                      onChangeForm('favicon', preview)
                      imgRef.current.favicon = { blob, preview }
                    }}
                    onDelete={() => {
                      onChangeForm('favicon', '')
                      imgRef.current.favicon = null
                    }}
                  ></Upload>
                </div>
              </div>
              <div className='flex-1'>
                <Select
                  className='flex-1 mb-4'
                  label='Logo shape'
                  value={logoShape}
                  options={[
                    { label: 'Rectangle', value: 'rectangle' },
                    { label: 'Square', value: 'square' },
                  ]}
                  onChange={shape => {
                    onChangeForm('logoShape', shape)
                  }}
                />
                <Field
                  className='flex-1'
                  label='Logo padding'
                  inputLabel={logoPadding ? 'Apply Padding' : 'No padding'}
                  name='Logo padding'
                  checked={logoPadding}
                  placeholder='Brand name'
                  type='checkbox'
                  onChange={checked => {
                    onChangeForm('logoPadding', checked)
                  }}
                />
              </div>
            </div>
            <div className='flex pt-6'>
              <div className='flex-1 mr-6'>
                <FieldColor
                  label='Branding color (hex code)'
                  value={brandColor}
                  colors={colorOptions}
                  key={colorOptions.length}
                  onChange={value => {
                    onChangeForm('brandColor', value)
                  }}
                />
              </div>

              <Select
                className='flex-1 mb-4'
                label='Website color style'
                value={websiteColor}
                options={[
                  { label: 'Light mode', value: 'light' },
                  { label: 'Dark mode', value: 'dark' },
                ]}
                onChange={color => {
                  onChangeForm('websiteColor', color)
                }}
              />
            </div>
            <div className='mt-6'>
              <SetImage
                labelText='Homepage banner'
                value={banner}
                onChange={(preview, blob) => {
                  onChangeForm('banner', preview)
                  imgRef.current.banner = { blob, preview }
                }}
                onRemove={() => {
                  onChangeForm('banner', '')
                  imgRef.current.banner = null
                }}
              ></SetImage>
            </div>
            <div className='py-8'>
              <Field
                label='Tagline'
                name='Tagline'
                value={tagLines || ''}
                onChange={e => {
                  onChangeForm('tagLines', e.target.value)
                }}
                placeholder=''
                type='text'
              />
            </div>
            <div className='w-full pb-6 border-b border-dashed border-zinc'>
              <TextareaComps
                value={about}
                label='About'
                onChange={value => onChangeForm('about', value)}
              />
            </div>
            <div className='flex my-6'>
              <div className='flex-1 mr-6 '>
                <Field
                  className='flex-1'
                  label='Instagram profile link'
                  name='Instagram profile link'
                  value={ins}
                  placeholder=''
                  type='text'
                  onChange={e => {
                    onChangeForm('ins', e.target.value)
                  }}
                />
              </div>
              <div className='flex-1'>
                <Field
                  className='flex-1'
                  label='Facebook profile link'
                  name='Facebook profile link'
                  value={fb}
                  placeholder=''
                  type='text'
                  onChange={e => {
                    onChangeForm('fb', e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='w-full mt-6 pb-6 border-b border-dashed border-zinc'>
              <AddLink
                value={links}
                onChange={value => {
                  onChangeForm('links', value)
                }}
              />
            </div>
            {/* <div className='mt-6 pb-6 border-b border-dashed border-zinc'>
              <Label className='mb-2'>Store locator via Mapbox</Label>
              <div className='flex'>
                <Field
                  type='text'
                  containerClassName='flex-1'
                  name='Store_locator_via_Mapbox'
                  value=''
                  placeholder='Input token here'
                  onChange={e => {
                    console.log(e.target.value)
                  }}
                />
                <Button className='w-40 ml-8' color='tertiary'>
                  CONNECT
                </Button>
              </div>
            </div> */}
            <div className='my-6'>
              <AddTracking
                value={trackings}
                onChange={value => {
                  onChangeForm('trackings', value)
                }}
              />
            </div>
          </div>
          <div className='px-8 pt-6 w-80 border-l border-zinc'>
            <WebsiteDisplay
              logo={imgRef.current?.logo?.preview || logo || ''}
              banner={imgRef.current?.banner?.preview || banner || ''}
              brandName={brandName}
              tagline={tagLines}
              themeColor={brandColor}
              logoShape={logoShape}
              logoPadding={logoPadding}
              link={defaultDomain}
            />
          </div>
        </div>
      </Spin>

      <>
        {formStatus.isDirty && (
          <SaveChangeBar
            error={formStatus.error}
            confirmRequest={formStatus.saveLoading}
            onConfirm={() => handleSave()}
            onCancel={() => {
              setFormStatus(prev => ({ ...prev, isDirty: false }))
              setState({ ...stateInit })
              imgRef.current.logo = null
              imgRef.current.banner = null
            }}
          />
        )}
      </>
      <UnsavedPrompt when={formStatus.isDirty} />
    </AdminPage>
  )
}

export default WebsiteSetting
