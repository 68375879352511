import React from 'react'

import { AngleDown, AngleUp, Check } from '@rushable/icons'
import type {
  ControlProps,
  StylesConfig,
  OptionProps,
  MultiValueGenericProps,
  IndicatorsContainerProps,
} from 'react-select'
import { components } from 'react-select'

export interface IOptions {
  label: string
  value: string | number | boolean
  isDisabled?: boolean
  isLoading?: boolean
  optionChildren?: React.ReactElement
  [propName: string]: any
}

const NORMALBORDERBLUE = '#00A5EB'
const TABLEFILTERBORDER = '#092235'

// 普通 select
export const customStyles: StylesConfig<IOptions> = {
  container: styles => {
    return {
      ...styles,
      borderRadius: 8,
    }
  },

  placeholder: styles => {
    return {
      ...styles,
      marginLeft: '0px',
      marginRight: '0px',
      color: '#D8DCDF',
      fontSize: '16px',
      lineHeight: '140%',
    }
  },

  menu: provided => ({
    ...provided,
    backgroundColor: '#fff',
    padding: 0,
    marginTop: -1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    zIndex: 999,
    borderLeft: `2px solid ${NORMALBORDERBLUE}`,
    borderRight: `2px solid ${NORMALBORDERBLUE}`,
    borderBottom: `2px solid ${NORMALBORDERBLUE}`,
    overflow: 'hidden',
    boxShadow: 'none',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    marginTop: 8,
  }),
  control: (provided, state) => {
    const borderBottomLeftRadius = state.menuIsOpen ? 0 : 8
    const borderBottomRightRadius = state.menuIsOpen ? 0 : 8
    const backgroundColor = state.menuIsOpen ? '#fff' : '#F4F6F8'
    const borderColor = state.menuIsOpen ? NORMALBORDERBLUE : 'transparent'
    const borderBottom = state.menuIsOpen ? '2px solid #e1e5e9' : ''

    return {
      ...provided,
      borderWidth: 2,
      cursor: 'pointer',
      borderBottom,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      boxShadow: 'none',
      padding: '12px 16px',
      fontSize: '16px',
      lineHeight: '140%',
      backgroundColor: state.isDisabled ? '#F9F9F9' : backgroundColor,
      borderColor: state.isDisabled ? '#F9F9F9' : borderColor,
      ':focus': {
        borderColor: NORMALBORDERBLUE,
        borderBottom: borderBottom,
      },
      ':active': {
        borderColor: NORMALBORDERBLUE,
        borderBottom: borderBottom,
      },
      ':hover': {
        borderColor: NORMALBORDERBLUE,
        borderBottom: borderBottom,
        backgroundColor: '#fff',
      },
    }
  },

  valueContainer: styles => {
    return {
      ...styles,
      height: '100%',
      padding: 0,
    }
  },
  input: (provided, state) => {
    return {
      ...provided,
      margin: 0,
      padding: 0,
      color: state.isDisabled ? '#B2B2B7' : '#092235',
      position: 'relative',
      visibility: 'visible',
    }
  },

  option: (provided, state) => {
    return {
      ...provided,
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      fontSize: '16px',
      lineHeight: '140%',
      color: state.isMulti && state.isSelected ? '#092235' : '#62727E',
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: '#F5F6FA',
      },
    }
  },

  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
    backgroundColor: 'transparent',
  }),

  singleValue: (provided, state) => {
    const transition = 'opacity 300ms'
    return {
      ...provided,
      fontSize: '16px',
      lineHeight: '140%',
      color: state.isDisabled ? '#B2B2B7' : '#092235',
      transition,
      margin: 0,
    }
  },
}

export const customTableFilterStyles: StylesConfig<IOptions> = {
  container: styles => {
    return {
      ...styles,
      borderRadius: 8,
    }
  },

  placeholder: styles => {
    return {
      ...styles,
      marginLeft: '0px',
      marginRight: '0px',
      color: '#D8DCDF',
      fontSize: '12px',
      lineHeight: '140%',
      fontWeight: 700,
    }
  },

  menu: provided => ({
    ...provided,
    backgroundColor: '#fff',
    padding: 0,
    marginTop: -1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    zIndex: 999,
    borderLeft: `2px solid ${TABLEFILTERBORDER}`,
    borderRight: `2px solid ${TABLEFILTERBORDER}`,
    borderBottom: `2px solid ${TABLEFILTERBORDER}`,
    overflow: 'hidden',
    boxShadow: 'none',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    marginTop: 8,
  }),
  control: (provided, state) => {
    const borderBottomLeftRadius = state.menuIsOpen ? 0 : 8
    const borderBottomRightRadius = state.menuIsOpen ? 0 : 8
    const borderColor = state.menuIsOpen ? TABLEFILTERBORDER : '#E1E5E9'
    const borderBottom = state.menuIsOpen ? '3px solid #e1e5e9' : ''

    return {
      ...provided,
      borderWidth: 2,
      cursor: 'pointer',
      borderBottom,
      borderBottomLeftRadius,
      borderBottomRightRadius,
      boxShadow: 'none',
      padding: '8px 16px',
      fontSize: '12px',
      lineHeight: '140%',
      fontWeight: 700,
      backgroundColor: '#fff',
      borderColor: state.isDisabled ? '#D8DCDF' : borderColor,
      ':focus': {
        borderColor: TABLEFILTERBORDER,
        borderBottom: borderBottom,
      },
      ':active': {
        borderColor: TABLEFILTERBORDER,
        borderBottom: borderBottom,
      },
      ':hover': {
        borderColor: TABLEFILTERBORDER,
        borderBottom: borderBottom,
      },
    }
  },

  valueContainer: styles => {
    return {
      ...styles,
      height: '100%',
      padding: 0,
    }
  },
  input: (provided, state) => {
    return {
      ...provided,
      margin: 0,
      padding: 0,
      color: state.isDisabled ? '#B2B2B7' : '#092235',
      position: 'relative',
      visibility: 'visible',
    }
  },

  option: provided => {
    return {
      ...provided,
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '12px',
      lineHeight: '140%',
      color: '#62727E',
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: '#F5F6FA',
      },
    }
  },

  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
    backgroundColor: 'transparent',
  }),

  singleValue: (provided, state) => {
    const transition = 'opacity 300ms'
    return {
      ...provided,
      fontSize: '12px',
      lineHeight: '140%',
      color: state.isDisabled ? '#B2B2B7' : '#092235',
      transition,
      margin: 0,
    }
  },
}

export const customTheme = (theme: any) => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary25: '#fff',
    primary: '#fff',
  },
})

export const Control = ({ children, ...props }: ControlProps<any, false>) => {
  const { icon } = props.selectProps as any
  return (
    <components.Control {...props}>
      {icon || ''}
      {children}
    </components.Control>
  )
}

export const IndicatorsContainer = (
  props: IndicatorsContainerProps<IOptions>,
) => {
  const { menuIsOpen, indicator } = props.selectProps as any
  return (
    <components.IndicatorsContainer {...props}>
      {indicator ? (
        indicator
      ) : menuIsOpen ? (
        <AngleUp color='#B0B8BE' />
      ) : (
        <AngleDown color='#B0B8BE' />
      )}
    </components.IndicatorsContainer>
  )
}

export const customMultiOptions = (props: OptionProps<IOptions>) => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className='w-full'>
        {data.optionChildren ? (
          data.optionChildren
        ) : (
          <div className='flex justify-between items-center'>
            {data.label}
            <Check className={`${props.isSelected && 'text-blue'}`} />
          </div>
        )}
      </div>
    </components.Option>
  )
}

export const customSingleOption = (props: OptionProps<IOptions>) => {
  const { data } = props
  return (
    <components.Option {...props}>
      {data.optionChildren ? data.optionChildren : data.label}
    </components.Option>
  )
}

// 自定义多选 control 内的样式
export const MultiValueContainer = (
  props: MultiValueGenericProps<IOptions>,
) => {
  const { data, selectProps } = props
  const len = selectProps.value?.length
  // @ts-ignore
  const isComma = selectProps.value?.[len - 1].value !== data.value
  return (
    <div className='text-ink text-base'>
      <span>{data.label}</span>
      {isComma && <span>,&nbsp;</span>}
    </div>
  )
}
