import { useState, useEffect } from 'react'

import AdminPage from 'components/AdminPage'
import AdminPageTitle from 'components/AdminPageTitle'
import { useParams } from 'react-router'
import {
  getCouponList,
  createCoupon,
  editCoupon,
  deleteCoupon,
} from 'redux/coupon'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import type { TCouponForm } from 'types/coupon'

import CouponDrawer from './components/CouponDrawer'
import CouponList from './components/CouponList'
import CouponNone from './components/CouponNone'

export default function CouponCode() {
  const dispatch = useAppDispatch()
  const { coupons } = useAppSelector(state => state.coupon)
  const { locationId, brandId } =
    useParams<{ locationId: string; brandId: string }>()
  const [drawerData, setDrawerData] = useState({
    open: false,
    id: '',
  })
  const [keyword, setKeyword] = useState<string>('')
  const [showNone, setShowNone] = useState(false)
  const handleAdd = () => {
    setDrawerData({
      open: true,
      id: '',
    })
  }
  const getCoupons = async (page = 1, search = '') => {
    const res = await dispatch(
      getCouponList({
        locationId,
        keyword: search ? search : keyword,
        page,
      }),
    )
    if (res.type === 'coupon/getCouponList/fulfilled') {
      page === 1 && search.length === 0 && res.payload?.data?.length === 0
        ? setShowNone(true)
        : setShowNone(false)
    }
  }
  const create = async (couponForm: TCouponForm) => {
    const data = await dispatch(
      createCoupon({
        locationId,
        params: {
          ...couponForm,
          brand_id: brandId,
        },
      }),
    )
    if (data.type === 'coupon/createCoupon/fulfilled') {
      getCoupons()
      setDrawerData({
        open: false,
        id: '',
      })
    }
  }
  const edit = async (couponForm: TCouponForm, id: string) => {
    const data = await dispatch(
      editCoupon({
        params: {
          ...couponForm,
        },
        id,
        locationId,
      }),
    )
    if (data.type === 'coupon/editCoupon/fulfilled') {
      getCoupons()
      setDrawerData({
        open: false,
        id: '',
      })
    }
  }
  const couponDelete = async (id: string) => {
    const data = await dispatch(
      deleteCoupon({
        id,
        locationId,
      }),
    )
    if (data.type === 'coupon/deleteCoupon/fulfilled') {
      getCoupons()
      setDrawerData({
        open: false,
        id: '',
      })
    }
  }
  const openCoupon = (id: string) => {
    setDrawerData({
      open: true,
      id,
    })
  }
  useEffect(() => {
    getCoupons()
  }, [])
  return (
    <AdminPage>
      <AdminPageTitle title='Coupon Code' />
      {showNone ? (
        <CouponNone handleAdd={handleAdd} />
      ) : (
        <CouponList
          keyword={keyword}
          setKeyword={setKeyword}
          handleAdd={handleAdd}
          coupons={coupons}
          getCoupons={getCoupons}
          openCoupon={openCoupon}
        />
      )}
      <CouponDrawer
        open={drawerData.open}
        id={drawerData.id}
        toggle={() =>
          setDrawerData({
            open: false,
            id: '',
          })
        }
        create={create}
        edit={edit}
        couponDelete={couponDelete}
      />
    </AdminPage>
  )
}
