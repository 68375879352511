import React, { useState } from 'react'

import Button from 'components/Button'
import CalendarField from 'components/CalendarField'
import Field from 'components/Field'
import ModalFull from 'components/Modal/ModalFull'
import { Select, TimeSelect } from 'components/Select'
import moment from 'moment'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { sendTestEmail } from 'redux/campaign'
import { useAppDispatch } from 'redux/hooks'
import type { TCampaignItem } from 'types/campaign'

import { audienceTypes, conditionalTypes, dateTypes } from '../helpers/constant'
import Line from './Line'

type TSendingRulesFormProps = {
  stepLoading: boolean
  prevStep: () => void
  createOrUpdate: () => void
  formData: TCampaignItem
  handleFormDataChange: (type: string, value: any) => void
  viewOnly?: boolean
}

export default function SendingRulesForm({
  stepLoading,
  prevStep,
  createOrUpdate,
  formData,
  handleFormDataChange,
  viewOnly,
}: TSendingRulesFormProps): JSX.Element {
  const dispatch = useAppDispatch()
  const { locationId } = useParams<{ locationId: string }>()
  const {
    trigger_of_date_time,
    campaign_audience,
    id,
    type: campaignType,
    trigger_of_inactive_days,
  } = formData
  let trigger_of_date = ''
  let trigger_of_time = ''
  if (trigger_of_date_time && trigger_of_date_time.length > 0) {
    trigger_of_date = trigger_of_date_time.split(' ')[0]
    trigger_of_time = trigger_of_date_time.split(' ')[1]
  }

  const type = campaign_audience?.type || ''
  const conditional_type = campaign_audience?.conditional_type || ''
  const lifetime_order_count_operator =
    campaign_audience?.lifetime_order_count_operator || ''
  const lifetime_order_count = campaign_audience?.lifetime_order_count || ''
  const recent_order_within_unit =
    campaign_audience?.recent_order_within_unit || ''
  const recent_order_within = campaign_audience?.recent_order_within || ''
  const [testEmail, setTestEmail] = useState('')
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const sendEmail = async () => {
    try {
      setLoading(true)
      setError('')
      const res = await dispatch(
        sendTestEmail(id as number, testEmail, locationId),
      )
      if (res) {
        setVisible(false)
        toast.success('Send Success')
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error.message) {
        setError(error.message)
      }
    }
  }

  return (
    <div className='mt-8'>
      <Button
        color='tertiary'
        className='w-full'
        onClick={() => setVisible(true)}
      >
        SEND A TEST EMAIL
      </Button>
      <Line className='mt-6 mb-6' />
      <div className='text-base text-ink font-bold pb-2'>Sending Schedule</div>
      {campaignType === 'automated' ? (
        <div className='h-[40px] px-4 flex items-center bg-ice rounded-lg'>
          {!trigger_of_inactive_days
            ? 'Send a wish/offer to a customer 7 days before the birthday date.'
            : ''}
          {trigger_of_inactive_days === 14
            ? '14 days after the customer’s last purchase'
            : ''}
          {trigger_of_inactive_days === 30
            ? '30 days after the customer’s last purchase'
            : ''}
          {trigger_of_inactive_days === 60
            ? '60 days after the customer’s last purchase'
            : ''}
        </div>
      ) : (
        <div className='flex'>
          <CalendarField
            className='flex-1'
            date={trigger_of_date ? new Date(trigger_of_date) : new Date()}
            placeholder='Select'
            calendarRestProps={{ minDate: new Date() }}
            disabled={viewOnly}
            value={
              trigger_of_date
                ? moment(trigger_of_date).format('MMM DD, YYYY')
                : ''
            }
            onChange={a => {
              if (a) {
                handleFormDataChange(
                  'trigger_of_date',
                  moment(a).format('YYYY-MM-DD'),
                )
              }
            }}
          />
          <TimeSelect
            className='ml-6 flex-1'
            placeholder='Select'
            value={trigger_of_time}
            disabled={viewOnly}
            onChange={value => {
              const time = `${trigger_of_date} ${value}`
              const isAfterTime = moment(time).isAfter(moment())
              if (!isAfterTime) {
                toast.error('Please select a future time')
              }
              handleFormDataChange('trigger_of_time', value)
            }}
          />
        </div>
      )}
      <Line className='mt-6 mb-6' />
      <div className='text-base text-ink font-bold pb-2'>Audience</div>
      {campaignType === 'automated' ? (
        <div className='h-[40px] px-4 flex items-center bg-ice rounded-lg'>
          Target all my subscribed customers
        </div>
      ) : (
        <>
          <Select
            options={audienceTypes}
            placeholder='Select'
            value={type}
            disabled={viewOnly}
            onChange={value => {
              handleFormDataChange('campaign_audience', {
                key: 'type',
                newValue: value,
              })
            }}
          />
          {type === 'conditional' && (
            <div className='flex mt-4 items-center'>
              <Select
                className='w-[180px]'
                options={conditionalTypes}
                placeholder='Select'
                value={conditional_type}
                hideIndicator={true}
                disabled={viewOnly}
                onChange={value => {
                  handleFormDataChange('campaign_audience', {
                    key: 'conditional_type',
                    newValue: value,
                  })
                }}
              />
              {conditional_type === 'lifetime_order_count' && (
                <div className='flex items-center'>
                  <div className='w-[2px] h-[16px] bg-zinc mx-4' />
                  <div className='flex items-center text-base text-ink'>
                    Placed
                    <Select
                      className='mx-2 w-[74px]'
                      options={[
                        { value: 'more', label: 'More' },
                        { value: 'less', label: 'Less' },
                      ]}
                      placeholder='Select'
                      value={lifetime_order_count_operator}
                      hideIndicator={true}
                      disabled={viewOnly}
                      onChange={value => {
                        handleFormDataChange('campaign_audience', {
                          key: 'lifetime_order_count_operator',
                          newValue: value,
                        })
                      }}
                    />
                    than
                    <div
                      className='mx-2'
                      style={{
                        width:
                          typeof lifetime_order_count === 'string' &&
                          lifetime_order_count.length > 3
                            ? `${(lifetime_order_count.length - 3) * 9 + 64}px`
                            : '64px',
                      }}
                    >
                      <Field
                        name='lifetime_order_count'
                        type='text'
                        className='w-full text-right'
                        value={lifetime_order_count}
                        disabled={viewOnly}
                        onChange={e => {
                          handleFormDataChange('campaign_audience', {
                            key: 'lifetime_order_count',
                            newValue: e.target.value,
                          })
                        }}
                      />
                    </div>
                    order(s) in lifetime.
                  </div>
                  <div></div>
                </div>
              )}
              {conditional_type === 'recent_order_within' && (
                <div className='flex items-center'>
                  <div className='w-[2px] h-[16px] bg-zinc mx-4' />
                  <div className='flex items-center text-base text-ink'>
                    Ordered within
                    <div
                      className='mx-2'
                      style={{
                        width:
                          typeof recent_order_within === 'string' &&
                          recent_order_within.length > 3
                            ? `${(recent_order_within.length - 3) * 9 + 64}px`
                            : '64px',
                      }}
                    >
                      <Field
                        name='recent_order_within'
                        type='text'
                        className='w-full text-right'
                        value={recent_order_within}
                        disabled={viewOnly}
                        onChange={e => {
                          handleFormDataChange('campaign_audience', {
                            key: 'recent_order_within',
                            newValue: e.target.value,
                          })
                        }}
                      />
                    </div>
                    <Select
                      className='mx-2 w-[100px]'
                      options={dateTypes}
                      placeholder='Select'
                      value={recent_order_within_unit}
                      hideIndicator={true}
                      disabled={viewOnly}
                      onChange={value => {
                        handleFormDataChange('campaign_audience', {
                          key: 'recent_order_within_unit',
                          newValue: value,
                        })
                      }}
                    />
                    recently.
                  </div>
                  <div></div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      <Line className='my-6' />
      <div className='flex justify-between'>
        <Button color='secondary' className='mr-3 w-full' onClick={prevStep}>
          PREVIOUS STEP
        </Button>
        <Button
          color='primary'
          className='w-full'
          onClick={() => {
            if (campaignType === 'one_off' && !viewOnly) {
              const time = `${trigger_of_date} ${trigger_of_time}`
              const isAfterTime = moment(time).isAfter(moment())
              if (!isAfterTime) {
                toast.error('Please select a future time')
                return
              }
            }
            createOrUpdate()
          }}
          loading={stepLoading}
        >
          NEXT STEP
        </Button>
      </div>
      <ModalFull
        open={visible}
        title='Send A Test Email'
        toggle={() => {
          setVisible(!visible)
        }}
        okText='SEND NOW'
        onCancel={() => {
          setVisible(false)
        }}
        loading={loading}
        onOk={sendEmail}
      >
        <Field
          label='Send test email to'
          name='email'
          value={testEmail}
          onChange={e => setTestEmail(e.target.value)}
          placeholder='Input email address'
          type='email'
          error={error}
        />
      </ModalFull>
    </div>
  )
}
