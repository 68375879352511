import React, { useRef } from 'react'

import iphoneDevice from 'assets/img/iphone-device2.png'
import Field from 'components/Field'
import TextArea from 'components/TextArea'

import EmailPreview from './EmailPreview'
import Line from './Line'
import SetImage from './SetImage'

type TContentFormEmail = {
  emailSubject: string
  emailImageUrl: string
  emailHeadline: string
  emailContent: string
  enableCta: number
  couponCode: string
  offerType: string
  viewOnly?: boolean
  handleFormDataChange: (type: string, value: any) => void
}

export default function ContentFormEmail({
  emailSubject,
  emailImageUrl,
  emailContent,
  emailHeadline,
  enableCta,
  couponCode,
  offerType,
  viewOnly,
  handleFormDataChange,
}: TContentFormEmail): JSX.Element {
  const inputRef = useRef<any>()
  const insertTermsToContent = (text: string) => {
    if (inputRef && inputRef.current) {
      const insert = inputRef.current.selectionStart
      const newEmailContent =
        emailContent.substr(0, insert) + text + emailContent.substr(insert)
      if (newEmailContent.length <= 1000) {
        handleFormDataChange('email_content', newEmailContent)
      }
    }
  }
  return (
    <>
      <Line type='solid' className='mt-4 mb-4' />
      <div className='flex'>
        <div className='flex-1 mr-8'>
          <Field
            label='Email Subject'
            className='mb-6'
            name='Email Subject'
            type='text'
            placeholder='Write the email subject here'
            value={emailSubject}
            disabled={viewOnly}
            onChange={e =>
              handleFormDataChange('email_subject', e.target.value)
            }
          />
          <div className='mb-6'>
            <SetImage
              labelText='Message image'
              value={emailImageUrl}
              viewOnly={viewOnly}
              onChange={(preview, blob) => {
                if (!blob) {
                  handleFormDataChange('email_image_url', preview)
                } else {
                  handleFormDataChange('email_image', { blob, preview })
                }
              }}
              onRemove={() => {
                handleFormDataChange('remove_image', '')
              }}
            />
          </div>
          <Field
            label='Message headline'
            className='mb-6'
            name='Message headline'
            type='text'
            placeholder='Write your headline here'
            value={emailHeadline || ''}
            disabled={viewOnly}
            onChange={e =>
              handleFormDataChange('email_headline', e.target.value)
            }
          />
          <div>
            <div className='flex justify-between text-xs text-silver'>
              <div className='font-bold'>Message content</div>
              <div>
                ({emailContent ? emailContent.length : 0}/1000 characters)
              </div>
            </div>
            <div className='flex mt-2'>
              <div
                className='mr-2 bg-field text-silver cursor-pointer'
                onClick={() => insertTermsToContent('[first_name]')}
              >
                [first_name]
              </div>
              <div
                className='mr-2 bg-field text-silver cursor-pointer'
                onClick={() => insertTermsToContent('[last_name]')}
              >
                [last_name]
              </div>
              <div
                className='mr-2 bg-field text-silver cursor-pointer'
                onClick={() => insertTermsToContent('[restaurant_name]')}
              >
                [restaurant_name]
              </div>
            </div>
            <TextArea
              ref={inputRef}
              name='TextInput'
              containerClass='mt-2'
              inputClass='resize-none h-[206px]'
              placeholder='Write your content here'
              value={emailContent || ''}
              disabled={viewOnly}
              onChange={e => {
                if (e.target.value.length <= 1000) {
                  handleFormDataChange('email_content', e.target.value)
                }
              }}
            />
          </div>
        </div>
        <div className='w-[220px]'>
          <div className='text-silver text-xs font-bold mb-2'>
            Email message preview
          </div>
          <div
            className='w-[220px] h-[451px] bg-contain'
            style={{
              backgroundImage: `url(${iphoneDevice})`,
            }}
          >
            <div>
              <EmailPreview
                enableCta={enableCta}
                imgUrl={emailImageUrl}
                emailHeadline={emailHeadline}
                emailContent={emailContent}
                couponCode={couponCode}
                offerType={offerType}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
