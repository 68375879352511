import React, { useState, useRef } from 'react'

import Button from 'components/Button'
import ModalFull from 'components/Modal/ModalFull'
import StripCard from 'components/StripCard'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addCreditCardApi } from 'redux/accountAndPayout'

interface IModalTypes {
  disabled?: boolean
  onSuccess: () => void
}

export default function ModalUpdateBank({ disabled, onSuccess }: IModalTypes) {
  const { locationId = '' } = useParams<TParamTypes>()
  const formRef = useRef<any>()
  const [isVisible, setIsVisible] = useState(false)
  const [formStatus, setFormStatus] = useState({
    loading: false,
    error: '',
  })

  const handleSubmit = async () => {
    setFormStatus(prev => ({ ...prev, loading: true }))
    try {
      const stripData = await formRef.current?.getPaymentId()
      const res = await addCreditCardApi(
        stripData?.paymentMethod?.id,
        locationId,
      )
      toast.success(res.message)
      setIsVisible(false)
      onSuccess()
    } catch (e: any) {
      const msg = e?.error?.message || e.message || 'Request error'
      setFormStatus({ error: msg, loading: false })
    }
    setFormStatus(prev => ({ ...prev, loading: false }))
  }

  return (
    <>
      <Button
        color='secondary'
        size='sm'
        disabled={disabled}
        onClick={() => setIsVisible(true)}
      >
        UPDATE PAYMENT METHOD
      </Button>
      <ModalFull
        open={isVisible}
        title='Update Payment Card'
        loading={formStatus.loading}
        error={formStatus.error}
        toggle={() => setIsVisible(false)}
        onOk={() => {
          handleSubmit()
        }}
      >
        <div>
          <StripCard
            label='Input card information'
            ref={formRef}
            onChange={() => {
              setFormStatus(prev => ({ ...prev, error: '' }))
            }}
          />
        </div>
      </ModalFull>
    </>
  )
}
