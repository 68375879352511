import React from 'react'

import Button from 'components/Button'
import html2canvas from 'html2canvas'

import type { TFormData } from '../MarketingFlyer'
import MarketingRender from './MarketingRender'

type TMarketingFlyerShow = {
  formData: TFormData
  storeHostname: string
  imgUrl: string
  storeLogo: string
  storeName: string
  storeBanner: string | null
}

export default function MarketingFlyerShow({
  formData,
  storeHostname,
  imgUrl,
  storeLogo,
  storeName,
  storeBanner,
}: TMarketingFlyerShow): JSX.Element {
  const { color, title, description, pCode, couponDescription } = formData
  const download = () => {
    html2canvas(document.querySelector('#marketing-card') as HTMLElement, {
      useCORS: true,
    }).then(canvas => {
      const pngUrl = canvas.toDataURL('image/jpeg')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = 'MarketingCardTemplate.jpeg'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    })
  }
  return (
    <div className='p-8 w-[344px]'>
      <div className='flex justify-between items-center pb-4'>
        <div className='text-xl text-ink font-bold'>Flyer display</div>
        <div className='text-xs text-lead font-bold relative top-1'>
          Size: 4” X 6”
        </div>
      </div>
      <div className='w-[280px] h-[420px] rounded overflow-hidden'>
        <div className='w-[1120px] h-[1680px] bg-black scale-[0.25] origin-top-left '>
          <MarketingRender
            color={color}
            title={title}
            description={description}
            couponDescription={couponDescription}
            pCode={pCode}
            storeHostname={storeHostname}
            imgUrl={imgUrl}
            storeLogo={storeLogo}
            storeName={storeName}
            storeBanner={storeBanner}
          />
        </div>
      </div>
      <Button color='primary' className='w-full mt-8' onClick={download}>
        DOWNLOAD
      </Button>
      <div className='mt-3 text-xs px-5 text-center leading-normal text-lead'>
        You can also contact Rushable support at{' '}
        <a className='text-lead font-bold' href='mailto:support@rushable.io'>
          support@rushable.io
        </a>{' '}
        to order directly.
      </div>
      <div className='flex justify-between pt-4 px-9'>
        <div className='text-xs text-lead'>1000 pieces: $79</div>
        <div className='text-xs text-lead'>2000 pieces: $119</div>
      </div>
      <div className='w-[1120px] h-[1680px] fixed left-0 top-[-1680px]'>
        <div
          id='marketing-card'
          className='w-[1120px] h-[1680px] bg-black scale-[0.5] origin-top-left'
        >
          <MarketingRender
            color={color}
            title={title}
            description={description}
            couponDescription={couponDescription}
            pCode={pCode}
            storeHostname={storeHostname}
            imgUrl={imgUrl}
            storeLogo={storeLogo}
            storeName={storeName}
            storeBanner={storeBanner}
            canDownload
          />
        </div>
      </div>
    </div>
  )
}
