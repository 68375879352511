import React, { useEffect, useState } from 'react'

import {
  DuoToneInvoice,
  AngleDown,
  DuoToneItems,
  DuoToneBank,
} from '@rushable/icons'
import moment from 'moment'
import { currencyFormatter } from 'utils/digit'

type TOrderSummaryProp = {
  ranges: any
  status: string
  formData: any
  isPDF?: boolean
}

export default function OrderSummary({
  ranges,
  status,
  formData,
  isPDF,
}: TOrderSummaryProp): JSX.Element {
  const currencyPrice = (value: number) => {
    return currencyFormatter(value || 0, {
      decimalNum: 2,
      thousandsSeparator: ',',
      decimalSeparator: '.',
      symbol: ' $',
    })
  }

  return (
    <div className={'pt-2'}>
      <div>
        <p className='leading-none text-ink'>
          Total <span className='text-blue'>{formData.order_count} orders</span>{' '}
          from:
        </p>
        <div className={`flex mt-${isPDF ? '4' : '2'}`}>
          <div
            className={`h-[46px] bg-ice px-4 py-3 mr-2 rounded-lg ${
              isPDF ? 'leading-none' : 'flex items-center'
            }`}
          >
            {status === ''
              ? 'Any Status'
              : `${status.slice(0, 1).toUpperCase() + status.slice(1)}`}
          </div>
          <div
            className={`h-[46px] bg-ice px-4 py-3 rounded-lg ${
              isPDF ? 'leading-none' : 'flex items-center'
            }`}
          >
            {`${moment(ranges[0].startDate).format('MM/DD/YY')} - ${moment(
              ranges[0].endDate,
            ).format('MM/DD/YY')}`}
          </div>
        </div>
      </div>

      <div className='mt-6 py-6 px-4 rounded-lg border border-solid border-zinc leading-none'>
        <div className='relative ml-4 text-ink  flex justify-between mr-4 font-bold  border-b border-dashed border-zinc pb-2'>
          <div
            className={`h-6 pl-8 ${
              isPDF ? 'leading-[0.5]' : 'flex items-center'
            }`}
          >
            <div className='absolute left-0 flex justify-center items-center w-6 h-6 bg-zinc rounded'>
              <DuoToneInvoice size={24} />
            </div>
            Original order total
          </div>
          <div
            className={`h-6  ${isPDF ? 'leading-[0.5]' : 'flex items-center'}`}
          >
            {currencyPrice(formData.original_order_total)}
          </div>
        </div>
        <div
          className={`flex flex-col space-y-1 leading-snug ml-auto  mr-4 w-[195px] text-lead mt-${
            isPDF ? '0' : '2'
          }`}
        >
          <div className='flex justify-between'>
            Subtotal <span>{currencyPrice(formData.subtotal)}</span>
          </div>
          <div className='flex justify-between'>
            Discount <span>{currencyPrice(formData.discount)}</span>
          </div>
          <div className='flex justify-between'>
            Custom fee
            <span>{currencyPrice(formData.custom_fee)}</span>
          </div>
          <div className='flex justify-between'>
            Tax <span>{currencyPrice(formData.tax)}</span>
          </div>
          <div className='flex justify-between'>
            Delivery fee <span>{currencyPrice(formData.delivery_fee)}</span>
          </div>
          <div className='flex justify-between'>
            Restaurant tip <span>{currencyPrice(formData.restaurant_tip)}</span>
          </div>
          <div className='flex justify-between'>
            Refund <span>{currencyPrice(formData.refund)}</span>
          </div>
        </div>
        <div
          className={`w-full bg-slate rounded-lg  px-4  mt-${
            isPDF ? '6' : '6'
          } pt-${isPDF ? '2' : '4'} pb-${isPDF ? '6' : '4'}`}
        >
          <div
            className={`flex justify-between font-bold text-blue leading-snug border-b border-dashed border-zinc pb-${
              isPDF ? '4' : '2'
            }`}
          >
            Additional fees paid by customers
            <span>{currencyPrice(formData.customer_fee)}</span>
          </div>
          <div className='flex justify-between'>
            <p className='w-[192px] leading-snug mt-2 text-ink'>
              These fees are excluded and not counted towards your revenue
            </p>
            <div
              className={`flex flex-col space-y-1 leading-snug ml-auto w-[195px] text-lead mt-${
                isPDF ? '0' : '2'
              }`}
            >
              <div className='flex justify-between'>
                Convenience fee{' '}
                <span>{currencyPrice(formData.convenience_fee)}</span>
              </div>
              <div className='flex justify-between'>
                Courier fee{' '}
                <span>{currencyPrice(formData.courier_fee_customer)}</span>
              </div>
              <div className='flex justify-between'>
                Courier tip <span>{currencyPrice(formData.courier_tip)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex my-4 flex-col items-center'>
        <AngleDown className='text-blue' size={16} />
      </div>
      <div className='py-6 px-8 rounded-lg border border-solid border-zinc leading-none'>
        <div className='relative flex justify-between pb-2 border-b border-dashed border-zinc font-bold text-ink'>
          <div
            className={`h-6 pl-8  ${
              isPDF ? 'leading-[0.5]' : 'flex items-center'
            }`}
          >
            <div className='absolute left-0 flex justify-center items-center w-6 h-6 bg-zinc rounded'>
              <DuoToneItems size={24} />
            </div>
            Fees paid by your restaurant
          </div>
          <div
            className={`h-6  ${isPDF ? 'leading-[0.5]' : 'flex items-center'}`}
          >
            {currencyPrice(formData.restaurant_fee)}
          </div>
        </div>
        <div
          className={`flex flex-col space-y-1 leading-snug ml-auto w-[195px] text-lead mt-${
            isPDF ? '0' : '2'
          } mb-${isPDF ? '2' : '0'}`}
        >
          <div className='flex justify-between'>
            Dispatch fee <span>{currencyPrice(formData.dispatch_fee)}</span>
          </div>
          <div className='flex justify-between'>
            Courier fee
            <span>{currencyPrice(formData.courier_fee_restaurant)}</span>
          </div>
          <div className='flex justify-between'>
            Processing fee
            <span>{currencyPrice(formData.processing_fee)}</span>
          </div>
        </div>
      </div>
      <div className='flex my-4 flex-col items-center'>
        <AngleDown className='text-blue' size={16} />
      </div>
      <div className='px-8 rounded-lg border border-solid border-zinc'>
        <div className='py-6 relative flex justify-between font-bold text-ink'>
          <div
            className={`h-6 pl-8 ${
              isPDF ? 'leading-[0.5]' : 'flex items-center'
            }`}
          >
            <div className='absolute left-0 flex justify-center items-center w-6 h-6 bg-zinc rounded'>
              <DuoToneBank size={24} />
            </div>
            Net payout from selected orders
          </div>
          <div
            className={`h-6 pl-8 ${
              isPDF ? 'leading-[0.5]' : 'flex items-center'
            }`}
          >
            {currencyPrice(formData.net_payout)}
          </div>
        </div>
      </div>
      <div className='my-6 text-center'>
        ⚠️ Detailed payout report is available from{' '}
        <span className='text-blue'>{'Performance > Financial'}</span>
      </div>
    </div>
  )
}
