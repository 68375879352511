import React, { useEffect, useState } from 'react'

import { Loading } from '@rushable/icons'
import cn from 'classnames'
import Button from 'components/Button'
import Pagination, { SimplePagination } from 'components/Pagination'
import { TableFilter } from 'components/Select'

import styles from './Table.module.scss'

const defaultPageItems = [
  { label: '20 records per page', value: 20 },
  { label: '50 records per page', value: 50 },
  { label: '100 records per page', value: 100 },
  { label: '200 records per page', value: 200 },
]

export type TRowProp = {
  [index: string]: any
}

export type TCustomValueProp = any

export type TColumnProp = {
  key: string
  name: string
  align?: string
  custom?: (
    value: TCustomValueProp,
    row: TRowProp,
    index: number,
  ) => JSX.Element | null
  className?: string
  tdClassName?: string
  thClassName?: string
  sortable?: boolean
}

export type TTableProp = {
  isPageSet?: boolean
  loading?: boolean
  resizePageSize?: number
  setUpPageItems?: { value: number; label: string }[]
  paginationShow?: boolean
  simplePaginationShow?: boolean
  pageLoadMore?: boolean
  showTotal?: boolean
  total?: number
  totalText?: string
  current?: number
  columns: Array<TColumnProp>
  data: Array<TRowProp>
  containerClassName?: string
  className?: string
  bodyClassName?: string
  trClassName?: string
  theadClassName?: string
  clickedRow?: (row: TRowProp) => void
  theadtrClassName?: string
  theadTextClassName?: string
  rightButton?: React.ReactElement
  customFilter?: React.ReactElement
  onPageNoChange?: (value: any) => void
  onPageSizeChange?: (value: any) => void
  onClickLoadMore?: () => void
}

export default function Table({
  loading = false,
  isPageSet,
  resizePageSize,
  setUpPageItems,
  paginationShow = true,
  simplePaginationShow = false,
  pageLoadMore = false,
  showTotal = true,
  total = 0,
  totalText = '',
  current = 1,
  columns,
  data,
  containerClassName = 'rounded-lg border border-solid border-zinc overflow-hidden',
  className = 'min-w-full',
  bodyClassName,
  trClassName,
  theadClassName = 'mb-2',
  clickedRow,
  theadtrClassName,
  theadTextClassName,
  rightButton,
  customFilter,
  onPageNoChange,
  onPageSizeChange,
  onClickLoadMore,
}: TTableProp): JSX.Element {
  const [pageSize, setPageSize] = useState(20)
  const [sortIndex, setSortIndex] = useState(0)
  const [sortOrder, setSortOrder] = useState(0)
  const [tableData, setTableData] = useState(Array<TRowProp>())
  const [pageItems, setPageItems] = useState(defaultPageItems)

  useEffect(() => {
    setTableData(data)
  }, [data])
  useEffect(() => {
    if (sortOrder == 0) {
      return
    }
    setTableData(
      data
        .map(each => each)
        .sort(
          (a, b) =>
            sortOrder *
            (a[columns[sortIndex].key] < b[columns[sortIndex].key] ? 1 : -1),
        ),
    )
  }, [sortIndex, sortOrder])

  useEffect(() => {
    if (resizePageSize) {
      setPageSize(resizePageSize)
    }
  }, [resizePageSize])

  useEffect(() => {
    if (setUpPageItems && setUpPageItems.length > 0) {
      setPageItems(setUpPageItems)
      setPageSize(setUpPageItems[0].value)
    } else {
      setPageItems(defaultPageItems)
    }
  }, [setUpPageItems])

  const setSort = (index: number) => {
    if (index === sortIndex) {
      setSortOrder(((sortOrder + 2) % 3) - 1)
    } else {
      setSortIndex(index)
      setSortOrder(1)
    }
  }

  const pageSizeChange = (value: number) => {
    setPageSize(value)
    onPageSizeChange && onPageSizeChange(value)
  }
  return (
    <>
      {isPageSet && (
        <div className='mb-4 flex items-center'>
          <TableFilter
            placeholder='pageSize'
            className='w-52'
            value={pageSize}
            options={pageItems}
            onChange={value => pageSizeChange(value)}
          />
          {customFilter && <div className='ml-4'>{customFilter}</div>}
          {showTotal && (
            <div className='text-xs text-lead ml-4'>
              Showing {total} {totalText}
            </div>
          )}
          {rightButton && <div className='ml-auto'>{rightButton}</div>}
        </div>
      )}
      <div className={cn(containerClassName)}>
        <table className={cn(className, styles.table, styles.fixedHeader)}>
          <thead
            className={cn(
              'text-xs bg-field text-silver border-b border-zinc',
              theadClassName,
            )}
          >
            <tr className={theadtrClassName}>
              {columns.map((column: TColumnProp, index: number) => (
                <th
                  key={`th-${index}`}
                  scope='col'
                  className={cn(
                    'px-4 py-3',
                    column.className,
                    column.thClassName,
                  )}
                >
                  <div
                    className={cn(
                      theadTextClassName,
                      column.align ? 'text-' + column.align : 'text-left',
                    )}
                    onClick={() => column.sortable && setSort(index)}
                  >
                    {column.name}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody
            className={cn(
              'bg-white relative',
              { 'min-h-[160px]': loading && tableData.length === 0 },
              bodyClassName,
            )}
          >
            {tableData.length > 0 ? (
              <>
                {tableData.map((row: TRowProp, rowIndex: number) => (
                  <tr
                    key={`tr-${rowIndex}`}
                    className={cn(
                      'text-sm mt-2 hover:bg-slate',
                      { 'cursor-pointer': clickedRow },
                      { 'opacity-40': loading },
                      trClassName,
                    )}
                    onClick={() => {
                      clickedRow && clickedRow(row)
                    }}
                  >
                    {columns.map((column: TColumnProp, index: number) => (
                      <td
                        key={index}
                        className={cn(
                          'px-4 py-2.5',
                          column.className,
                          column.tdClassName,
                        )}
                      >
                        <div
                          className={cn(
                            column.align ? 'text-' + column.align : '',
                          )}
                        >
                          {column.custom
                            ? column.custom(
                                row[column.key],
                                Object.assign({ id: rowIndex }, row),
                                rowIndex,
                              )
                            : row[column.key]}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                {!loading && (
                  <td
                    className='px-4 py-2.5 text-center'
                    colSpan={columns.length}
                  >
                    No data
                  </td>
                )}
              </tr>
            )}
            {loading && (
              <tr
                className='w-full h-full absolute top-0 left-0'
                style={{
                  position: 'absolute',
                  border: 'none',
                }}
              >
                <td className='w-full h-full flex justify-center items-center'>
                  <div className='flex items-center'>
                    <Loading
                      className='text-dark-32 mr-2 animate-spin'
                      size={16}
                    />
                    <span className='text-dark-32'>loading...</span>
                  </div>
                  <div className='select-none pointer-events-none w-full h-full absolute  z-10'></div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {pageLoadMore && !!tableData.length && (
          <div className='mx-6 py-4'>
            <Button
              className='m-auto'
              color='tertiary'
              size='sm'
              loading={loading}
              onClick={onClickLoadMore}
            >
              LOAD MORE
            </Button>
          </div>
        )}
        {simplePaginationShow && (
          <SimplePagination className='mx-6 py-4' onChange={onPageNoChange} />
        )}
        {total > pageSize && paginationShow ? (
          <Pagination
            className='mx-6 py-4'
            current={current}
            total={total}
            pageSize={pageSize}
            onChange={onPageNoChange}
          />
        ) : null}
      </div>
    </>
  )
}
