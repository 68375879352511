import { ReactElement, useRef } from 'react'

import { Check, Loading, Minus } from '@rushable/icons'
import cn from 'classnames'

export type TCheckboxProp = {
  className?: string
  size?: number
  labelClass?: string
  children?: string | ReactElement | ReactElement[]
  shape?: 'square' | 'circle'
  checked?: boolean
  partial?: boolean // 部分选中
  disabled?: boolean
  loading?: boolean
  onChange?: (e: boolean) => void
}

export default function CheckBox({
  className = '',
  size = 24,
  labelClass,
  children = '',
  shape = 'square',
  disabled,
  checked,
  partial,
  loading,
  onChange,
}: TCheckboxProp): JSX.Element {
  const labelIdRef = useRef(Math.random() + '')
  const iconCls =
    'text-white absolute left-1/2	top-1/2	translate-x-50 translate-y-50 pointer-events-none'
  const iconSize = size * 0.75
  return (
    <div className={cn('flex items-center', className)}>
      {loading ? (
        <span
          className={cn('flex justify-center')}
          style={{ width: `${size}px`, height: `${size}px` }}
        >
          <Loading size={iconSize} />
        </span>
      ) : (
        <span
          className='relative flex'
          style={{ width: `${size}px`, height: `${size}px`, padding: '1px' }}
        >
          <input
            className={cn(
              'cursor-pointer appearance-none w-full h-full',
              shape === 'circle' ? 'rounded-full' : 'rounded',
              checked || partial
                ? 'bg-blue'
                : 'border-2 border-dark-16 bg-transparent',
              disabled ? 'opacity-50' : '',
            )}
            id={labelIdRef.current}
            type='checkbox'
            checked={checked}
            disabled={disabled}
            onChange={e => onChange?.(e.target.checked)}
          />
          {checked ? (
            <Check size={iconSize} className={iconCls} />
          ) : (
            partial && <Minus size={iconSize} className={iconCls} />
          )}
        </span>
      )}
      {children && (
        <label
          className={cn('pl-1.5 cursor-pointer', labelClass)}
          htmlFor={labelIdRef.current}
        >
          {children}
        </label>
      )}
    </div>
  )
}
